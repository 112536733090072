import { Typography, TableContainer, Paper, LinearProgress, Box } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { Timestamp } from "@bufbuild/protobuf";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ProjectContext } from "../App";

export const ReportOrgSummary = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [reportHTML, setReportBlob] = useState<string | null>(null);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [reportOffset, setReportOffset] = useState<number>(0);
  const project = useContext(ProjectContext);

  let refreshReport = () => {
    if (!project) {
      return;
    }

    var dates = getTimeframe(reportOffset);
    setReportBlob(null);
    client.generateOrgSummaryReport({ reportStart: dates.start, reportEnd: dates.end, projectId: project.id }).then(
      (res) => {
        setReportBlob(res.reportHtml);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
  };

  interface TimeframeDates {
    start: Timestamp;
    end: Timestamp;
  }

  const getTimeframe = useCallback((offset: number): TimeframeDates => {
    const duration = 7 * 24 * 60 * 60 * 1000;
    const today = new Date();
    var dates = {
      start: Timestamp.fromDate(new Date(today.getTime() - duration * (offset + 1))),
      end: Timestamp.fromDate(new Date(today.getTime() - duration * offset)),
    };
    return dates;
  }, []);

  const getTimeframeString = (offset: number): string => {
    var dates = getTimeframe(offset);
    return (
      new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
      }).format(dates.start?.toDate()) +
      " - " +
      new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
      }).format(dates.end?.toDate())
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    setReportOffset(Number(event.target.value));
  };

  useEffect(refreshReport, [client, project, reportOffset, getTimeframe]);

  // Set title
  useEffect(() => {
    document.title = "Summary Report - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Summary Report
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: "0px", display: "inline-block", marginRight: "10px", lineHeight: "40px" }}
      >
        Newly found security issues for
      </Typography>
      <FormControl sx={{ display: "inline-block", minWidth: 120 }} size="small">
        <Select id="timeframe" value={String(reportOffset)} onChange={handleChange}>
          <MenuItem value="0">{getTimeframeString(0)} (latest)</MenuItem>
          <MenuItem value="1">{getTimeframeString(1)}</MenuItem>
          <MenuItem value="2">{getTimeframeString(2)}</MenuItem>
        </Select>
      </FormControl>

      <TableContainer
        component={Paper}
        sx={{
          display: "block",
          marginTop: "20px",
          border: "1px solid #ddd",
          width: "100%",
        }}
      >
        {/* This content is only served from the backend and is considered safe – just pre-rendered.
            Don't let dangerouslySetInnerHTML scare you. It's the supported way to jam HTML into the DOM */}
        {reportHTML !== null && reportHTML !== "" && (
          <Box sx={{ padding: "20px" }} dangerouslySetInnerHTML={{ __html: reportHTML }}></Box>
        )}
        {reportHTML == null && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "block", width: "100%", marginTop: "30px", marginBottom: "10px", textAlign: "center" }}
            >
              Loading report...
            </Typography>
            <LinearProgress
              sx={{
                width: "200px",
                marginTop: "3px",
                marginRight: "auto",
                marginBottom: "30px",
                marginLeft: "auto",
              }}
            />
          </>
        )}
        {reportHTML === "" && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "block", width: "100%", marginTop: "30px", marginBottom: "30px", textAlign: "center" }}
            >
              No new issues in the timeperiod
            </Typography>
          </>
        )}
      </TableContainer>
    </BodyWrapperProjectScoped>
  );
};
