import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PartialMessage } from "@bufbuild/protobuf";
import { useNavigate } from "react-router-dom";
import { BodyWrapper } from "../components/BodyWrapper";
import { IntegrationSettings, IntegrationSettingsData } from "../components/IntegrationSettings";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { useClient } from "../api/client";
import { JiraIntegrationSettings, JiraProject } from "../pb/edgebit/platform/v1alpha/platform_pb";

type JiraSettings = {
  id: string;
  name: string;
  enabled: boolean;
  jiraProject?: JiraProject;
  jiraIssueTypeId: string;
  availProjects: JiraProject[];
};

export const JiraDetail = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const { intgId } = useParams();
  const [settings, setSettings] = useState<JiraSettings[]>([]);
  const [formError, setFormError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSettingsChange = (data: IntegrationSettingsData, projectIndex: number) => {
    settings[projectIndex] = { ...settings[projectIndex], ...data };
    setSettings([...settings]);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        // TODO: AuthenticatedApp() already does listProjects, use that.
        const projects = (await client.listProjects({})).projects;

        const install = await client.getJiraIntegration({
          integrationId: intgId,
        });

        const settings = projects.map((p): JiraSettings => {
          // locate the project in the install
          const s = install.settings.find((s) => s.projectId === p.id);
          const jiraProject = install.availJiraProjects.find((p) => p.id === s?.jiraProjectId);

          return {
            id: p.id,
            name: p.name,
            enabled: s !== undefined,
            jiraProject: jiraProject || install.availJiraProjects.at(0),
            jiraIssueTypeId: s?.jiraIssueTypeId || "10002", // Task
            availProjects: install.availJiraProjects,
          };
        });

        setSettings(settings);
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    fetch();
  }, [client, intgId]);

  const handleJiraProjectChange = (i: number, event: SelectChangeEvent) => {
    settings[i].jiraProject = settings[i].availProjects.find((p) => p.id === event.target.value);
    setSettings([...settings]);
  };

  const handleJiraIssueTypeChange = (i: number, event: SelectChangeEvent) => {
    settings[i].jiraIssueTypeId = event.target.value;
    setSettings([...settings]);
  };

  // Save Form
  const handleSave = async () => {
    setFormError(null);

    try {
      const pbSettings = settings.reduce((prev: PartialMessage<JiraIntegrationSettings>[], s) => {
        if (s.enabled) {
          prev.push({
            projectId: s.id,
            jiraProjectId: s.jiraProject?.id || "",
            jiraIssueTypeId: s.jiraIssueTypeId,
          });
        }
        return prev;
      }, []);

      await client.updateJiraIntegration({
        integrationId: intgId,
        settings: pbSettings,
      });

      navigate(`/admin/integrations/`);
    } catch (err) {
      console.log(err);
      setConnectivity(false);
    }
  };

  useEffect(() => {
    document.title = "Configure Jira Integration";
  });

  function MenuItemWithIcon(props: { value: string; icon: string }) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box component="img" src={props.icon} sx={{ maxWidth: "22px", maxHeight: "22px", alignSelf: "center" }} />
        <Box sx={{ fontSize: "14px" }}>{props.value}</Box>
      </Box>
    );
  }

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom sx={{ marginBottom: "30px" }}>
        Jira Settings
      </Typography>

      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>Project Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Preferences</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(settings.entries()).map(([i, proj]) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ verticalAlign: "top", lineHeight: "38px" }}>{proj.name}</TableCell>
                <TableCell>
                  <IntegrationSettings data={proj} projectIndex={i} onChange={handleSettingsChange} />
                </TableCell>
                <TableCell sx={{ minWidth: "300px" }}>
                  {proj.enabled ? (
                    <>
                      <FormControl
                        sx={{
                          minWidth: 120,
                          backgroundColor: "#fff",
                          marginRight: "10px",
                        }}
                        size="small"
                      >
                        <InputLabel>Jira Project</InputLabel>
                        <Select
                          name="jiraproject"
                          label="Jira Project"
                          value={proj.jiraProject?.id || ""}
                          onChange={(event: SelectChangeEvent) => handleJiraProjectChange(i, event)}
                        >
                          {proj.availProjects.map((p) => (
                            <MenuItem value={p.id} key={p.id}>
                              {p.name} ({p.key})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ minWidth: 120, backgroundColor: "#fff" }} size="small">
                        <InputLabel>Issue Type</InputLabel>
                        <Select
                          name="jiratype"
                          label="Issue Type"
                          value={proj.jiraIssueTypeId}
                          onChange={(event: SelectChangeEvent) => handleJiraIssueTypeChange(i, event)}
                          renderValue={(value) => {
                            const selected = proj.jiraProject?.availIssueTypes.find((t) => t.id === value);
                            return <MenuItemWithIcon value={selected?.name || ""} icon={selected?.iconUrl || ""} />;
                          }}
                        >
                          {proj.jiraProject?.availIssueTypes.map((issueType) => (
                            <MenuItem value={issueType.id} key={issueType.id}>
                              <MenuItemWithIcon value={issueType.name} icon={issueType.iconUrl} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <Typography variant="body1" sx={{ color: "#999", fontSize: "14px" }}>
                      Disabled
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {formError && (
        <Alert style={{ marginTop: 11 }} severity="error">
          {formError}
        </Alert>
      )}

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        onClick={handleSave}
        sx={{ marginTop: "20px", marginBottom: "0px" }}
      >
        Save Settings
      </EdgeBitPrimaryButton>
    </BodyWrapper>
  );
};
