import { ShieldOutlined } from "@mui/icons-material";
import Button, { ButtonProps } from "@mui/material/Button";

export const SSOLoginButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={<ShieldOutlined />}
      sx={{
        color: "#000",
        width: "335px",
        marginTop: "11px",
      }}
    >
      Sign in with SSO
    </Button>
  );
};
