// @generated by protoc-gen-connect-web v0.9.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/user_account.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetUserProfileRequest, GetUserProfileResponse, LogoutRequest, LogoutResponse } from "./user_account_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service edgebit.platform.v1alpha.UserAccountService
 */
export const UserAccountService = {
  typeName: "edgebit.platform.v1alpha.UserAccountService",
  methods: {
    /**
     * @generated from rpc edgebit.platform.v1alpha.UserAccountService.GetUserProfile
     */
    getUserProfile: {
      name: "GetUserProfile",
      I: GetUserProfileRequest,
      O: GetUserProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.UserAccountService.Logout
     */
    logout: {
      name: "Logout",
      I: LogoutRequest,
      O: LogoutResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

