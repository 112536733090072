import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from "@mui/icons-material";
import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState } from "react";
import { PackageType, packageDisplayName } from "../components/PackageType";
import VulnMultiIconDisplay from "../components/VulnMultiIconDisplay";
import { VulnSeverity } from "../pb/edgebit/platform/v1alpha/platform_pb";
import VulnChip from "./VulnChip";
import getStateText from "../utils/vuln_state";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import TextOverflow from "./TextOverflow";
import { SBOMInventoryPackage, GraphRelationship, SBOMInventoryVuln } from "../features/sbomDetailSlice";

export default function SBOMDetailPackageTable(props: {
  packages: SBOMInventoryPackage[] | undefined;
  relationships: GraphRelationship[] | undefined;
}) {
  const [packageFilterToggle, setPackageFilterToggle] = useState("all");
  const handlePackageFilterToggleChange = (event: React.MouseEvent<HTMLElement>, newToggle: string) => {
    setPackageFilterToggle(newToggle);
  };

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={packageFilterToggle}
          exclusive
          onChange={handlePackageFilterToggleChange}
          aria-label="Platform"
          sx={{ marginBottom: "10px", marginRight: "20px" }}
        >
          <ToggleButton value="all">All Packages</ToggleButton>
          <ToggleButton value="cveAny">All CVEs</ToggleButton>
          <ToggleButton value="cveCriticalHigh">Critical &amp; High CVEs</ToggleButton>
        </ToggleButtonGroup>
        <PackageTypeBreakdown packages={props.packages} filterCVE={packageFilterToggle} />
      </Box>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>License</TableCell>
              <TableCell>Severity Summary</TableCell>
            </TableRow>
          </TableHead>
          <PackageTableBody packages={props.packages} filterCVE={packageFilterToggle} />
        </Table>
      </TableContainer>
    </Container>
  );
}

function PackageTypeBreakdown(props: { packages: SBOMInventoryPackage[] | undefined; filterCVE: string }) {
  // Loading State
  if (props.packages === undefined) {
    return <Box></Box>;
  }

  // Empty State: No packages
  if (props.packages.length === 0) {
    return <Box></Box>;
  }

  // Empty State: no packages after filtering
  const filteredPackages = props.packages.filter((pkg: SBOMInventoryPackage) => showPackage(pkg, props.filterCVE));
  if (filteredPackages.length === 0) {
    return <Box></Box>;
  }

  // Loop through unique package types
  const packageTypeCounts = filteredPackages.reduce((acc: Record<string, number>, pkg) => {
    const packageType = pkg.packageType;
    if (acc.hasOwnProperty(packageType)) {
      acc[packageType]++;
    } else {
      acc[packageType] = 1;
    }
    return acc;
  }, {});

  // Add up total of all types
  let total = Object.values(packageTypeCounts).reduce((a, b) => a + b, 0);
  let colors = ["#6C95F8", "#4473D4", "#2457B1", "#163A8F", "#09216F", "#999", "#777", "#555", "#333", "#111"];
  let width = 300;

  return (
    <Box sx={{ width: width }}>
      <Box sx={{ height: "10px", background: "#ccc", width: width, display: "flex", borderRadius: "3px" }}>
        {Object.keys(packageTypeCounts)
          .sort((a, b) => packageTypeCounts[b] - packageTypeCounts[a])
          .map((type, index) => (
            <Tooltip
              key={type} // Move the key here
              title={`${packageDisplayName(type)} ${((packageTypeCounts[type] / total) * 100).toFixed(0)}%`}
              placement="bottom-start"
            >
              <Box
                sx={{
                  width: `${(packageTypeCounts[type] / total) * 100}%`,
                  height: "10px",
                  backgroundColor: colors[index % colors.length], // Cycle through colors if needed
                  display: "inline-block",
                }}
              ></Box>
            </Tooltip>
          ))}
      </Box>
      <Box
        sx={{ width: width, height: "20px", fontSize: "12px", display: "flex", marginTop: "5px", overflowY: "scroll" }}
      >
        <Box sx={{ display: "inline-block" }}>
          {Object.keys(packageTypeCounts)
            .sort((a, b) => packageTypeCounts[b] - packageTypeCounts[a])
            .map((type, index) => (
              <Box key={type} sx={{ display: "inline-block", paddingRight: "10px" }}>
                <Box
                  sx={{
                    backgroundColor: colors[index % colors.length], // Use index to cycle through colors
                    height: "7px",
                    width: "7px",
                    marginRight: "3px",
                    display: "inline-block",
                  }}
                ></Box>
                {packageDisplayName(type)} {((packageTypeCounts[type] / total) * 100).toFixed(0)}%
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

function PackageTableBody(props: { packages: SBOMInventoryPackage[] | undefined; filterCVE: string }) {
  // Expanded rows
  const [expandedRows, setExpandedRows] = useState(new Set<string>());

  const handlePkgExpansionToggled = (pkg: SBOMInventoryPackage) => {
    const expanded = new Set(expandedRows);
    if (expanded.has(pkg.packageId)) {
      expanded.delete(pkg.packageId);
    } else {
      expanded.add(pkg.packageId);
    }

    setExpandedRows(expanded);
  };

  // Loading State
  if (props.packages === undefined) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={6}>
            <Box
              sx={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "400px",
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                Analyzing Active SBOMs...
              </Typography>
              <LinearProgress
                sx={{
                  width: "200px",
                  marginTop: "3px",
                  marginRight: "auto",
                  marginBottom: "20px",
                  marginLeft: "auto",
                }}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  // Empty State: No packages
  if (props.packages.length === 0) {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No packages found in this SBOM
        </Typography>
      </EmptyState>
    );
  }

  // Empty State: no packages after filtering
  const filteredPackages = props.packages.filter((pkg: SBOMInventoryPackage) => showPackage(pkg, props.filterCVE));
  if (filteredPackages.length === 0) {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No matching vulnerabilities.
        </Typography>
      </EmptyState>
    );
  }

  return (
    <TableBody>
      {filteredPackages.map((pkg: SBOMInventoryPackage) => (
        <React.Fragment key={pkg.packageId}>
          <TableRow key={pkg.packageId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>
              <Link
                component="button"
                sx={{
                  color: "#000",
                  textDecoration: "none",
                  verticalAlign: "unset",
                  textAlign: "left",
                }}
                onClick={() => handlePkgExpansionToggled(pkg)}
              >
                {expandedRows.has(pkg.packageId) ? (
                  <IndeterminateCheckBoxOutlined
                    fontSize="inherit"
                    sx={{
                      color: "#999",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                ) : (
                  <AddBoxOutlined
                    fontSize="inherit"
                    sx={{
                      color: "#999",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                )}
                <TextOverflow max={28} text={pkg.packageName} />
              </Link>
            </TableCell>
            <TableCell>
              <TextOverflow max={25} text={pkg.packageVersion} />
            </TableCell>
            <TableCell>
              <PackageType type={pkg.packageType} name={true} />
            </TableCell>
            <TableCell>
              {pkg.license?.clauses.map((clause) => (
                <React.Fragment key={JSON.stringify(clause)}>
                  {clause.terms.length > 1 && (
                    <Tooltip title={clause.terms.join(", ")} placement="bottom-start">
                      <Box sx={{ "&:hover": { cursor: "pointer" } }}>
                        <FilterNoneIcon
                          sx={{
                            fontSize: "16px",
                            paddingRight: "3px",
                            verticalAlign: "middle",
                          }}
                        />
                        Multiple
                      </Box>
                    </Tooltip>
                  )}
                  {clause.terms.length === 1 && <TextOverflow max={20} text={clause.terms[0]} />}
                  {!clause.terms.length && <Box sx={{ display: "inline", color: "#999" }}>Not detected</Box>}
                </React.Fragment>
              ))}
            </TableCell>
            <TableCell>
              {pkg.vulns.length > 0 ? (
                <VulnMultiIconDisplay
                  vulns={pkg.vulns}
                  showVulnSeverity={true}
                  showComponentVulns={false}
                ></VulnMultiIconDisplay>
              ) : (
                <VulnMultiIconDisplay
                  vulns={[]}
                  showVulnSeverity={true}
                  showComponentVulns={false}
                ></VulnMultiIconDisplay>
              )}
            </TableCell>
          </TableRow>
          {expandedRows.has(pkg.packageId) && (
            <TableRow key={"expand" + pkg.packageId}>
              <TableCell colSpan={5} sx={{ padding: "0", background: "#fafafa", borderBottom: "0" }}>
                <Table
                  size="small"
                  aria-label="simple table"
                  sx={{
                    borderBottom: "0",
                    marginLeft: "0px",
                    background: "#fafafa",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                      >
                        Evident By
                      </TableCell>
                      {/* Hiding this until this is populated in the API */}
                      {/* <TableCell sx={{fontSize: '12px', paddingTop: '2px', paddingBottom: '2px'}}>Installed By</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          whiteSpace: "nowrap",
                          maxWidth: "200px",
                        }}
                      >
                        {pkg.evidence?.evidentBy.map((location) => <Box>{location}</Box>)}
                        {pkg.evidence?.evidentBy.length === 0 && <Box>No evidence found in SBOM</Box>}
                      </TableCell>
                      {/* Hiding this until this is populated in the API */}
                      {/* <TableCell sx={{fontSize: '12px', paddingTop: '2px', paddingBottom: '2px', whiteSpace: 'nowrap', maxWidth: '200px'}}>
                        {pkg.evidence?.installedBy.map((location) => (
                          <Box>{location}</Box>
                        ))}
                        {(pkg.evidence?.installedBy.length === 0) && (
                          <Box>No install evidence found in SBOM</Box>
                        )}
                      </TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
                <Table
                  size="small"
                  aria-label="simple table"
                  sx={{
                    borderBottom: "0",
                    marginLeft: "0px",
                    background: "#fafafa",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                      >
                        CVE
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                      >
                        Severity
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                        }}
                      >
                        Desciption
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pkg.vulns?.map((vuln: SBOMInventoryVuln) => (
                      <React.Fragment key={vuln.vulnerabilityId}>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              whiteSpace: "nowrap",
                              maxWidth: "200px",
                            }}
                          >
                            <Link href={vuln.references[0].url}>{vuln.vulnerabilityId}</Link>
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                            }}
                          >
                            <VulnChip preset={VulnSeverity[vuln.severity]} innerText={getStateText(vuln)} />
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              maxWidth: "500px",
                            }}
                          >
                            {vuln.summary ? vuln.summary : <Box sx={{ color: "#999" }}>No description included</Box>}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                    {pkg.vulns?.length === 0 && (
                      <React.Fragment key={pkg.packageId + "empty"}>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            sx={{
                              fontSize: "12px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              whiteSpace: "nowrap",
                              maxWidth: "200px",
                            }}
                          >
                            No vulnerabilities found in this package
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </TableBody>
  );
}

function EmptyState(props: { children: any }) {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={6}>
          <Box
            sx={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              width: "550px",
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {props.children}
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export function showPackage(pkg: SBOMInventoryPackage, filterCVE: string) {
  // Find if any vulns match our filter
  if (filterCVE === "cveCriticalHigh") {
    var include = false;
    pkg.vulns.forEach((vuln) => {
      if (
        vuln.severity.valueOf() === VulnSeverity.CRITICAL.valueOf() ||
        vuln.severity.valueOf() === VulnSeverity.HIGH.valueOf()
      ) {
        include = true;
      }
    });
    if (!include) return false;
  } else if (filterCVE === "cveAny") {
    if (pkg.vulns.length === 0) return false;
  }

  return true;
}
