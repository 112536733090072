import { Code } from "@bufbuild/connect";
import { Alert, Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { buildSSOAuthURL, useUnauthenticatedClient } from "../api/client";
import { AuthContext } from "../components/AuthContextProvider";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import { LoginService } from "../pb/edgebit/platform/v1alpha/login_connectweb";
import { LoginMethod } from "../pb/edgebit/platform/v1alpha/login_pb";
import { GoogleLoginButton } from "../components/GoogleLoginButton";
import { SSOLoginButton } from "../components/SSOLoginButton";
import { sanitizeNextPath, signupReflector } from "../utils/next_urls";

/**
 * Login page.
 *
 * Login is responsible for retrieving the login method for this organization
 * and rendering the appropriate login form.
 */
export const Login = () => {
  const client = useUnauthenticatedClient(LoginService);
  const { transport } = useContext(AuthContext);
  const navigate = useNavigate();

  // We'll look for a "next" query parameter to redirect to after login.
  const [queryParams] = useSearchParams();
  const location = useLocation();
  const next = sanitizeNextPath(queryParams.get("next") || location?.state?.next);

  useEffect(() => {
    if (transport) {
      navigate(next);
    }
  }, [transport, navigate, next]);

  const fromLogout = location?.state?.fromLogout ? true : false;

  const [formLoadError, setFormLoadError] = useState<string | null>(null);
  const [loginMethod, setLoginMethod] = useState<LoginMethod | null>(null);

  useEffect(() => {
    client.getLoginMethod({}).then(
      (res) => {
        setLoginMethod(res.method);
      },
      (err) => {
        if (err.code === Code.InvalidArgument) {
          setFormLoadError(err.rawMessage);
        } else {
          setFormLoadError("Unknown error");
        }
      },
    );
  }, [client]);

  useEffect(() => {
    document.title = "Login to EdgeBit";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {formLoadError ? (
          <LoginFormWrapper>
            <FriendlyError error={formLoadError} next={next} />
          </LoginFormWrapper>
        ) : (
          <LoginForm loginMethod={loginMethod} next={next} fromLogout={fromLogout} />
        )}
      </Box>
    </BodyWrapper>
  );
};

/**
 * LoginForm examines the specified loginMethod and renders the appropriate form.
 *
 * If loginMethod is null, LoginForm will render a loading indicator.
 */
const LoginForm = (props: { loginMethod: LoginMethod | null; next: string; fromLogout: boolean }) => {
  switch (props.loginMethod) {
    case LoginMethod.PASSWORD:
      return PasswordLoginForm(props);
    case LoginMethod.OIDC:
      return SSOLoginForm(props);
    case LoginMethod.GOOGLE:
      return GoogleLoginForm(props);
    case LoginMethod.OKTA_OIDC:
      return SSOLoginForm(props);
  }

  return (
    <Typography variant="h4" sx={{ color: "#fff" }}>
      Loading....
    </Typography>
  );
};

const FriendlyError = (props: { error: string; next: string }) => {
  switch (props.error) {
    case "organization not found":
      return (
        <>
          <Alert severity="error">{props.error}</Alert>
          <Typography variant="h4" gutterBottom sx={{ marginTop: "20px" }}>
            Need to create an account?
          </Typography>
          <EdgeBitPrimaryButton
            type="button"
            variant="outlined"
            size="large"
            sx={{ marginTop: "30px", marginBottom: "10px" }}
            onClick={() => {
              window.location.href = `${signupReflector()}/signup?next=${props.next}`;
            }}
          >
            Create new Account
          </EdgeBitPrimaryButton>
        </>
      );
    default:
      return (
        <>
          <Typography variant="h4" gutterBottom>
            Something Went Wrong...
          </Typography>
          <Alert severity="error">{props.error}</Alert>
        </>
      );
  }
};

const SSOLoginForm = (props: { next: string; fromLogout: boolean }) => {
  return <BaseSSOLoginForm {...props} buttonType={SSOLoginButton} />;
};

const GoogleLoginForm = (props: { next: string; fromLogout: boolean }) => {
  return <BaseSSOLoginForm {...props} buttonType={GoogleLoginButton} />;
};

interface ClickableComponentProps {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const BaseSSOLoginForm = (props: {
  next: string;
  fromLogout: boolean;
  buttonType: React.ComponentType<ClickableComponentProps>;
}) => {
  const authURL = buildSSOAuthURL(props.next);

  // If this login form was redirected to from the logout page, don't
  // immediately redirect back into the SSO flow, which might simply
  // auto-complete and log the user back in. Instead, show a button.
  if (!props.fromLogout) {
    window.location.href = authURL;
    return null;
  }

  function handleClick() {
    window.location.href = authURL;
  }

  return (
    <LoginFormWrapper>
      <Typography variant="h4" gutterBottom>
        Login to EdgeBit
      </Typography>
      <props.buttonType onClick={handleClick} />
      <Typography variant="subtitle2" gutterBottom sx={{ fontSize: "11px", textAlign: "left", marginTop: "15px" }}>
        By using EdgeBit, you are agreeing to our{" "}
        <a target="_blank" href="https://edgebit.io/legal/privacy" rel="noreferrer">
          privacy policy
        </a>{" "}
        and{" "}
        <a target="_blank" href="https://edgebit.io/legal/terms" rel="noreferrer">
          terms of use
        </a>
        .
      </Typography>
    </LoginFormWrapper>
  );
};

const PasswordLoginForm = (props: {}) => {
  const { setAuthToken } = useContext(AuthContext);

  const client = useUnauthenticatedClient(LoginService);

  const [formError, setFormError] = useState<string | null>(null);
  const [loginFormState, setLoginFormState] = useState({
    email: "",
    password: "",
  });

  const handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormError(null);
    client
      .passwordLogin({
        email: loginFormState.email,
        password: loginFormState.password,
      })
      .then(
        (res) => {
          setAuthToken(res.token);
        },
        (err) => {
          if (err.code === Code.InvalidArgument) {
            setFormError(`Error: ${err.rawMessage}`);
          } else {
            setFormError("Unknown error");
          }
        },
      );
  };

  return (
    <LoginFormWrapper>
      <Typography variant="h4" gutterBottom>
        Login to EdgeBit
      </Typography>
      <Box
        component="form"
        sx={{
          "& > :not(style)": {},
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleLoginSubmit}
      >
        <EdgeBitTextField
          label="Work Email"
          placeholder="you@company.com"
          id="email"
          variant="filled"
          autoComplete="email"
          autoFocus
          style={{ marginTop: 11, width: "355px" }}
          value={loginFormState.email}
          onChange={(e) => setLoginFormState({ ...loginFormState, email: e.target.value })}
        />
        <EdgeBitTextField
          label="Password"
          placeholder="•••••••"
          id="password"
          type="password"
          variant="filled"
          autoComplete="current-password"
          style={{ marginTop: 11, width: "355px" }}
          value={loginFormState.password}
          onChange={(e) => setLoginFormState({ ...loginFormState, password: e.target.value })}
        />
        {formError && (
          <Alert style={{ marginTop: 11 }} severity="error">
            {formError}
          </Alert>
        )}
        <Typography variant="subtitle2" gutterBottom sx={{ fontSize: "11px", textAlign: "left", marginTop: "15px" }}>
          By using EdgeBit, you are agreeing to our{" "}
          <a target="_blank" href="https://edgebit.io/legal/privacy" rel="noreferrer">
            privacy policy
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://edgebit.io/legal/terms" rel="noreferrer">
            terms of use
          </a>
          .
        </Typography>
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="large"
          sx={{ marginTop: "30px", marginBottom: "10px" }}
        >
          Log in
        </EdgeBitPrimaryButton>
      </Box>
    </LoginFormWrapper>
  );
};

const LoginFormWrapper = (props: { children: React.ReactNode }) => {
  return (
    <>
      <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
      <Box
        sx={{
          textAlign: "center",
          background: "#fff",
          borderRadius: "5px",
          padding: "40px 40px 30px 40px",
          maxWidth: "435px",
        }}
      >
        {props.children}
      </Box>
    </>
  );
};
