import { Paper, LinearProgress, Box, Typography, Link, Button, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState } from "react";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { Timestamp } from "@bufbuild/protobuf";
import { ProjectContext } from "../App";
import {
  ComponentIssueState,
  GenerateComponentIssuesReportItem,
  VulnSeverity,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import FormattedTimestamp from "../components/FormattedTimestamp";
import VulnChip from "../components/VulnChip";
import { NavLink } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { downloadFile } from "../api/files";

export const ReportCompliance = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [reportIssues, setReportIssues] = useState<any[] | undefined>([]);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const project = useContext(ProjectContext);

  let refreshReport = () => {
    if (!project) {
      return;
    }

    if (startDate == null || endDate == null) {
      return;
    }

    setReportIssues(undefined);
    client
      .generateComponentIssuesReport({
        reportStart: Timestamp.fromDate(startDate?.toDate()),
        reportEnd: Timestamp.fromDate(endDate?.toDate()),
        projectId: project.id,
      })
      .then(
        (res) => {
          setReportIssues(res.items);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
  };

  const handleReportDownload = (startDate: Dayjs | null, endDate: Dayjs | null) => {
    if (!project) {
      return;
    }
    if (startDate == null || endDate == null) {
      return;
    }
    var windowUrl = "";
    client
      .getComponentIssuesReportDownloadUrl({
        projectId: project.id,
        startDate: startDate?.format("YYYY-MM-DD"),
        endDate: endDate?.format("YYYY-MM-DD"),
      })
      .then(
        (res) => {
          windowUrl = res.reportDownloadPath;
          downloadFile(windowUrl);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
  };

  const stateNames: Record<ComponentIssueState, string> = {
    [ComponentIssueState.UNSPECIFIED]: "Unknown", // This should never happen
    [ComponentIssueState.IGNORED]: "Ignored",
    [ComponentIssueState.OPEN]: "Open",
    [ComponentIssueState.RESOLVED]: "Resolved",
  };
  const vulnSentenceCase = (vulnSeverityString: string): string => {
    return vulnSeverityString.charAt(0).toUpperCase() + vulnSeverityString.slice(1).toLowerCase();
  };

  useEffect(refreshReport, [client, project, endDate, startDate]);

  // Set title
  useEffect(() => {
    document.title = "Summary Report - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Compliance Report
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: "0px", display: "inline-block", marginRight: "10px", lineHeight: "40px" }}
      >
        Report Start:
      </Typography>
      <DatePicker
        value={startDate}
        onChange={(newValue) => setStartDate(newValue)}
        defaultValue={dayjs().subtract(1, "month")}
        slotProps={{ textField: { size: "small" } }}
      />
      <Typography
        variant="body1"
        sx={{
          marginBottom: "0px",
          display: "inline-block",
          marginRight: "10px",
          lineHeight: "40px",
          marginLeft: "30px",
        }}
      >
        Report End:
      </Typography>
      <DatePicker
        value={endDate}
        onChange={(newValue) => setEndDate(newValue)}
        slotProps={{ textField: { size: "small" } }}
      />
      <Button
        variant="outlined"
        onClick={() => handleReportDownload(startDate, endDate)}
        sx={{ height: "40px", verticalAlign: "top", marginLeft: "30px" }}
      >
        Download CSV
      </Button>

      <TableContainer
        component={Paper}
        sx={{
          display: "block",
          marginTop: "20px",
          border: "1px solid #ddd",
          width: "100%",
        }}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Component</TableCell>
              <TableCell>Issue #</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Package</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Fix Version</TableCell>
              <TableCell>Vulnerability ID</TableCell>
              <TableCell>First Observed</TableCell>
              <TableCell>Fix By</TableCell>
              <TableCell>Fixed On</TableCell>
              <TableCell>SLA</TableCell>
              <TableCell>Links</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportIssues !== undefined &&
              reportIssues.map((row: GenerateComponentIssuesReportItem) => (
                <TableRow key={row.componentRef?.name + row.issueId + row.packageName}>
                  <TableCell>
                    <Link component={NavLink} to={"/components/" + row.componentRef?.id + "/vulnerabilities"}>
                      {row.componentRef?.displayName}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ minWidth: "80px" }}>
                    <Link component={NavLink} to={"/components/" + row.componentRef?.id + "/issues/" + row.issueId}>
                      {row.issueId}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <VulnChip
                      preset={VulnSeverity[row.severity]}
                      innerText={vulnSentenceCase(VulnSeverity[row.severity])}
                    ></VulnChip>
                  </TableCell>
                  <TableCell>{row.packageName}</TableCell>
                  <TableCell>{stateNames[row?.state]}</TableCell>
                  <TableCell sx={{ minWidth: "100px" }}>
                    {row.fixVersions.length === 0 && <Box sx={{ color: "#999" }}>No data</Box>}
                    {row.fixVersions.length === 1 && <Box>{row.fixVersions}</Box>}
                    {row.fixVersions.length > 1 && (
                      <Box>
                        {row.fixVersions.map((version) => (
                          <Box key={version}>{version}</Box>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: "190px" }}>{row.vulnId}</TableCell>
                  <TableCell sx={{ minWidth: "190px" }}>
                    <FormattedTimestamp timestamp={row.createdAt} />
                  </TableCell>
                  <TableCell sx={{ minWidth: "190px" }}>
                    {row.dueAt !== undefined ? (
                      <FormattedTimestamp timestamp={row.dueAt} />
                    ) : (
                      <Box sx={{ color: "#999" }}>No date</Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: "190px" }}>
                    {row.state === ComponentIssueState.RESOLVED && <FormattedTimestamp timestamp={row.updatedAt} />}
                    {row.state === ComponentIssueState.OPEN && <Box sx={{ color: "#999" }}>Open</Box>}
                    {row.state === ComponentIssueState.IGNORED && "Ignored"}
                  </TableCell>
                  <TableCell>
                    {row.state === ComponentIssueState.RESOLVED &&
                      row.updatedAt !== undefined &&
                      row.dueAt !== undefined &&
                      row.updatedAt <= row.dueAt && <Box>Met</Box>}
                    {row.state === ComponentIssueState.RESOLVED &&
                      row.updatedAt !== undefined &&
                      row.dueAt !== undefined &&
                      row.updatedAt > row.dueAt && <Box>Overdue</Box>}
                    {row.state === ComponentIssueState.OPEN && <Box sx={{ color: "#999" }}>Open</Box>}
                    {row.state === ComponentIssueState.IGNORED && <Box sx={{ color: "#999" }}>Ignored</Box>}
                  </TableCell>
                  <TableCell sx={{ minWidth: "150px" }}>
                    {Object.keys(row.links).map((key) => (
                      <Link href="{row.links[key]}" key={key} sx={{ display: "block" }}>
                        {key}
                      </Link>
                    ))}
                    {Object.keys(row.links).length === 0 && <Box sx={{ color: "#999" }}>None</Box>}
                  </TableCell>
                  <TableCell sx={{ minWidth: "250px" }}>
                    {row.notes !== "" ? (
                      <Tooltip title={row.notes} placement="left">
                        <Box
                          sx={{ width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          {row.notes}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box sx={{ color: "#999" }}>None</Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {(reportIssues !== undefined && reportIssues.length) === 0 && (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
                      No issues found for this time period
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {reportIssues === undefined && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "650px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      Generating report...
                    </Typography>
                    <LinearProgress
                      sx={{
                        width: "200px",
                        marginTop: "3px",
                        marginRight: "auto",
                        marginBottom: "20px",
                        marginLeft: "auto",
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </BodyWrapperProjectScoped>
  );
};
