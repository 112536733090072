import { Alert, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { ProjectContext } from "../App";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { ComponentSettings, ComponentSettingsData } from "../components/ComponentSettings";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { ComponentType, SBOMGenerationSource, VCSImport } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { useNavigate } from "react-router-dom";
import { Code } from "@bufbuild/connect";
import { PartialMessage } from "@bufbuild/protobuf";

export const ComponentCreate = () => {
  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const project = useContext(ProjectContext);
  const queryParams = new URLSearchParams(window.location.search);
  const integrationId = queryParams.get("integration-id") || undefined;
  const owner = queryParams.get("owner") || undefined;
  const repoName = queryParams.get("repo") || undefined;
  const defaultSBOMSources = useMemo<SBOMGenerationSource[]>(
    () => [SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED, SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE],
    [],
  );
  const initFormInputs = useRef<ComponentSettingsData>({
    name: queryParams.get("name") || undefined,
    displayName: queryParams.get("display-name") || undefined,
    sourceRepository: queryParams.get("repo-url") || undefined,
    selectedSBOMSource: queryParams.get("repo-url") !== null ? 1 : undefined,
    availSBOMSources: queryParams.get("repo-url") !== null ? defaultSBOMSources : undefined,
    autoUpdateName: true,
  });

  const [formInputs, setFormInputs] = useState<ComponentSettingsData>({
    ...initFormInputs.current,
  });
  const [formError, setFormError] = useState<string | null>(null);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (project === null || integrationId === undefined || owner === undefined || repoName === undefined) {
      return;
    }

    const promise = async () => {
      try {
        const result = await client.analyzeGitHubRepo({
          projectId: project?.id,
          integrationId: integrationId,
          owner: owner,
          name: repoName,
        });

        const available = result.buildActionPresent
          ? [...defaultSBOMSources, SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD]
          : defaultSBOMSources;

        const selected = result.buildActionPresent
          ? SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD
          : SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE;

        setFormInputs({
          ...initFormInputs.current,
          availSBOMSources: available,
          selectedSBOMSource: selected,
        });
      } catch (err) {
        console.log(err);
        setConnectivity(false);
      }
    };

    promise();
  }, [client, project, integrationId, owner, repoName, defaultSBOMSources]);

  // Save Form
  const handleComponentSave = () => {
    setFormError(null);

    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    const vcsImport: PartialMessage<VCSImport> | undefined =
      formInputs.selectedSBOMSource !== undefined
        ? {
            kind: {
              case: "github",
              value: {
                integrationId: integrationId,
                owner: owner,
                name: repoName,
                url: formInputs.sourceRepository,
                sbomSource: formInputs.selectedSBOMSource,
              },
            },
          }
        : undefined;

    client
      .createComponent({
        projectId: project.id,
        name: formInputs.name,
        displayName: formInputs.displayName,
        type: ComponentType.SBOM_COLLECTION,
        sourceRepository: formInputs.sourceRepository,
        vcsImport: vcsImport,
      })
      .then(
        (res) => {
          console.log(res);
          navigate(`/components/${res.component?.id}`);
        },
        (err) => {
          if (err.code === Code.InvalidArgument) {
            setFormError(err.rawMessage);
          } else {
            setFormError("Unknown error");
          }
        },
      );
  };

  useEffect(() => {
    document.title = "Create Component";
  });

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom sx={{ marginBottom: "30px" }}>
        Create Component
      </Typography>
      <ComponentSettings data={formInputs} onChange={setFormInputs} />
      <Box
        sx={{
          borderTop: "1px solid #ddd",
          marginTop: "10px",
          paddingTop: "10px",
        }}
      >
        {formError && (
          <Alert style={{ marginTop: 11 }} severity="error">
            {formError}
          </Alert>
        )}
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          onClick={handleComponentSave}
          sx={{ marginTop: "20px", marginBottom: "0px" }}
        >
          Create Component
        </EdgeBitPrimaryButton>
      </Box>
    </BodyWrapperProjectScoped>
  );
};
