import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SBOMFormat, ImageExternalIDType, Image as PBImage } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { RootState, ThunkExtra } from "../app/store";

export interface GenericImage {}

export interface DockerImage {
  tag: string;
}

export interface AMI {
  name: string;
  tags: string[];
}

export type Image =
  | { case: "generic"; value: GenericImage }
  | { case: "docker"; value: DockerImage }
  | { case: "ami"; value: AMI }
  | undefined;

export function imageFromPB(pb: PBImage | undefined): Image {
  switch (pb?.kind.case) {
    case "generic":
      return { case: "generic", value: {} };
    case "docker":
      return { case: "docker", value: { ...pb.kind.value } };
    case "ami":
      return { case: "ami", value: { ...pb.kind.value } };
    default:
      return undefined;
  }
}

export interface ComponentRef {
  id: string;
  name: string;
  displayName: string;
}

export interface MachineRef {
  id: string;
  hostname: string;
}

export interface ExternalImageID {
  type: ImageExternalIDType;
  id: string;
}

export interface SBOMResponse {
  id: string;
  projectId: string;
  format: SBOMFormat;
  labels: { [key: string]: string };
  imageId: string;
  image?: Image;
  sourceRepoUrl: string;
  sourceCommitId: string;
  createdAt?: string;
  machineId: string;
  componentRef?: ComponentRef;
  machineRef?: MachineRef;
  pullRequest: string;
  imageIds: ExternalImageID[];
}

export interface VulnReference {
  url: string;
}

export interface SBOMInventoryVuln {
  vulnerabilityId: string;
  summary: string;
  severity: number; // VulnSeverity.UNSPECIFIED;
  references: VulnReference[];
  fixState: number; // VulnFixState.UNSPECIFIED;
  fixVersions: string[];
}

export interface InstallationEvidence {
  evidentBy: string[];
  installedBy: string[];
}

export interface LicenseClause {
  terms: string[];
}

export interface LicenseExpr {
  clauses: LicenseClause[];
}

export interface SBOMInventoryPackage {
  packageId: string;
  packageName: string;
  packageType: string;
  packageVersion: string;
  vulns: SBOMInventoryVuln[];
  evidence?: InstallationEvidence;
  license?: LicenseExpr;
}

export interface GraphRelationship {
  id: string;
  relatedId: string;
  relationshipType: string;
}

export interface SBOMInventoryResponse {
  packages: SBOMInventoryPackage[];
  relationships: GraphRelationship[];
}

interface SBOMState {
  sbomDetail: SBOMResponse | null;
  sbomInventory: SBOMInventoryResponse | null;
  downloadUrl: string | null;
  connectivity: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: SBOMState = {
  sbomDetail: null,
  sbomInventory: null,
  downloadUrl: null,
  connectivity: true,
  status: "idle",
};

export const fetchSBOMDownloadUrl = createAsyncThunk<string, { projectId: string; sbomId: string }, ThunkExtra>(
  "sbom/fetchDownloadUrl",
  async ({ projectId, sbomId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getSBOMDownloadUrl({ projectId, sbomId });
      return response.sbomDownloadPath;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchSBOMDetails = createAsyncThunk<SBOMResponse, { projectId: string; sbomId: string }, ThunkExtra>(
  "sbom/fetchDetails",
  async ({ projectId, sbomId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getSBOM({ projectId, id: sbomId });

      const sbomResponse = {
        ...response,
        image: imageFromPB(response.image),
        componentRef: response.componentRef ? { ...response.componentRef } : undefined,
        machineRef: response.machineRef ? response.machineRef : undefined,
        imageIds: response.imageIds,
        createdAt: response.createdAt ? String(response.createdAt.toDate()) : "",
      };

      return sbomResponse as SBOMResponse;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchSBOMInventory = createAsyncThunk<
  SBOMInventoryResponse,
  { projectId: string; sbomId: string },
  ThunkExtra
>("sbom/fetchInventory", async ({ projectId, sbomId }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.getSBOMInventory({ projectId, id: sbomId });

    const sbomInventoryPackages: SBOMInventoryPackage[] = response.packages.map((pkg) => ({
      ...pkg,
      vulns: pkg.vulns
        ? pkg.vulns.map((sbomInventoryVuln) => ({
            ...sbomInventoryVuln,
            references: sbomInventoryVuln.references.map((vulnRef) => ({ ...vulnRef })),
          }))
        : [],

      evidence: pkg.evidence
        ? {
            evidentBy: pkg.evidence.evidentBy,
            installedBy: pkg.evidence.installedBy,
          }
        : undefined,

      license: pkg.license
        ? {
            clauses: pkg.license.clauses.map((license) => ({ terms: license.terms })),
          }
        : undefined,
    }));

    const graphRelationships: GraphRelationship[] = response.relationships.map((relationship: GraphRelationship) => ({
      id: relationship.id,
      relatedId: relationship.relatedId,
      relationshipType: relationship.relationshipType,
    }));

    const sbomInventoryResponse: SBOMInventoryResponse = {
      packages: sbomInventoryPackages,
      relationships: graphRelationships,
    };

    return sbomInventoryResponse;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const sbomSlice = createSlice({
  name: "sbom",
  initialState,
  reducers: {
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSBOMDetails.pending, (state) => {
        state.status = "loading";
        state.sbomDetail = null;
      })
      .addCase(fetchSBOMDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.sbomDetail = action.payload;
      })
      .addCase(fetchSBOMDetails.rejected, (state) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchSBOMInventory.pending, (state) => {
        state.status = "loading";
        state.sbomInventory = null;
      })
      .addCase(fetchSBOMInventory.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.sbomInventory = action.payload;
      })
      .addCase(fetchSBOMInventory.rejected, (state) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchSBOMDownloadUrl.fulfilled, (state, action) => {
        state.connectivity = true;
        state.downloadUrl = action.payload;
      })
      .addCase(fetchSBOMDownloadUrl.rejected, (state) => {
        state.connectivity = false;
      });
  },
});

export const { setConnectivity } = sbomSlice.actions;

// Selectors to access the state
export const selectSBOMDetail = (state: RootState) => state.sbom.sbomDetail;
export const selectSBOMInventory = (state: RootState) => state.sbom.sbomInventory;
export const selectSBOMStatus = (state: RootState) => state.sbom.status;
export const selectSBOMConnectivity = (state: RootState) => state.sbom.connectivity;

export default sbomSlice.reducer;
