import { PlainMessage } from "@bufbuild/protobuf";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import { SBOMListItem as PBSBOMListItem } from "./../pb/edgebit/platform/v1alpha/platform_pb";
import { ComponentRef, Image, MachineRef, imageFromPB } from "./sbomDetailSlice";

export type SBOMListItem = Omit<PlainMessage<PBSBOMListItem>, "createdAt" | "image" | "componentRef" | "machineRef"> & {
  createdAt: string;
  image?: Image;
  componentRef?: ComponentRef;
  machineRef?: MachineRef;
};

export function sbomListItemFromPB(pb: PBSBOMListItem): SBOMListItem {
  return {
    ...pb,
    image: imageFromPB(pb.image),
    createdAt: pb.createdAt ? String(pb.createdAt.toDate()) : "",
    componentRef: pb.componentRef
      ? {
          id: pb.componentRef.id,
          name: pb.componentRef.name,
          displayName: pb.componentRef.displayName,
        }
      : undefined,
    machineRef: pb.machineRef
      ? {
          ...pb.machineRef,
        }
      : undefined,
  };
}

interface SBOMListState {
  sbomList: SBOMListItem[] | null;
  connectivity: boolean;
  value: number;
  projectID: string;
  status: "idle" | "loading" | "failed";
}

const initialState: SBOMListState = {
  sbomList: null,
  connectivity: true,
  value: 0,
  projectID: "",
  status: "idle",
};

export const fetchSBOMs = createAsyncThunk<SBOMListItem[], { projectId: string }, ThunkExtra>(
  "sbomList/fetchSBOMs",
  async ({ projectId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.listSBOMs({ projectId });

      const sbomList: SBOMListItem[] = response.items.map(sbomListItemFromPB);

      return sbomList;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const sbomListSlice = createSlice({
  name: "sbomList",
  initialState,
  reducers: {
    setSBOMList: (state, action: PayloadAction<SBOMListItem[] | null>) => {
      state.sbomList = action.payload;
    },
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
    setValue: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
    setProjectID: (state, action: PayloadAction<string>) => {
      state.projectID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSBOMs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSBOMs.fulfilled, (state, action) => {
        state.status = "idle";
        state.sbomList = action.payload;
      })
      .addCase(fetchSBOMs.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setSBOMList, setConnectivity, setValue, setProjectID } = sbomListSlice.actions;

export const selectSBOMList = (state: RootState) => state.sbomList.sbomList;
export const selectConnectivity = (state: RootState) => state.sbomList.connectivity;
export const selectValue = (state: RootState) => state.sbomList.value;
export const selectProjectID = (state: RootState) => state.sbomList.projectID;
export const selectSBOMListStatus = (state: RootState) => state.sbomList.status;

export default sbomListSlice.reducer;
