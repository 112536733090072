import { SBOMFormat } from "../pb/edgebit/platform/v1alpha/platform_pb";

export function PrettySBOMFormat(props: { format: SBOMFormat }): JSX.Element {
  switch (props.format) {
    case SBOMFormat.SBOM_FORMAT_SYFT:
      return <>Syft</>;
    case SBOMFormat.SBOM_FORMAT_SPDX_JSON:
      return <>SPDX JSON</>;
    case SBOMFormat.SBOM_FORMAT_CYCLONEDX_JSON:
      return <>CycloneDX JSON</>;
    default:
      return <>Unknown</>;
  }
}
