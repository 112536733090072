import { useContext, useEffect } from "react";
import { ProjectContext } from "../App";
import { BodyWrapperProjectScoped } from "./BodyWrapperProjectScoped";

type ProjectScopedComponent = (props: { projectId: string }) => JSX.Element;

export const withProjectSelector = (title: string, WrappedComponent: ProjectScopedComponent) => {
  return () => {
    const project = useContext(ProjectContext);

    useEffect(() => {
      document.title = `${title} - EdgeBit`;
    });

    return (
      <BodyWrapperProjectScoped>
        {project ? <WrappedComponent projectId={project.id} /> : <div>Loading...</div>}
      </BodyWrapperProjectScoped>
    );
  };
};
