import { GitHub } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { withProjectSelector } from "../components/WithProjectBodyWrapper";
import {
  PackageAndProposal,
  RepoDetails as RepoDetailsType,
  SourceRepo,
  fetchPackagesAndProposals,
  fetchRepo,
  scanRepo,
  selectConnectivity,
  selectPackages,
  selectRepo,
  selectScanning,
} from "../features/repoDetailSlice";
import { CodeRepo } from "../components/CodeRepo";
import { PackageType } from "../components/PackageType";

export const RepoDetails = withProjectSelector("Repository Details", (props: { projectId: string }) => {
  const { repoId } = useParams();
  const dispatch = useAppDispatch();
  const connectivity = useAppSelector(selectConnectivity);
  const repo = useAppSelector(selectRepo);
  const packages = useAppSelector(selectPackages);
  const scanning = useAppSelector(selectScanning);

  if (!repoId) {
    return <GlobalError message="No repository selected" />;
  }

  useEffect(() => {
    dispatch(fetchRepo({ projectId: props.projectId, repoId: repoId }));
    dispatch(fetchPackagesAndProposals({ projectId: props.projectId, repoId: repoId }));
  }, [dispatch, props.projectId, repoId]);

  const triggerScan = () => {
    dispatch(scanRepo({ projectId: props.projectId, repoId: repoId }));
  };

  return (
    <>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Repositories / {repo && repo.displayName}
      </Typography>

      <Button
        type="submit"
        variant="outlined"
        size="medium"
        disabled={scanning}
        sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
        onClick={triggerScan}
      >
        Re-Scan Repo
      </Button>

      <OverviewBox title="Source Repository">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBoxItem label="Repository Name" isEmpty={!repo} emptyMessage="None">
              <CodeRepo repo={repo ? repo : undefined} />
            </OverviewBoxItem>
            <OverviewBoxTextItem label="Default Branch" emptyMessage="None" text={repo?.targetBranch} />
            <OverviewBoxItem label="Link" emptyMessage="None" isEmpty={repo?.details.case === undefined}>
              <SourceRepoLink details={repo?.details} />
            </OverviewBoxItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBoxTextItem label="Repository ID" emptyMessage="None" text={repo?.id} />
            <OverviewBoxTextItem
              label="Repository Type"
              emptyMessage="None"
              text={
                repo && repo.details.case
                  ? {
                      githubRepoDetails: "GitHub",
                    }[repo.details.case]
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </OverviewBox>
      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
        Package Inventory
      </Typography>
      {packages && repo && <RepoPackageTable repo={repo} packages={packages} />}
    </>
  );
});

const SourceRepoLink = (props: { details: RepoDetailsType | undefined }) => {
  if (!props.details) {
    return <></>;
  }

  switch (props.details.case) {
    case "githubRepoDetails":
      return (
        <Typography>
          <GitHub sx={{ fontSize: "18px", verticalAlign: "bottom" }} />{" "}
          <Link
            href={`https://github.com/${props.details.value.owner}/${props.details.value.repo}`}
            target="_blank"
            sx={{ fontSize: "14px", verticalAlign: "baseline" }}
          >
            github.com/{props.details.value.owner}/{props.details.value.repo}
          </Link>
        </Typography>
      );
    default:
      return <></>;
  }
};

const RepoPackageTable = (props: { repo: SourceRepo; packages: PackageAndProposal[] }) => {
  return (
    <Container disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Installed Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.packages?.map((pap: PackageAndProposal) => (
              <TableRow key={pap.package.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <PackageType type={pap.package.packageType} name={true} />
                </TableCell>
                <TableCell>{pap.package.packageName}</TableCell>
                <TableCell>{pap.package.packageVersion}</TableCell>
              </TableRow>
            ))}
            {props.packages.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      Repository is being inventoried for packages
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      The inventory will be complete in just a few minutes
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
