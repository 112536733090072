import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

export type Integration = {
  id: string;
  type_: string;
  icon: string;
  account: string;
  avatar: string;
  status: string;
  details: string;
};

export interface Integrations {
  data: Integration[];
}

export function IntegrationsTable(props: Integrations) {
  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((intg) => (
              <TableRow key={intg.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Box
                    component="img"
                    sx={{
                      display: "inline-block",
                      borderRadius: "3px",
                      width: "20px",
                      height: "20px",
                      background: "#f5f5f5",
                      verticalAlign: "middle",
                      marginRight: "5px",
                    }}
                    src={intg.icon}
                    alt={intg.type_}
                  />
                  <Link component={NavLink} to={intg.details}>
                    {intg.type_}
                  </Link>
                </TableCell>
                <TableCell>
                  {intg.avatar && (
                    <Box
                      component="img"
                      sx={{
                        display: "inline-block",
                        borderRadius: "3px",
                        width: "20px",
                        height: "20px",
                        background: "#f5f5f5",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                      src={intg.avatar}
                      alt={intg.account}
                    />
                  )}
                  {intg.account}
                </TableCell>
                <TableCell>{intg.status}</TableCell>
              </TableRow>
            ))}
            {props.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      You don't have any integrations configured
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Add an integration to connect EdgeBit to your tools
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
