import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { DonutChart } from "../components/DonutChart";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewIssueTable } from "../components/OverviewIssueTable";
import useProjectId from "../hooks/useProjectId";
import { ComponentIssueState } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { VulnHistory } from "../components/ComponentVulnHistory";
import { EPSSActions } from "../components/EPSSActions";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  fetchProjectIssueTrend,
  fetchIssues,
  selectComponentIssues,
  selectConnectivity,
  selectProjectIssueTrend,
  selectSuppressedUnresolvedIssues,
  selectTotalUnresolvedIssues,
  selectSuppressionPercent,
} from "../features/overviewSlice";

export const Home = () => {
  const dispatch = useAppDispatch();
  const connectivity = useAppSelector(selectConnectivity);
  const issues = useAppSelector(selectComponentIssues);
  const projectIssueTrend = useAppSelector(selectProjectIssueTrend);
  const suppressionPercent = useAppSelector(selectSuppressionPercent);
  const suppressedUnresolvedIssues = useAppSelector(selectSuppressedUnresolvedIssues);
  const totalUnresolvedIssues = useAppSelector(selectTotalUnresolvedIssues);

  // Default threshold = 1% chance of exploitation in the next 30 days
  const [epssThreshold] = useState(0.1);

  useProjectId((projectId) => {
    dispatch(fetchProjectIssueTrend({ projectId: projectId }));

    dispatch(
      fetchIssues({
        projectId: projectId,
        states: [ComponentIssueState.OPEN],
      }),
    );
  });

  useEffect(() => {
    document.title = "Overview - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}

      <Typography variant="h4" gutterBottom>
        Your Inventory
      </Typography>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sm={12} md={7} lg={6} sx={{ marginTop: "0px" }}>
          <OverviewBox title="Vulnerability Trend">
            <VulnHistory type="full" trend={projectIssueTrend}></VulnHistory>
          </OverviewBox>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <OverviewBox title="Suppressions">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DonutChart percent={suppressionPercent / 100} count={suppressionPercent + "%"}></DonutChart>
              </Grid>
              <Grid item xs={8}>
                <p>
                  {suppressedUnresolvedIssues} out of {totalUnresolvedIssues} unresolved issues found in your
                  infrastructure were suppressed.
                </p>
              </Grid>
            </Grid>
          </OverviewBox>
          <OverviewBox title="Exploit Prediction (EPSS)" sx={{ maxHeight: "305px", overflow: "auto" }}>
            <Box sx={{ fontSize: "12px", marginBottom: "10px" }}>
              AI-based measure of the probability of exploitation in the next 30 days.
            </Box>
            <EPSSActions issues={issues} threshold={epssThreshold}></EPSSActions>
          </OverviewBox>
        </Grid>
      </Grid>
      <Typography variant="h4" gutterBottom>
        Actionable Supply Chain Risks
      </Typography>
      <OverviewIssueTable issues={issues} />
    </BodyWrapperProjectScoped>
  );
};
