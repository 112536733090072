import { Code } from "@bufbuild/connect";
import { Alert, Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUnauthenticatedClient } from "../api/client";
import { AuthContext } from "../components/AuthContextProvider";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import { LoginService } from "../pb/edgebit/platform/v1alpha/login_connectweb";

export const AcceptInvite = (props: { location?: Location }) => {
  const { setAuthToken } = useContext(AuthContext);
  const client = useUnauthenticatedClient(LoginService);
  const navigate = useNavigate();
  const [submissionState, setSubmissionState] = useState<{
    done: boolean;
    error: string | undefined;
  }>({ done: false, error: undefined });
  const [inviteFormState, setInviteFormState] = useState({
    password: "",
  });

  const [queryParams] = useSearchParams();
  let inviteToken = queryParams.get("token") || undefined;

  const handleAcceptSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmissionState({ done: false, error: undefined });
    client
      .exchangeInviteToken({
        token: inviteToken,
        password: inviteFormState.password,
      })
      .then(
        (res) => {
          setAuthToken(res.sessionToken);
          setSubmissionState({ done: true, error: undefined });
        },
        (err) => {
          if (err.code === Code.InvalidArgument) {
            setSubmissionState({ done: false, error: err.rawMessage });
          } else {
            setSubmissionState({ done: false, error: "Unknown error" });
          }
        },
      );
  };

  useEffect(() => {
    if (submissionState.done && !submissionState.error) {
      navigate("/onboarding");
    }
  }, [submissionState, navigate]);

  useEffect(() => {
    document.title = "Accept Invitation to EdgeBit";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
        <Box
          sx={{
            textAlign: "center",
            background: "#fff",
            borderRadius: "5px",
            padding: "40px 40px 30px 40px",
            maxWidth: "435px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Accept EdgeBit Invite
          </Typography>
          <Typography>Choose a password for your EdgeBit account.</Typography>
          <Box
            component="form"
            sx={{
              "& > :not(style)": {},
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleAcceptSubmit}
          >
            <EdgeBitTextField
              label="Password"
              placeholder="•••••••"
              id="password"
              type="password"
              variant="filled"
              autoComplete="current-password"
              style={{ marginTop: 11, width: "355px" }}
              value={inviteFormState.password}
              onChange={(e) =>
                setInviteFormState({
                  ...inviteFormState,
                  password: e.target.value,
                })
              }
            />
            {submissionState.error && (
              <Alert style={{ marginTop: 11 }} severity="error">
                {submissionState.error}
              </Alert>
            )}
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ fontSize: "11px", textAlign: "left", marginTop: "15px" }}
            >
              By using EdgeBit, you are agreeing to our{" "}
              <a target="_blank" href="https://edgebit.io/legal/privacy" rel="noreferrer">
                privacy policy
              </a>{" "}
              and{" "}
              <a target="_blank" href="https://edgebit.io/legal/terms" rel="noreferrer">
                terms of use
              </a>
              .
            </Typography>
            <EdgeBitPrimaryButton
              type="submit"
              variant="outlined"
              size="large"
              sx={{ marginTop: "30px", marginBottom: "10px" }}
            >
              Create Account
            </EdgeBitPrimaryButton>
          </Box>
        </Box>
      </Box>
    </BodyWrapper>
  );
};
