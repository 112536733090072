// import * as React from 'react';
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { NavLink } from "react-router-dom";
import { LabelDisplay } from "./LabelDisplay";
import { ComponentWithMetrics } from "../pb/edgebit/platform/v1alpha/platform_pb";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import VulnMultiIconDisplay from "./VulnMultiIconDisplay";

export function ComponentTable(props: { componentsWithMetrics: ComponentWithMetrics[] | null }) {
  const sortedComponentsWithMetrics = props.componentsWithMetrics?.sort((a, b) => {
    const displayNameA = a.component?.displayName || "";
    const displayNameB = b.component?.displayName || "";
    return displayNameA.localeCompare(displayNameB);
  });

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Component Name</TableCell>
              <TableCell>Labels</TableCell>
              <TableCell>Vulnerability Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.componentsWithMetrics &&
              sortedComponentsWithMetrics?.map((componentWithMetrics: ComponentWithMetrics) => (
                <TableRow
                  key={componentWithMetrics.component?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link component={NavLink} to={"/components/" + componentWithMetrics.component?.id}>
                      {componentWithMetrics.component?.displayName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <LabelDisplay labels={componentWithMetrics.component?.labels} />
                  </TableCell>
                  <TableCell>
                    {componentWithMetrics.componentIssueTrendItem ? (
                      <VulnMultiIconDisplay
                        componentIssueTrendItem={componentWithMetrics.componentIssueTrendItem}
                        showVulnSeverity={false}
                        showComponentVulns={true}
                      />
                    ) : (
                      <Box sx={{ color: "#999" }}> "-"</Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {sortedComponentsWithMetrics?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      You don't have any components
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Create a component for your frontend and connect it to a build pipeline
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: "10px", marginBottom: "20px" }}
                      href={"https://edgebit.io/docs/0.x/components/"}
                      target="_blank"
                    >
                      Components Documentation
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
