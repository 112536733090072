import { Box, Container, Button, Menu, MenuItem } from "@mui/material";
import { useContext, useState } from "react";
import { ProjectContext } from "../App";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSearchParams, createSearchParams } from "react-router-dom";
// import { Project } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { useSelector } from "react-redux";
import { Project, fetchProjects, selectProjects } from "../features/projectSlice";

export function BodyWrapperProjectScoped(props: { children: any }) {
  const project = useContext(ProjectContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const projectList = useSelector(selectProjects);

  // Project dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(fetchProjects());
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeProject = (newProject: Project) => {
    setAnchorEl(null);
    const updatedParams = createSearchParams(searchParams);
    updatedParams.set("project", newProject.name);
    setSearchParams(updatedParams);
    navigate({
      search: updatedParams.toString(),
    });
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        height: "100vh",
        overflow: "auto",
      }}
    >
      {project && (
        <Box
          sx={{
            fontSize: "15px",
            borderBottom: "1px solid #eee",
            width: "100%",
            paddingLeft: "15px",
            paddingTop: "3px",
            paddingBottom: "3px",
          }}
        >
          <Box sx={{ display: "inline-block", fontWeight: 300 }}>Project:</Box>
          <Button
            sx={{
              display: "inline-block",
              paddingLeft: "3px",
              fontWeight: 500,
              textTransform: "unset",
              fontSize: "unset",
              color: "unset",
              lineHeight: "unset",
              verticalAlign: "unset",
              padding: "0px 0px 0px 3px",
              "&:hover": { cursor: "pointer" },
            }}
            id="project-selector"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleOpen}
            size="small"
          >
            {project.description}
            <ArrowDropDownIcon
              fontSize="small"
              sx={{
                display: "inline-block",
                verticalAlign: "text-top",
                color: "#ccc",
              }}
            />
          </Button>
          <Menu
            id="project-list"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "project-selector",
            }}
          >
            {projectList?.map((project: Project) => (
              <MenuItem key={project.id} value={project.name} onClick={() => handleChangeProject(project)}>
                {project.description}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
      <Container sx={{ mt: 4, mb: 4, ml: 2, mr: 2, width: "auto" }}>{props.children}</Container>
    </Box>
  );
}
