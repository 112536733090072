import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ComponentIssue, ComponentIssueTrendItem } from "./componentDetailSlice";
import { RootState, ThunkExtra } from "../app/store";
import {
  ComponentIssueState,
  GetProjectIssueTrendResponse as PBGetProjectIssueTrendResponse,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import { PlainMessage } from "@bufbuild/protobuf";

type GetProjectIssueTrendResponse = Omit<PlainMessage<PBGetProjectIssueTrendResponse>, "items"> & {
  items: ComponentIssueTrendItem[];
};

interface OverviewState {
  currentProject?: string | null;
  componentIssueTrendItems: ComponentIssueTrendItem[];
  totalUnresolvedIssues: number;
  suppressedUnresolvedIssues: number;
  suppressedIssuesByPolicy: { [key: string]: number };
  componentIssues: ComponentIssue[];
  suppressionPercent: number;
  connectivity: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: OverviewState = {
  currentProject: null,
  componentIssueTrendItems: [],
  totalUnresolvedIssues: 0,
  suppressedUnresolvedIssues: 0,
  suppressedIssuesByPolicy: {},
  componentIssues: [],
  suppressionPercent: 0,
  connectivity: true,
  status: "idle",
};

export const fetchProjectIssueTrend = createAsyncThunk<GetProjectIssueTrendResponse, { projectId: string }, ThunkExtra>(
  "overview/fetchProjectIssueTrend",
  async ({ projectId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getProjectIssueTrend({ projectId });

      const formattedItems = response.items.map((item) => ({
        ...item,
        date: item.date ? String(item.date.toDate()) : "",
      }));

      return {
        ...response,
        items: formattedItems,
      };
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchIssues = createAsyncThunk<
  ComponentIssue[],
  { projectId: string; states: ComponentIssueState[] },
  ThunkExtra
>("overview/fetchIssues", async ({ projectId, states }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.listComponentIssuesByStateForProject({ projectId, states });

    return response.items.map((issue): ComponentIssue => {
      return {
        ...issue,
        createdAt: issue.createdAt ? String(issue.createdAt.toDate()) : "",
        componentRef: issue.componentRef ? { ...issue.componentRef } : undefined,
        updatedAt: issue.updatedAt ? String(issue.updatedAt.toDate()) : "",
        dueAt: issue.dueAt ? String(issue.dueAt.toDate()) : "",
        details: issue.details.case
          ? {
              value: {
                ...issue.details.value,
                references: issue.details.value.references.map((ref) => {
                  return { ...ref };
                }),
              },
              case: "vulnDetails",
            }
          : { case: undefined, value: undefined },
      };
    });
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectIssueTrend.fulfilled, (state, action) => {
        state.componentIssueTrendItems = action.payload.items;
        state.suppressedUnresolvedIssues = action.payload.suppressedUnresolvedIssues;
        state.totalUnresolvedIssues = action.payload.totalUnresolvedIssues;
        state.suppressedIssuesByPolicy = action.payload.suppressedIssuesByPolicy;

        if (state.totalUnresolvedIssues === 0) {
          state.suppressionPercent = 0;
        } else {
          state.suppressionPercent = Math.round((state.suppressedUnresolvedIssues / state.totalUnresolvedIssues) * 100);
        }

        state.status = "idle";
        state.connectivity = true;
      })
      .addCase(fetchProjectIssueTrend.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchIssues.fulfilled, (state, action) => {
        state.componentIssues = action.payload;
        state.status = "idle";
        state.connectivity = true;
      })
      .addCase(fetchIssues.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      });
  },
});

export const { setConnectivity } = overviewSlice.actions;

export const selectConnectivity = (state: RootState) => state.overview.connectivity;
export const selectComponentIssues = (state: RootState) => state.overview.componentIssues;
export const selectProjectIssueTrend = (state: RootState) => state.overview.componentIssueTrendItems;
export const selectSuppressionPercent = (state: RootState) => state.overview.suppressionPercent;
export const selectSuppressedUnresolvedIssues = (state: RootState) => state.overview.suppressedUnresolvedIssues;
export const selectTotalUnresolvedIssues = (state: RootState) => state.overview.totalUnresolvedIssues;

export default overviewSlice.reducer;
