import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useClient } from "../api/client";
import { UserDirectoryService } from "../pb/edgebit/platform/v1alpha/users_connectweb";
import { UserAccountService } from "../pb/edgebit/platform/v1alpha/user_account_connectweb";
import { ProjectContext } from "../App";
import { useState, useContext, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import { User } from "../features/usersSlice";
import FormattedTimestamp from "./FormattedTimestamp";

export function OrgUserListTable(props: {
  users: User[] | null;
  handleCreateUserClick: (event: React.MouseEvent<HTMLElement>) => void;
  refreshUsers: () => void;
}) {
  const userDirectoryClient = useClient(UserDirectoryService);
  const userAccountClient = useClient(UserAccountService);

  // TODO: this whole pattern is totally unmaintainable and has tons of weird edge cases.
  const project = useContext(ProjectContext);

  const [currentUserEmail, setCurrentUserEmail] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState("");

  const openDeleteDialog = (userID: string) => {
    setSelectedUserID(userID);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setSelectedUserID("");
    setDeleteDialogOpen(false);
  };

  const confirmDeleteUser = () => {
    deleteUser(selectedUserID);
    closeDeleteDialog();
  };

  useEffect(() => {
    userAccountClient.getUserProfile({}).then(
      (res) => {
        if (res.email) {
          setCurrentUserEmail(res.email);
        } else {
          console.error("No user ID found");
        }
      },
      (err) => {
        console.error("Error fetching profile", err);
      },
    );
  }, [userAccountClient]);

  const deleteUser = (userID: string) => {
    if (project && project.id) {
      userDirectoryClient
        .deleteUser({
          id: userID,
        })
        .then(
          (res) => {
            props.refreshUsers();
          },
          (err) => {
            console.log(err);
          },
        );
    }
  };

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Last Login</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users?.map((user: User) => (
              <TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {user.email}
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={user.createdAt} />
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={user.lastLoginAt} />
                </TableCell>
                <TableCell>
                  {currentUserEmail && currentUserEmail !== user.email ? (
                    <Link onClick={() => openDeleteDialog(user.id)}>
                      <DeleteRoundedIcon sx={{ color: "#333" }} />
                    </Link>
                  ) : (
                    <Tooltip title="You can't delete yourself from this account">
                      <DeleteOutlineIcon sx={{ color: "#ccc" }} />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>Are you sure you want to delete the user?</DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={confirmDeleteUser}>Delete</EdgeBitPrimaryButton>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
