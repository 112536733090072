import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { ComponentTable } from "../components/ComponentTable";
import useProjectId from "../hooks/useProjectId";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { ComponentWithMetrics } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const ComponentList = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [componentListWithMetrics, setComponentListWithMetrics] = useState<ComponentWithMetrics[] | null>(null);
  const [githubEnabled, setGithubEnabled] = useState(false);

  useProjectId((projectId) => {
    client.listComponentsWithMetrics({ projectId: projectId }).then(
      (res) => {
        setComponentListWithMetrics(res.componentsWithMetrics);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );

    client.hasGitHubIntegration({ projectId: projectId }).then(
      (res) => {
        setGithubEnabled(res.present);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
  });

  useEffect(() => {
    document.title = "Components - EdgeBit";
  }, []);

  // Navigate to create page
  const navigate = useNavigate();

  const handleCreateComponent = () => {
    navigate(`/components/create`);
  };

  const handleImportComponents = () => {
    navigate("/components/github-import");
  };

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Components
      </Typography>
      <Typography variant="body1" gutterBottom>
        Components group SBOMs, Vulnerabilities and Suppressions into a logical group, like a backend or a frontend.
      </Typography>
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
        onClick={handleCreateComponent}
      >
        Create Component
      </EdgeBitPrimaryButton>
      <Tooltip title={!githubEnabled && "Set up GitHub integration for this project to enable import"}>
        <Box sx={{ display: "inline-block" }}>
          <EdgeBitPrimaryButton
            type="submit"
            variant="outlined"
            size="medium"
            disabled={!githubEnabled}
            sx={{ marginTop: "20px", marginBottom: "0px", marginRight: "20px" }}
            onClick={handleImportComponents}
          >
            Import from GitHub
          </EdgeBitPrimaryButton>
        </Box>
      </Tooltip>
      <ComponentTable componentsWithMetrics={componentListWithMetrics}></ComponentTable>
    </BodyWrapperProjectScoped>
  );
};
