import { Box, Typography } from "@mui/material";
import { CodeRepo } from "./CodeRepo";
import { SourceRepo, SourceRepoPackage } from "../features/repoDetailSlice";
import { PackageType } from "./PackageType";
import { ComponentIssue } from "../features/componentDetailSlice";
import VulnChip from "./VulnChip";
import { VulnSeverity } from "../pb/edgebit/platform/v1alpha/platform_pb";

interface PackageAutocompleteProps {
  results: SourceRepoPackage[];
  repos: SourceRepo[] | null;
  issues: ComponentIssue[] | null;
  search: string;
  setTargetPkg: (pkg: SourceRepoPackage, branch: string | undefined) => void;
  setTargetIssue: (issue: ComponentIssue) => void;
}

export default function PackageAutocomplete(props: PackageAutocompleteProps) {
  if (props.search.length === 0) {
    return null;
  }

  if (props.repos === null) {
    return null;
  }

  let pkgs = props.results.filter((pkg) => pkg.packageType === "npm");

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          background: "#fff",
          boxShadow: "2px 3px 4px rgba(0,0,0,.1)",
          border: "1px solid #ddd;",
          display: "flex",
          flexDirection: "row",
          fontSize: "14px",
        }}
      >
        <PackageInventory pkgs={pkgs} repos={props.repos} setTargetPkg={props.setTargetPkg} />
        <IssueInventory pkgs={pkgs} issues={props.issues} repos={props.repos} setTargetIssue={props.setTargetIssue} />
      </Box>
    </Box>
  );
}

function PackageInventory(props: {
  pkgs: SourceRepoPackage[];
  repos: SourceRepo[];
  setTargetPkg: (pkg: SourceRepoPackage, branch: string | undefined) => void;
}) {
  return (
    <Box
      sx={{
        minWidth: "300px",
        padding: "10px 10px 10px 6px",
      }}
    >
      <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "4px" }}>Packages</Typography>
      {props.pkgs != null &&
        props.pkgs.map((pkg) => (
          <Box
            key={pkg.id}
            sx={{
              padding: "4px 4px 5px 5px",
              borderRadius: "3px",
              "&:hover": {
                background: "#F1F4FD",
                cursor: "pointer",
              },
            }}
            onClick={() => props.setTargetPkg(pkg, props.repos.find((repo) => repo.id === pkg.repoId)?.targetBranch)}
          >
            <CodeRepo repo={props.repos.find((repo) => repo.id === pkg.repoId)} />
            <Box sx={{ display: "inline-block", marginLeft: "4px" }}>
              {" "}
              &bull;
              <Box sx={{ marginLeft: "5px", display: "inline-block" }}>
                <PackageType type={pkg.packageType} />
                {pkg.packageName}
              </Box>
            </Box>
          </Box>
        ))}
      {props.pkgs != null && props.pkgs.length === 0 && (
        <Box sx={{ padding: "4px 5px" }}>No repositories containing this package</Box>
      )}
      {props.pkgs === null && <Box sx={{ padding: "4px 5px" }}>Searching repositories...</Box>}
    </Box>
  );
}

function IssueInventory(props: {
  issues: ComponentIssue[] | null;
  pkgs: SourceRepoPackage[];
  repos: SourceRepo[] | null;
  setTargetIssue: (issue: ComponentIssue) => void;
}) {
  const vulnSeverityString = (issue: ComponentIssue) => {
    const vulnSeverityString = VulnSeverity[issue.severity].toString();
    const vulnSeverityStringSentenceCase =
      VulnSeverity[issue.severity].toString().charAt(0).toUpperCase() + vulnSeverityString.slice(1).toLowerCase();
    return vulnSeverityStringSentenceCase;
  };

  return (
    <Box
      sx={{
        minWidth: "300px",
        padding: "10px 10px 10px 6px",
      }}
    >
      <Typography sx={{ fontSize: "14px", color: "#999", paddingLeft: "4px" }}>Open Component Issues</Typography>
      {props.issues != null &&
        props.issues.map((issue) => (
          <Box
            key={issue.id}
            sx={{
              padding: "4px 4px 5px 5px",
              borderRadius: "3px",
              "&:hover": {
                background: "#F1F4FD",
                cursor: "pointer",
              },
            }}
            onClick={() => props.setTargetIssue(issue)}
          >
            <Box sx={{ marginLeft: "5px", display: "inline-block", marginRight: "6px" }}>
              <Box sx={{ fontWeight: 500, display: "inline-block" }}>{issue.componentRef?.displayName}</Box> #{issue.id}
            </Box>
            &bull;
            <Box sx={{ display: "inline-block", margin: "0 6px" }}>
              <PackageType type={issue.packageType} /> {issue.packageName}
            </Box>
            &bull;
            <Box sx={{ display: "inline-block", marginLeft: "6px" }}>
              <VulnChip preset={VulnSeverity[issue.severity]} innerText={vulnSeverityString(issue)}></VulnChip>
            </Box>
          </Box>
        ))}
      {props.issues != null && props.issues.length === 0 && (
        <Box sx={{ padding: "4px 5px" }}>No issues containing this vulnerability</Box>
      )}
      {props.issues === null && <Box sx={{ padding: "4px 5px" }}>Searching issues...</Box>}
    </Box>
  );
}
