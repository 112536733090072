import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import { ComponentSettingsData } from "../components/ComponentSettings";
import { daysStringFromDuration } from "../pages/ComponentDetail";
import {
  ComponentIssueSeverity,
  ComponentIssueState,
  ComponentType,
  DormantPackagePolicy,
  PolicyItem,
  VulnFixState,
  ComponentIssue as ComponentIssuePB,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import { ComponentRef, VulnReference } from "./sbomDetailSlice";
import { SBOMListItem, sbomListItemFromPB } from "./sbomListSlice";

export interface ComponentIssueTrendItem {
  date?: string;
  criticalVulns: number;
  highVulns: number;
  mediumVulns: number;
  lowVulns: number;
  negligibleVulns: number;
}

export interface GetComponentIssueTrendResponse {
  items: ComponentIssueTrendItem[];
  totalUnresolvedIssues: number;
  suppressedUnresolvedIssues: number;
  suppressedIssuesByPolicy: { [key: string]: number };
}

export interface ComponentEnrichedSBOMSummary {
  sbom?: SBOMListItem;
  tags: string[];
  activeWorkloads: number;
}

export interface GetComponentTagsOverviewResponse {
  defaultSbom?: ComponentEnrichedSBOMSummary;
  otherActiveSboms: ComponentEnrichedSBOMSummary[];
  undeployedSbomCount: number;
  retiredSbomCount: number;
  defaultTagName: string;
  selectedTagName: string;
}

export interface VulnIssueDetails {
  id: string;
  description: string;
  references: VulnReference[];
  fixState: VulnFixState;
  fixVersions: string[];
}

export interface ComponentIssue {
  id: string;
  componentId: string;
  createdAt?: string;
  updatedAt?: string;
  packageType: string;
  packageName: string;
  upstreamSeverity: ComponentIssueSeverity;
  state: ComponentIssueState;
  severity: ComponentIssueSeverity;
  affectedPackageUrls: string[];
  details:
    | {
        value: VulnIssueDetails;
        case: "vulnDetails";
      }
    | { case: undefined; value?: undefined };
  labels: { [key: string]: string };
  componentRef?: ComponentRef;
  dueAt?: string;
}

export interface SLAPolicy {
  criticalSla: string;
  highSla: string;
  mediumSla: string;
  lowSla: string;
  negligibleSla: string;
}

export interface ComponentTag {
  name: string;
  sbomId: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Component {
  id: string;
  projectId: string;
  name: string;
  displayName: string;
  createdAt?: string;
  updatedAt?: string;
  type: ComponentType;
  labels: { [key: string]: string };
  sourceRepository: string;
  machineSelector: string;
  defaultTagName: string;
  policyIgnoreSeverityThreshold: ComponentIssueSeverity;
  policyDormantPackage: DormantPackagePolicy;
}

interface ComponentState {
  connectivity: boolean;
  modifyTagsFormOpen: boolean;
  desiredTags: { tags: string } | null;
  sbomDownloadUrl: string | null;
  modifySuppressionFormOpen: boolean;
  desiredSuppression: {
    id: string | undefined;
    desired: number;
    current: number;
    existingAutoSuppression: boolean;
  } | null;
  component: Component | undefined;
  componentSettingsData: ComponentSettingsData;
  settingsFormError: string | null;
  sboms: SBOMListItem[] | null;
  tagsOverview: GetComponentTagsOverviewResponse | null;
  policies: PolicyItem[] | undefined;
  issueStateFilter: ComponentIssueState[];
  issues: ComponentIssue[] | null;
  trend: ComponentIssueTrendItem[] | null;
  suppressedByThreshold: number | null;
  suppressedByDormancy: number | null;
  suppressionPercent: number;
  projectIssueSLAPolicy: SLAPolicy | undefined;
  ignoreFormOpen: boolean;
  ignoreVuln: {
    id: string | undefined;
    comment: string | undefined;
    justification: string | undefined;
  } | null;
  ignoreIssue: ComponentIssue | undefined;
  componentSBOMTags: ComponentTag[] | null;
  showConfirmDeleteDialog: boolean;
  jiraOrgHostname: string | undefined;
  epssThreshold: number;
  vexDownloadUrl: string | null;
  status: "idle" | "loading" | "failed";
}

// Define the initial state using that type
const initialState: ComponentState = {
  connectivity: true,
  modifyTagsFormOpen: false,
  desiredTags: null,
  sbomDownloadUrl: null,
  modifySuppressionFormOpen: false,
  desiredSuppression: null,
  component: undefined,
  componentSettingsData: {},
  settingsFormError: null,
  sboms: null,
  tagsOverview: null,
  policies: undefined,
  issueStateFilter: [ComponentIssueState.OPEN],
  issues: null,
  trend: null,
  suppressedByThreshold: null,
  suppressedByDormancy: null,
  suppressionPercent: 0,
  projectIssueSLAPolicy: undefined,
  ignoreFormOpen: false,
  ignoreVuln: null,
  ignoreIssue: undefined,
  componentSBOMTags: null,
  showConfirmDeleteDialog: false,
  jiraOrgHostname: undefined,
  epssThreshold: 0.1,
  vexDownloadUrl: null,
  status: "idle",
};

export const fetchSBOMs = createAsyncThunk<SBOMListItem[], { projectId: string; componentId: string }, ThunkExtra>(
  "component/fetchSBOMs",
  async ({ projectId, componentId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.listSBOMs({ projectId, componentId });

      const sbomListItems: SBOMListItem[] = response.items.map(sbomListItemFromPB);

      return sbomListItems;
    } catch (err: any) {
      console.log("HERE", err);
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchSBOMDownloadUrl = createAsyncThunk<string, { projectId: string; sbomId: string }, ThunkExtra>(
  "component/fetchSBOMDownloadUrl",
  async ({ projectId, sbomId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getSBOMDownloadUrl({ projectId, sbomId });
      const url = response.sbomDownloadPath;
      return url;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchVexDownloadUrl = createAsyncThunk<string, { projectId: string; componentId: string }, ThunkExtra>(
  "component/fetchVexDownloadUrl",
  async ({ projectId, componentId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getVexDownloadUrl({ projectId, componentId });
      return response.vexDownloadPath;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const listComponentIssues = createAsyncThunk<
  ComponentIssue[],
  { projectId: string; componentId: string; states: ComponentIssueState[] },
  ThunkExtra
>("component/listComponentIssues", async ({ projectId, componentId, states }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.listComponentIssues({ projectId, componentId, states });

    const componentIssues: ComponentIssue[] = response.items.map((issue: ComponentIssuePB): ComponentIssue => {
      return {
        ...issue,
        createdAt: issue.createdAt ? String(issue.createdAt.toDate()) : "",
        dueAt: issue.dueAt ? String(issue.dueAt.toDate()) : "",
        componentRef: issue.componentRef
          ? {
              id: issue.componentRef.id,
              name: issue.componentRef.name,
              displayName: issue.componentRef.displayName,
            }
          : undefined,
        updatedAt: issue.updatedAt ? String(issue.updatedAt.toDate()) : "",
        details: issue.details?.value
          ? {
              value: {
                id: issue.details.value.id,
                description: issue.details.value.description,
                references: issue.details.value.references.map((ref: { url: string }) => {
                  return {
                    url: ref.url,
                  };
                }),
                fixState: issue.details.value.fixState,
                fixVersions: issue.details.value.fixVersions,
              },
              case: "vulnDetails",
            }
          : {
              case: undefined,
              value: undefined,
            },
      };
    });

    return componentIssues;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const listComponentIssuesByVulnID = createAsyncThunk<
  ComponentIssue[],
  { projectId: string; search: string; states: ComponentIssueState[] },
  ThunkExtra
>("component/listComponentIssues", async ({ projectId, search, states }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.listComponentIssuesByVulnIDForProject({ projectId, search, states });

    const componentIssues: ComponentIssue[] = response.items.map((issue: ComponentIssuePB): ComponentIssue => {
      return {
        ...issue,
        createdAt: issue.createdAt ? String(issue.createdAt.toDate()) : "",
        dueAt: issue.dueAt ? String(issue.dueAt.toDate()) : "",
        componentRef: issue.componentRef
          ? {
              id: issue.componentRef.id,
              name: issue.componentRef.name,
              displayName: issue.componentRef.displayName,
            }
          : undefined,
        updatedAt: issue.updatedAt ? String(issue.updatedAt.toDate()) : "",
        details: issue.details?.value
          ? {
              value: {
                id: issue.details.value.id,
                description: issue.details.value.description,
                references: issue.details.value.references.map((ref: { url: string }) => {
                  return {
                    url: ref.url,
                  };
                }),
                fixState: issue.details.value.fixState,
                fixVersions: issue.details.value.fixVersions,
              },
              case: "vulnDetails",
            }
          : {
              case: undefined,
              value: undefined,
            },
      };
    });

    return componentIssues;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchComponent = createAsyncThunk<Component, { projectId: string; componentId: string }, ThunkExtra>(
  "component/fetchComponent",
  async ({ projectId, componentId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getComponent({ projectId: projectId, id: componentId });

      if (!response.component) {
        throw new Error("Component data is missing in the response");
      }

      const component: Component = {
        ...response.component,
        createdAt: response.component.createdAt ? String(response.component.createdAt.toDate()) : "",
        updatedAt: response.component.updatedAt ? String(response.component.updatedAt.toDate()) : "",
      };

      return component;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateComponent = createAsyncThunk<
  Component,
  {
    projectId: string;
    componentId: string;
    name: string;
    displayName: string;
    labels: { [key: string]: string };
    machineSelector: string;
    defaultTagName: string;
    sourceRepository: string;
    policyDormantPackage: DormantPackagePolicy;
    policyIgnoreSeverityThreshold: ComponentIssueSeverity;
  },
  ThunkExtra
>(
  "component/updateComponent",
  async (
    {
      projectId,
      componentId,
      name,
      displayName,
      labels,
      machineSelector,
      defaultTagName,
      sourceRepository,
      policyDormantPackage,
      policyIgnoreSeverityThreshold,
    },
    thunkAPI,
  ) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.updateComponent({
        projectId: projectId,
        id: componentId,
        name: name,
        displayName: displayName,
        labels: labels,
        machineSelector: machineSelector,
        defaultTagName: defaultTagName,
        sourceRepository: sourceRepository,
        policyDormantPackage: policyDormantPackage,
        policyIgnoreSeverityThreshold: policyIgnoreSeverityThreshold,
      });

      if (!response.component) {
        throw new Error("Component data is missing in the response");
      }

      const component: Component = {
        ...response.component,
        id: response.component ? response.component.id : "",
        projectId: response.component ? response.component.projectId : "",
        name: response.component ? response.component.name : "",
        createdAt: response.component?.createdAt ? String(response.component.createdAt.toDate()) : "",
        updatedAt: response.component?.updatedAt ? String(response.component.updatedAt.toDate()) : "",
      };

      return component;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const deleteComponent = createAsyncThunk<undefined, { projectId: string; componentId: string }, ThunkExtra>(
  "component/deleteComponent",
  async ({ projectId, componentId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      await apiClient.deleteComponent({ projectId: projectId, id: componentId });

      return undefined;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchComponentTagsOverview = createAsyncThunk<
  GetComponentTagsOverviewResponse,
  { projectId: string; componentId: string },
  ThunkExtra
>("component/fetchComponentTagsOverview", async ({ projectId, componentId }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.getComponentTagsOverview({ projectId, componentId });

    const otherSboms: ComponentEnrichedSBOMSummary[] = response.otherActiveSboms.map(
      (item): ComponentEnrichedSBOMSummary => {
        return {
          sbom: item.sbom ? sbomListItemFromPB(item.sbom) : undefined,
          tags: item.tags,
          activeWorkloads: item.activeWorkloads,
        };
      },
    );

    const tagsOverview: GetComponentTagsOverviewResponse = {
      ...response,
      defaultSbom: {
        sbom: response.defaultSbom?.sbom ? sbomListItemFromPB(response.defaultSbom.sbom) : undefined,
        tags: response.defaultSbom?.tags || [],
        activeWorkloads: response.defaultSbom?.activeWorkloads || 0,
      },
      otherActiveSboms: otherSboms,
    };

    return tagsOverview;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchComponentIssueTrend = createAsyncThunk<
  GetComponentIssueTrendResponse,
  { projectId: string; componentId: string },
  ThunkExtra
>("component/fetchComponentIssueTrend", async ({ projectId, componentId }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.getComponentIssueTrend({ projectId, componentId });
    const componentIssueTrendItems: ComponentIssueTrendItem[] = response.items.map((item): ComponentIssueTrendItem => {
      return {
        ...item,
        date: item.date ? String(item.date.toDate()) : "",
      };
    });

    const componentIssueTrendResponse: GetComponentIssueTrendResponse = {
      ...response,
      items: componentIssueTrendItems,
    };

    return componentIssueTrendResponse;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchProjectIssueSLAPolicy = createAsyncThunk<SLAPolicy, { projectId: string }, ThunkExtra>(
  "component/fetchProjectIssueSLAPolicy",
  async ({ projectId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.getProjectIssueSLAPolicy({ projectId: projectId });

      const policyFormatted = {
        criticalSla: daysStringFromDuration(response.policy?.criticalSla),
        highSla: daysStringFromDuration(response.policy?.highSla),
        mediumSla: daysStringFromDuration(response.policy?.mediumSla),
        lowSla: daysStringFromDuration(response.policy?.lowSla),
        negligibleSla: daysStringFromDuration(response.policy?.negligibleSla),
      };

      return policyFormatted;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const listComponentTags = createAsyncThunk<
  ComponentTag[],
  { projectId: string; componentId: string },
  ThunkExtra
>("component/listComponentTags", async ({ projectId, componentId }, thunkAPI) => {
  try {
    const { apiClient } = thunkAPI.extra;
    const response = await apiClient.listComponentTags({ projectId, componentId });

    const componentTags: ComponentTag[] = response.tags.map((tag): ComponentTag => {
      return {
        ...tag,
        createdAt: tag.createdAt ? String(tag.createdAt.toDate()) : "",
        updatedAt: tag.updatedAt ? String(tag.updatedAt.toDate()) : "",
      };
    });

    return componentTags;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const componentDetailSlice = createSlice({
  name: "component",
  initialState,
  reducers: {
    setConnectivity(state, action: PayloadAction<boolean>) {
      state.connectivity = action.payload;
    },
    setModifyTagsFormOpen(state, action: PayloadAction<boolean>) {
      state.modifyTagsFormOpen = action.payload;
    },
    setDesiredTags(state, action: PayloadAction<{ tags: string } | null>) {
      state.desiredTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSBOMs.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.sboms = action.payload;
      })
      .addCase(fetchSBOMs.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchSBOMDownloadUrl.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.sbomDownloadUrl = action.payload;
      })
      .addCase(fetchSBOMDownloadUrl.rejected, (state) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchVexDownloadUrl.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.vexDownloadUrl = action.payload;
      })
      .addCase(fetchVexDownloadUrl.rejected, (state) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchComponent.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.component = action.payload;
      })
      .addCase(fetchComponent.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(updateComponent.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.component = action.payload;
      })
      .addCase(updateComponent.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(deleteComponent.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.component = undefined;
      })
      .addCase(deleteComponent.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(listComponentIssues.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.issues = action.payload;
      })
      .addCase(listComponentIssues.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchComponentTagsOverview.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.tagsOverview = action.payload;
      })
      .addCase(fetchComponentTagsOverview.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchComponentIssueTrend.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.trend = action.payload.items;

        state.suppressedByDormancy = 0;
        state.suppressedByThreshold = 0;
        Object.entries(action.payload.suppressedIssuesByPolicy).forEach(([name, count]: [string, number]) => {
          // Pull out the count of policy suppressed issues
          if (name.startsWith("policy_ignore_severity_threshold")) {
            if (state.suppressedByThreshold) {
              state.suppressedByThreshold += count;
            } else {
              state.suppressedByThreshold = count;
            }
          } else if (name.startsWith("policy_dormant_package")) {
            if (state.suppressedByDormancy) {
              state.suppressedByDormancy += count;
            } else {
              state.suppressedByDormancy = count;
            }
          }
        });

        if (action.payload.totalUnresolvedIssues === 0) {
          state.suppressionPercent = 0;
        } else {
          state.suppressionPercent = Math.round(
            (action.payload.suppressedUnresolvedIssues / action.payload.totalUnresolvedIssues) * 100,
          );
        }
      })
      .addCase(fetchComponentIssueTrend.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(fetchProjectIssueSLAPolicy.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.projectIssueSLAPolicy = action.payload;
      })
      .addCase(fetchProjectIssueSLAPolicy.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(listComponentTags.fulfilled, (state, action) => {
        state.status = "idle";
        state.connectivity = true;
        state.componentSBOMTags = action.payload;
      })
      .addCase(listComponentTags.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      });
  },
});

export const { setConnectivity, setModifyTagsFormOpen, setDesiredTags } = componentDetailSlice.actions;

export const selectSBOMConnectivity = (state: RootState) => state.component.connectivity;
export const selectSBOMs = (state: RootState) => state.component.sboms;
export const selectComponentIssues = (state: RootState) => state.component.issues;
export const selectComponent = (state: RootState) => state.component.component;
export const selectTagsOverview = (state: RootState) => state.component.tagsOverview;
export const selectTrend = (state: RootState) => state.component.trend;
export const selectSuppressedByThreshold = (state: RootState) => state.component.suppressedByThreshold;
export const selectSuppressedByDormancy = (state: RootState) => state.component.suppressedByDormancy;
export const selectSuppressionPercent = (state: RootState) => state.component.suppressionPercent;
export const selectProjectIssueSLAPolicy = (state: RootState) => state.component.projectIssueSLAPolicy;
export const selectComponentSBOMTags = (state: RootState) => state.component.componentSBOMTags;

export default componentDetailSlice.reducer;
