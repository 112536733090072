import { useContext, useEffect } from "react";
import { ProjectContext } from "../App";

export default function useProjectId(callback: (projectId: string) => void) {
  const project = useContext(ProjectContext);

  useEffect(() => {
    if (project) {
      callback(project.id);
    }

    // TODO: This needs to be dealth with. The callback actually changes on every render
    // so if we list it as a dependency then we end up invoking it on every render. In
    // practice we can get away with not listing it here because our existing callbacks
    // don't (I guess, I haven't searched exhaustively) bind any other function or values
    // which might change on a subsequent render - typically hoooks themselves. But we're
    // treading on thin ice here, so probably need a better pattern.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);
}
