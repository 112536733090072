import { Box } from "@mui/material";

export function DonutChart(props: { children?: any; percent: number; count: number | string }) {
  // Clamp number between two values
  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

  const percent = clamp(props.percent * 100, 1, 100);
  const rest = 100 - percent;

  return (
    <Box component="span" sx={{ "& svg": { maxWidth: "100%" } }}>
      <svg width="100px" height="100px" viewBox="0 0 42 42" className="donut">
        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#EEEEEE"
          strokeWidth="3"
        ></circle>
        <circle
          className="donut-segment"
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="#6096FF"
          strokeWidth="3"
          strokeDasharray={`${percent} ${rest}`}
          strokeDashoffset="15"
        ></circle>
        <text x="21" y="22" fill="#333" className="donut-count" dominantBaseline="middle" textAnchor="middle">
          {props.count}
        </text>
      </svg>
    </Box>
  );
}
