// @generated by protoc-gen-connect-web v0.9.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/signup.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateOrgRequest, CreateOrgResponse, TestOrgNameRequest, TestOrgNameResponse } from "./signup_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service edgebit.platform.v1alpha.SignupService
 */
export const SignupService = {
  typeName: "edgebit.platform.v1alpha.SignupService",
  methods: {
    /**
     * @generated from rpc edgebit.platform.v1alpha.SignupService.CreateOrg
     */
    createOrg: {
      name: "CreateOrg",
      I: CreateOrgRequest,
      O: CreateOrgResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SignupService.TestOrgName
     */
    testOrgName: {
      name: "TestOrgName",
      I: TestOrgNameRequest,
      O: TestOrgNameResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

