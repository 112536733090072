import { useState } from "react";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

export interface IntegrationSettingsData {
  id: string;
  name: string;
  enabled: boolean;
}

export const PreText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "inline-block",
        fontFamily: "monospace",
        color: "#6096FF",
        padding: "0 5px",
      }}
    >
      {children}
    </Box>
  );
};

interface IntegrationSettingsProps {
  data: IntegrationSettingsData;
  projectIndex: number;
  onChange: (data: IntegrationSettingsData, projectIndex: number) => void;
}

export function IntegrationSettings(props: IntegrationSettingsProps) {
  const [enabled, setEnabled] = useState(props.data.enabled);

  const handleEnabledChange = (event: React.MouseEvent<HTMLElement>, isEnabled: boolean) => {
    setEnabled(isEnabled);
    props.onChange({ ...props.data, enabled: isEnabled }, props.projectIndex);
  };

  return (
    <Container disableGutters>
      <FormGroup>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={enabled}
          exclusive
          onChange={handleEnabledChange}
          aria-label="SBOM"
          sx={{}}
        >
          <ToggleButton value={true}>Enabled</ToggleButton>
          <ToggleButton value={false}>Disabled</ToggleButton>
        </ToggleButtonGroup>
      </FormGroup>
    </Container>
  );
}
