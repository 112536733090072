// @generated by protoc-gen-connect-web v0.9.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/source_repos.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AnalyzeProposalRequest, AnalyzeProposalResponse, CreateProposalRequest, CreateProposalResponse, GetProposalOverviewRequest, GetProposalOverviewResponse, GetSourceRepoRequest, GetSourceRepoResponse, ListProposalsRequest, ListProposalsResponse, ListSourceRepoPackageForComponentIssuePackageNameRequest, ListSourceRepoPackageForComponentIssuePackageNameResponse, ListSourceRepoPackagesAndProposalsRequest, ListSourceRepoPackagesAndProposalsResponse, ListSourceReposRequest, ListSourceReposResponse, PostProposalPRRequest, PostProposalPRResponse, ScanRepoRequest, ScanRepoResponse, SearchPackagesRequest, SearchPackagesResponse, SyncReposRequest, SyncReposResponse, UpdateSourceRepoStatusRequest, UpdateSourceRepoStatusResponse } from "./source_repos_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service edgebit.platform.v1alpha.SourceRepoService
 */
export const SourceRepoService = {
  typeName: "edgebit.platform.v1alpha.SourceRepoService",
  methods: {
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.ListSourceRepos
     */
    listSourceRepos: {
      name: "ListSourceRepos",
      I: ListSourceReposRequest,
      O: ListSourceReposResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.GetSourceRepo
     */
    getSourceRepo: {
      name: "GetSourceRepo",
      I: GetSourceRepoRequest,
      O: GetSourceRepoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.UpdateSourceRepoStatus
     */
    updateSourceRepoStatus: {
      name: "UpdateSourceRepoStatus",
      I: UpdateSourceRepoStatusRequest,
      O: UpdateSourceRepoStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.ListProposals
     */
    listProposals: {
      name: "ListProposals",
      I: ListProposalsRequest,
      O: ListProposalsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.CreateProposal
     */
    createProposal: {
      name: "CreateProposal",
      I: CreateProposalRequest,
      O: CreateProposalResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.SearchPackages
     */
    searchPackages: {
      name: "SearchPackages",
      I: SearchPackagesRequest,
      O: SearchPackagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.ListSourceRepoPackageForComponentIssuePackageName
     */
    listSourceRepoPackageForComponentIssuePackageName: {
      name: "ListSourceRepoPackageForComponentIssuePackageName",
      I: ListSourceRepoPackageForComponentIssuePackageNameRequest,
      O: ListSourceRepoPackageForComponentIssuePackageNameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.ListSourceRepoPackagesAndProposals
     */
    listSourceRepoPackagesAndProposals: {
      name: "ListSourceRepoPackagesAndProposals",
      I: ListSourceRepoPackagesAndProposalsRequest,
      O: ListSourceRepoPackagesAndProposalsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.GetProposalOverview
     */
    getProposalOverview: {
      name: "GetProposalOverview",
      I: GetProposalOverviewRequest,
      O: GetProposalOverviewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.SyncRepos
     */
    syncRepos: {
      name: "SyncRepos",
      I: SyncReposRequest,
      O: SyncReposResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.ScanRepo
     */
    scanRepo: {
      name: "ScanRepo",
      I: ScanRepoRequest,
      O: ScanRepoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.AnalyzeProposal
     */
    analyzeProposal: {
      name: "AnalyzeProposal",
      I: AnalyzeProposalRequest,
      O: AnalyzeProposalResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.SourceRepoService.PostProposalPR
     */
    postProposalPR: {
      name: "PostProposalPR",
      I: PostProposalPRRequest,
      O: PostProposalPRResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

