import { Box, Typography } from "@mui/material";
import React from "react";

type OverviewBoxItemProps = {
  label: string;
  emptyMessage: string;
  isEmpty: boolean;
  children: React.ReactNode;
};

export function OverviewBoxItem(props: OverviewBoxItemProps) {
  return (
    <>
      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
        {props.label}
      </Typography>
      <Box sx={{ fontSize: "14px", marginBottom: "10px", wordWrap: "break-word" }}>
        {props.isEmpty ? (
          <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px", color: "#999" }}>
            {props.emptyMessage}
          </Typography>
        ) : (
          props.children
        )}
      </Box>
    </>
  );
}
