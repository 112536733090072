import { Tooltip, Box } from "@mui/material";
import { VulnSeverity } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { ComponentIssueTrendItem } from "../pb/edgebit/platform/v1alpha/platform_pb";
import VulnChip from "./VulnChip";
interface Vuln {
  severity: VulnSeverity;
}

export default function VulnMulticonDisplay(props: {
  vulns?: Vuln[];
  componentIssueTrendItem?: ComponentIssueTrendItem;
  showComponentVulns: boolean;
  showVulnSeverity: boolean;
}) {
  if (!props.showComponentVulns && !props.showVulnSeverity) {
    return null;
  }

  let chips: JSX.Element[] = [];
  let severities: { severityCount: number; severityLevel: string }[] = [];

  if (props.showComponentVulns && props.componentIssueTrendItem) {
    severities = [
      {
        severityCount: props.componentIssueTrendItem.criticalVulns,
        severityLevel: "Critical",
      },
      {
        severityCount: props.componentIssueTrendItem.highVulns,
        severityLevel: "High",
      },
      {
        severityCount: props.componentIssueTrendItem.mediumVulns,
        severityLevel: "Medium",
      },
      {
        severityCount: props.componentIssueTrendItem.lowVulns,
        severityLevel: "Low",
      },
      {
        severityCount: props.componentIssueTrendItem.negligibleVulns,
        severityLevel: "Negligible",
      },
    ];
  } else if (props.showVulnSeverity && props.vulns) {
    const severityCounts = props.vulns.reduce((acc: Map<string, number>, vuln: Vuln) => {
      const vulnSeverity = VulnSeverity[vuln.severity].toLowerCase();
      acc.set(vulnSeverity, (acc.get(vulnSeverity) || 0) + 1);
      return acc;
    }, new Map<string, number>());

    const severityLevels = ["Critical", "High", "Medium", "Low", "Negligible"];

    severities = severityLevels.map((severityLevel) => {
      const count = severityCounts.get(severityLevel.toLowerCase()) || 0;
      return { severityCount: count, severityLevel };
    });
  } else {
    return null;
  }

  chips = severities.map(({ severityCount, severityLevel }) => (
    <Tooltip title={`${severityCount} ${severityLevel}`} key={severityLevel}>
      <Box
        component="span"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        {severityCount ? (
          <VulnChip preset={severityLevel} innerText={severityCount.toString()} />
        ) : (
          <VulnChip preset="none" avatarText={severityLevel.substring(0, 1)} />
        )}
      </Box>
    </Tooltip>
  ));

  return <Box sx={{ display: "inline-block", minWidth: 55 * severities.length + "px" }}>{chips}</Box>;
}
