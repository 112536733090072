import { PartialMessage } from "@bufbuild/protobuf";
import {
  Alert,
  Autocomplete,
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import SettingsField from "../components/SettingsField";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import {
  AWSIntegrationSettings,
  FetchAWSIntegrationIAMDocsResponse,
  GetAWSIntegrationResponse,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type ProjectIntegrationSettings = {
  projectID: string;
  projectName: string;
  ecsRegions: string[];
};

const AWS_REGIONS = [
  "us-east-2",
  "us-east-1",
  "us-west-1",
  "us-west-2",
  "af-south-1",
  "ap-east-1",
  "ap-south-2",
  "ap-southeast-3",
  "ap-southeast-4",
  "ap-south-1",
  "ap-northeast-3",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ca-central-1",
  "ca-west-1",
  "eu-central-1",
  "eu-west-1",
  "eu-west-2",
  "eu-south-1",
  "eu-west-3",
  "eu-south-2",
  "eu-north-1",
  "eu-central-2",
  "il-central-1",
  "me-south-1",
  "me-central-1",
  "sa-east-1",
];

export const AWSDetail = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const { intgId } = useParams();
  const [integration, setIntegration] = useState<GetAWSIntegrationResponse | null>(null);
  const [settings, setSettings] = useState<ProjectIntegrationSettings[]>([]);
  const [formError, setFormError] = useState<string | null>(null);
  const [iamDocs, setIamDocs] = useState<FetchAWSIntegrationIAMDocsResponse | null>(null);

  const navigate = useNavigate();

  const fetch = useCallback(async () => {
    try {
      // TODO: AuthenticatedApp() already does listProjects, use that.
      const projects = (await client.listProjects({})).projects;

      const integration = await client.getAWSIntegration({
        integrationId: intgId,
      });

      setIntegration(integration);

      const settings = projects.map((p): ProjectIntegrationSettings => {
        const s = integration.settings.find((s) => s.projectId === p.id);

        return {
          projectID: p.id,
          projectName: p.name,
          ecsRegions: s?.ecsRegions || [],
        };
      });

      setSettings(settings);
    } catch (err) {
      console.log(err);
      setConnectivity(false);
    }
  }, [client, intgId, setIntegration, setSettings, setConnectivity]);

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const iamDocs = await client.fetchAWSIntegrationIAMDocs({
          integrationId: intgId,
        });
        setIamDocs(iamDocs);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDocs();
    fetch();
  }, [fetch, intgId, client]);

  // Save Form
  const handleSave = async () => {
    setFormError(null);

    try {
      const pbSettings = settings.reduce((prev: PartialMessage<AWSIntegrationSettings>[], s) => {
        if (s.ecsRegions.length > 0) {
          prev.push({
            projectId: s.projectID,
            ecsRegions: s.ecsRegions,
          });
        }

        return prev;
      }, []);

      await client.updateAWSIntegrationSettings({
        integrationId: intgId,
        settings: pbSettings,
      });

      navigate(`/admin/integrations/`);
    } catch (err) {
      console.log(err);
      setConnectivity(false);
    }
  };

  const handleInterstitialSave: setAWSIntegrationAssumeRoleConfigCallback = async (params) => {
    const res = await client.setAWSIntegrationAssumeRoleConfig(params);

    // Refresh integration
    fetch();

    return res;
  };

  useEffect(() => {
    document.title = "Configure AWS Integration";
  });

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        AWS Settings
      </Typography>
      <Typography variant="body1">
        EdgeBit will track ECS workloads in enabled regions and map them to the EdgeBit projects based on the
        configuration below.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "30px" }}>
        Projects without regions selected are disabled.
      </Typography>

      {integration && !integration.assumeRoleConfig ? (
        <ConfigureAssumeRoleInterstitial iamDocs={iamDocs} integration={integration} onSave={handleInterstitialSave} />
      ) : (
        <>
          <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "150px" }}>Project Name</TableCell>
                  <TableCell>Enabled Regions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settings.map((proj, i) => (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell sx={{ verticalAlign: "middle", lineHeight: "38px" }}>{proj.projectName}</TableCell>
                    <TableCell sx={{ verticalAlign: "middle" }}>
                      <Autocomplete
                        multiple
                        options={AWS_REGIONS}
                        value={proj.ecsRegions}
                        onChange={(_, value) => {
                          setSettings((prevSettings) => {
                            const newSettings = [...prevSettings];
                            newSettings[i] = { ...newSettings[i], ecsRegions: value };
                            return newSettings;
                          });
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              placeholder="Select a region"
                              sx={{ maxWidth: "600px", marginTop: "3px", marginBottom: "3px" }}
                            />
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {formError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {formError}
            </Alert>
          )}

          <EdgeBitPrimaryButton
            type="submit"
            variant="outlined"
            size="medium"
            onClick={handleSave}
            sx={{ marginTop: "20px", marginBottom: "0px" }}
          >
            Save Settings
          </EdgeBitPrimaryButton>
        </>
      )}
    </BodyWrapper>
  );
};

function ConfigureAssumeRoleInterstitial(props: {
  integration: GetAWSIntegrationResponse;
  onSave: setAWSIntegrationAssumeRoleConfigCallback;
  iamDocs: FetchAWSIntegrationIAMDocsResponse | null;
}) {
  return (
    <>
      <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>Configure IAM Role</Typography>

      <Typography variant="body1" sx={{ marginBottom: "30px" }}>
        The IAM role that EdgeBit will assume to interact with your AWS account. Note that the "sts:ExternalId" is
        unqiue to your integration and should not be re-used between accounts.
      </Typography>

      <Box sx={{ maxWidth: "600px", width: "100%", position: "relative" }}>
        <TextField
          id="trustpolicy"
          label="Trust Policy"
          fullWidth
          sx={{ display: "inline-block", marginRight: "15px" }}
          inputProps={{
            style: {
              fontFamily: "monospace",
              fontSize: "12px",
              color: "#333",
              WebkitTextFillColor: "#333",
              lineHeight: "14px",
            },
          }}
          defaultValue={props.iamDocs?.iamRoleTrustPolicy || ""}
          disabled
          multiline
          maxRows={10}
        />
        <Box
          onClick={() => {
            navigator.clipboard?.writeText(props.iamDocs?.iamRoleTrustPolicy || "");
          }}
          sx={{
            cursor: "pointer",
            color: "#666",
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "14px",
            verticalAlign: "top",
            ":hover": {
              color: "#6096FF",
            },
          }}
        >
          <ContentCopyIcon sx={{ fontSize: "14px", verticalAlign: "middle" }} /> Copy Policy
        </Box>
      </Box>

      <Typography sx={{ marginTop: "30px", fontSize: "18px", fontWeight: 600 }}>Configure IAM Policy</Typography>

      <Typography variant="body1" gutterBottom sx={{ marginBottom: "30px" }}>
        The IAM Policy that will grant EdgeBit the necessary permissions to interact with your ECS workloads.
      </Typography>

      <Box sx={{ maxWidth: "600px", width: "100%", position: "relative" }}>
        <TextField
          id="rolepolicy"
          label="Role Policy"
          fullWidth
          sx={{ display: "inline-block", marginRight: "15px" }}
          inputProps={{
            style: {
              fontFamily: "monospace",
              fontSize: "12px",
              color: "#333",
              WebkitTextFillColor: "#333",
              lineHeight: "14px",
            },
          }}
          defaultValue={props.iamDocs?.iamPermissionsPolicy || ""}
          disabled
          multiline
          maxRows={10}
        />
        <Box
          onClick={() => {
            navigator.clipboard?.writeText(props.iamDocs?.iamPermissionsPolicy || "");
          }}
          sx={{
            cursor: "pointer",
            color: "#666",
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "14px",
            verticalAlign: "top",
            ":hover": {
              color: "#6096FF",
            },
          }}
        >
          <ContentCopyIcon sx={{ fontSize: "14px", verticalAlign: "middle" }} /> Copy Policy
        </Box>
      </Box>

      <AssumeRoleSettingsForm integrationId={props.integration.integrationId} onSave={props.onSave} />
    </>
  );
}

type setAWSIntegrationAssumeRoleConfigCallback = (params: {
  integrationId: string;
  assumeRoleConfig: {
    accountId: string;
    roleName: string;
  };
}) => Promise<{}>;

function AssumeRoleSettingsForm(props: { integrationId: string; onSave: setAWSIntegrationAssumeRoleConfigCallback }) {
  const [accountId, setAccountId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [formError, setFormError] = useState<string | null>(null);

  const handleSaveRole = async () => {
    setFormError(null);
    try {
      await props.onSave({
        integrationId: props.integrationId,
        assumeRoleConfig: {
          accountId: accountId,
          roleName: roleName,
        },
      });
    } catch (error) {
      // Handle error
      console.error("Error saving role configuration", error);
      setFormError("Error saving role configuration");
    }
  };

  return (
    <>
      <Container disableGutters>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            marginTop: "30px",
          }}
        >
          Role Configuration
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "30px" }}>
          After applying these IAM objects to your account, fill in the details below to complete the configuration.
        </Typography>
      </Container>

      <Box
        sx={{
          marginTop: "10px",
        }}
      >
        <SettingsField
          id="accountid"
          label="AWS Account ID"
          onChange={(event) => setAccountId(event.target.value)}
          helpText="The AWS account ID that EdgeBit will integrate with."
        />

        <SettingsField
          id="rolename"
          label="Role Name"
          onChange={(event) => setRoleName(event.target.value)}
          helpText="The name of the IAM role that EdgeBit will assume."
        />

        {formError && (
          <Alert style={{ marginTop: 11 }} severity="error">
            {formError}
          </Alert>
        )}

        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          onClick={handleSaveRole}
          sx={{ marginTop: "20px", marginBottom: "0px" }}
        >
          Configure Role
        </EdgeBitPrimaryButton>
      </Box>
    </>
  );
}
