import { Container, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { PackageType } from "../components/PackageType";
import { LabelDisplay } from "../components/LabelDisplay";
import { WorkloadItem } from "../pb/edgebit/platform/v1alpha/platform_pb";

function hasLabels(workload: WorkloadItem, filterLabels: Array<string>): boolean {
  const labels = workload.labels;
  var match = false;

  Object.entries(labels).forEach(([labelKey, labelValue]) => {
    filterLabels.forEach((filterLabelString) => {
      if (filterLabelString === labelKey + "=" + labelValue) {
        match = true;
      }
    });
  });

  if (match || filterLabels.length === 0 || filterLabels[0] === "") {
    return true;
  }

  return false; // No labels matched
}

function workloadName(workload: WorkloadItem): string {
  if (workload.labels["kube:pod:name"]) {
    return workload.labels["kube:pod:name"];
  }
  if (workload.labels["container-name"]) {
    return workload.labels["container-name"];
  }
  if (workload.imageName) {
    return workload.imageName;
  }
  return workload.name || "Unnamed";
}

export function WorkloadTable(props: {
  workloads: WorkloadItem[] | null;
  filterLabels: Array<string>;
  groupingLabel: string;
}) {
  // Group workloads by the desired label
  const desiredLabelGroup = props.groupingLabel;
  const groupedWorkloads: { [group: string]: WorkloadItem[] } = {};

  props.workloads
    ?.filter((workload: WorkloadItem) => hasLabels(workload, props.filterLabels))
    .forEach((workload: WorkloadItem) => {
      const group = workload.labels[desiredLabelGroup] || "Ungrouped";
      if (!groupedWorkloads[group]) {
        groupedWorkloads[group] = [];
      }
      groupedWorkloads[group].push(workload);
    });

  return (
    <Container sx={{ mt: 3, display: "table-cell" }} disableGutters>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Workload Name</TableCell>
            <TableCell>Labels</TableCell>
            <TableCell>Total Vulnerabilities</TableCell>
            <TableCell>Latest SBOM</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedWorkloads)
            .sort(([groupA], [groupB]) => {
              if (groupA === "Ungrouped") return 1; // "Ungrouped" should be placed at the end
              if (groupB === "Ungrouped") return -1; // "Ungrouped" should be placed at the end
              return groupA.localeCompare(groupB); // Sort alphabetically for other groups
            })
            .map(([group, workloads]) => (
              <React.Fragment key={group}>
                {/* Add row for grouping if not an empty string */}
                {props.groupingLabel && (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      colSpan={4}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        background: "#f9f9f9",
                        padding: "2px 12px",
                      }}
                    >
                      {group}
                    </TableCell>
                  </TableRow>
                )}

                {workloads.map((workload: WorkloadItem) => (
                  <React.Fragment key={workload.id}>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          paddingLeft: props.groupingLabel ? "30px" : "20px",
                        }}
                      >
                        <PackageType type={workload.type} name={false} />
                        <Link component={NavLink} to={"/workloads/" + workload.id}>
                          {workloadName(workload)}
                        </Link>
                        <br />
                      </TableCell>
                      <TableCell>
                        <LabelDisplay labels={workload.labels} truncate={1}></LabelDisplay>
                      </TableCell>
                      <TableCell>
                        {workload.totalVulnerabilities > 0 && workload.totalVulnerabilities}
                        {workload.sbomId && !workload.totalVulnerabilities && "0"}
                        {!workload.sbomId && !workload.totalVulnerabilities && (
                          <Box sx={{ color: "#999" }}>No SBOM</Box>
                        )}
                      </TableCell>
                      <TableCell>
                        {workload.sbomId && workload.type === "base_os" && (
                          <Link component={NavLink} to={"/sboms/" + workload.sbomId}>
                            Realtime {workload.sbomId.substring(0, 6)}
                          </Link>
                        )}
                        {workload.sbomId && workload.type === "container" && (
                          <Link component={NavLink} to={"/sboms/" + workload.sbomId}>
                            Build {workload.sbomId.substring(0, 6)}
                          </Link>
                        )}
                        {!workload.sbomId && <Box sx={{ color: "#999" }}>No SBOM</Box>}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          {props.workloads?.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "550px",
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                    No workloads found
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ display: "block" }}>
                    Workloads will appear after you've registered your first machine.
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                    component={NavLink}
                    to={"/machines/"}
                  >
                    View Machines
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}
