import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useState } from "react";
import { useClient } from "../api/client";
import { ProjectContext } from "../App";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { AgentDeployToken } from "../pb/edgebit/platform/v1alpha/platform_pb";
import FormattedTimestamp from "./FormattedTimestamp";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";

export function AgentDeployTokenTable(props: {
  tokens: AgentDeployToken[] | null;
  handleCreateTokenClick: (event: React.MouseEvent<HTMLElement>) => void;
  refreshTokens: () => void;
}) {
  const client = useClient(EdgeBitPublicAPIService);

  // TODO: this whole pattern is totally unmaintainable and has tons of weird edge cases.
  const project = useContext(ProjectContext);

  const [openDialog, setOpenDialog] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState("");

  const handleDeleteTokenClick = (tokenID: string) => {
    setTokenToDelete(tokenID);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    deleteToken("");
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    deleteToken(tokenToDelete);
    setOpenDialog(false);
  };

  const deleteToken = (tokenID: string) => {
    if (project && project.id) {
      client
        .deleteAgentDeployToken({
          projectId: project.id,
          tokenId: tokenID,
        })
        .then(
          (res) => {
            props.refreshTokens();
          },
          (err) => {
            console.log(err);
          },
        );
    }
  };

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Last Used</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tokens?.map((token: AgentDeployToken) => (
              <TableRow key={token.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {token.description}
                </TableCell>
                <TableCell>{token.roles.join(", ")}</TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={token.createdAt} />
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={token.lastUsed} />
                </TableCell>
                <TableCell>
                  <Link onClick={() => handleDeleteTokenClick(token.id)}>
                    <DeleteRoundedIcon sx={{ color: "#333" }} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            {props.tokens?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      You don't have any access tokens
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: "10px", marginBottom: "20px" }}
                      onClick={props.handleCreateTokenClick}
                    >
                      Create an Access Token
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Token</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Deployment Token?</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleConfirmDelete}>Delete</EdgeBitPrimaryButton>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
