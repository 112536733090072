import { Grid, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import FormattedTimestamp from "../components/FormattedTimestamp";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { PackageType } from "../components/PackageType";
import { LabelDisplay } from "../components/LabelDisplay";
import useProjectId from "../hooks/useProjectId";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { WorkloadItem } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { Code } from "@bufbuild/connect";
import { ContainerArtifactInfo } from "../components/ContainerArtifactInfo";

function workloadName(workload: WorkloadItem): string {
  if (workload.labels["kube:pod:name"]) {
    return workload.labels["kube:pod:name"];
  }
  if (workload.labels["container-name"]) {
    return workload.labels["container-name"];
  }
  if (workload.imageName) {
    return workload.imageName;
  }
  return workload.name || "Unnamed";
}

function artifactName(workload: WorkloadItem): string {
  if (workload.imageName) {
    return workload.imageName;
  }

  if (workload.labels["image-tag"]) {
    return workload.labels["image-tag"];
  }

  if (workload.labels["os-pretty-name"]) {
    return workload.labels["os-pretty-name"];
  }

  return "Unnamed";
}

export const WorkloadDetail = () => {
  const { workloadId } = useParams();

  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const navigate = useNavigate();

  const [workloadDetail, setWorkloadDetail] = useState<WorkloadItem | null>(null);

  const [connectivity, setConnectivity] = useState<boolean>(true);

  useProjectId((projectId) => {
    client.getWorkload({ projectId: projectId, workloadId: workloadId }).then(
      (res) => {
        var workload = res.workload;

        if (workload) {
          setWorkloadDetail(workload);
          document.title = workloadName(workload) + " / Workloads";
        }
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/workloads/`);
        setConnectivity(false);
      },
    );
  });

  useEffect(() => {
    document.title = workloadId + " / Workloads";
  }, [workloadId]);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Workloads / {workloadDetail ? workloadName(workloadDetail) : "Unnamed"}
      </Typography>

      {workloadDetail && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBox title="Source Artifact">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OverviewBoxItem label="Workload Labels" isEmpty={false} emptyMessage="No labels set">
                    <LabelDisplay
                      labels={workloadDetail ? workloadDetail.labels : undefined}
                      prefix={null}
                    ></LabelDisplay>
                  </OverviewBoxItem>
                  <OverviewBoxItem label="Cloud Labels" isEmpty={false} emptyMessage="No labels set">
                    <LabelDisplay
                      labels={workloadDetail ? workloadDetail.labels : undefined}
                      prefix={["cloud:", "aws:", "azure:", "gcp:"]}
                    ></LabelDisplay>
                  </OverviewBoxItem>
                  <OverviewBoxItem label="Kubernetes Labels" isEmpty={false} emptyMessage="No labels set">
                    <LabelDisplay
                      labels={workloadDetail ? workloadDetail.labels : undefined}
                      prefix="kube:"
                    ></LabelDisplay>
                  </OverviewBoxItem>
                  <OverviewBoxItem label="Artifact" isEmpty={false} emptyMessage="No release artifact">
                    <ReleaseArtifactInfo workload={workloadDetail} />
                  </OverviewBoxItem>
                  <OverviewBoxItem
                    label="SBOM ID"
                    isEmpty={workloadDetail.sbomId ? false : true}
                    emptyMessage="No SBOM"
                  >
                    <Link component={NavLink} to={"/sboms/" + workloadDetail.sbomId}>
                      {workloadDetail.sbomId}
                    </Link>
                  </OverviewBoxItem>
                </Grid>
              </Grid>
            </OverviewBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBox title="Metadata">
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <OverviewBoxTextItem label="Workload ID" emptyMessage="No Workload ID" text={workloadDetail.id} />
                  {workloadDetail?.type === "container" && (
                    <OverviewBoxTextItem
                      label="Container Runtime ID"
                      emptyMessage="No ID"
                      text={workloadDetail.externalId}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <OverviewBoxItem label="Started At" isEmpty={false} emptyMessage="Unknown start time">
                    <FormattedTimestamp timestamp={workloadDetail.startedAt} />
                  </OverviewBoxItem>
                  <OverviewBoxItem
                    label="Ended At"
                    isEmpty={workloadDetail.endedAt ? false : true}
                    emptyMessage="Actively running"
                  >
                    <FormattedTimestamp timestamp={workloadDetail.endedAt} />
                  </OverviewBoxItem>
                  <OverviewBoxItem
                    label="Machine"
                    isEmpty={workloadDetail.machineRef ? false : true}
                    emptyMessage="None"
                  >
                    <Link component={NavLink} to={"/machines/" + workloadDetail.machineRef?.id}>
                      {workloadDetail.machineRef?.hostname}
                    </Link>
                  </OverviewBoxItem>
                  <OverviewBoxItem
                    label="Component"
                    isEmpty={workloadDetail.componentRef ? false : true}
                    emptyMessage="None"
                  >
                    <Link component={NavLink} to={"/components/" + workloadDetail.componentRef?.id}>
                      {workloadDetail.componentRef?.displayName}
                    </Link>
                  </OverviewBoxItem>
                </Grid>
              </Grid>
            </OverviewBox>
          </Grid>
        </Grid>
      )}
    </BodyWrapperProjectScoped>
  );
};

type ReleaseArtifactInfoProps = {
  workload: WorkloadItem;
};

const ReleaseArtifactInfo = (props: ReleaseArtifactInfoProps) => {
  if (!props.workload.imageName) {
    return <Box>No image provided</Box>;
  }

  switch (props.workload.type) {
    case "container":
      return (
        <ContainerArtifactInfo imageIds={props.workload.imageExternalId} imageName={artifactName(props.workload)} />
      );
    case "base_os":
      return (
        <PackageType type={"base_os"} name={true}>
          {artifactName(props.workload)}
        </PackageType>
      );
    default:
      return <Box>Unknown Workload Type</Box>;
  }
};
