import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import { IntegrationType } from "../pb/edgebit/platform/v1alpha/platform_pb";

interface IntegrationsState {
  integrations: Integration[] | null;
  connectivity: boolean;
  projectID: string;
  status: "idle" | "loading" | "failed";
}

const initialState: IntegrationsState = {
  integrations: null,
  connectivity: true,
  projectID: "",
  status: "idle",
};

export interface Integration {
  type: IntegrationType;
  id: string;
  account: string;
  avatarUrl: string;
  lastError: string;
}

export const fetchIntegrations = createAsyncThunk<Integration[], { projectId: string }, ThunkExtra>(
  "integrationList/fetchIntegrations",
  async ({ projectId }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.listIntegrations({ projectId });
      const integrations: Integration[] = response.integrations.map((integration) => ({
        type: integration.type,
        id: integration.id,
        account: integration.account,
        avatarUrl: integration.avatarUrl,
        lastError: integration.lastError,
      }));
      return integrations;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const integrations = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    fetchIntegrations: (state, action: PayloadAction<Integration[]>) => {
      state.integrations = action.payload;
    },
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
    setProjectID: (state, action: PayloadAction<string>) => {
      state.projectID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.status = "idle";
        state.integrations = action.payload;
      })
      .addCase(fetchIntegrations.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setConnectivity, setProjectID } = integrations.actions;

export const selectConnectivity = (state: RootState) => state.integrations.connectivity;
export const selectIntegrations = (state: RootState) => state.integrations.integrations;
export const selectProjectID = (state: RootState) => state.integrations.projectID;

export default integrations.reducer;
