import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PartialMessage } from "@bufbuild/protobuf";
import { useNavigate } from "react-router-dom";
import { BodyWrapper } from "../components/BodyWrapper";
import { IntegrationSettings, IntegrationSettingsData } from "../components/IntegrationSettings";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { useClient } from "../api/client";
import { SlackIntegrationSettings } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const SlackDetail = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const { intgId } = useParams();
  const [settings, setSettings] = useState<IntegrationSettingsData[]>([]);
  const [formError, setFormError] = useState<string | null>(null);

  const projs = Array.from(settings.entries());
  const navigate = useNavigate();

  const handleSettingsChange = (data: IntegrationSettingsData, projectIndex: number) => {
    settings[projectIndex] = data;
    setSettings([...settings]);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        // TODO: AuthenticatedApp() already does listProjects, use that.
        const projects = (await client.listProjects({})).projects;

        const install = await client.getSlackIntegration({
          integrationId: intgId,
        });

        const settings = projects.map((p) => {
          // locate the project in the install
          const s = install.settings.find((s) => s.projectId === p.id);
          return {
            id: p.id,
            name: p.name,
            enabled: s !== undefined,
          };
        });

        setSettings(settings);
      } catch (err) {
        setFormError("Unknown error encountered when fetching Slack settings.");
        setConnectivity(false);
      }
    };

    fetch();
  }, [client, intgId]);

  // Save Form
  const handleSave = async () => {
    setFormError(null);

    try {
      const pbSettings = settings.reduce((prev: PartialMessage<SlackIntegrationSettings>[], s) => {
        if (s.enabled) {
          prev.push({
            projectId: s.id,
          });
        }
        return prev;
      }, []);

      await client.updateSlackIntegration({
        integrationId: intgId,
        settings: pbSettings,
      });

      navigate(`/admin/integrations/`);
    } catch (err) {
      setFormError("Unknown error encountered when saving Slack settings.");
      setConnectivity(false);
    }
  };

  useEffect(() => {
    document.title = "Configure Slack Integration";
  });

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Slack Settings
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "30px" }}>
        Slack users will be notified of dependency updates for the projects below.
      </Typography>

      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>Project Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projs.map(([i, proj]) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ verticalAlign: "top", lineHeight: "38px" }}>{proj.name}</TableCell>
                <TableCell>
                  <IntegrationSettings data={proj} projectIndex={i} onChange={handleSettingsChange} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {formError && (
        <Alert style={{ marginTop: 11 }} severity="error">
          {formError}
        </Alert>
      )}

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        onClick={handleSave}
        sx={{ marginTop: "20px", marginBottom: "0px" }}
      >
        Save Settings
      </EdgeBitPrimaryButton>
    </BodyWrapper>
  );
};
