import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";
import { SourceRepo, sourceRepoFromPB, SourceRepoPackage } from "./repoDetailSlice";

interface RepoListState {
  repos: SourceRepo[] | null;
  syncing: boolean;
  connectivity: boolean;
  status: "idle" | "loading" | "failed";
  newSourceRepoPackage: SourceRepoPackage | null;
}

const initialState: RepoListState = {
  repos: null,
  syncing: false,
  connectivity: true,
  status: "idle",
  newSourceRepoPackage: null,
};

export const fetchRepoList = createAsyncThunk<SourceRepo[], { projectId: string }, ThunkExtra>(
  "repoList/fetchSourceRepos",
  async ({ projectId }, thunkAPI) => {
    while (true) {
      try {
        const { repoClient } = thunkAPI.extra;
        const response = await repoClient.listSourceRepos({ projectId });

        if (response.sourceRepos.length === 0) {
          // retry in 1s
          await new Promise((resolve) => setTimeout(resolve, 1000));
          continue;
        }

        return response.sourceRepos.map(sourceRepoFromPB);
      } catch (err: any) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  },
);

export const syncRepos = createAsyncThunk<void, { projectId: string }, ThunkExtra>(
  "repoList/syncRepos",
  async ({ projectId }, thunkAPI) => {
    try {
      const { repoClient } = thunkAPI.extra;
      await repoClient.syncRepos({ projectId });
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateRepoStatus = createAsyncThunk<
  void,
  { projectId: string; repoId: string; active: boolean },
  ThunkExtra
>("repoList/updateRepoStatus", async ({ projectId, repoId, active }, thunkAPI) => {
  try {
    const { repoClient } = thunkAPI.extra;
    console.log(active);
    await repoClient.updateSourceRepoStatus({ projectId, repoId, enabled: active });
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const repoListSlice = createSlice({
  name: "repoList",
  initialState,
  reducers: {
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
    setSyncing: (state, action: PayloadAction<boolean>) => {
      state.syncing = action.payload;
    },
    setNewSourceRepoPackage: (state, action: PayloadAction<SourceRepoPackage | undefined>) => {
      state.newSourceRepoPackage = action.payload!;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepoList.pending, (state) => {
        state.status = "loading";
        state.repos = null;
      })
      .addCase(fetchRepoList.fulfilled, (state, action) => {
        state.repos = action.payload;
        state.status = "idle";
        state.connectivity = true;
      })
      .addCase(fetchRepoList.rejected, (state, action) => {
        state.status = "failed";
        state.connectivity = false;
      })
      .addCase(syncRepos.pending, (state) => {
        state.syncing = true;
      })
      .addCase(syncRepos.rejected, (state) => {
        state.syncing = false;
        state.connectivity = false;
        state.status = "failed";
      });
  },
});

export const { setSyncing, setNewSourceRepoPackage } = repoListSlice.actions;

export const selectConnectivity = (state: RootState) => state.repoList.connectivity;
export const selectRepos = (state: RootState) => state.repoList.repos;
export const selectSyncing = (state: RootState) => state.repoList.syncing;
export const selectNewSourceRepoPackage = (state: RootState) => state.repoList.newSourceRepoPackage;

export default repoListSlice.reducer;
