import { Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { LabelDisplay } from "../components/LabelDisplay";
import FormattedTimestamp from "../components/FormattedTimestamp";
import useInterval from "../hooks/useInterval";
import useProjectId from "../hooks/useProjectId";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { Machine, WorkloadItem } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { Code } from "@bufbuild/connect";
import { MachineDetailTable } from "../components/MachineDetailTable";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";

function machineName(machine: Machine | undefined): string | undefined {
  if (machine !== undefined) {
    return machine.hostname.length > 0 ? machine.hostname : machine.labels["kube:node:name"];
  } else {
    return undefined;
  }
}

function safeMachineName(machine: Machine | undefined): string {
  const name = machineName(machine);
  return name === undefined ? "unknown" : name;
}

export const MachineDetail = () => {
  const { machineId } = useParams();

  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const navigate = useNavigate();

  const [currentProjectID, setCurrentProjectID] = useState<string | undefined>();
  const [workloadItems, setWorkloadItems] = useState<WorkloadItem[]>([]);
  const [machine, setMachine] = useState<Machine>();

  const [connectivity, setConnectivity] = useState<boolean>(true);

  const [pollDelay] = useState<number>(20000);

  const [isPlaying, setPlaying] = useState<boolean>(false);

  useProjectId((projectId) => {
    setCurrentProjectID(projectId);

    client.getMachine({ projectId: projectId, machineId: machineId }).then(
      (res) => {
        setMachine(res.machine);
        const name = machineName(res.machine);
        if (name) {
          document.title = name + " / Machines";
        }
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/machines/`);
        setConnectivity(false);
      },
    );

    client.listWorkloadItemsForMachine({ projectId: projectId, machineId: machineId }).then(
      (res) => {
        setWorkloadItems(res.workloadItems);

        if (res.workloadItems.length > 0) {
          setPlaying(false);
        } else if (res.workloadItems.length === 0) {
          setPlaying(true);
        }
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/machines/`);
        setConnectivity(false);
      },
    );
  });

  useInterval(
    () => {
      client
        .listWorkloadItemsForMachine({
          projectId: currentProjectID,
          machineId: machineId,
        })
        .then(
          (res) => {
            setWorkloadItems(res.workloadItems);
            if (res.workloadItems.length > 0) {
              setPlaying(false);
            }
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );
    },
    // Delay in milliseconds or null to stop it
    isPlaying ? pollDelay : null,
  );

  useEffect(() => {
    document.title = machineId + " / Machines";
  }, [machineId]);

  function workloadName(workload: WorkloadItem): string {
    if (workload === undefined) {
      return "Unnamed";
    }
    if (workload.labels && workload.labels["kube:pod:name"]) {
      return workload.labels["kube:pod:name"];
    }
    if (workload.labels && workload.labels["container-name"]) {
      return workload.labels["container-name"];
    }
    if (workload.imageName) {
      return workload.imageName;
    }
    return workload.name || "Unnamed";
  }

  // Find Base OS in workload list
  var baseOS = workloadItems.filter((workload: WorkloadItem) => workload.type === "base_os")[0];
  var filteredWorkloadItems = workloadItems.filter((workload: WorkloadItem) => workload.type === "container");

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Machines / {safeMachineName(machine)}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <OverviewBox title="Machine Details">
            <OverviewBoxItem label="Machine Labels" isEmpty={false} emptyMessage="No labels set">
              <LabelDisplay labels={baseOS ? baseOS.labels : undefined} prefix={null}></LabelDisplay>
            </OverviewBoxItem>
            <OverviewBoxItem label="Cloud Labels" isEmpty={false} emptyMessage="No labels set">
              <LabelDisplay
                labels={baseOS ? baseOS.labels : undefined}
                prefix={["cloud:", "aws:", "azure:", "gcp:"]}
              ></LabelDisplay>
            </OverviewBoxItem>
            <OverviewBoxItem label="Kubernetes Labels" isEmpty={false} emptyMessage="No labels set">
              <LabelDisplay labels={machine ? machine.labels : undefined} prefix={null}></LabelDisplay>
            </OverviewBoxItem>
            <OverviewBoxTextItem label="Machine OS" emptyMessage="Unknown OS" text={baseOS?.imageName} />
          </OverviewBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <OverviewBox title="Metadata">
            <OverviewBoxItem
              label="Workload"
              emptyMessage="No workload ID"
              isEmpty={baseOS?.id === undefined || baseOS === undefined}
            >
              <Link component={NavLink} to={"/workloads/" + baseOS?.id}>
                {workloadName(baseOS)}
              </Link>
            </OverviewBoxItem>

            <OverviewBoxItem
              label="Started At"
              isEmpty={baseOS?.startedAt === undefined}
              emptyMessage="Unknown start time"
            >
              <FormattedTimestamp timestamp={baseOS?.startedAt} />
            </OverviewBoxItem>
            <OverviewBoxItem label="Ended At" isEmpty={baseOS?.endedAt === undefined} emptyMessage="Actively Running">
              <FormattedTimestamp timestamp={baseOS?.endedAt} />
            </OverviewBoxItem>
            <OverviewBoxItem label="SBOM ID" isEmpty={baseOS?.name === undefined} emptyMessage="No SBOM ID">
              <Link component={NavLink} to={"/sboms/" + baseOS?.sbomId}>
                Realtime {baseOS?.sbomId.substring(0, 6)}
              </Link>
            </OverviewBoxItem>
          </OverviewBox>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
        Containers
      </Typography>
      <MachineDetailTable workloadItems={filteredWorkloadItems} />
    </BodyWrapperProjectScoped>
  );
};
