import {
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Radio,
  RadioGroup,
  Link,
  Typography,
  FormControlLabel,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";
import { EdgeBitTextField } from "./EdgeBitTextField";
import { ComponentIssueState } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { useClient } from "../api/client";

export function IssueIgnoreModal(props: {
  issue: any;
  ignoreFormOpen: boolean;
  closeIgnoreForm: any;
  desiredSuppression: any;
  setIgnoreVuln: any;
  ignoreVuln: any;
  project: any;
  componentId: string | undefined;
  refreshAfterSave: any;
}) {
  // API client
  const client = useClient(EdgeBitPublicAPIService);

  const handleIgnoreJustificationChange = (event: SelectChangeEvent) => {
    if (props.ignoreVuln) {
      props.setIgnoreVuln({
        id: props.ignoreVuln.id,
        comment: props.ignoreVuln.comment,
        justification: event.target.value,
      });
    } else {
      console.log("No state to mutate");
    }
  };

  // Save ignore Suppression Modal
  const handleIgnoreSave = async () => {
    if (!props.project) {
      console.log("No project ID set, can't create token");
      return;
    }

    try {
      await client.ignoreIssue({
        projectId: props.project.id,
        componentId: props.componentId,
        issueId: props.ignoreVuln?.id as string,
        comment: props.ignoreVuln?.comment,
        justification: props.ignoreVuln?.justification,
      });

      props.refreshAfterSave();
    } catch (err) {
      console.log(err);
      // todo: send back error trigger
    }

    props.closeIgnoreForm(false);
  };

  return (
    <Dialog
      open={props.ignoreFormOpen ? true : false}
      onClose={props.closeIgnoreForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ignore Vulnerability</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enter a justification for why{" "}
          {props.issue && props.issue.id && (
            <Link href={props.issue.details.value?.references[0].url}>{props.issue?.details?.value?.id}</Link>
          )}{" "}
          doesn't apply to this component.
        </DialogContentText>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={props.desiredSuppression?.current}
            value={props.desiredSuppression?.desired}
            onChange={handleIgnoreJustificationChange}
            name="radio-buttons-group"
          >
            <Typography
              variant="body1"
              sx={{
                display: "block",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Common reasons:
            </Typography>
            <FormControlLabel
              value="InlineMitigationsAlreadyExist"
              control={<Radio />}
              label={"Inline mitigations already exist"}
            />
            <FormControlLabel
              value="VulnerableCodeCannotBeControlledByAdversary"
              control={<Radio />}
              label={"Vulnerable code cannot be controlled by adversary"}
            />
            <FormControlLabel
              value="VulnerableCodeNotInExecutePath"
              control={<Radio />}
              label={"Vulnerable code not in execution path"}
            />
            <Typography
              variant="body1"
              sx={{
                display: "block",
                marginTop: "10px",
                fontWeight: "500",
              }}
            >
              Less common reasons:
            </Typography>
            <FormControlLabel value="ComponentNotPresent" control={<Radio />} label={"Dependency not present"} />
            <FormControlLabel
              value="VulnerableCodeNotPresent"
              control={<Radio />}
              label={"Vulnerable code not present"}
            />
            {/* <FormControlLabel value="" control={<Radio />} label={"None"} /> */}
          </RadioGroup>
        </FormControl>
        <DialogContentText id="alert-dialog-description" sx={{ marginTop: "10px", fontWeight: "500", color: "#333" }}>
          Comment or supporting reasoning:
        </DialogContentText>
        <EdgeBitTextField
          label="Comment"
          placeholder=""
          id="comment"
          type="comment"
          variant="filled"
          autoComplete=""
          style={{ marginTop: 11, marginBottom: 11, width: "100%" }}
          onChange={(e) =>
            props.setIgnoreVuln({
              id: props.ignoreVuln?.id,
              comment: e.target.value,
              justification: props.ignoreVuln?.justification,
            })
          }
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}>
        <EdgeBitPrimaryButton onClick={handleIgnoreSave}>Ignore Issue</EdgeBitPrimaryButton>
        <Button onClick={props.closeIgnoreForm} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function IssueModificationMenu(props: {
  anchorEl: any;
  open: boolean;
  onClose: any;
  handleIgnore: any;
  issue: any;
  anchorOrigin: any;
  transformOrigin: any;
}) {
  return (
    <Menu
      id="issue-modification-menu"
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
      MenuListProps={{
        "aria-labelledby": "sbom-button",
      }}
      anchorOrigin={props.anchorOrigin}
      transformOrigin={props.transformOrigin}
    >
      {props.issue?.state !== ComponentIssueState.IGNORED ? (
        <MenuItem onClick={() => props.handleIgnore(props.anchorEl?.dataset.issueid)}>Ignore Vulnerability</MenuItem>
      ) : (
        <MenuItem sx={{ color: "#999" }}>No actions available</MenuItem>
      )}
    </Menu>
  );
}
