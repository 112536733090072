import { Code } from "@bufbuild/connect";
import { Duration } from "@bufbuild/protobuf";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import FormattedTimestamp from "../components/FormattedTimestamp";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { Component, PolicyItem, Project } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const ProjectDetail = () => {
  const { projectId } = useParams();

  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleModifyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleModifyClose = () => {
    setAnchorEl(null);
  };

  const [createPolicyFormOpen, setCreatePolicyFormOpen] = useState<boolean>(false);
  const [createPolicyFormError, setCreatePolicyFormError] = useState<string | null>(null);
  const [modifyPolicyFormOpen, setModifyPolicyFormOpen] = useState<boolean>(false);
  const [modifyPolicyFormError] = useState<string | null>(null);
  const [components, setComponents] = useState<Component[] | undefined>(undefined);
  const [policies, setPolicies] = useState<PolicyItem[] | undefined>(undefined);
  const [projectDetail, setProjectDetail] = useState<Project | undefined>(undefined);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [policyToModify, setPolicyToModify] = useState<PolicyItem | undefined>(undefined);
  const [mutatedPolicy, setMutatedPolicy] = useState({
    projectId: "",
    name: "",
    labelSelector: "",
    componentId: "",
  });
  const [projectIssueSLAPolicy, setProjectIssueSLAPolicy] = useState<SLAPolicy | undefined>(undefined);

  const [selectedComponentId, setselectedComponentName] = React.useState("");
  const handleComponentIdChange = (event: any) => {
    mutatedPolicy.componentId = event.target.value;
    setselectedComponentName(mutatedPolicy.componentId);
  };

  const getComponentName = (componentId: string) => {
    const component = components?.find((component) => component.id === componentId);

    if (component) {
      return component.displayName;
    } else {
      // Component not found
      return "";
    }
  };

  useEffect(() => {
    document.title = projectId + " / Project";
    client.getProject({ projectId: projectId }).then(
      (res) => {
        setProjectDetail(res.project);
        document.title = res.project?.description + " / Project";
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/projects/`);
        setConnectivity(false);
      },
    );

    client.getServerSBOMRoutingPolicy({ projectId: projectId }).then(
      (res) => {
        setPolicies(res.policy?.policyItems);
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/projects/`);
        setConnectivity(false);
      },
    );

    client.listComponents({ projectId: projectId }).then(
      (res) => {
        setComponents(res.components);
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/projects/`);
        setConnectivity(false);
      },
    );

    client.getProjectIssueSLAPolicy({ projectId: projectId }).then(
      (res) => {
        setProjectIssueSLAPolicy({
          criticalSla: daysStringFromDuration(res.policy?.criticalSla),
          highSla: daysStringFromDuration(res.policy?.highSla),
          mediumSla: daysStringFromDuration(res.policy?.mediumSla),
          lowSla: daysStringFromDuration(res.policy?.lowSla),
          negligibleSla: daysStringFromDuration(res.policy?.negligibleSla),
        });
      },
      (err) => {
        console.log(err);
        if (err.code === Code.NotFound) navigate(`/projects/`);
        setConnectivity(false);
      },
    );
  }, [client, navigate, projectId]);

  // Create Policy Modal
  const openCreatePolicyForm = () => {
    setCreatePolicyFormOpen(true);
  };

  const closeCreatePolicyForm = () => {
    setMutatedPolicy({
      ...mutatedPolicy,
      labelSelector: "",
      name: "",
      componentId: "",
    });
    setCreatePolicyFormOpen(false);
  };

  // Create the Policy
  const createPolicy = async () => {
    if (!mutatedPolicy.name.trim()) {
      setCreatePolicyFormError("Invalid Policy Name");
      return;
    }

    const newPolicyItem = {
      displayName: mutatedPolicy.name,
      labelSelector: mutatedPolicy.labelSelector,
      componentId: mutatedPolicy.componentId,
    };

    let updatedPolicies: any[] = [newPolicyItem];
    if (policies) {
      updatedPolicies = [...policies, newPolicyItem];
    }

    client
      .updateServerSbomRoutingPolicy({
        projectId: projectId,
        policyItems: updatedPolicies,
      })
      .then(
        (res) => {
          setPolicies(Array.from(updatedPolicies));
          closeCreatePolicyForm();
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
  };

  // Modify Policy Modal
  const openModifyPolicyForm = () => {
    if (policies && anchorEl?.dataset.policyindex) {
      setPolicyToModify(policies[Number(anchorEl.dataset.policyindex)]);
      if (policyToModify) {
        mutatedPolicy.labelSelector = policyToModify.labelSelector;
        setselectedComponentName(policyToModify.componentId);
      }
      setModifyPolicyFormOpen(true);
    }
  };

  const closeModifyPolicyForm = () => {
    setMutatedPolicy({
      ...mutatedPolicy,
      labelSelector: "",
      name: "",
      componentId: "",
    });
    setModifyPolicyFormOpen(false);
    handleModifyClose();
  };

  // Modify the Policy
  const modifyPolicy = async () => {
    if (policies) {
      let updatedPolicies = [...policies];
      updatedPolicies[Number(anchorEl?.dataset.policyindex)].labelSelector = mutatedPolicy.labelSelector;
      updatedPolicies[Number(anchorEl?.dataset.policyindex)].componentId = mutatedPolicy.componentId;

      client
        .updateServerSbomRoutingPolicy({
          projectId: projectId,
          policyItems: updatedPolicies,
        })
        .then(
          (res) => {
            setPolicies(Array.from(updatedPolicies));
            closeModifyPolicyForm();
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );
    }
  };

  // Delete Policy
  const deletePolicy = () => {
    if (policies && anchorEl) {
      const policyIndex = Number(anchorEl.dataset.policyindex);
      if (!isNaN(policyIndex) && policyIndex >= 0 && policyIndex < policies.length) {
        const updatedPolicies = policies.filter((_, index) => index !== policyIndex);

        client
          .updateServerSbomRoutingPolicy({
            projectId: projectId,
            policyItems: updatedPolicies,
          })
          .then(
            (res) => {
              setPolicies(updatedPolicies);
              closeModifyPolicyForm();
            },
            (err) => {
              console.log(err);
              setConnectivity(false);
            },
          );
      } else {
        console.error("Invalid policy index");
      }
    }
  };

  // Function to move a policy up
  const movePolicyUp = (index: number) => {
    if (policies && projectId) {
      if (index > 0) {
        const updatedOrder = [...policies];
        [updatedOrder[index - 1], updatedOrder[index]] = [updatedOrder[index], updatedOrder[index - 1]];
        setPolicies(Array.from(updatedOrder));

        client
          .updateServerSbomRoutingPolicy({
            projectId: projectId,
            policyItems: updatedOrder,
          })
          .then(
            (res) => {},
            (err) => {
              console.log(err);
              setConnectivity(false);
            },
          );
      }
    }
  };

  // Function to move a policy down
  const movePolicyDown = (index: number) => {
    if (policies && projectId) {
      if (index < policies.length - 1) {
        const updatedOrder = [...policies];
        [updatedOrder[index], updatedOrder[index + 1]] = [updatedOrder[index + 1], updatedOrder[index]];
        setPolicies(Array.from(updatedOrder));

        client
          .updateServerSbomRoutingPolicy({
            projectId: projectId,
            policyItems: updatedOrder,
          })
          .then(
            (res) => {},
            (err) => {
              console.log(err);
              setConnectivity(false);
            },
          );
      }
    }
  };

  const saveSLAPolicy = () => {
    if (projectIssueSLAPolicy) {
      client
        .setProjectIssueSLAPolicy({
          projectId: projectId,
          criticalSla: {
            seconds: BigInt(Number(projectIssueSLAPolicy.criticalSla) * 86400),
          },
          highSla: {
            seconds: BigInt(Number(projectIssueSLAPolicy.highSla) * 86400),
          },
          mediumSla: {
            seconds: BigInt(Number(projectIssueSLAPolicy.mediumSla) * 86400),
          },
          lowSla: {
            seconds: BigInt(Number(projectIssueSLAPolicy.lowSla) * 86400),
          },
          negligibleSla: {
            seconds: BigInt(Number(projectIssueSLAPolicy.negligibleSla) * 86400),
          },
        })
        .then(
          (res) => {
            setProjectIssueSLAPolicy({
              criticalSla: daysStringFromDuration(res.policy?.criticalSla),
              highSla: daysStringFromDuration(res.policy?.highSla),
              mediumSla: daysStringFromDuration(res.policy?.mediumSla),
              lowSla: daysStringFromDuration(res.policy?.lowSla),
              negligibleSla: daysStringFromDuration(res.policy?.negligibleSla),
            });
            navigate(`/projects/`);
          },
          (err) => {
            console.log(err);
            setConnectivity(false);
          },
        );
    }
  };

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Projects / {projectDetail?.description ? projectDetail.description : projectDetail?.name}
      </Typography>

      {/* Create Policy Modal */}
      <Dialog open={createPolicyFormOpen} onClose={closeCreatePolicyForm} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">Create New Mapping</DialogTitle>
        <DialogContent>
          <EdgeBitTextField
            id="name"
            label="Display Name"
            value={mutatedPolicy.name}
            variant="filled"
            autoFocus
            onChange={(e) => setMutatedPolicy({ ...mutatedPolicy, name: e.target.value })}
            sx={{ marginBottom: "10px", marginTop: "15px", minWidth: "200px" }}
          />
          <EdgeBitTextField
            id="labelSelector"
            label="Machine Label Selector"
            value={mutatedPolicy.labelSelector}
            variant="filled"
            onChange={(e) =>
              setMutatedPolicy({
                ...mutatedPolicy,
                labelSelector: e.target.value,
              })
            }
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "15px", minWidth: "200px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
            Label selector used to map Real-Time Machine SBOMs to components based on machine labels. The first matching
            component will receive the SBOMs.
          </Typography>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Component</InputLabel>
            <Select
              labelId="component"
              id="component"
              value={selectedComponentId}
              label="Component"
              onChange={handleComponentIdChange}
            >
              {components?.map((component) => (
                <MenuItem key={component.id} value={component.id}>
                  {component.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
            The component to group selected SBOM's within
          </Typography>
          {createPolicyFormError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {createPolicyFormError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={createPolicy}>Create Mapping</EdgeBitPrimaryButton>
          <Button tabIndex={-1} onClick={closeCreatePolicyForm}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modify Policy Modal */}
      <Dialog open={modifyPolicyFormOpen} onClose={closeModifyPolicyForm} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">Modify Mapping: {policyToModify?.displayName}</DialogTitle>
        <DialogContent>
          <EdgeBitTextField
            id="labelSelector"
            label={`Machine Label Selector:`}
            value={mutatedPolicy.labelSelector}
            variant="filled"
            onChange={(e) =>
              setMutatedPolicy({
                ...mutatedPolicy,
                labelSelector: e.target.value,
              })
            }
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "15px", minWidth: "200px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
            Label selector used to map Real-Time Machine SBOMs to components based on machine labels. The first matching
            component will receive the SBOMs.
          </Typography>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Component</InputLabel>
            <Select
              labelId="component"
              id="component"
              value={selectedComponentId}
              label="Component"
              onChange={handleComponentIdChange}
            >
              {components?.map((component) => (
                <MenuItem key={component.id} value={component.id}>
                  {component.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
            The component to group selected SBOM's within
          </Typography>
          {modifyPolicyFormError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {modifyPolicyFormError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={modifyPolicy}>Modify Mapping</EdgeBitPrimaryButton>
          <Button tabIndex={-1} onClick={closeModifyPolicyForm}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {projectDetail && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <OverviewBox title="Metadata">
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Name
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                      {projectDetail.name}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Description
                    </Typography>
                    {projectDetail.description ? (
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        {projectDetail.description}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "10px",
                          color: "#999",
                        }}
                      >
                        None
                      </Typography>
                    )}
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Created At
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                      <FormattedTimestamp timestamp={projectDetail.createdAt} />
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                      Updated At
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                      <FormattedTimestamp timestamp={projectDetail.updatedAt} />
                    </Typography>
                  </Grid>
                </Grid>
              </OverviewBox>
            </Grid>
          </Grid>

          <Container sx={{ mt: 3, mb: 3 }} disableGutters>
            <Menu
              id="policy-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleModifyClose}
              MenuListProps={{
                "aria-labelledby": "proj-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={openModifyPolicyForm}>Modify Mapping</MenuItem>
              <MenuItem onClick={deletePolicy}>Delete Mapping</MenuItem>
            </Menu>
            <Typography variant="h4" gutterBottom>
              Machine to Component Mappings
            </Typography>
            <EdgeBitPrimaryButton
              type="submit"
              variant="outlined"
              size="medium"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
              onClick={openCreatePolicyForm}
            >
              Create Mapping
            </EdgeBitPrimaryButton>
            <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Priority</TableCell>
                    <TableCell>Display Name</TableCell>
                    <TableCell>Machine Label Selector</TableCell>
                    <TableCell>Component Name</TableCell>
                    <TableCell sx={{ width: "30px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policies?.map((policy: PolicyItem, index: number) => (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell sx={{ width: "80px", verticalAlign: "middle" }}>
                        <Link onClick={() => movePolicyUp(index)}>
                          <ArrowUpwardIcon sx={{ fill: index === 0 ? "#ccc" : "#6096FF" }} />
                        </Link>
                        <Link onClick={() => movePolicyDown(index)}>
                          <ArrowDownwardIcon
                            sx={{
                              fill: index === policies?.length - 1 ? "#ccc" : "#6096FF",
                            }}
                          />
                        </Link>
                      </TableCell>
                      <TableCell>{policy.displayName}</TableCell>
                      <TableCell>
                        {policy.labelSelector !== "" ? (
                          policy.labelSelector
                        ) : (
                          <Box sx={{ color: "#999", display: "inline-block" }}>Empty selector (matches all)</Box>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link component={NavLink} to={"/components/" + policy.componentId}>
                          {getComponentName(policy.componentId)}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Button
                          id="policy-button"
                          aria-controls={menuOpen ? "policy-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={menuOpen ? "true" : undefined}
                          onClick={handleModifyClick}
                          sx={{ minWidth: "auto", float: "left" }}
                          data-policyindex={index}
                        >
                          <SettingsIcon
                            sx={{
                              verticalAlign: "middle",
                              fontSize: "18px",
                              color: "#666",
                            }}
                          ></SettingsIcon>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
          <IssueSLASettings
            policy={projectIssueSLAPolicy}
            setPolicy={setProjectIssueSLAPolicy}
            savePolicy={saveSLAPolicy}
          />
        </div>
      )}
    </BodyWrapper>
  );
};

interface SLAPolicy {
  criticalSla: string;
  highSla: string;
  mediumSla: string;
  lowSla: string;
  negligibleSla: string;
}

interface IssueSLASettingsProps {
  policy: SLAPolicy | undefined;
  setPolicy: (policy: SLAPolicy) => void;
  savePolicy: () => void;
}

function IssueSLASettings(props: IssueSLASettingsProps) {
  if (props.policy === undefined) {
    return (
      <Container sx={{ mt: 3, mb: 3 }} disableGutters>
        <Typography variant="h4" gutterBottom>
          Vulnerability SLAs
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 3, mb: 3 }} disableGutters>
      <Typography variant="h4" gutterBottom sx={{ marginTop: "50px" }}>
        Vulnerability SLAs
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999" }}>
        EdgeBit will calculate individual SLAs on this target. Unset targets will not have an SLA calculated.
        <br />
        These targets will be exported to your configured integration when applicable.
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Critical Vulnerability
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={1} lg={1}>
          <TextField
            required
            id="critical-sla"
            value={props.policy.criticalSla}
            onChange={(e) => {
              props.setPolicy({
                ...props.policy!,
                criticalSla: e.target.value,
              });
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Box sx={{ lineHeight: "40px" }}>days</Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            High Vulnerability
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={1} lg={1}>
          <TextField
            required
            id="high-sla"
            value={props.policy.highSla}
            onChange={(e) => {
              props.setPolicy({
                ...props.policy!,
                highSla: e.target.value,
              });
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Box sx={{ lineHeight: "40px" }}>days</Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Medium Vulnerability
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={1} lg={1}>
          <TextField
            required
            id="medium-sla"
            value={props.policy.mediumSla}
            onChange={(e) => {
              props.setPolicy({
                ...props.policy!,
                mediumSla: e.target.value,
              });
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Box sx={{ lineHeight: "40px" }}>days</Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Low Vulnerability
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={1} lg={1}>
          <TextField
            required
            id="low-sla"
            value={props.policy.lowSla}
            onChange={(e) => {
              props.setPolicy({
                ...props.policy!,
                lowSla: e.target.value,
              });
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Box sx={{ lineHeight: "40px" }}>days</Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Negligible Vulnerability
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={1} lg={1}>
          <TextField
            required
            id="negligible-sla"
            value={props.policy.negligibleSla}
            onChange={(e) => {
              props.setPolicy({
                ...props.policy!,
                negligibleSla: e.target.value,
              });
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Box sx={{ lineHeight: "40px" }}>days</Box>
        </Grid>
      </Grid>

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "20px" }}
        onClick={props.savePolicy}
      >
        Save SLA Policy
      </EdgeBitPrimaryButton>
    </Container>
  );
}

function daysStringFromDuration(duration: Duration | undefined): string {
  if (!duration) {
    return "";
  }

  const durationInSeconds = duration.seconds ?? 0;
  const durationInDays = Math.floor(Number(durationInSeconds) / 86400);
  const durationString = durationInDays.toString();

  return durationString;
}
