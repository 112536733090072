import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { SBOMTable } from "../components/SBOMTable";
import { UploadSBOMButton } from "../components/UploadSBOMButton";
import {
  SBOMListItem,
  fetchSBOMs,
  selectConnectivity,
  selectProjectID,
  selectSBOMList,
  selectValue,
  setProjectID,
  setValue,
} from "../features/sbomListSlice";
import useProjectId from "../hooks/useProjectId";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ marginTop: "30px" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SBOMList = () => {
  const sbomList = useAppSelector(selectSBOMList);
  const connectivity = useAppSelector(selectConnectivity);
  const value = useAppSelector(selectValue);
  const projectID = useAppSelector(selectProjectID);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setValue(newValue));
  };

  useProjectId((projectId: string) => {
    dispatch(fetchSBOMs({ projectId }));
    dispatch(setProjectID(projectId));
  });

  useEffect(() => {
    document.title = "SBOMs - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Source Bills of Material
      </Typography>
      <Container sx={{ mt: 3 }} disableGutters>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Build Pipeline Artifacts" {...a11yProps(0)} />
              <Tab label="Realtime Server" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {projectID && (
            <Box sx={{ marginTop: "20px" }}>
              <UploadSBOMButton projectId={projectID} />
            </Box>
          )}
          <TabPanel value={value} index={0}>
            {sbomList && (
              <SBOMTable
                sboms={sbomList.filter((sbom: { machineId: string }) => !sbom.machineId)}
                showComponent={true}
              ></SBOMTable>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {sbomList && (
              <SBOMTable
                sboms={sbomList.filter((sbom: SBOMListItem) => sbom.machineRef?.id !== undefined)}
                showComponent={true}
                showMachine={true}
              ></SBOMTable>
            )}
          </TabPanel>
        </Box>
      </Container>
    </BodyWrapperProjectScoped>
  );
};
