import { Box, Grid, TextField, TextFieldProps, Typography } from "@mui/material";

export interface SettingsFieldProps {
  id: string;
  value?: string;
  label: string;
  onChange: TextFieldProps["onChange"];
  helpText: string;
  show?: boolean;
}

export default function SettingsField(props: SettingsFieldProps) {
  // Hide only when show is explicitly set to false
  if (props.show === false) {
    return null;
  }

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={6} sm={4} md={4} lg={3} sx={{ fontWeight: "600" }}>
        <Box sx={{ marginTop: "6px" }}>{props.label}</Box>
      </Grid>
      <Grid item xs={8} sm={8} md={6} lg={6}>
        <TextField
          hiddenLabel
          id={props.id}
          size="small"
          sx={{ width: "100%" }}
          value={props.value}
          onChange={props.onChange}
        />
        <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
          {props.helpText}
        </Typography>
      </Grid>
    </Grid>
  );
}
