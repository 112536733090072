import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";

export interface Project {
  id: string;
  name: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
}

interface ProjectState {
  currentProject: Project | null;
  projects: Project[];
  status: "idle" | "loading" | "failed";
  createProjectStatus: "idle" | "loading" | "failed";
  createProjectError: string | null;
}

const initialState: ProjectState = {
  currentProject: null,
  projects: [],
  status: "idle",
  createProjectStatus: "idle",
  createProjectError: null,
};

export const fetchProjects = createAsyncThunk<Project[], void, ThunkExtra>(
  "project/fetchProjects",
  async (_, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.listProjects({});
      if (response.projects.length > 0) {
        const projects: Project[] = response.projects.map((project) => ({
          ...project,
          createdAt: project.createdAt ? String(project.createdAt.toDate()) : "",
          updatedAt: project.updatedAt ? String(project.updatedAt.toDate()) : "",
        }));

        return projects;
      } else {
        return thunkAPI.rejectWithValue("No projects found");
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const createProject = createAsyncThunk<Project, { name: string; description: string }, ThunkExtra>(
  "project/createProject",
  async ({ name, description }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      const response = await apiClient.createProject({ name: name, description: description });
      if (!response.project) {
        throw new Error("SBOM data is missing in the response");
      }

      return response.project as Project;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateProject = createAsyncThunk<void, { id: string; name: string; description: string }, ThunkExtra>(
  "project/updateProject",
  async ({ id, name, description }, thunkAPI) => {
    try {
      const { apiClient } = thunkAPI.extra;
      await apiClient.updateProject({
        projectId: id,
        name: name,
        description: description,
      });
      return;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setCurrentProject: (state, action: PayloadAction<Project | null>) => {
      state.currentProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "idle";
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createProject.pending, (state) => {
        state.createProjectStatus = "loading";
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.createProjectStatus = "idle";
        state.projects.push(action.payload);
        state.currentProject = action.payload; // Set the new project as the current project
      })
      .addCase(createProject.rejected, (state, action) => {
        state.createProjectStatus = "failed";
        state.createProjectError = action.payload as string;
      })
      .addCase(updateProject.fulfilled, (state, action) => {})
      .addCase(updateProject.rejected, (state, action) => {});
  },
});

export const { setCurrentProject } = projectSlice.actions;

export const selectCurrentProject = (state: RootState) => state.project.currentProject;
export const selectProjects = (state: RootState) => state.project.projects;
export const selectProjectStatus = (state: RootState) => state.project.status;
export const selectCreateProjectStatus = (state: RootState) => state.project.createProjectStatus;
export const selectCreateProjectError = (state: RootState) => state.project.createProjectError;

export default projectSlice.reducer;
