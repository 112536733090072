import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import { VCSRepository } from "../pb/edgebit/platform/v1alpha/platform_pb";

export interface GitHubRepositories {
  repos: VCSRepository[];
  loading: boolean;
}

export function GitHubAllRepos(props: GitHubRepositories) {
  return (
    <Container disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Repository Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.repos.map((repo: VCSRepository) => {
              return (
                <TableRow
                  key={repo.owner + repo.name}
                  sx={{
                    height: "53px",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell sx={{ width: "50%" }}>
                    <Box sx={{ fontWeight: "500" }}>
                      <Box
                        component="img"
                        sx={{
                          display: "inline-block",
                          borderRadius: "3px",
                          width: "20px",
                          height: "20px",
                          background: "#f5f5f5",
                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                        src={"https://avatars.githubusercontent.com/" + repo.owner}
                      />
                      {repo.owner + "/" + repo.name}
                    </Box>
                    <Box sx={{ fontSize: "11px", marginTop: "3px" }}>{repo.description}</Box>
                  </TableCell>
                </TableRow>
              );
            })}
            {!props.loading && props.repos.length === 0 && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      EdgeBit can't access any repositories
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Grant EdgeBit access to more repositories on GitHub
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {props.loading && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "400px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                      Loading GitHub Repositories...
                    </Typography>
                    <LinearProgress
                      sx={{
                        width: "200px",
                        marginTop: "3px",
                        marginRight: "auto",
                        marginBottom: "20px",
                        marginLeft: "auto",
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
