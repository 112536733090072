// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/platform.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum edgebit.platform.v1alpha.VulnSeverity
 */
export enum VulnSeverity {
  /**
   * @generated from enum value: VULN_SEVERITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VULN_SEVERITY_NEGLIGIBLE = 1;
   */
  NEGLIGIBLE = 1,

  /**
   * @generated from enum value: VULN_SEVERITY_LOW = 2;
   */
  LOW = 2,

  /**
   * @generated from enum value: VULN_SEVERITY_MEDIUM = 3;
   */
  MEDIUM = 3,

  /**
   * @generated from enum value: VULN_SEVERITY_HIGH = 4;
   */
  HIGH = 4,

  /**
   * @generated from enum value: VULN_SEVERITY_CRITICAL = 5;
   */
  CRITICAL = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(VulnSeverity)
proto3.util.setEnumType(VulnSeverity, "edgebit.platform.v1alpha.VulnSeverity", [
  { no: 0, name: "VULN_SEVERITY_UNSPECIFIED" },
  { no: 1, name: "VULN_SEVERITY_NEGLIGIBLE" },
  { no: 2, name: "VULN_SEVERITY_LOW" },
  { no: 3, name: "VULN_SEVERITY_MEDIUM" },
  { no: 4, name: "VULN_SEVERITY_HIGH" },
  { no: 5, name: "VULN_SEVERITY_CRITICAL" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.VulnFixState
 */
export enum VulnFixState {
  /**
   * @generated from enum value: VULN_FIX_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VULN_FIX_STATE_NOTFIXED = 1;
   */
  NOTFIXED = 1,

  /**
   * @generated from enum value: VULN_FIX_STATE_WONTFIX = 2;
   */
  WONTFIX = 2,

  /**
   * @generated from enum value: VULN_FIX_STATE_FIXED = 3;
   */
  FIXED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(VulnFixState)
proto3.util.setEnumType(VulnFixState, "edgebit.platform.v1alpha.VulnFixState", [
  { no: 0, name: "VULN_FIX_STATE_UNSPECIFIED" },
  { no: 1, name: "VULN_FIX_STATE_NOTFIXED" },
  { no: 2, name: "VULN_FIX_STATE_WONTFIX" },
  { no: 3, name: "VULN_FIX_STATE_FIXED" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.SBOMFormat
 */
export enum SBOMFormat {
  /**
   * @generated from enum value: SBOM_FORMAT_UNSPECIFIED = 0;
   */
  SBOM_FORMAT_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SBOM_FORMAT_SYFT = 1;
   */
  SBOM_FORMAT_SYFT = 1,

  /**
   * @generated from enum value: SBOM_FORMAT_SPDX_JSON = 2;
   */
  SBOM_FORMAT_SPDX_JSON = 2,

  /**
   * @generated from enum value: SBOM_FORMAT_CYCLONEDX_JSON = 3;
   */
  SBOM_FORMAT_CYCLONEDX_JSON = 3,

  /**
   * @generated from enum value: SBOM_FORMAT_CYCLONEDX_XML = 4;
   */
  SBOM_FORMAT_CYCLONEDX_XML = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SBOMFormat)
proto3.util.setEnumType(SBOMFormat, "edgebit.platform.v1alpha.SBOMFormat", [
  { no: 0, name: "SBOM_FORMAT_UNSPECIFIED" },
  { no: 1, name: "SBOM_FORMAT_SYFT" },
  { no: 2, name: "SBOM_FORMAT_SPDX_JSON" },
  { no: 3, name: "SBOM_FORMAT_CYCLONEDX_JSON" },
  { no: 4, name: "SBOM_FORMAT_CYCLONEDX_XML" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.ImageExternalIDType
 */
export enum ImageExternalIDType {
  /**
   * @generated from enum value: IMAGE_EXTERNAL_ID_TYPE_UNSPECIFIED = 0;
   */
  IMAGE_EXTERNAL_ID_TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: IMAGE_EXTERNAL_ID_TYPE_OCI_DOCKER_ID = 1;
   */
  IMAGE_EXTERNAL_ID_TYPE_OCI_DOCKER_ID = 1,

  /**
   * @generated from enum value: IMAGE_EXTERNAL_ID_TYPE_OCI_REPO_DIGEST = 2;
   */
  IMAGE_EXTERNAL_ID_TYPE_OCI_REPO_DIGEST = 2,

  /**
   * @generated from enum value: IMAGE_EXTERNAL_ID_TYPE_AMI_ID = 3;
   */
  IMAGE_EXTERNAL_ID_TYPE_AMI_ID = 3,

  /**
   * @generated from enum value: IMAGE_EXTERNAL_ID_TYPE_GENERIC = 4;
   */
  IMAGE_EXTERNAL_ID_TYPE_GENERIC = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ImageExternalIDType)
proto3.util.setEnumType(ImageExternalIDType, "edgebit.platform.v1alpha.ImageExternalIDType", [
  { no: 0, name: "IMAGE_EXTERNAL_ID_TYPE_UNSPECIFIED" },
  { no: 1, name: "IMAGE_EXTERNAL_ID_TYPE_OCI_DOCKER_ID" },
  { no: 2, name: "IMAGE_EXTERNAL_ID_TYPE_OCI_REPO_DIGEST" },
  { no: 3, name: "IMAGE_EXTERNAL_ID_TYPE_AMI_ID" },
  { no: 4, name: "IMAGE_EXTERNAL_ID_TYPE_GENERIC" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.DormantPackagePolicy
 */
export enum DormantPackagePolicy {
  /**
   * @generated from enum value: DORMANT_PACKAGE_POLICY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DORMANT_PACKAGE_POLICY_IGNORE = 1;
   */
  IGNORE = 1,

  /**
   * @generated from enum value: DORMANT_PACKAGE_POLICY_DROP_1 = 2;
   */
  DROP_1 = 2,

  /**
   * @generated from enum value: DORMANT_PACKAGE_POLICY_DROP_2 = 3;
   */
  DROP_2 = 3,

  /**
   * @generated from enum value: DORMANT_PACKAGE_POLICY_SET_LOW = 4;
   */
  SET_LOW = 4,

  /**
   * @generated from enum value: DORMANT_PACKAGE_POLICY_SET_MEDIUM = 5;
   */
  SET_MEDIUM = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(DormantPackagePolicy)
proto3.util.setEnumType(DormantPackagePolicy, "edgebit.platform.v1alpha.DormantPackagePolicy", [
  { no: 0, name: "DORMANT_PACKAGE_POLICY_UNSPECIFIED" },
  { no: 1, name: "DORMANT_PACKAGE_POLICY_IGNORE" },
  { no: 2, name: "DORMANT_PACKAGE_POLICY_DROP_1" },
  { no: 3, name: "DORMANT_PACKAGE_POLICY_DROP_2" },
  { no: 4, name: "DORMANT_PACKAGE_POLICY_SET_LOW" },
  { no: 5, name: "DORMANT_PACKAGE_POLICY_SET_MEDIUM" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.ComponentType
 */
export enum ComponentType {
  /**
   * @generated from enum value: COMPONENT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPONENT_TYPE_SBOM_COLLECTION = 1;
   */
  SBOM_COLLECTION = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ComponentType)
proto3.util.setEnumType(ComponentType, "edgebit.platform.v1alpha.ComponentType", [
  { no: 0, name: "COMPONENT_TYPE_UNSPECIFIED" },
  { no: 1, name: "COMPONENT_TYPE_SBOM_COLLECTION" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.ComponentIssueState
 */
export enum ComponentIssueState {
  /**
   * @generated from enum value: COMPONENT_ISSUE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPONENT_ISSUE_STATE_OPEN = 1;
   */
  OPEN = 1,

  /**
   * @generated from enum value: COMPONENT_ISSUE_STATE_RESOLVED = 2;
   */
  RESOLVED = 2,

  /**
   * @generated from enum value: COMPONENT_ISSUE_STATE_IGNORED = 3;
   */
  IGNORED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ComponentIssueState)
proto3.util.setEnumType(ComponentIssueState, "edgebit.platform.v1alpha.ComponentIssueState", [
  { no: 0, name: "COMPONENT_ISSUE_STATE_UNSPECIFIED" },
  { no: 1, name: "COMPONENT_ISSUE_STATE_OPEN" },
  { no: 2, name: "COMPONENT_ISSUE_STATE_RESOLVED" },
  { no: 3, name: "COMPONENT_ISSUE_STATE_IGNORED" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.ComponentIssueSeverity
 */
export enum ComponentIssueSeverity {
  /**
   * @generated from enum value: COMPONENT_ISSUE_SEVERITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: COMPONENT_ISSUE_SEVERITY_NEGLIGIBLE = 1;
   */
  NEGLIGIBLE = 1,

  /**
   * @generated from enum value: COMPONENT_ISSUE_SEVERITY_LOW = 2;
   */
  LOW = 2,

  /**
   * @generated from enum value: COMPONENT_ISSUE_SEVERITY_MEDIUM = 3;
   */
  MEDIUM = 3,

  /**
   * @generated from enum value: COMPONENT_ISSUE_SEVERITY_HIGH = 4;
   */
  HIGH = 4,

  /**
   * @generated from enum value: COMPONENT_ISSUE_SEVERITY_CRITICAL = 5;
   */
  CRITICAL = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ComponentIssueSeverity)
proto3.util.setEnumType(ComponentIssueSeverity, "edgebit.platform.v1alpha.ComponentIssueSeverity", [
  { no: 0, name: "COMPONENT_ISSUE_SEVERITY_UNSPECIFIED" },
  { no: 1, name: "COMPONENT_ISSUE_SEVERITY_NEGLIGIBLE" },
  { no: 2, name: "COMPONENT_ISSUE_SEVERITY_LOW" },
  { no: 3, name: "COMPONENT_ISSUE_SEVERITY_MEDIUM" },
  { no: 4, name: "COMPONENT_ISSUE_SEVERITY_HIGH" },
  { no: 5, name: "COMPONENT_ISSUE_SEVERITY_CRITICAL" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.CIBotCommentFlavor
 */
export enum CIBotCommentFlavor {
  /**
   * @generated from enum value: CI_BOT_COMMENT_FLAVOR_UNSPECIFIED = 0;
   */
  CI_BOT_COMMENT_FLAVOR_UNSPECIFIED = 0,

  /**
   * @generated from enum value: CI_BOT_COMMENT_FLAVOR_GITHUB = 1;
   */
  CI_BOT_COMMENT_FLAVOR_GITHUB = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(CIBotCommentFlavor)
proto3.util.setEnumType(CIBotCommentFlavor, "edgebit.platform.v1alpha.CIBotCommentFlavor", [
  { no: 0, name: "CI_BOT_COMMENT_FLAVOR_UNSPECIFIED" },
  { no: 1, name: "CI_BOT_COMMENT_FLAVOR_GITHUB" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.SBOMGenerationSource
 */
export enum SBOMGenerationSource {
  /**
   * @generated from enum value: SBOM_GENERATION_SOURCE_UNSPECIFIED = 0;
   */
  SBOM_GENERATION_SOURCE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SBOM_GENERATION_SOURCE_BUILD = 1;
   */
  SBOM_GENERATION_SOURCE_BUILD = 1,

  /**
   * @generated from enum value: SBOM_GENERATION_SOURCE_SOURCE = 2;
   */
  SBOM_GENERATION_SOURCE_SOURCE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SBOMGenerationSource)
proto3.util.setEnumType(SBOMGenerationSource, "edgebit.platform.v1alpha.SBOMGenerationSource", [
  { no: 0, name: "SBOM_GENERATION_SOURCE_UNSPECIFIED" },
  { no: 1, name: "SBOM_GENERATION_SOURCE_BUILD" },
  { no: 2, name: "SBOM_GENERATION_SOURCE_SOURCE" },
]);

/**
 * @generated from enum edgebit.platform.v1alpha.IntegrationType
 */
export enum IntegrationType {
  /**
   * @generated from enum value: INTEGRATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INTEGRATION_TYPE_GITHUB = 1;
   */
  GITHUB = 1,

  /**
   * @generated from enum value: INTEGRATION_TYPE_JIRA = 2;
   */
  JIRA = 2,

  /**
   * @generated from enum value: INTEGRATION_TYPE_VANTA = 3;
   */
  VANTA = 3,

  /**
   * @generated from enum value: INTEGRATION_TYPE_SLACK = 4;
   */
  SLACK = 4,

  /**
   * @generated from enum value: INTEGRATION_TYPE_AWS = 5;
   */
  AWS = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(IntegrationType)
proto3.util.setEnumType(IntegrationType, "edgebit.platform.v1alpha.IntegrationType", [
  { no: 0, name: "INTEGRATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "INTEGRATION_TYPE_GITHUB" },
  { no: 2, name: "INTEGRATION_TYPE_JIRA" },
  { no: 3, name: "INTEGRATION_TYPE_VANTA" },
  { no: 4, name: "INTEGRATION_TYPE_SLACK" },
  { no: 5, name: "INTEGRATION_TYPE_AWS" },
]);

/**
 * @generated from message edgebit.platform.v1alpha.Project
 */
export class Project extends Message<Project> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Project>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Project";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Project {
    return new Project().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJsonString(jsonString, options);
  }

  static equals(a: Project | PlainMessage<Project> | undefined, b: Project | PlainMessage<Project> | undefined): boolean {
    return proto3.util.equals(Project, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ServerSBOMRoutingPolicy
 */
export class ServerSBOMRoutingPolicy extends Message<ServerSBOMRoutingPolicy> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.PolicyItem policy_items = 1;
   */
  policyItems: PolicyItem[] = [];

  constructor(data?: PartialMessage<ServerSBOMRoutingPolicy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ServerSBOMRoutingPolicy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy_items", kind: "message", T: PolicyItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServerSBOMRoutingPolicy {
    return new ServerSBOMRoutingPolicy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServerSBOMRoutingPolicy {
    return new ServerSBOMRoutingPolicy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServerSBOMRoutingPolicy {
    return new ServerSBOMRoutingPolicy().fromJsonString(jsonString, options);
  }

  static equals(a: ServerSBOMRoutingPolicy | PlainMessage<ServerSBOMRoutingPolicy> | undefined, b: ServerSBOMRoutingPolicy | PlainMessage<ServerSBOMRoutingPolicy> | undefined): boolean {
    return proto3.util.equals(ServerSBOMRoutingPolicy, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PolicyItem
 */
export class PolicyItem extends Message<PolicyItem> {
  /**
   * @generated from field: string display_name = 1;
   */
  displayName = "";

  /**
   * @generated from field: string label_selector = 2;
   */
  labelSelector = "";

  /**
   * @generated from field: string component_id = 3;
   */
  componentId = "";

  constructor(data?: PartialMessage<PolicyItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PolicyItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label_selector", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PolicyItem {
    return new PolicyItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PolicyItem {
    return new PolicyItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PolicyItem {
    return new PolicyItem().fromJsonString(jsonString, options);
  }

  static equals(a: PolicyItem | PlainMessage<PolicyItem> | undefined, b: PolicyItem | PlainMessage<PolicyItem> | undefined): boolean {
    return proto3.util.equals(PolicyItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetServerSBOMRoutingPolicyRequest
 */
export class GetServerSBOMRoutingPolicyRequest extends Message<GetServerSBOMRoutingPolicyRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<GetServerSBOMRoutingPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetServerSBOMRoutingPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetServerSBOMRoutingPolicyRequest {
    return new GetServerSBOMRoutingPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetServerSBOMRoutingPolicyRequest {
    return new GetServerSBOMRoutingPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetServerSBOMRoutingPolicyRequest {
    return new GetServerSBOMRoutingPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetServerSBOMRoutingPolicyRequest | PlainMessage<GetServerSBOMRoutingPolicyRequest> | undefined, b: GetServerSBOMRoutingPolicyRequest | PlainMessage<GetServerSBOMRoutingPolicyRequest> | undefined): boolean {
    return proto3.util.equals(GetServerSBOMRoutingPolicyRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetServerSBOMRoutingPolicyResponse
 */
export class GetServerSBOMRoutingPolicyResponse extends Message<GetServerSBOMRoutingPolicyResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ServerSBOMRoutingPolicy policy = 1;
   */
  policy?: ServerSBOMRoutingPolicy;

  constructor(data?: PartialMessage<GetServerSBOMRoutingPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetServerSBOMRoutingPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: ServerSBOMRoutingPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetServerSBOMRoutingPolicyResponse {
    return new GetServerSBOMRoutingPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetServerSBOMRoutingPolicyResponse {
    return new GetServerSBOMRoutingPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetServerSBOMRoutingPolicyResponse {
    return new GetServerSBOMRoutingPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetServerSBOMRoutingPolicyResponse | PlainMessage<GetServerSBOMRoutingPolicyResponse> | undefined, b: GetServerSBOMRoutingPolicyResponse | PlainMessage<GetServerSBOMRoutingPolicyResponse> | undefined): boolean {
    return proto3.util.equals(GetServerSBOMRoutingPolicyResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateServerSbomRoutingPolicyRequest
 */
export class UpdateServerSbomRoutingPolicyRequest extends Message<UpdateServerSbomRoutingPolicyRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.PolicyItem policy_items = 2;
   */
  policyItems: PolicyItem[] = [];

  constructor(data?: PartialMessage<UpdateServerSbomRoutingPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateServerSbomRoutingPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_items", kind: "message", T: PolicyItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServerSbomRoutingPolicyRequest {
    return new UpdateServerSbomRoutingPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServerSbomRoutingPolicyRequest {
    return new UpdateServerSbomRoutingPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServerSbomRoutingPolicyRequest {
    return new UpdateServerSbomRoutingPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServerSbomRoutingPolicyRequest | PlainMessage<UpdateServerSbomRoutingPolicyRequest> | undefined, b: UpdateServerSbomRoutingPolicyRequest | PlainMessage<UpdateServerSbomRoutingPolicyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateServerSbomRoutingPolicyRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateServerSbomRoutingPolicyResponse
 */
export class UpdateServerSbomRoutingPolicyResponse extends Message<UpdateServerSbomRoutingPolicyResponse> {
  constructor(data?: PartialMessage<UpdateServerSbomRoutingPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateServerSbomRoutingPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateServerSbomRoutingPolicyResponse {
    return new UpdateServerSbomRoutingPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateServerSbomRoutingPolicyResponse {
    return new UpdateServerSbomRoutingPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateServerSbomRoutingPolicyResponse {
    return new UpdateServerSbomRoutingPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateServerSbomRoutingPolicyResponse | PlainMessage<UpdateServerSbomRoutingPolicyResponse> | undefined, b: UpdateServerSbomRoutingPolicyResponse | PlainMessage<UpdateServerSbomRoutingPolicyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateServerSbomRoutingPolicyResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SetProjectIssueSLAPolicyRequest
 */
export class SetProjectIssueSLAPolicyRequest extends Message<SetProjectIssueSLAPolicyRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: google.protobuf.Duration negligible_sla = 2;
   */
  negligibleSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration low_sla = 3;
   */
  lowSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration medium_sla = 4;
   */
  mediumSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration high_sla = 5;
   */
  highSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration critical_sla = 6;
   */
  criticalSla?: Duration;

  constructor(data?: PartialMessage<SetProjectIssueSLAPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SetProjectIssueSLAPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "negligible_sla", kind: "message", T: Duration },
    { no: 3, name: "low_sla", kind: "message", T: Duration },
    { no: 4, name: "medium_sla", kind: "message", T: Duration },
    { no: 5, name: "high_sla", kind: "message", T: Duration },
    { no: 6, name: "critical_sla", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetProjectIssueSLAPolicyRequest {
    return new SetProjectIssueSLAPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetProjectIssueSLAPolicyRequest {
    return new SetProjectIssueSLAPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetProjectIssueSLAPolicyRequest {
    return new SetProjectIssueSLAPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetProjectIssueSLAPolicyRequest | PlainMessage<SetProjectIssueSLAPolicyRequest> | undefined, b: SetProjectIssueSLAPolicyRequest | PlainMessage<SetProjectIssueSLAPolicyRequest> | undefined): boolean {
    return proto3.util.equals(SetProjectIssueSLAPolicyRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProjectIssueSLAPolicy
 */
export class ProjectIssueSLAPolicy extends Message<ProjectIssueSLAPolicy> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: google.protobuf.Duration negligible_sla = 2;
   */
  negligibleSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration low_sla = 3;
   */
  lowSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration medium_sla = 4;
   */
  mediumSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration high_sla = 5;
   */
  highSla?: Duration;

  /**
   * @generated from field: google.protobuf.Duration critical_sla = 6;
   */
  criticalSla?: Duration;

  constructor(data?: PartialMessage<ProjectIssueSLAPolicy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProjectIssueSLAPolicy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "negligible_sla", kind: "message", T: Duration },
    { no: 3, name: "low_sla", kind: "message", T: Duration },
    { no: 4, name: "medium_sla", kind: "message", T: Duration },
    { no: 5, name: "high_sla", kind: "message", T: Duration },
    { no: 6, name: "critical_sla", kind: "message", T: Duration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectIssueSLAPolicy {
    return new ProjectIssueSLAPolicy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectIssueSLAPolicy {
    return new ProjectIssueSLAPolicy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectIssueSLAPolicy {
    return new ProjectIssueSLAPolicy().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectIssueSLAPolicy | PlainMessage<ProjectIssueSLAPolicy> | undefined, b: ProjectIssueSLAPolicy | PlainMessage<ProjectIssueSLAPolicy> | undefined): boolean {
    return proto3.util.equals(ProjectIssueSLAPolicy, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SetProjectIssueSLAPolicyResponse
 */
export class SetProjectIssueSLAPolicyResponse extends Message<SetProjectIssueSLAPolicyResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ProjectIssueSLAPolicy policy = 1;
   */
  policy?: ProjectIssueSLAPolicy;

  constructor(data?: PartialMessage<SetProjectIssueSLAPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SetProjectIssueSLAPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: ProjectIssueSLAPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetProjectIssueSLAPolicyResponse {
    return new SetProjectIssueSLAPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetProjectIssueSLAPolicyResponse {
    return new SetProjectIssueSLAPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetProjectIssueSLAPolicyResponse {
    return new SetProjectIssueSLAPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetProjectIssueSLAPolicyResponse | PlainMessage<SetProjectIssueSLAPolicyResponse> | undefined, b: SetProjectIssueSLAPolicyResponse | PlainMessage<SetProjectIssueSLAPolicyResponse> | undefined): boolean {
    return proto3.util.equals(SetProjectIssueSLAPolicyResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProjectIssueSLAPolicyRequest
 */
export class GetProjectIssueSLAPolicyRequest extends Message<GetProjectIssueSLAPolicyRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<GetProjectIssueSLAPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProjectIssueSLAPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectIssueSLAPolicyRequest {
    return new GetProjectIssueSLAPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectIssueSLAPolicyRequest {
    return new GetProjectIssueSLAPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectIssueSLAPolicyRequest {
    return new GetProjectIssueSLAPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectIssueSLAPolicyRequest | PlainMessage<GetProjectIssueSLAPolicyRequest> | undefined, b: GetProjectIssueSLAPolicyRequest | PlainMessage<GetProjectIssueSLAPolicyRequest> | undefined): boolean {
    return proto3.util.equals(GetProjectIssueSLAPolicyRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProjectIssueSLAPolicyResponse
 */
export class GetProjectIssueSLAPolicyResponse extends Message<GetProjectIssueSLAPolicyResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ProjectIssueSLAPolicy policy = 1;
   */
  policy?: ProjectIssueSLAPolicy;

  constructor(data?: PartialMessage<GetProjectIssueSLAPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProjectIssueSLAPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policy", kind: "message", T: ProjectIssueSLAPolicy },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectIssueSLAPolicyResponse {
    return new GetProjectIssueSLAPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectIssueSLAPolicyResponse {
    return new GetProjectIssueSLAPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectIssueSLAPolicyResponse {
    return new GetProjectIssueSLAPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectIssueSLAPolicyResponse | PlainMessage<GetProjectIssueSLAPolicyResponse> | undefined, b: GetProjectIssueSLAPolicyResponse | PlainMessage<GetProjectIssueSLAPolicyResponse> | undefined): boolean {
    return proto3.util.equals(GetProjectIssueSLAPolicyResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListProjectsRequest
 */
export class ListProjectsRequest extends Message<ListProjectsRequest> {
  constructor(data?: PartialMessage<ListProjectsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListProjectsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectsRequest | PlainMessage<ListProjectsRequest> | undefined, b: ListProjectsRequest | PlainMessage<ListProjectsRequest> | undefined): boolean {
    return proto3.util.equals(ListProjectsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListProjectsResponse
 */
export class ListProjectsResponse extends Message<ListProjectsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Project projects = 1;
   */
  projects: Project[] = [];

  constructor(data?: PartialMessage<ListProjectsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListProjectsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "projects", kind: "message", T: Project, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectsResponse | PlainMessage<ListProjectsResponse> | undefined, b: ListProjectsResponse | PlainMessage<ListProjectsResponse> | undefined): boolean {
    return proto3.util.equals(ListProjectsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateProjectRequest
 */
export class CreateProjectRequest extends Message<CreateProjectRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<CreateProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectRequest | PlainMessage<CreateProjectRequest> | undefined, b: CreateProjectRequest | PlainMessage<CreateProjectRequest> | undefined): boolean {
    return proto3.util.equals(CreateProjectRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateProjectResponse
 */
export class CreateProjectResponse extends Message<CreateProjectResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<CreateProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectResponse | PlainMessage<CreateProjectResponse> | undefined, b: CreateProjectResponse | PlainMessage<CreateProjectResponse> | undefined): boolean {
    return proto3.util.equals(CreateProjectResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProjectRequest
 */
export class GetProjectRequest extends Message<GetProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<GetProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectRequest | PlainMessage<GetProjectRequest> | undefined, b: GetProjectRequest | PlainMessage<GetProjectRequest> | undefined): boolean {
    return proto3.util.equals(GetProjectRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProjectResponse
 */
export class GetProjectResponse extends Message<GetProjectResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<GetProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectResponse | PlainMessage<GetProjectResponse> | undefined, b: GetProjectResponse | PlainMessage<GetProjectResponse> | undefined): boolean {
    return proto3.util.equals(GetProjectResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateProjectRequest
 */
export class UpdateProjectRequest extends Message<UpdateProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  constructor(data?: PartialMessage<UpdateProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectRequest {
    return new UpdateProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectRequest {
    return new UpdateProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectRequest {
    return new UpdateProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectRequest | PlainMessage<UpdateProjectRequest> | undefined, b: UpdateProjectRequest | PlainMessage<UpdateProjectRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProjectRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateProjectResponse
 */
export class UpdateProjectResponse extends Message<UpdateProjectResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Project project = 1;
   */
  project?: Project;

  constructor(data?: PartialMessage<UpdateProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectResponse {
    return new UpdateProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectResponse {
    return new UpdateProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectResponse {
    return new UpdateProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectResponse | PlainMessage<UpdateProjectResponse> | undefined, b: UpdateProjectResponse | PlainMessage<UpdateProjectResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProjectResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateAgentDeployTokenRequest
 */
export class GenerateAgentDeployTokenRequest extends Message<GenerateAgentDeployTokenRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: repeated string roles = 2;
   */
  roles: string[] = [];

  constructor(data?: PartialMessage<GenerateAgentDeployTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateAgentDeployTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAgentDeployTokenRequest {
    return new GenerateAgentDeployTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAgentDeployTokenRequest {
    return new GenerateAgentDeployTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAgentDeployTokenRequest {
    return new GenerateAgentDeployTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAgentDeployTokenRequest | PlainMessage<GenerateAgentDeployTokenRequest> | undefined, b: GenerateAgentDeployTokenRequest | PlainMessage<GenerateAgentDeployTokenRequest> | undefined): boolean {
    return proto3.util.equals(GenerateAgentDeployTokenRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateAgentDeployTokenResponse
 */
export class GenerateAgentDeployTokenResponse extends Message<GenerateAgentDeployTokenResponse> {
  /**
   * @generated from field: string deploy_token = 1;
   */
  deployToken = "";

  constructor(data?: PartialMessage<GenerateAgentDeployTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateAgentDeployTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deploy_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateAgentDeployTokenResponse {
    return new GenerateAgentDeployTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateAgentDeployTokenResponse {
    return new GenerateAgentDeployTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateAgentDeployTokenResponse {
    return new GenerateAgentDeployTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateAgentDeployTokenResponse | PlainMessage<GenerateAgentDeployTokenResponse> | undefined, b: GenerateAgentDeployTokenResponse | PlainMessage<GenerateAgentDeployTokenResponse> | undefined): boolean {
    return proto3.util.equals(GenerateAgentDeployTokenResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateAgentDeployTokenRequest
 */
export class CreateAgentDeployTokenRequest extends Message<CreateAgentDeployTokenRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated string roles = 3;
   */
  roles: string[] = [];

  constructor(data?: PartialMessage<CreateAgentDeployTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateAgentDeployTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAgentDeployTokenRequest {
    return new CreateAgentDeployTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAgentDeployTokenRequest {
    return new CreateAgentDeployTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAgentDeployTokenRequest {
    return new CreateAgentDeployTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAgentDeployTokenRequest | PlainMessage<CreateAgentDeployTokenRequest> | undefined, b: CreateAgentDeployTokenRequest | PlainMessage<CreateAgentDeployTokenRequest> | undefined): boolean {
    return proto3.util.equals(CreateAgentDeployTokenRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateAgentDeployTokenResponse
 */
export class CreateAgentDeployTokenResponse extends Message<CreateAgentDeployTokenResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  constructor(data?: PartialMessage<CreateAgentDeployTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateAgentDeployTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAgentDeployTokenResponse {
    return new CreateAgentDeployTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAgentDeployTokenResponse {
    return new CreateAgentDeployTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAgentDeployTokenResponse {
    return new CreateAgentDeployTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAgentDeployTokenResponse | PlainMessage<CreateAgentDeployTokenResponse> | undefined, b: CreateAgentDeployTokenResponse | PlainMessage<CreateAgentDeployTokenResponse> | undefined): boolean {
    return proto3.util.equals(CreateAgentDeployTokenResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListAgentDeployTokensRequest
 */
export class ListAgentDeployTokensRequest extends Message<ListAgentDeployTokensRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListAgentDeployTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListAgentDeployTokensRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAgentDeployTokensRequest {
    return new ListAgentDeployTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAgentDeployTokensRequest {
    return new ListAgentDeployTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAgentDeployTokensRequest {
    return new ListAgentDeployTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAgentDeployTokensRequest | PlainMessage<ListAgentDeployTokensRequest> | undefined, b: ListAgentDeployTokensRequest | PlainMessage<ListAgentDeployTokensRequest> | undefined): boolean {
    return proto3.util.equals(ListAgentDeployTokensRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListAgentDeployTokensResponse
 */
export class ListAgentDeployTokensResponse extends Message<ListAgentDeployTokensResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.AgentDeployToken tokens = 1;
   */
  tokens: AgentDeployToken[] = [];

  constructor(data?: PartialMessage<ListAgentDeployTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListAgentDeployTokensResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tokens", kind: "message", T: AgentDeployToken, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAgentDeployTokensResponse {
    return new ListAgentDeployTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAgentDeployTokensResponse {
    return new ListAgentDeployTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAgentDeployTokensResponse {
    return new ListAgentDeployTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAgentDeployTokensResponse | PlainMessage<ListAgentDeployTokensResponse> | undefined, b: ListAgentDeployTokensResponse | PlainMessage<ListAgentDeployTokensResponse> | undefined): boolean {
    return proto3.util.equals(ListAgentDeployTokensResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AgentDeployToken
 */
export class AgentDeployToken extends Message<AgentDeployToken> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_used = 5;
   */
  lastUsed?: Timestamp;

  /**
   * @generated from field: repeated string roles = 6;
   */
  roles: string[] = [];

  constructor(data?: PartialMessage<AgentDeployToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AgentDeployToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "last_used", kind: "message", T: Timestamp },
    { no: 6, name: "roles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgentDeployToken {
    return new AgentDeployToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgentDeployToken {
    return new AgentDeployToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgentDeployToken {
    return new AgentDeployToken().fromJsonString(jsonString, options);
  }

  static equals(a: AgentDeployToken | PlainMessage<AgentDeployToken> | undefined, b: AgentDeployToken | PlainMessage<AgentDeployToken> | undefined): boolean {
    return proto3.util.equals(AgentDeployToken, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteAgentDeployTokenRequest
 */
export class DeleteAgentDeployTokenRequest extends Message<DeleteAgentDeployTokenRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string token_id = 2;
   */
  tokenId = "";

  constructor(data?: PartialMessage<DeleteAgentDeployTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteAgentDeployTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAgentDeployTokenRequest {
    return new DeleteAgentDeployTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAgentDeployTokenRequest {
    return new DeleteAgentDeployTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAgentDeployTokenRequest {
    return new DeleteAgentDeployTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAgentDeployTokenRequest | PlainMessage<DeleteAgentDeployTokenRequest> | undefined, b: DeleteAgentDeployTokenRequest | PlainMessage<DeleteAgentDeployTokenRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAgentDeployTokenRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteAgentDeployTokenResponse
 */
export class DeleteAgentDeployTokenResponse extends Message<DeleteAgentDeployTokenResponse> {
  constructor(data?: PartialMessage<DeleteAgentDeployTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteAgentDeployTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAgentDeployTokenResponse {
    return new DeleteAgentDeployTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAgentDeployTokenResponse {
    return new DeleteAgentDeployTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAgentDeployTokenResponse {
    return new DeleteAgentDeployTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAgentDeployTokenResponse | PlainMessage<DeleteAgentDeployTokenResponse> | undefined, b: DeleteAgentDeployTokenResponse | PlainMessage<DeleteAgentDeployTokenResponse> | undefined): boolean {
    return proto3.util.equals(DeleteAgentDeployTokenResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListMachinesRequest
 */
export class ListMachinesRequest extends Message<ListMachinesRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListMachinesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListMachinesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMachinesRequest {
    return new ListMachinesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMachinesRequest {
    return new ListMachinesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMachinesRequest {
    return new ListMachinesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMachinesRequest | PlainMessage<ListMachinesRequest> | undefined, b: ListMachinesRequest | PlainMessage<ListMachinesRequest> | undefined): boolean {
    return proto3.util.equals(ListMachinesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListMachinesResponse
 */
export class ListMachinesResponse extends Message<ListMachinesResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Machine machines = 1;
   */
  machines: Machine[] = [];

  constructor(data?: PartialMessage<ListMachinesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListMachinesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "machines", kind: "message", T: Machine, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMachinesResponse {
    return new ListMachinesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMachinesResponse {
    return new ListMachinesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMachinesResponse {
    return new ListMachinesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMachinesResponse | PlainMessage<ListMachinesResponse> | undefined, b: ListMachinesResponse | PlainMessage<ListMachinesResponse> | undefined): boolean {
    return proto3.util.equals(ListMachinesResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetMachineRequest
 */
export class GetMachineRequest extends Message<GetMachineRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string machine_id = 2;
   */
  machineId = "";

  constructor(data?: PartialMessage<GetMachineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetMachineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMachineRequest {
    return new GetMachineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMachineRequest {
    return new GetMachineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMachineRequest {
    return new GetMachineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMachineRequest | PlainMessage<GetMachineRequest> | undefined, b: GetMachineRequest | PlainMessage<GetMachineRequest> | undefined): boolean {
    return proto3.util.equals(GetMachineRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetMachineResponse
 */
export class GetMachineResponse extends Message<GetMachineResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Machine machine = 1;
   */
  machine?: Machine;

  constructor(data?: PartialMessage<GetMachineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetMachineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "machine", kind: "message", T: Machine },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMachineResponse {
    return new GetMachineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMachineResponse {
    return new GetMachineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMachineResponse {
    return new GetMachineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMachineResponse | PlainMessage<GetMachineResponse> | undefined, b: GetMachineResponse | PlainMessage<GetMachineResponse> | undefined): boolean {
    return proto3.util.equals(GetMachineResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Machine
 */
export class Machine extends Message<Machine> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string hostname = 3;
   */
  hostname = "";

  /**
   * @generated from field: int32 policy_violations = 4;
   */
  policyViolations = 0;

  /**
   * @generated from field: int32 workloads = 5;
   */
  workloads = 0;

  /**
   * @generated from field: int32 total_vulnerabilities = 6;
   */
  totalVulnerabilities = 0;

  /**
   * @generated from field: int32 actionable_vulnerabilities = 7;
   */
  actionableVulnerabilities = 0;

  /**
   * @generated from field: string operating_system = 8;
   */
  operatingSystem = "";

  /**
   * @generated from field: google.protobuf.Timestamp last_updated = 9;
   */
  lastUpdated?: Timestamp;

  /**
   * @generated from field: bool agent_alive = 10;
   */
  agentAlive = false;

  /**
   * @generated from field: map<string, string> labels = 11;
   */
  labels: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Machine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Machine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "policy_violations", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "workloads", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "total_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "actionable_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "operating_system", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "last_updated", kind: "message", T: Timestamp },
    { no: 10, name: "agent_alive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Machine {
    return new Machine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Machine {
    return new Machine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Machine {
    return new Machine().fromJsonString(jsonString, options);
  }

  static equals(a: Machine | PlainMessage<Machine> | undefined, b: Machine | PlainMessage<Machine> | undefined): boolean {
    return proto3.util.equals(Machine, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Package
 */
export class Package extends Message<Package> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: bool in_use = 4;
   */
  inUse = false;

  /**
   * @generated from field: int32 total_vulnerabilities = 5;
   */
  totalVulnerabilities = 0;

  /**
   * @generated from field: int32 actionable_vulnerabilities = 6;
   */
  actionableVulnerabilities = 0;

  /**
   * @generated from field: string workload_id = 7;
   */
  workloadId = "";

  /**
   * @generated from field: string source_id = 8;
   */
  sourceId = "";

  /**
   * @generated from field: repeated string vulnerability_ids = 9;
   */
  vulnerabilityIds: string[] = [];

  /**
   * @generated from field: string version = 10;
   */
  version = "";

  constructor(data?: PartialMessage<Package>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Package";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "in_use", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "total_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "actionable_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "workload_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "vulnerability_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Package {
    return new Package().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Package {
    return new Package().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Package {
    return new Package().fromJsonString(jsonString, options);
  }

  static equals(a: Package | PlainMessage<Package> | undefined, b: Package | PlainMessage<Package> | undefined): boolean {
    return proto3.util.equals(Package, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.File
 */
export class File extends Message<File> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string filename = 2;
   */
  filename = "";

  /**
   * @generated from field: bool in_use = 3;
   */
  inUse = false;

  /**
   * @generated from field: bool executable = 4;
   */
  executable = false;

  /**
   * @generated from field: string package_id = 5;
   */
  packageId = "";

  /**
   * @generated from field: string source_id = 6;
   */
  sourceId = "";

  constructor(data?: PartialMessage<File>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.File";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "in_use", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "executable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): File {
    return new File().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): File {
    return new File().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): File {
    return new File().fromJsonString(jsonString, options);
  }

  static equals(a: File | PlainMessage<File> | undefined, b: File | PlainMessage<File> | undefined): boolean {
    return proto3.util.equals(File, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.VulnReference
 */
export class VulnReference extends Message<VulnReference> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<VulnReference>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.VulnReference";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VulnReference {
    return new VulnReference().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VulnReference {
    return new VulnReference().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VulnReference {
    return new VulnReference().fromJsonString(jsonString, options);
  }

  static equals(a: VulnReference | PlainMessage<VulnReference> | undefined, b: VulnReference | PlainMessage<VulnReference> | undefined): boolean {
    return proto3.util.equals(VulnReference, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Vulnerability
 */
export class Vulnerability extends Message<Vulnerability> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnSeverity severity = 3;
   */
  severity = VulnSeverity.UNSPECIFIED;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VulnReference references = 4;
   */
  references: VulnReference[] = [];

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnFixState fix_state = 5;
   */
  fixState = VulnFixState.UNSPECIFIED;

  /**
   * @generated from field: repeated string fix_versions = 6;
   */
  fixVersions: string[] = [];

  constructor(data?: PartialMessage<Vulnerability>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Vulnerability";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "severity", kind: "enum", T: proto3.getEnumType(VulnSeverity) },
    { no: 4, name: "references", kind: "message", T: VulnReference, repeated: true },
    { no: 5, name: "fix_state", kind: "enum", T: proto3.getEnumType(VulnFixState) },
    { no: 6, name: "fix_versions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vulnerability {
    return new Vulnerability().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vulnerability {
    return new Vulnerability().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vulnerability {
    return new Vulnerability().fromJsonString(jsonString, options);
  }

  static equals(a: Vulnerability | PlainMessage<Vulnerability> | undefined, b: Vulnerability | PlainMessage<Vulnerability> | undefined): boolean {
    return proto3.util.equals(Vulnerability, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Workload
 */
export class Workload extends Message<Workload> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  /**
   * @generated from field: string trust = 5;
   */
  trust = "";

  /**
   * @generated from field: int32 total_vulnerabilities = 6;
   */
  totalVulnerabilities = 0;

  /**
   * @generated from field: int32 actionable_vulnerabilities = 7;
   */
  actionableVulnerabilities = 0;

  /**
   * @generated from field: string source_id = 8;
   */
  sourceId = "";

  /**
   * @generated from field: string image_name = 9;
   */
  imageName = "";

  /**
   * @generated from field: google.protobuf.Timestamp started_at = 10;
   */
  startedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp ended_at = 11;
   */
  endedAt?: Timestamp;

  constructor(data?: PartialMessage<Workload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Workload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "trust", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "total_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "actionable_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "image_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "started_at", kind: "message", T: Timestamp },
    { no: 11, name: "ended_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Workload {
    return new Workload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Workload {
    return new Workload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Workload {
    return new Workload().fromJsonString(jsonString, options);
  }

  static equals(a: Workload | PlainMessage<Workload> | undefined, b: Workload | PlainMessage<Workload> | undefined): boolean {
    return proto3.util.equals(Workload, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListWorkloadsForMachineRequest
 */
export class ListWorkloadsForMachineRequest extends Message<ListWorkloadsForMachineRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string machine_id = 2;
   */
  machineId = "";

  constructor(data?: PartialMessage<ListWorkloadsForMachineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListWorkloadsForMachineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWorkloadsForMachineRequest {
    return new ListWorkloadsForMachineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWorkloadsForMachineRequest {
    return new ListWorkloadsForMachineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWorkloadsForMachineRequest {
    return new ListWorkloadsForMachineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListWorkloadsForMachineRequest | PlainMessage<ListWorkloadsForMachineRequest> | undefined, b: ListWorkloadsForMachineRequest | PlainMessage<ListWorkloadsForMachineRequest> | undefined): boolean {
    return proto3.util.equals(ListWorkloadsForMachineRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListWorkloadsForMachineResponse
 */
export class ListWorkloadsForMachineResponse extends Message<ListWorkloadsForMachineResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Machine machine = 1;
   */
  machine?: Machine;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Workload workloads = 2;
   */
  workloads: Workload[] = [];

  constructor(data?: PartialMessage<ListWorkloadsForMachineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListWorkloadsForMachineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "machine", kind: "message", T: Machine },
    { no: 2, name: "workloads", kind: "message", T: Workload, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWorkloadsForMachineResponse {
    return new ListWorkloadsForMachineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWorkloadsForMachineResponse {
    return new ListWorkloadsForMachineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWorkloadsForMachineResponse {
    return new ListWorkloadsForMachineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListWorkloadsForMachineResponse | PlainMessage<ListWorkloadsForMachineResponse> | undefined, b: ListWorkloadsForMachineResponse | PlainMessage<ListWorkloadsForMachineResponse> | undefined): boolean {
    return proto3.util.equals(ListWorkloadsForMachineResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetMachineInventoryRequest
 */
export class GetMachineInventoryRequest extends Message<GetMachineInventoryRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string machine_id = 2;
   */
  machineId = "";

  constructor(data?: PartialMessage<GetMachineInventoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetMachineInventoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMachineInventoryRequest {
    return new GetMachineInventoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMachineInventoryRequest {
    return new GetMachineInventoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMachineInventoryRequest {
    return new GetMachineInventoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMachineInventoryRequest | PlainMessage<GetMachineInventoryRequest> | undefined, b: GetMachineInventoryRequest | PlainMessage<GetMachineInventoryRequest> | undefined): boolean {
    return proto3.util.equals(GetMachineInventoryRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetMachineInventoryResponse
 */
export class GetMachineInventoryResponse extends Message<GetMachineInventoryResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Machine machine = 1;
   */
  machine?: Machine;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Package packages = 2;
   */
  packages: Package[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.File files_in_use = 3;
   */
  filesInUse: File[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Workload workloads = 4;
   */
  workloads: Workload[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Vulnerability vulnerabilities = 5;
   */
  vulnerabilities: Vulnerability[] = [];

  constructor(data?: PartialMessage<GetMachineInventoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetMachineInventoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "machine", kind: "message", T: Machine },
    { no: 2, name: "packages", kind: "message", T: Package, repeated: true },
    { no: 3, name: "files_in_use", kind: "message", T: File, repeated: true },
    { no: 4, name: "workloads", kind: "message", T: Workload, repeated: true },
    { no: 5, name: "vulnerabilities", kind: "message", T: Vulnerability, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMachineInventoryResponse {
    return new GetMachineInventoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMachineInventoryResponse {
    return new GetMachineInventoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMachineInventoryResponse {
    return new GetMachineInventoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMachineInventoryResponse | PlainMessage<GetMachineInventoryResponse> | undefined, b: GetMachineInventoryResponse | PlainMessage<GetMachineInventoryResponse> | undefined): boolean {
    return proto3.util.equals(GetMachineInventoryResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OverviewRequest
 */
export class OverviewRequest extends Message<OverviewRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<OverviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OverviewRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OverviewRequest {
    return new OverviewRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OverviewRequest {
    return new OverviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OverviewRequest {
    return new OverviewRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OverviewRequest | PlainMessage<OverviewRequest> | undefined, b: OverviewRequest | PlainMessage<OverviewRequest> | undefined): boolean {
    return proto3.util.equals(OverviewRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OverviewResponse
 */
export class OverviewResponse extends Message<OverviewResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.OverviewPackage packages = 1;
   */
  packages: OverviewPackage[] = [];

  /**
   * @generated from field: int32 active_workloads = 2;
   */
  activeWorkloads = 0;

  /**
   * @generated from field: int32 unique_packages = 3;
   */
  uniquePackages = 0;

  /**
   * @generated from field: int32 active_packages = 4;
   */
  activePackages = 0;

  /**
   * @generated from field: int32 total_vulnerabilities = 5;
   */
  totalVulnerabilities = 0;

  /**
   * @generated from field: int32 actionable_vulnerabilities = 6;
   */
  actionableVulnerabilities = 0;

  /**
   * @generated from field: edgebit.platform.v1alpha.OverviewOnboardingState onboarding_state = 7;
   */
  onboardingState?: OverviewOnboardingState;

  constructor(data?: PartialMessage<OverviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OverviewResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "packages", kind: "message", T: OverviewPackage, repeated: true },
    { no: 2, name: "active_workloads", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "unique_packages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "active_packages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "actionable_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "onboarding_state", kind: "message", T: OverviewOnboardingState },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OverviewResponse {
    return new OverviewResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OverviewResponse {
    return new OverviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OverviewResponse {
    return new OverviewResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OverviewResponse | PlainMessage<OverviewResponse> | undefined, b: OverviewResponse | PlainMessage<OverviewResponse> | undefined): boolean {
    return proto3.util.equals(OverviewResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OverviewPackage
 */
export class OverviewPackage extends Message<OverviewPackage> {
  /**
   * @generated from field: string package_id = 1;
   */
  packageId = "";

  /**
   * @generated from field: string package_name = 2;
   */
  packageName = "";

  /**
   * @generated from field: string package_version = 3;
   */
  packageVersion = "";

  /**
   * @generated from field: int32 seen_total = 4;
   */
  seenTotal = 0;

  /**
   * @generated from field: int32 seen_active = 5;
   */
  seenActive = 0;

  /**
   * @generated from field: string package_type = 6;
   */
  packageType = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.OverviewPackageVuln vulns = 7;
   */
  vulns: OverviewPackageVuln[] = [];

  constructor(data?: PartialMessage<OverviewPackage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OverviewPackage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "package_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seen_total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "seen_active", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "package_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "vulns", kind: "message", T: OverviewPackageVuln, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OverviewPackage {
    return new OverviewPackage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OverviewPackage {
    return new OverviewPackage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OverviewPackage {
    return new OverviewPackage().fromJsonString(jsonString, options);
  }

  static equals(a: OverviewPackage | PlainMessage<OverviewPackage> | undefined, b: OverviewPackage | PlainMessage<OverviewPackage> | undefined): boolean {
    return proto3.util.equals(OverviewPackage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OverviewPackageVuln
 */
export class OverviewPackageVuln extends Message<OverviewPackageVuln> {
  /**
   * @generated from field: string vulnerability_id = 1;
   */
  vulnerabilityId = "";

  /**
   * @generated from field: string summary = 2;
   */
  summary = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnSeverity severity = 3;
   */
  severity = VulnSeverity.UNSPECIFIED;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VulnReference references = 4;
   */
  references: VulnReference[] = [];

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnFixState fix_state = 5;
   */
  fixState = VulnFixState.UNSPECIFIED;

  /**
   * @generated from field: repeated string fix_versions = 6;
   */
  fixVersions: string[] = [];

  constructor(data?: PartialMessage<OverviewPackageVuln>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OverviewPackageVuln";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vulnerability_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "severity", kind: "enum", T: proto3.getEnumType(VulnSeverity) },
    { no: 4, name: "references", kind: "message", T: VulnReference, repeated: true },
    { no: 5, name: "fix_state", kind: "enum", T: proto3.getEnumType(VulnFixState) },
    { no: 6, name: "fix_versions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OverviewPackageVuln {
    return new OverviewPackageVuln().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OverviewPackageVuln {
    return new OverviewPackageVuln().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OverviewPackageVuln {
    return new OverviewPackageVuln().fromJsonString(jsonString, options);
  }

  static equals(a: OverviewPackageVuln | PlainMessage<OverviewPackageVuln> | undefined, b: OverviewPackageVuln | PlainMessage<OverviewPackageVuln> | undefined): boolean {
    return proto3.util.equals(OverviewPackageVuln, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OverviewOnboardingState
 */
export class OverviewOnboardingState extends Message<OverviewOnboardingState> {
  /**
   * @generated from field: bool agent_deployed = 1;
   */
  agentDeployed = false;

  /**
   * @generated from field: bool containers_detected = 2;
   */
  containersDetected = false;

  /**
   * @generated from field: bool sbom_uploaded = 3;
   */
  sbomUploaded = false;

  constructor(data?: PartialMessage<OverviewOnboardingState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OverviewOnboardingState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agent_deployed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "containers_detected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "sbom_uploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OverviewOnboardingState {
    return new OverviewOnboardingState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OverviewOnboardingState {
    return new OverviewOnboardingState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OverviewOnboardingState {
    return new OverviewOnboardingState().fromJsonString(jsonString, options);
  }

  static equals(a: OverviewOnboardingState | PlainMessage<OverviewOnboardingState> | undefined, b: OverviewOnboardingState | PlainMessage<OverviewOnboardingState> | undefined): boolean {
    return proto3.util.equals(OverviewOnboardingState, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateOrgAccessTokenRequest
 */
export class CreateOrgAccessTokenRequest extends Message<CreateOrgAccessTokenRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<CreateOrgAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateOrgAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgAccessTokenRequest {
    return new CreateOrgAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgAccessTokenRequest {
    return new CreateOrgAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrgAccessTokenRequest {
    return new CreateOrgAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrgAccessTokenRequest | PlainMessage<CreateOrgAccessTokenRequest> | undefined, b: CreateOrgAccessTokenRequest | PlainMessage<CreateOrgAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrgAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateOrgAccessTokenResponse
 */
export class CreateOrgAccessTokenResponse extends Message<CreateOrgAccessTokenResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  constructor(data?: PartialMessage<CreateOrgAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateOrgAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgAccessTokenResponse {
    return new CreateOrgAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgAccessTokenResponse {
    return new CreateOrgAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrgAccessTokenResponse {
    return new CreateOrgAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrgAccessTokenResponse | PlainMessage<CreateOrgAccessTokenResponse> | undefined, b: CreateOrgAccessTokenResponse | PlainMessage<CreateOrgAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrgAccessTokenResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListOrgAccessTokensRequest
 */
export class ListOrgAccessTokensRequest extends Message<ListOrgAccessTokensRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListOrgAccessTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListOrgAccessTokensRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgAccessTokensRequest {
    return new ListOrgAccessTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgAccessTokensRequest {
    return new ListOrgAccessTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgAccessTokensRequest {
    return new ListOrgAccessTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrgAccessTokensRequest | PlainMessage<ListOrgAccessTokensRequest> | undefined, b: ListOrgAccessTokensRequest | PlainMessage<ListOrgAccessTokensRequest> | undefined): boolean {
    return proto3.util.equals(ListOrgAccessTokensRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListOrgAccessTokensResponse
 */
export class ListOrgAccessTokensResponse extends Message<ListOrgAccessTokensResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.OrgAccessToken tokens = 1;
   */
  tokens: OrgAccessToken[] = [];

  constructor(data?: PartialMessage<ListOrgAccessTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListOrgAccessTokensResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tokens", kind: "message", T: OrgAccessToken, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrgAccessTokensResponse {
    return new ListOrgAccessTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgAccessTokensResponse {
    return new ListOrgAccessTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrgAccessTokensResponse {
    return new ListOrgAccessTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrgAccessTokensResponse | PlainMessage<ListOrgAccessTokensResponse> | undefined, b: ListOrgAccessTokensResponse | PlainMessage<ListOrgAccessTokensResponse> | undefined): boolean {
    return proto3.util.equals(ListOrgAccessTokensResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.OrgAccessToken
 */
export class OrgAccessToken extends Message<OrgAccessToken> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_used = 5;
   */
  lastUsed?: Timestamp;

  constructor(data?: PartialMessage<OrgAccessToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.OrgAccessToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "last_used", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgAccessToken {
    return new OrgAccessToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgAccessToken {
    return new OrgAccessToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgAccessToken {
    return new OrgAccessToken().fromJsonString(jsonString, options);
  }

  static equals(a: OrgAccessToken | PlainMessage<OrgAccessToken> | undefined, b: OrgAccessToken | PlainMessage<OrgAccessToken> | undefined): boolean {
    return proto3.util.equals(OrgAccessToken, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteOrgAccessTokenRequest
 */
export class DeleteOrgAccessTokenRequest extends Message<DeleteOrgAccessTokenRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteOrgAccessTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteOrgAccessTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOrgAccessTokenRequest {
    return new DeleteOrgAccessTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOrgAccessTokenRequest {
    return new DeleteOrgAccessTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOrgAccessTokenRequest {
    return new DeleteOrgAccessTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOrgAccessTokenRequest | PlainMessage<DeleteOrgAccessTokenRequest> | undefined, b: DeleteOrgAccessTokenRequest | PlainMessage<DeleteOrgAccessTokenRequest> | undefined): boolean {
    return proto3.util.equals(DeleteOrgAccessTokenRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteOrgAccessTokenResponse
 */
export class DeleteOrgAccessTokenResponse extends Message<DeleteOrgAccessTokenResponse> {
  constructor(data?: PartialMessage<DeleteOrgAccessTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteOrgAccessTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteOrgAccessTokenResponse {
    return new DeleteOrgAccessTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteOrgAccessTokenResponse {
    return new DeleteOrgAccessTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteOrgAccessTokenResponse {
    return new DeleteOrgAccessTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteOrgAccessTokenResponse | PlainMessage<DeleteOrgAccessTokenResponse> | undefined, b: DeleteOrgAccessTokenResponse | PlainMessage<DeleteOrgAccessTokenResponse> | undefined): boolean {
    return proto3.util.equals(DeleteOrgAccessTokenResponse, a, b);
  }
}

/**
 * An image with no further details. This primarily happens when an SBOM tool
 * (e.g. syft) is ran on the workload (e.g. after a host boots)
 *
 * @generated from message edgebit.platform.v1alpha.GenericImage
 */
export class GenericImage extends Message<GenericImage> {
  constructor(data?: PartialMessage<GenericImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenericImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenericImage {
    return new GenericImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenericImage {
    return new GenericImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenericImage {
    return new GenericImage().fromJsonString(jsonString, options);
  }

  static equals(a: GenericImage | PlainMessage<GenericImage> | undefined, b: GenericImage | PlainMessage<GenericImage> | undefined): boolean {
    return proto3.util.equals(GenericImage, a, b);
  }
}

/**
 * Docker image
 *
 * @generated from message edgebit.platform.v1alpha.DockerImage
 */
export class DockerImage extends Message<DockerImage> {
  /**
   * Potentially full tag in registry/user/name:tag format.
   * The full format does not need to be used. The same defaults
   * that "docker pull" assumes will work.
   *
   * @generated from field: string tag = 1;
   */
  tag = "";

  /**
   * @generated from field: repeated string repo_digests = 2;
   */
  repoDigests: string[] = [];

  constructor(data?: PartialMessage<DockerImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DockerImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_digests", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DockerImage {
    return new DockerImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DockerImage {
    return new DockerImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DockerImage {
    return new DockerImage().fromJsonString(jsonString, options);
  }

  static equals(a: DockerImage | PlainMessage<DockerImage> | undefined, b: DockerImage | PlainMessage<DockerImage> | undefined): boolean {
    return proto3.util.equals(DockerImage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AMI
 */
export class AMI extends Message<AMI> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated string tags = 2;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<AMI>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AMI";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AMI {
    return new AMI().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AMI {
    return new AMI().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AMI {
    return new AMI().fromJsonString(jsonString, options);
  }

  static equals(a: AMI | PlainMessage<AMI> | undefined, b: AMI | PlainMessage<AMI> | undefined): boolean {
    return proto3.util.equals(AMI, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Image
 */
export class Image extends Message<Image> {
  /**
   * type: Docker | OCI | AMI | ...
   *
   * @generated from oneof edgebit.platform.v1alpha.Image.kind
   */
  kind: {
    /**
     * @generated from field: edgebit.platform.v1alpha.GenericImage generic = 1;
     */
    value: GenericImage;
    case: "generic";
  } | {
    /**
     * @generated from field: edgebit.platform.v1alpha.DockerImage docker = 2;
     */
    value: DockerImage;
    case: "docker";
  } | {
    /**
     * @generated from field: edgebit.platform.v1alpha.AMI ami = 3;
     */
    value: AMI;
    case: "ami";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "generic", kind: "message", T: GenericImage, oneof: "kind" },
    { no: 2, name: "docker", kind: "message", T: DockerImage, oneof: "kind" },
    { no: 3, name: "ami", kind: "message", T: AMI, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Image {
    return new Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJsonString(jsonString, options);
  }

  static equals(a: Image | PlainMessage<Image> | undefined, b: Image | PlainMessage<Image> | undefined): boolean {
    return proto3.util.equals(Image, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ImageExternalID
 */
export class ImageExternalID extends Message<ImageExternalID> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ImageExternalIDType type = 1;
   */
  type = ImageExternalIDType.IMAGE_EXTERNAL_ID_TYPE_UNSPECIFIED;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<ImageExternalID>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ImageExternalID";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(ImageExternalIDType) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageExternalID {
    return new ImageExternalID().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageExternalID {
    return new ImageExternalID().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageExternalID {
    return new ImageExternalID().fromJsonString(jsonString, options);
  }

  static equals(a: ImageExternalID | PlainMessage<ImageExternalID> | undefined, b: ImageExternalID | PlainMessage<ImageExternalID> | undefined): boolean {
    return proto3.util.equals(ImageExternalID, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UploadSBOMHeader
 */
export class UploadSBOMHeader extends Message<UploadSBOMHeader> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.SBOMFormat format = 2;
   */
  format = SBOMFormat.SBOM_FORMAT_UNSPECIFIED;

  /**
   * @generated from field: map<string, string> labels = 3;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string image_id = 4;
   */
  imageId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.Image image = 5;
   */
  image?: Image;

  /**
   * @generated from field: string source_repo_url = 6;
   */
  sourceRepoUrl = "";

  /**
   * @generated from field: string source_commit_id = 7;
   */
  sourceCommitId = "";

  /**
   * @generated from field: string component_name = 8;
   */
  componentName = "";

  /**
   * @generated from field: repeated string tags = 9;
   */
  tags: string[] = [];

  /**
   * @generated from field: uint64 size = 10;
   */
  size = protoInt64.zero;

  /**
   * @generated from field: string pull_request = 11;
   */
  pullRequest = "";

  constructor(data?: PartialMessage<UploadSBOMHeader>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UploadSBOMHeader";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "format", kind: "enum", T: proto3.getEnumType(SBOMFormat) },
    { no: 3, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "image", kind: "message", T: Image },
    { no: 6, name: "source_repo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "source_commit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "component_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 11, name: "pull_request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadSBOMHeader {
    return new UploadSBOMHeader().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadSBOMHeader {
    return new UploadSBOMHeader().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadSBOMHeader {
    return new UploadSBOMHeader().fromJsonString(jsonString, options);
  }

  static equals(a: UploadSBOMHeader | PlainMessage<UploadSBOMHeader> | undefined, b: UploadSBOMHeader | PlainMessage<UploadSBOMHeader> | undefined): boolean {
    return proto3.util.equals(UploadSBOMHeader, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UploadSBOMRequest
 */
export class UploadSBOMRequest extends Message<UploadSBOMRequest> {
  /**
   * @generated from oneof edgebit.platform.v1alpha.UploadSBOMRequest.kind
   */
  kind: {
    /**
     * @generated from field: edgebit.platform.v1alpha.UploadSBOMHeader header = 1;
     */
    value: UploadSBOMHeader;
    case: "header";
  } | {
    /**
     * @generated from field: bytes data = 2;
     */
    value: Uint8Array;
    case: "data";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadSBOMRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UploadSBOMRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "header", kind: "message", T: UploadSBOMHeader, oneof: "kind" },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadSBOMRequest {
    return new UploadSBOMRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadSBOMRequest {
    return new UploadSBOMRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadSBOMRequest {
    return new UploadSBOMRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadSBOMRequest | PlainMessage<UploadSBOMRequest> | undefined, b: UploadSBOMRequest | PlainMessage<UploadSBOMRequest> | undefined): boolean {
    return proto3.util.equals(UploadSBOMRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UploadSBOMResponse
 */
export class UploadSBOMResponse extends Message<UploadSBOMResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<UploadSBOMResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UploadSBOMResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadSBOMResponse {
    return new UploadSBOMResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadSBOMResponse {
    return new UploadSBOMResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadSBOMResponse {
    return new UploadSBOMResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadSBOMResponse | PlainMessage<UploadSBOMResponse> | undefined, b: UploadSBOMResponse | PlainMessage<UploadSBOMResponse> | undefined): boolean {
    return proto3.util.equals(UploadSBOMResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SBOMListItem
 */
export class SBOMListItem extends Message<SBOMListItem> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.SBOMFormat format = 3;
   */
  format = SBOMFormat.SBOM_FORMAT_UNSPECIFIED;

  /**
   * @generated from field: map<string, string> labels = 4;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string image_id = 5;
   */
  imageId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.Image image = 6;
   */
  image?: Image;

  /**
   * @generated from field: string source_repo_url = 7;
   */
  sourceRepoUrl = "";

  /**
   * @generated from field: string source_commit_id = 8;
   */
  sourceCommitId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 9;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string machine_id = 10;
   */
  machineId = "";

  /**
   * Deprecated
   *
   * @generated from field: string component_id = 11;
   */
  componentId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentRef component_ref = 12;
   */
  componentRef?: ComponentRef;

  /**
   * @generated from field: edgebit.platform.v1alpha.MachineRef machine_ref = 13;
   */
  machineRef?: MachineRef;

  constructor(data?: PartialMessage<SBOMListItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SBOMListItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "format", kind: "enum", T: proto3.getEnumType(SBOMFormat) },
    { no: 4, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image", kind: "message", T: Image },
    { no: 7, name: "source_repo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "source_commit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "component_ref", kind: "message", T: ComponentRef },
    { no: 13, name: "machine_ref", kind: "message", T: MachineRef },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SBOMListItem {
    return new SBOMListItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SBOMListItem {
    return new SBOMListItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SBOMListItem {
    return new SBOMListItem().fromJsonString(jsonString, options);
  }

  static equals(a: SBOMListItem | PlainMessage<SBOMListItem> | undefined, b: SBOMListItem | PlainMessage<SBOMListItem> | undefined): boolean {
    return proto3.util.equals(SBOMListItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSBOMsRequest
 */
export class ListSBOMsRequest extends Message<ListSBOMsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  constructor(data?: PartialMessage<ListSBOMsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSBOMsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSBOMsRequest {
    return new ListSBOMsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSBOMsRequest {
    return new ListSBOMsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSBOMsRequest {
    return new ListSBOMsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSBOMsRequest | PlainMessage<ListSBOMsRequest> | undefined, b: ListSBOMsRequest | PlainMessage<ListSBOMsRequest> | undefined): boolean {
    return proto3.util.equals(ListSBOMsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSBOMsResponse
 */
export class ListSBOMsResponse extends Message<ListSBOMsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SBOMListItem items = 1;
   */
  items: SBOMListItem[] = [];

  constructor(data?: PartialMessage<ListSBOMsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSBOMsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: SBOMListItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSBOMsResponse {
    return new ListSBOMsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSBOMsResponse {
    return new ListSBOMsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSBOMsResponse {
    return new ListSBOMsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSBOMsResponse | PlainMessage<ListSBOMsResponse> | undefined, b: ListSBOMsResponse | PlainMessage<ListSBOMsResponse> | undefined): boolean {
    return proto3.util.equals(ListSBOMsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMRequest
 */
export class GetSBOMRequest extends Message<GetSBOMRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<GetSBOMRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMRequest {
    return new GetSBOMRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMRequest {
    return new GetSBOMRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMRequest {
    return new GetSBOMRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMRequest | PlainMessage<GetSBOMRequest> | undefined, b: GetSBOMRequest | PlainMessage<GetSBOMRequest> | undefined): boolean {
    return proto3.util.equals(GetSBOMRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMResponse
 */
export class GetSBOMResponse extends Message<GetSBOMResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.SBOMFormat format = 3;
   */
  format = SBOMFormat.SBOM_FORMAT_UNSPECIFIED;

  /**
   * @generated from field: map<string, string> labels = 4;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string image_id = 5;
   */
  imageId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.Image image = 6;
   */
  image?: Image;

  /**
   * @generated from field: string source_repo_url = 7;
   */
  sourceRepoUrl = "";

  /**
   * @generated from field: string source_commit_id = 8;
   */
  sourceCommitId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 9;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string machine_id = 10;
   */
  machineId = "";

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ComponentRef component_ref = 11;
   */
  componentRef?: ComponentRef;

  /**
   * @generated from field: edgebit.platform.v1alpha.MachineRef machine_ref = 12;
   */
  machineRef?: MachineRef;

  /**
   * @generated from field: string pull_request = 13;
   */
  pullRequest = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ImageExternalID image_ids = 14;
   */
  imageIds: ImageExternalID[] = [];

  constructor(data?: PartialMessage<GetSBOMResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "format", kind: "enum", T: proto3.getEnumType(SBOMFormat) },
    { no: 4, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image", kind: "message", T: Image },
    { no: 7, name: "source_repo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "source_commit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "component_ref", kind: "message", T: ComponentRef, opt: true },
    { no: 12, name: "machine_ref", kind: "message", T: MachineRef },
    { no: 13, name: "pull_request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "image_ids", kind: "message", T: ImageExternalID, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMResponse {
    return new GetSBOMResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMResponse {
    return new GetSBOMResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMResponse {
    return new GetSBOMResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMResponse | PlainMessage<GetSBOMResponse> | undefined, b: GetSBOMResponse | PlainMessage<GetSBOMResponse> | undefined): boolean {
    return proto3.util.equals(GetSBOMResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMInventoryRequest
 */
export class GetSBOMInventoryRequest extends Message<GetSBOMInventoryRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<GetSBOMInventoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMInventoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMInventoryRequest {
    return new GetSBOMInventoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMInventoryRequest {
    return new GetSBOMInventoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMInventoryRequest {
    return new GetSBOMInventoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMInventoryRequest | PlainMessage<GetSBOMInventoryRequest> | undefined, b: GetSBOMInventoryRequest | PlainMessage<GetSBOMInventoryRequest> | undefined): boolean {
    return proto3.util.equals(GetSBOMInventoryRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMInventoryResponse
 */
export class GetSBOMInventoryResponse extends Message<GetSBOMInventoryResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SBOMInventoryPackage packages = 1;
   */
  packages: SBOMInventoryPackage[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.GraphRelationship relationships = 2;
   */
  relationships: GraphRelationship[] = [];

  constructor(data?: PartialMessage<GetSBOMInventoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMInventoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "packages", kind: "message", T: SBOMInventoryPackage, repeated: true },
    { no: 2, name: "relationships", kind: "message", T: GraphRelationship, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMInventoryResponse {
    return new GetSBOMInventoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMInventoryResponse {
    return new GetSBOMInventoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMInventoryResponse {
    return new GetSBOMInventoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMInventoryResponse | PlainMessage<GetSBOMInventoryResponse> | undefined, b: GetSBOMInventoryResponse | PlainMessage<GetSBOMInventoryResponse> | undefined): boolean {
    return proto3.util.equals(GetSBOMInventoryResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.InstallationEvidence
 */
export class InstallationEvidence extends Message<InstallationEvidence> {
  /**
   * The metadata files that the cataloger used to locate the package
   *
   * @generated from field: repeated string evident_by = 1;
   */
  evidentBy: string[] = [];

  /**
   * Suppose pyfoo was installed in via Dockfile and a requirements.txt
   * Then "installed_by" would look like ["/app/Dockerfile:25: RUN pip install requirements.txt", "/app/requirements.txt:10: pyfoo==1.2.3"]
   *
   * @generated from field: repeated string installed_by = 2;
   */
  installedBy: string[] = [];

  constructor(data?: PartialMessage<InstallationEvidence>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.InstallationEvidence";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "evident_by", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "installed_by", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstallationEvidence {
    return new InstallationEvidence().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstallationEvidence {
    return new InstallationEvidence().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstallationEvidence {
    return new InstallationEvidence().fromJsonString(jsonString, options);
  }

  static equals(a: InstallationEvidence | PlainMessage<InstallationEvidence> | undefined, b: InstallationEvidence | PlainMessage<InstallationEvidence> | undefined): boolean {
    return proto3.util.equals(InstallationEvidence, a, b);
  }
}

/**
 * Multiple licenses can be combined via AND or OR. For example:
 * libfoo is licensed under Apache-2 OR MIT
 * libbar is licensed under GPLv2
 * A compoenent using both licenses would need to abide by "(Apache-2 OR MIT) AND GPLv2"
 * A license set is a conjunctive list of disjunctive clauses, i.e. inner licenses are OR'ed
 * and those are AND'ed.
 *
 * @generated from message edgebit.platform.v1alpha.LicenseExpr
 */
export class LicenseExpr extends Message<LicenseExpr> {
  /**
   * These are AND'ed together
   *
   * @generated from field: repeated edgebit.platform.v1alpha.LicenseClause clauses = 1;
   */
  clauses: LicenseClause[] = [];

  constructor(data?: PartialMessage<LicenseExpr>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.LicenseExpr";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clauses", kind: "message", T: LicenseClause, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LicenseExpr {
    return new LicenseExpr().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LicenseExpr {
    return new LicenseExpr().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LicenseExpr {
    return new LicenseExpr().fromJsonString(jsonString, options);
  }

  static equals(a: LicenseExpr | PlainMessage<LicenseExpr> | undefined, b: LicenseExpr | PlainMessage<LicenseExpr> | undefined): boolean {
    return proto3.util.equals(LicenseExpr, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.LicenseClause
 */
export class LicenseClause extends Message<LicenseClause> {
  /**
   * These are OR'ed together
   *
   * @generated from field: repeated string terms = 1;
   */
  terms: string[] = [];

  constructor(data?: PartialMessage<LicenseClause>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.LicenseClause";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "terms", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LicenseClause {
    return new LicenseClause().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LicenseClause {
    return new LicenseClause().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LicenseClause {
    return new LicenseClause().fromJsonString(jsonString, options);
  }

  static equals(a: LicenseClause | PlainMessage<LicenseClause> | undefined, b: LicenseClause | PlainMessage<LicenseClause> | undefined): boolean {
    return proto3.util.equals(LicenseClause, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SBOMInventoryPackage
 */
export class SBOMInventoryPackage extends Message<SBOMInventoryPackage> {
  /**
   * @generated from field: string package_id = 1;
   */
  packageId = "";

  /**
   * @generated from field: string package_name = 2;
   */
  packageName = "";

  /**
   * @generated from field: string package_type = 3;
   */
  packageType = "";

  /**
   * @generated from field: string package_version = 4;
   */
  packageVersion = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SBOMInventoryVuln vulns = 5;
   */
  vulns: SBOMInventoryVuln[] = [];

  /**
   * @generated from field: edgebit.platform.v1alpha.InstallationEvidence evidence = 6;
   */
  evidence?: InstallationEvidence;

  /**
   * @generated from field: edgebit.platform.v1alpha.LicenseExpr license = 7;
   */
  license?: LicenseExpr;

  constructor(data?: PartialMessage<SBOMInventoryPackage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SBOMInventoryPackage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "package_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "package_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "vulns", kind: "message", T: SBOMInventoryVuln, repeated: true },
    { no: 6, name: "evidence", kind: "message", T: InstallationEvidence },
    { no: 7, name: "license", kind: "message", T: LicenseExpr },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SBOMInventoryPackage {
    return new SBOMInventoryPackage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SBOMInventoryPackage {
    return new SBOMInventoryPackage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SBOMInventoryPackage {
    return new SBOMInventoryPackage().fromJsonString(jsonString, options);
  }

  static equals(a: SBOMInventoryPackage | PlainMessage<SBOMInventoryPackage> | undefined, b: SBOMInventoryPackage | PlainMessage<SBOMInventoryPackage> | undefined): boolean {
    return proto3.util.equals(SBOMInventoryPackage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SBOMInventoryVuln
 */
export class SBOMInventoryVuln extends Message<SBOMInventoryVuln> {
  /**
   * @generated from field: string vulnerability_id = 1;
   */
  vulnerabilityId = "";

  /**
   * @generated from field: string summary = 2;
   */
  summary = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnSeverity severity = 3;
   */
  severity = VulnSeverity.UNSPECIFIED;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VulnReference references = 4;
   */
  references: VulnReference[] = [];

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnFixState fix_state = 5;
   */
  fixState = VulnFixState.UNSPECIFIED;

  /**
   * @generated from field: repeated string fix_versions = 6;
   */
  fixVersions: string[] = [];

  constructor(data?: PartialMessage<SBOMInventoryVuln>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SBOMInventoryVuln";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vulnerability_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "severity", kind: "enum", T: proto3.getEnumType(VulnSeverity) },
    { no: 4, name: "references", kind: "message", T: VulnReference, repeated: true },
    { no: 5, name: "fix_state", kind: "enum", T: proto3.getEnumType(VulnFixState) },
    { no: 6, name: "fix_versions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SBOMInventoryVuln {
    return new SBOMInventoryVuln().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SBOMInventoryVuln {
    return new SBOMInventoryVuln().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SBOMInventoryVuln {
    return new SBOMInventoryVuln().fromJsonString(jsonString, options);
  }

  static equals(a: SBOMInventoryVuln | PlainMessage<SBOMInventoryVuln> | undefined, b: SBOMInventoryVuln | PlainMessage<SBOMInventoryVuln> | undefined): boolean {
    return proto3.util.equals(SBOMInventoryVuln, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DebugWorkloadPackageInUseRequest
 */
export class DebugWorkloadPackageInUseRequest extends Message<DebugWorkloadPackageInUseRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string workload_id = 2;
   */
  workloadId = "";

  /**
   * @generated from field: string package_id = 3;
   */
  packageId = "";

  constructor(data?: PartialMessage<DebugWorkloadPackageInUseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DebugWorkloadPackageInUseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workload_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebugWorkloadPackageInUseRequest {
    return new DebugWorkloadPackageInUseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebugWorkloadPackageInUseRequest {
    return new DebugWorkloadPackageInUseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebugWorkloadPackageInUseRequest {
    return new DebugWorkloadPackageInUseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DebugWorkloadPackageInUseRequest | PlainMessage<DebugWorkloadPackageInUseRequest> | undefined, b: DebugWorkloadPackageInUseRequest | PlainMessage<DebugWorkloadPackageInUseRequest> | undefined): boolean {
    return proto3.util.equals(DebugWorkloadPackageInUseRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DebugWorkloadPackageInUseResponse
 */
export class DebugWorkloadPackageInUseResponse extends Message<DebugWorkloadPackageInUseResponse> {
  /**
   * @generated from field: string package_id = 1;
   */
  packageId = "";

  /**
   * @generated from field: string package_name = 2;
   */
  packageName = "";

  /**
   * @generated from field: string package_type = 3;
   */
  packageType = "";

  /**
   * @generated from field: string package_version = 4;
   */
  packageVersion = "";

  /**
   * @generated from field: bool supports_in_use_detection = 5;
   */
  supportsInUseDetection = false;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.DebugWorkloadPackageInUseResponse.InUseDetectionLocation in_use_locations = 6;
   */
  inUseLocations: DebugWorkloadPackageInUseResponse_InUseDetectionLocation[] = [];

  /**
   * @generated from field: repeated string other_workload_in_use_file_paths = 7;
   */
  otherWorkloadInUseFilePaths: string[] = [];

  constructor(data?: PartialMessage<DebugWorkloadPackageInUseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DebugWorkloadPackageInUseResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "package_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "package_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "supports_in_use_detection", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "in_use_locations", kind: "message", T: DebugWorkloadPackageInUseResponse_InUseDetectionLocation, repeated: true },
    { no: 7, name: "other_workload_in_use_file_paths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebugWorkloadPackageInUseResponse {
    return new DebugWorkloadPackageInUseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebugWorkloadPackageInUseResponse {
    return new DebugWorkloadPackageInUseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebugWorkloadPackageInUseResponse {
    return new DebugWorkloadPackageInUseResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DebugWorkloadPackageInUseResponse | PlainMessage<DebugWorkloadPackageInUseResponse> | undefined, b: DebugWorkloadPackageInUseResponse | PlainMessage<DebugWorkloadPackageInUseResponse> | undefined): boolean {
    return proto3.util.equals(DebugWorkloadPackageInUseResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DebugWorkloadPackageInUseResponse.InUseDetectionLocation
 */
export class DebugWorkloadPackageInUseResponse_InUseDetectionLocation extends Message<DebugWorkloadPackageInUseResponse_InUseDetectionLocation> {
  /**
   * @generated from field: string file_path = 1;
   */
  filePath = "";

  /**
   * @generated from field: bool in_use = 2;
   */
  inUse = false;

  constructor(data?: PartialMessage<DebugWorkloadPackageInUseResponse_InUseDetectionLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DebugWorkloadPackageInUseResponse.InUseDetectionLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "in_use", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebugWorkloadPackageInUseResponse_InUseDetectionLocation {
    return new DebugWorkloadPackageInUseResponse_InUseDetectionLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebugWorkloadPackageInUseResponse_InUseDetectionLocation {
    return new DebugWorkloadPackageInUseResponse_InUseDetectionLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebugWorkloadPackageInUseResponse_InUseDetectionLocation {
    return new DebugWorkloadPackageInUseResponse_InUseDetectionLocation().fromJsonString(jsonString, options);
  }

  static equals(a: DebugWorkloadPackageInUseResponse_InUseDetectionLocation | PlainMessage<DebugWorkloadPackageInUseResponse_InUseDetectionLocation> | undefined, b: DebugWorkloadPackageInUseResponse_InUseDetectionLocation | PlainMessage<DebugWorkloadPackageInUseResponse_InUseDetectionLocation> | undefined): boolean {
    return proto3.util.equals(DebugWorkloadPackageInUseResponse_InUseDetectionLocation, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentsRequest
 */
export class ListComponentsRequest extends Message<ListComponentsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListComponentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentsRequest {
    return new ListComponentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentsRequest {
    return new ListComponentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentsRequest {
    return new ListComponentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentsRequest | PlainMessage<ListComponentsRequest> | undefined, b: ListComponentsRequest | PlainMessage<ListComponentsRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentsResponse
 */
export class ListComponentsResponse extends Message<ListComponentsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Component components = 1;
   */
  components: Component[] = [];

  constructor(data?: PartialMessage<ListComponentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "components", kind: "message", T: Component, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentsResponse {
    return new ListComponentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentsResponse {
    return new ListComponentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentsResponse {
    return new ListComponentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentsResponse | PlainMessage<ListComponentsResponse> | undefined, b: ListComponentsResponse | PlainMessage<ListComponentsResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentRequest
 */
export class GetComponentRequest extends Message<GetComponentRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<GetComponentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentRequest {
    return new GetComponentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentRequest {
    return new GetComponentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentRequest {
    return new GetComponentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentRequest | PlainMessage<GetComponentRequest> | undefined, b: GetComponentRequest | PlainMessage<GetComponentRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentResponse
 */
export class GetComponentResponse extends Message<GetComponentResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Component component = 1;
   */
  component?: Component;

  constructor(data?: PartialMessage<GetComponentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component", kind: "message", T: Component },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentResponse {
    return new GetComponentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentResponse {
    return new GetComponentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentResponse {
    return new GetComponentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentResponse | PlainMessage<GetComponentResponse> | undefined, b: GetComponentResponse | PlainMessage<GetComponentResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.VCSImport
 */
export class VCSImport extends Message<VCSImport> {
  /**
   * @generated from oneof edgebit.platform.v1alpha.VCSImport.kind
   */
  kind: {
    /**
     * @generated from field: edgebit.platform.v1alpha.GitHubRepoToImport github = 1;
     */
    value: GitHubRepoToImport;
    case: "github";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<VCSImport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.VCSImport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "github", kind: "message", T: GitHubRepoToImport, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VCSImport {
    return new VCSImport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VCSImport {
    return new VCSImport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VCSImport {
    return new VCSImport().fromJsonString(jsonString, options);
  }

  static equals(a: VCSImport | PlainMessage<VCSImport> | undefined, b: VCSImport | PlainMessage<VCSImport> | undefined): boolean {
    return proto3.util.equals(VCSImport, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateComponentRequest
 */
export class CreateComponentRequest extends Message<CreateComponentRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentType type = 4;
   */
  type = ComponentType.UNSPECIFIED;

  /**
   * @generated from field: map<string, string> labels = 5;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string source_repository = 6;
   */
  sourceRepository = "";

  /**
   * @generated from field: string machine_selector = 7;
   */
  machineSelector = "";

  /**
   * @generated from field: string default_tag_name = 8;
   */
  defaultTagName = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity policy_ignore_severity_threshold = 9;
   */
  policyIgnoreSeverityThreshold = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.DormantPackagePolicy policy_dormant_package = 10;
   */
  policyDormantPackage = DormantPackagePolicy.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.VCSImport vcs_import = 11;
   */
  vcsImport?: VCSImport;

  constructor(data?: PartialMessage<CreateComponentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateComponentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(ComponentType) },
    { no: 5, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "source_repository", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "machine_selector", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "default_tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "policy_ignore_severity_threshold", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 10, name: "policy_dormant_package", kind: "enum", T: proto3.getEnumType(DormantPackagePolicy) },
    { no: 11, name: "vcs_import", kind: "message", T: VCSImport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateComponentRequest {
    return new CreateComponentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateComponentRequest {
    return new CreateComponentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateComponentRequest {
    return new CreateComponentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateComponentRequest | PlainMessage<CreateComponentRequest> | undefined, b: CreateComponentRequest | PlainMessage<CreateComponentRequest> | undefined): boolean {
    return proto3.util.equals(CreateComponentRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateComponentResponse
 */
export class CreateComponentResponse extends Message<CreateComponentResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Component component = 1;
   */
  component?: Component;

  constructor(data?: PartialMessage<CreateComponentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateComponentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component", kind: "message", T: Component },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateComponentResponse {
    return new CreateComponentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateComponentResponse {
    return new CreateComponentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateComponentResponse {
    return new CreateComponentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateComponentResponse | PlainMessage<CreateComponentResponse> | undefined, b: CreateComponentResponse | PlainMessage<CreateComponentResponse> | undefined): boolean {
    return proto3.util.equals(CreateComponentResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateComponentRequest
 */
export class UpdateComponentRequest extends Message<UpdateComponentRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string display_name = 4;
   */
  displayName = "";

  /**
   * @generated from field: map<string, string> labels = 5;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string source_repository = 6;
   */
  sourceRepository = "";

  /**
   * @generated from field: string machine_selector = 7;
   */
  machineSelector = "";

  /**
   * @generated from field: string default_tag_name = 8;
   */
  defaultTagName = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity policy_ignore_severity_threshold = 9;
   */
  policyIgnoreSeverityThreshold = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.DormantPackagePolicy policy_dormant_package = 10;
   */
  policyDormantPackage = DormantPackagePolicy.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateComponentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateComponentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "source_repository", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "machine_selector", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "default_tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "policy_ignore_severity_threshold", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 10, name: "policy_dormant_package", kind: "enum", T: proto3.getEnumType(DormantPackagePolicy) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateComponentRequest {
    return new UpdateComponentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateComponentRequest {
    return new UpdateComponentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateComponentRequest {
    return new UpdateComponentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateComponentRequest | PlainMessage<UpdateComponentRequest> | undefined, b: UpdateComponentRequest | PlainMessage<UpdateComponentRequest> | undefined): boolean {
    return proto3.util.equals(UpdateComponentRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateComponentResponse
 */
export class UpdateComponentResponse extends Message<UpdateComponentResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Component component = 1;
   */
  component?: Component;

  constructor(data?: PartialMessage<UpdateComponentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateComponentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component", kind: "message", T: Component },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateComponentResponse {
    return new UpdateComponentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateComponentResponse {
    return new UpdateComponentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateComponentResponse {
    return new UpdateComponentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateComponentResponse | PlainMessage<UpdateComponentResponse> | undefined, b: UpdateComponentResponse | PlainMessage<UpdateComponentResponse> | undefined): boolean {
    return proto3.util.equals(UpdateComponentResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteComponentRequest
 */
export class DeleteComponentRequest extends Message<DeleteComponentRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteComponentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteComponentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteComponentRequest {
    return new DeleteComponentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteComponentRequest {
    return new DeleteComponentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteComponentRequest {
    return new DeleteComponentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteComponentRequest | PlainMessage<DeleteComponentRequest> | undefined, b: DeleteComponentRequest | PlainMessage<DeleteComponentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteComponentRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteComponentResponse
 */
export class DeleteComponentResponse extends Message<DeleteComponentResponse> {
  constructor(data?: PartialMessage<DeleteComponentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteComponentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteComponentResponse {
    return new DeleteComponentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteComponentResponse {
    return new DeleteComponentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteComponentResponse {
    return new DeleteComponentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteComponentResponse | PlainMessage<DeleteComponentResponse> | undefined, b: DeleteComponentResponse | PlainMessage<DeleteComponentResponse> | undefined): boolean {
    return proto3.util.equals(DeleteComponentResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AttestationChain
 */
export class AttestationChain extends Message<AttestationChain> {
  constructor(data?: PartialMessage<AttestationChain>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AttestationChain";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttestationChain {
    return new AttestationChain().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttestationChain {
    return new AttestationChain().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AttestationChain {
    return new AttestationChain().fromJsonString(jsonString, options);
  }

  static equals(a: AttestationChain | PlainMessage<AttestationChain> | undefined, b: AttestationChain | PlainMessage<AttestationChain> | undefined): boolean {
    return proto3.util.equals(AttestationChain, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AttestationChainItem
 */
export class AttestationChainItem extends Message<AttestationChainItem> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.AttestationChainItemTrust trust = 2;
   */
  trust?: AttestationChainItemTrust;

  /**
   * @generated from field: map<string, string> audit = 3;
   */
  audit: { [key: string]: string } = {};

  constructor(data?: PartialMessage<AttestationChainItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AttestationChainItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "trust", kind: "message", T: AttestationChainItemTrust },
    { no: 3, name: "audit", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttestationChainItem {
    return new AttestationChainItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttestationChainItem {
    return new AttestationChainItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AttestationChainItem {
    return new AttestationChainItem().fromJsonString(jsonString, options);
  }

  static equals(a: AttestationChainItem | PlainMessage<AttestationChainItem> | undefined, b: AttestationChainItem | PlainMessage<AttestationChainItem> | undefined): boolean {
    return proto3.util.equals(AttestationChainItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AttestationChainItemTrust
 */
export class AttestationChainItemTrust extends Message<AttestationChainItemTrust> {
  /**
   * @generated from field: bool step = 1;
   */
  step = false;

  /**
   * @generated from field: bool downstream = 2;
   */
  downstream = false;

  constructor(data?: PartialMessage<AttestationChainItemTrust>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AttestationChainItemTrust";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "step", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "downstream", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttestationChainItemTrust {
    return new AttestationChainItemTrust().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttestationChainItemTrust {
    return new AttestationChainItemTrust().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AttestationChainItemTrust {
    return new AttestationChainItemTrust().fromJsonString(jsonString, options);
  }

  static equals(a: AttestationChainItemTrust | PlainMessage<AttestationChainItemTrust> | undefined, b: AttestationChainItemTrust | PlainMessage<AttestationChainItemTrust> | undefined): boolean {
    return proto3.util.equals(AttestationChainItemTrust, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentDefaultTagSBOMAttestationRequest
 */
export class GetComponentDefaultTagSBOMAttestationRequest extends Message<GetComponentDefaultTagSBOMAttestationRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  constructor(data?: PartialMessage<GetComponentDefaultTagSBOMAttestationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentDefaultTagSBOMAttestationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentDefaultTagSBOMAttestationRequest {
    return new GetComponentDefaultTagSBOMAttestationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentDefaultTagSBOMAttestationRequest {
    return new GetComponentDefaultTagSBOMAttestationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentDefaultTagSBOMAttestationRequest {
    return new GetComponentDefaultTagSBOMAttestationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentDefaultTagSBOMAttestationRequest | PlainMessage<GetComponentDefaultTagSBOMAttestationRequest> | undefined, b: GetComponentDefaultTagSBOMAttestationRequest | PlainMessage<GetComponentDefaultTagSBOMAttestationRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentDefaultTagSBOMAttestationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentDefaultTagSBOMAttestationResponse
 */
export class GetComponentDefaultTagSBOMAttestationResponse extends Message<GetComponentDefaultTagSBOMAttestationResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.AttestationChainItem chain_items = 1;
   */
  chainItems: AttestationChainItem[] = [];

  constructor(data?: PartialMessage<GetComponentDefaultTagSBOMAttestationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentDefaultTagSBOMAttestationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_items", kind: "message", T: AttestationChainItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentDefaultTagSBOMAttestationResponse {
    return new GetComponentDefaultTagSBOMAttestationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentDefaultTagSBOMAttestationResponse {
    return new GetComponentDefaultTagSBOMAttestationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentDefaultTagSBOMAttestationResponse {
    return new GetComponentDefaultTagSBOMAttestationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentDefaultTagSBOMAttestationResponse | PlainMessage<GetComponentDefaultTagSBOMAttestationResponse> | undefined, b: GetComponentDefaultTagSBOMAttestationResponse | PlainMessage<GetComponentDefaultTagSBOMAttestationResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentDefaultTagSBOMAttestationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMAttestationRequest
 */
export class GetSBOMAttestationRequest extends Message<GetSBOMAttestationRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string sbom_id = 2;
   */
  sbomId = "";

  constructor(data?: PartialMessage<GetSBOMAttestationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMAttestationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMAttestationRequest {
    return new GetSBOMAttestationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMAttestationRequest {
    return new GetSBOMAttestationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMAttestationRequest {
    return new GetSBOMAttestationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMAttestationRequest | PlainMessage<GetSBOMAttestationRequest> | undefined, b: GetSBOMAttestationRequest | PlainMessage<GetSBOMAttestationRequest> | undefined): boolean {
    return proto3.util.equals(GetSBOMAttestationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMAttestationResponse
 */
export class GetSBOMAttestationResponse extends Message<GetSBOMAttestationResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.AttestationChainItem chain_items = 1;
   */
  chainItems: AttestationChainItem[] = [];

  constructor(data?: PartialMessage<GetSBOMAttestationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMAttestationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_items", kind: "message", T: AttestationChainItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMAttestationResponse {
    return new GetSBOMAttestationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMAttestationResponse {
    return new GetSBOMAttestationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMAttestationResponse {
    return new GetSBOMAttestationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMAttestationResponse | PlainMessage<GetSBOMAttestationResponse> | undefined, b: GetSBOMAttestationResponse | PlainMessage<GetSBOMAttestationResponse> | undefined): boolean {
    return proto3.util.equals(GetSBOMAttestationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Component
 */
export class Component extends Message<Component> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string display_name = 4;
   */
  displayName = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentType type = 7;
   */
  type = ComponentType.UNSPECIFIED;

  /**
   * @generated from field: map<string, string> labels = 8;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string source_repository = 9;
   */
  sourceRepository = "";

  /**
   * @generated from field: string machine_selector = 10;
   */
  machineSelector = "";

  /**
   * @generated from field: string default_tag_name = 11;
   */
  defaultTagName = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity policy_ignore_severity_threshold = 12;
   */
  policyIgnoreSeverityThreshold = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.DormantPackagePolicy policy_dormant_package = 13;
   */
  policyDormantPackage = DormantPackagePolicy.UNSPECIFIED;

  constructor(data?: PartialMessage<Component>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Component";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 7, name: "type", kind: "enum", T: proto3.getEnumType(ComponentType) },
    { no: 8, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 9, name: "source_repository", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "machine_selector", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "default_tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "policy_ignore_severity_threshold", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 13, name: "policy_dormant_package", kind: "enum", T: proto3.getEnumType(DormantPackagePolicy) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Component {
    return new Component().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Component {
    return new Component().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Component {
    return new Component().fromJsonString(jsonString, options);
  }

  static equals(a: Component | PlainMessage<Component> | undefined, b: Component | PlainMessage<Component> | undefined): boolean {
    return proto3.util.equals(Component, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentsWithMetricsRequest
 */
export class ListComponentsWithMetricsRequest extends Message<ListComponentsWithMetricsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListComponentsWithMetricsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentsWithMetricsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentsWithMetricsRequest {
    return new ListComponentsWithMetricsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentsWithMetricsRequest {
    return new ListComponentsWithMetricsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentsWithMetricsRequest {
    return new ListComponentsWithMetricsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentsWithMetricsRequest | PlainMessage<ListComponentsWithMetricsRequest> | undefined, b: ListComponentsWithMetricsRequest | PlainMessage<ListComponentsWithMetricsRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentsWithMetricsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentsWithMetricsResponse
 */
export class ListComponentsWithMetricsResponse extends Message<ListComponentsWithMetricsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentWithMetrics components_with_metrics = 1;
   */
  componentsWithMetrics: ComponentWithMetrics[] = [];

  constructor(data?: PartialMessage<ListComponentsWithMetricsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentsWithMetricsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "components_with_metrics", kind: "message", T: ComponentWithMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentsWithMetricsResponse {
    return new ListComponentsWithMetricsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentsWithMetricsResponse {
    return new ListComponentsWithMetricsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentsWithMetricsResponse {
    return new ListComponentsWithMetricsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentsWithMetricsResponse | PlainMessage<ListComponentsWithMetricsResponse> | undefined, b: ListComponentsWithMetricsResponse | PlainMessage<ListComponentsWithMetricsResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentsWithMetricsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentWithMetrics
 */
export class ComponentWithMetrics extends Message<ComponentWithMetrics> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Component component = 1;
   */
  component?: Component;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ComponentIssueTrendItem component_issue_trend_item = 2;
   */
  componentIssueTrendItem?: ComponentIssueTrendItem;

  constructor(data?: PartialMessage<ComponentWithMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentWithMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component", kind: "message", T: Component },
    { no: 2, name: "component_issue_trend_item", kind: "message", T: ComponentIssueTrendItem, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentWithMetrics {
    return new ComponentWithMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentWithMetrics {
    return new ComponentWithMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentWithMetrics {
    return new ComponentWithMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentWithMetrics | PlainMessage<ComponentWithMetrics> | undefined, b: ComponentWithMetrics | PlainMessage<ComponentWithMetrics> | undefined): boolean {
    return proto3.util.equals(ComponentWithMetrics, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SetComponentTagRequest
 */
export class SetComponentTagRequest extends Message<SetComponentTagRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string sbom_id = 4;
   */
  sbomId = "";

  constructor(data?: PartialMessage<SetComponentTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SetComponentTagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetComponentTagRequest {
    return new SetComponentTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetComponentTagRequest {
    return new SetComponentTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetComponentTagRequest {
    return new SetComponentTagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetComponentTagRequest | PlainMessage<SetComponentTagRequest> | undefined, b: SetComponentTagRequest | PlainMessage<SetComponentTagRequest> | undefined): boolean {
    return proto3.util.equals(SetComponentTagRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SetComponentTagResponse
 */
export class SetComponentTagResponse extends Message<SetComponentTagResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentTag tag = 1;
   */
  tag?: ComponentTag;

  constructor(data?: PartialMessage<SetComponentTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SetComponentTagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "message", T: ComponentTag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetComponentTagResponse {
    return new SetComponentTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetComponentTagResponse {
    return new SetComponentTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetComponentTagResponse {
    return new SetComponentTagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetComponentTagResponse | PlainMessage<SetComponentTagResponse> | undefined, b: SetComponentTagResponse | PlainMessage<SetComponentTagResponse> | undefined): boolean {
    return proto3.util.equals(SetComponentTagResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentTagRequest
 */
export class GetComponentTagRequest extends Message<GetComponentTagRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<GetComponentTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentTagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentTagRequest {
    return new GetComponentTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentTagRequest {
    return new GetComponentTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentTagRequest {
    return new GetComponentTagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentTagRequest | PlainMessage<GetComponentTagRequest> | undefined, b: GetComponentTagRequest | PlainMessage<GetComponentTagRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentTagRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentTagResponse
 */
export class GetComponentTagResponse extends Message<GetComponentTagResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentTag tag = 1;
   */
  tag?: ComponentTag;

  constructor(data?: PartialMessage<GetComponentTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentTagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "message", T: ComponentTag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentTagResponse {
    return new GetComponentTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentTagResponse {
    return new GetComponentTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentTagResponse {
    return new GetComponentTagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentTagResponse | PlainMessage<GetComponentTagResponse> | undefined, b: GetComponentTagResponse | PlainMessage<GetComponentTagResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentTagResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentTagsRequest
 */
export class ListComponentTagsRequest extends Message<ListComponentTagsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  constructor(data?: PartialMessage<ListComponentTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentTagsRequest {
    return new ListComponentTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentTagsRequest {
    return new ListComponentTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentTagsRequest {
    return new ListComponentTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentTagsRequest | PlainMessage<ListComponentTagsRequest> | undefined, b: ListComponentTagsRequest | PlainMessage<ListComponentTagsRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentTagsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentTagsResponse
 */
export class ListComponentTagsResponse extends Message<ListComponentTagsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentTag tags = 1;
   */
  tags: ComponentTag[] = [];

  constructor(data?: PartialMessage<ListComponentTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentTagsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "message", T: ComponentTag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentTagsResponse {
    return new ListComponentTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentTagsResponse {
    return new ListComponentTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentTagsResponse {
    return new ListComponentTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentTagsResponse | PlainMessage<ListComponentTagsResponse> | undefined, b: ListComponentTagsResponse | PlainMessage<ListComponentTagsResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentTagsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteComponentTagRequest
 */
export class DeleteComponentTagRequest extends Message<DeleteComponentTagRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<DeleteComponentTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteComponentTagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteComponentTagRequest {
    return new DeleteComponentTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteComponentTagRequest {
    return new DeleteComponentTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteComponentTagRequest {
    return new DeleteComponentTagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteComponentTagRequest | PlainMessage<DeleteComponentTagRequest> | undefined, b: DeleteComponentTagRequest | PlainMessage<DeleteComponentTagRequest> | undefined): boolean {
    return proto3.util.equals(DeleteComponentTagRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.DeleteComponentTagResponse
 */
export class DeleteComponentTagResponse extends Message<DeleteComponentTagResponse> {
  constructor(data?: PartialMessage<DeleteComponentTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.DeleteComponentTagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteComponentTagResponse {
    return new DeleteComponentTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteComponentTagResponse {
    return new DeleteComponentTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteComponentTagResponse {
    return new DeleteComponentTagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteComponentTagResponse | PlainMessage<DeleteComponentTagResponse> | undefined, b: DeleteComponentTagResponse | PlainMessage<DeleteComponentTagResponse> | undefined): boolean {
    return proto3.util.equals(DeleteComponentTagResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentTag
 */
export class ComponentTag extends Message<ComponentTag> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string sbom_id = 2;
   */
  sbomId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<ComponentTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentTag {
    return new ComponentTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentTag {
    return new ComponentTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentTag {
    return new ComponentTag().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentTag | PlainMessage<ComponentTag> | undefined, b: ComponentTag | PlainMessage<ComponentTag> | undefined): boolean {
    return proto3.util.equals(ComponentTag, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentTagsOverviewRequest
 */
export class GetComponentTagsOverviewRequest extends Message<GetComponentTagsOverviewRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string selected_tag_name = 3;
   */
  selectedTagName = "";

  constructor(data?: PartialMessage<GetComponentTagsOverviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentTagsOverviewRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "selected_tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentTagsOverviewRequest {
    return new GetComponentTagsOverviewRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentTagsOverviewRequest {
    return new GetComponentTagsOverviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentTagsOverviewRequest {
    return new GetComponentTagsOverviewRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentTagsOverviewRequest | PlainMessage<GetComponentTagsOverviewRequest> | undefined, b: GetComponentTagsOverviewRequest | PlainMessage<GetComponentTagsOverviewRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentTagsOverviewRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentTagsOverviewResponse
 */
export class GetComponentTagsOverviewResponse extends Message<GetComponentTagsOverviewResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentEnrichedSBOMSummary default_sbom = 1;
   */
  defaultSbom?: ComponentEnrichedSBOMSummary;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentEnrichedSBOMSummary other_active_sboms = 2;
   */
  otherActiveSboms: ComponentEnrichedSBOMSummary[] = [];

  /**
   * @generated from field: int32 undeployed_sbom_count = 4;
   */
  undeployedSbomCount = 0;

  /**
   * @generated from field: int32 retired_sbom_count = 5;
   */
  retiredSbomCount = 0;

  /**
   * @generated from field: string default_tag_name = 6;
   */
  defaultTagName = "";

  /**
   * @generated from field: string selected_tag_name = 7;
   */
  selectedTagName = "";

  constructor(data?: PartialMessage<GetComponentTagsOverviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentTagsOverviewResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default_sbom", kind: "message", T: ComponentEnrichedSBOMSummary },
    { no: 2, name: "other_active_sboms", kind: "message", T: ComponentEnrichedSBOMSummary, repeated: true },
    { no: 4, name: "undeployed_sbom_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "retired_sbom_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "default_tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "selected_tag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentTagsOverviewResponse {
    return new GetComponentTagsOverviewResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentTagsOverviewResponse {
    return new GetComponentTagsOverviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentTagsOverviewResponse {
    return new GetComponentTagsOverviewResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentTagsOverviewResponse | PlainMessage<GetComponentTagsOverviewResponse> | undefined, b: GetComponentTagsOverviewResponse | PlainMessage<GetComponentTagsOverviewResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentTagsOverviewResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesRequest
 */
export class ListComponentIssuesRequest extends Message<ListComponentIssuesRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueState states = 3;
   */
  states: ComponentIssueState[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "states", kind: "enum", T: proto3.getEnumType(ComponentIssueState), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesRequest {
    return new ListComponentIssuesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesRequest {
    return new ListComponentIssuesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesRequest {
    return new ListComponentIssuesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesRequest | PlainMessage<ListComponentIssuesRequest> | undefined, b: ListComponentIssuesRequest | PlainMessage<ListComponentIssuesRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesResponse
 */
export class ListComponentIssuesResponse extends Message<ListComponentIssuesResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssue items = 1;
   */
  items: ComponentIssue[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: ComponentIssue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesResponse {
    return new ListComponentIssuesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesResponse {
    return new ListComponentIssuesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesResponse {
    return new ListComponentIssuesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesResponse | PlainMessage<ListComponentIssuesResponse> | undefined, b: ListComponentIssuesResponse | PlainMessage<ListComponentIssuesResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesByStateForProjectRequest
 */
export class ListComponentIssuesByStateForProjectRequest extends Message<ListComponentIssuesByStateForProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueState states = 2;
   */
  states: ComponentIssueState[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesByStateForProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesByStateForProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "states", kind: "enum", T: proto3.getEnumType(ComponentIssueState), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesByStateForProjectRequest {
    return new ListComponentIssuesByStateForProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesByStateForProjectRequest {
    return new ListComponentIssuesByStateForProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesByStateForProjectRequest {
    return new ListComponentIssuesByStateForProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesByStateForProjectRequest | PlainMessage<ListComponentIssuesByStateForProjectRequest> | undefined, b: ListComponentIssuesByStateForProjectRequest | PlainMessage<ListComponentIssuesByStateForProjectRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesByStateForProjectRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesByStateForProjectResponse
 */
export class ListComponentIssuesByStateForProjectResponse extends Message<ListComponentIssuesByStateForProjectResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssue items = 1;
   */
  items: ComponentIssue[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesByStateForProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesByStateForProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: ComponentIssue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesByStateForProjectResponse {
    return new ListComponentIssuesByStateForProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesByStateForProjectResponse {
    return new ListComponentIssuesByStateForProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesByStateForProjectResponse {
    return new ListComponentIssuesByStateForProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesByStateForProjectResponse | PlainMessage<ListComponentIssuesByStateForProjectResponse> | undefined, b: ListComponentIssuesByStateForProjectResponse | PlainMessage<ListComponentIssuesByStateForProjectResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesByStateForProjectResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesByVulnIDForProjectRequest
 */
export class ListComponentIssuesByVulnIDForProjectRequest extends Message<ListComponentIssuesByVulnIDForProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string search = 2;
   */
  search = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueState states = 3;
   */
  states: ComponentIssueState[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesByVulnIDForProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesByVulnIDForProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "states", kind: "enum", T: proto3.getEnumType(ComponentIssueState), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesByVulnIDForProjectRequest {
    return new ListComponentIssuesByVulnIDForProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesByVulnIDForProjectRequest {
    return new ListComponentIssuesByVulnIDForProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesByVulnIDForProjectRequest {
    return new ListComponentIssuesByVulnIDForProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesByVulnIDForProjectRequest | PlainMessage<ListComponentIssuesByVulnIDForProjectRequest> | undefined, b: ListComponentIssuesByVulnIDForProjectRequest | PlainMessage<ListComponentIssuesByVulnIDForProjectRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesByVulnIDForProjectRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesByVulnIDForProjectResponse
 */
export class ListComponentIssuesByVulnIDForProjectResponse extends Message<ListComponentIssuesByVulnIDForProjectResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssuesBySearchSummary summary = 1;
   */
  summary?: ComponentIssuesBySearchSummary;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssue items = 2;
   */
  items: ComponentIssue[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesByVulnIDForProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesByVulnIDForProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary", kind: "message", T: ComponentIssuesBySearchSummary },
    { no: 2, name: "items", kind: "message", T: ComponentIssue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesByVulnIDForProjectResponse {
    return new ListComponentIssuesByVulnIDForProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesByVulnIDForProjectResponse {
    return new ListComponentIssuesByVulnIDForProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesByVulnIDForProjectResponse {
    return new ListComponentIssuesByVulnIDForProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesByVulnIDForProjectResponse | PlainMessage<ListComponentIssuesByVulnIDForProjectResponse> | undefined, b: ListComponentIssuesByVulnIDForProjectResponse | PlainMessage<ListComponentIssuesByVulnIDForProjectResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesByVulnIDForProjectResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesByPackageSubstringForProjectRequest
 */
export class ListComponentIssuesByPackageSubstringForProjectRequest extends Message<ListComponentIssuesByPackageSubstringForProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string search = 2;
   */
  search = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueState states = 3;
   */
  states: ComponentIssueState[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesByPackageSubstringForProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesByPackageSubstringForProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "states", kind: "enum", T: proto3.getEnumType(ComponentIssueState), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesByPackageSubstringForProjectRequest {
    return new ListComponentIssuesByPackageSubstringForProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesByPackageSubstringForProjectRequest {
    return new ListComponentIssuesByPackageSubstringForProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesByPackageSubstringForProjectRequest {
    return new ListComponentIssuesByPackageSubstringForProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesByPackageSubstringForProjectRequest | PlainMessage<ListComponentIssuesByPackageSubstringForProjectRequest> | undefined, b: ListComponentIssuesByPackageSubstringForProjectRequest | PlainMessage<ListComponentIssuesByPackageSubstringForProjectRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesByPackageSubstringForProjectRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssuesByPackageSubstringForProjectResponse
 */
export class ListComponentIssuesByPackageSubstringForProjectResponse extends Message<ListComponentIssuesByPackageSubstringForProjectResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssuesBySearchSummary summary = 1;
   */
  summary?: ComponentIssuesBySearchSummary;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssue items = 2;
   */
  items: ComponentIssue[] = [];

  constructor(data?: PartialMessage<ListComponentIssuesByPackageSubstringForProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssuesByPackageSubstringForProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary", kind: "message", T: ComponentIssuesBySearchSummary },
    { no: 2, name: "items", kind: "message", T: ComponentIssue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssuesByPackageSubstringForProjectResponse {
    return new ListComponentIssuesByPackageSubstringForProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssuesByPackageSubstringForProjectResponse {
    return new ListComponentIssuesByPackageSubstringForProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssuesByPackageSubstringForProjectResponse {
    return new ListComponentIssuesByPackageSubstringForProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssuesByPackageSubstringForProjectResponse | PlainMessage<ListComponentIssuesByPackageSubstringForProjectResponse> | undefined, b: ListComponentIssuesByPackageSubstringForProjectResponse | PlainMessage<ListComponentIssuesByPackageSubstringForProjectResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentIssuesByPackageSubstringForProjectResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentIssuesBySearchSummary
 */
export class ComponentIssuesBySearchSummary extends Message<ComponentIssuesBySearchSummary> {
  /**
   * @generated from field: int32 active_workloads = 1;
   */
  activeWorkloads = 0;

  /**
   * @generated from field: int32 machines = 2;
   */
  machines = 0;

  /**
   * @generated from field: int32 components = 3;
   */
  components = 0;

  constructor(data?: PartialMessage<ComponentIssuesBySearchSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentIssuesBySearchSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active_workloads", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "machines", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "components", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentIssuesBySearchSummary {
    return new ComponentIssuesBySearchSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentIssuesBySearchSummary {
    return new ComponentIssuesBySearchSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentIssuesBySearchSummary {
    return new ComponentIssuesBySearchSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentIssuesBySearchSummary | PlainMessage<ComponentIssuesBySearchSummary> | undefined, b: ComponentIssuesBySearchSummary | PlainMessage<ComponentIssuesBySearchSummary> | undefined): boolean {
    return proto3.util.equals(ComponentIssuesBySearchSummary, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentIssueRequest
 */
export class GetComponentIssueRequest extends Message<GetComponentIssueRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<GetComponentIssueRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentIssueRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentIssueRequest {
    return new GetComponentIssueRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentIssueRequest {
    return new GetComponentIssueRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentIssueRequest {
    return new GetComponentIssueRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentIssueRequest | PlainMessage<GetComponentIssueRequest> | undefined, b: GetComponentIssueRequest | PlainMessage<GetComponentIssueRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentIssueRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentIssueResponse
 */
export class GetComponentIssueResponse extends Message<GetComponentIssueResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssue issue = 1;
   */
  issue?: ComponentIssue;

  constructor(data?: PartialMessage<GetComponentIssueResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentIssueResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "issue", kind: "message", T: ComponentIssue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentIssueResponse {
    return new GetComponentIssueResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentIssueResponse {
    return new GetComponentIssueResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentIssueResponse {
    return new GetComponentIssueResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentIssueResponse | PlainMessage<GetComponentIssueResponse> | undefined, b: GetComponentIssueResponse | PlainMessage<GetComponentIssueResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentIssueResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateComponentIssueRequest
 */
export class UpdateComponentIssueRequest extends Message<UpdateComponentIssueRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueState state = 4;
   */
  state = ComponentIssueState.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity severity = 5;
   */
  severity = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: string comment = 6;
   */
  comment = "";

  constructor(data?: PartialMessage<UpdateComponentIssueRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateComponentIssueRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(ComponentIssueState) },
    { no: 5, name: "severity", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 6, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateComponentIssueRequest {
    return new UpdateComponentIssueRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateComponentIssueRequest {
    return new UpdateComponentIssueRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateComponentIssueRequest {
    return new UpdateComponentIssueRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateComponentIssueRequest | PlainMessage<UpdateComponentIssueRequest> | undefined, b: UpdateComponentIssueRequest | PlainMessage<UpdateComponentIssueRequest> | undefined): boolean {
    return proto3.util.equals(UpdateComponentIssueRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateComponentIssueResponse
 */
export class UpdateComponentIssueResponse extends Message<UpdateComponentIssueResponse> {
  constructor(data?: PartialMessage<UpdateComponentIssueResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateComponentIssueResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateComponentIssueResponse {
    return new UpdateComponentIssueResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateComponentIssueResponse {
    return new UpdateComponentIssueResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateComponentIssueResponse {
    return new UpdateComponentIssueResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateComponentIssueResponse | PlainMessage<UpdateComponentIssueResponse> | undefined, b: UpdateComponentIssueResponse | PlainMessage<UpdateComponentIssueResponse> | undefined): boolean {
    return proto3.util.equals(UpdateComponentIssueResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.VulnIssueDetails
 */
export class VulnIssueDetails extends Message<VulnIssueDetails> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VulnReference references = 3;
   */
  references: VulnReference[] = [];

  /**
   * @generated from field: edgebit.platform.v1alpha.VulnFixState fix_state = 4;
   */
  fixState = VulnFixState.UNSPECIFIED;

  /**
   * @generated from field: repeated string fix_versions = 6;
   */
  fixVersions: string[] = [];

  constructor(data?: PartialMessage<VulnIssueDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.VulnIssueDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "references", kind: "message", T: VulnReference, repeated: true },
    { no: 4, name: "fix_state", kind: "enum", T: proto3.getEnumType(VulnFixState) },
    { no: 6, name: "fix_versions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VulnIssueDetails {
    return new VulnIssueDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VulnIssueDetails {
    return new VulnIssueDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VulnIssueDetails {
    return new VulnIssueDetails().fromJsonString(jsonString, options);
  }

  static equals(a: VulnIssueDetails | PlainMessage<VulnIssueDetails> | undefined, b: VulnIssueDetails | PlainMessage<VulnIssueDetails> | undefined): boolean {
    return proto3.util.equals(VulnIssueDetails, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentIssue
 */
export class ComponentIssue extends Message<ComponentIssue> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Deprecated
   *
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 4;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: string package_type = 5;
   */
  packageType = "";

  /**
   * @generated from field: string package_name = 6;
   */
  packageName = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity upstream_severity = 7;
   */
  upstreamSeverity = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueState state = 8;
   */
  state = ComponentIssueState.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity severity = 9;
   */
  severity = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: repeated string affected_package_urls = 10;
   */
  affectedPackageUrls: string[] = [];

  /**
   * @generated from oneof edgebit.platform.v1alpha.ComponentIssue.details
   */
  details: {
    /**
     * @generated from field: edgebit.platform.v1alpha.VulnIssueDetails vuln_details = 11;
     */
    value: VulnIssueDetails;
    case: "vulnDetails";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: map<string, string> labels = 12;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentRef component_ref = 13;
   */
  componentRef?: ComponentRef;

  /**
   * @generated from field: optional google.protobuf.Timestamp due_at = 14;
   */
  dueAt?: Timestamp;

  constructor(data?: PartialMessage<ComponentIssue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentIssue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "package_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "upstream_severity", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 8, name: "state", kind: "enum", T: proto3.getEnumType(ComponentIssueState) },
    { no: 9, name: "severity", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 10, name: "affected_package_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "vuln_details", kind: "message", T: VulnIssueDetails, oneof: "details" },
    { no: 12, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 13, name: "component_ref", kind: "message", T: ComponentRef },
    { no: 14, name: "due_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentIssue {
    return new ComponentIssue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentIssue {
    return new ComponentIssue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentIssue {
    return new ComponentIssue().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentIssue | PlainMessage<ComponentIssue> | undefined, b: ComponentIssue | PlainMessage<ComponentIssue> | undefined): boolean {
    return proto3.util.equals(ComponentIssue, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProjectIssueTrendRequest
 */
export class GetProjectIssueTrendRequest extends Message<GetProjectIssueTrendRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<GetProjectIssueTrendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProjectIssueTrendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectIssueTrendRequest {
    return new GetProjectIssueTrendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectIssueTrendRequest {
    return new GetProjectIssueTrendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectIssueTrendRequest {
    return new GetProjectIssueTrendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectIssueTrendRequest | PlainMessage<GetProjectIssueTrendRequest> | undefined, b: GetProjectIssueTrendRequest | PlainMessage<GetProjectIssueTrendRequest> | undefined): boolean {
    return proto3.util.equals(GetProjectIssueTrendRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProjectIssueTrendResponse
 */
export class GetProjectIssueTrendResponse extends Message<GetProjectIssueTrendResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueTrendItem items = 1;
   */
  items: ComponentIssueTrendItem[] = [];

  /**
   * @generated from field: int32 total_unresolved_issues = 2;
   */
  totalUnresolvedIssues = 0;

  /**
   * @generated from field: int32 suppressed_unresolved_issues = 3;
   */
  suppressedUnresolvedIssues = 0;

  /**
   * @generated from field: map<string, int32> suppressed_issues_by_policy = 4;
   */
  suppressedIssuesByPolicy: { [key: string]: number } = {};

  constructor(data?: PartialMessage<GetProjectIssueTrendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProjectIssueTrendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: ComponentIssueTrendItem, repeated: true },
    { no: 2, name: "total_unresolved_issues", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "suppressed_unresolved_issues", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "suppressed_issues_by_policy", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectIssueTrendResponse {
    return new GetProjectIssueTrendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectIssueTrendResponse {
    return new GetProjectIssueTrendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectIssueTrendResponse {
    return new GetProjectIssueTrendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectIssueTrendResponse | PlainMessage<GetProjectIssueTrendResponse> | undefined, b: GetProjectIssueTrendResponse | PlainMessage<GetProjectIssueTrendResponse> | undefined): boolean {
    return proto3.util.equals(GetProjectIssueTrendResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentIssueTrendRequest
 */
export class GetComponentIssueTrendRequest extends Message<GetComponentIssueTrendRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  constructor(data?: PartialMessage<GetComponentIssueTrendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentIssueTrendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentIssueTrendRequest {
    return new GetComponentIssueTrendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentIssueTrendRequest {
    return new GetComponentIssueTrendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentIssueTrendRequest {
    return new GetComponentIssueTrendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentIssueTrendRequest | PlainMessage<GetComponentIssueTrendRequest> | undefined, b: GetComponentIssueTrendRequest | PlainMessage<GetComponentIssueTrendRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentIssueTrendRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentIssueTrendResponse
 */
export class GetComponentIssueTrendResponse extends Message<GetComponentIssueTrendResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueTrendItem items = 1;
   */
  items: ComponentIssueTrendItem[] = [];

  /**
   * @generated from field: int32 total_unresolved_issues = 2;
   */
  totalUnresolvedIssues = 0;

  /**
   * @generated from field: int32 suppressed_unresolved_issues = 3;
   */
  suppressedUnresolvedIssues = 0;

  /**
   * @generated from field: map<string, int32> suppressed_issues_by_policy = 4;
   */
  suppressedIssuesByPolicy: { [key: string]: number } = {};

  constructor(data?: PartialMessage<GetComponentIssueTrendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentIssueTrendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: ComponentIssueTrendItem, repeated: true },
    { no: 2, name: "total_unresolved_issues", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "suppressed_unresolved_issues", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "suppressed_issues_by_policy", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 5 /* ScalarType.INT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentIssueTrendResponse {
    return new GetComponentIssueTrendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentIssueTrendResponse {
    return new GetComponentIssueTrendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentIssueTrendResponse {
    return new GetComponentIssueTrendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentIssueTrendResponse | PlainMessage<GetComponentIssueTrendResponse> | undefined, b: GetComponentIssueTrendResponse | PlainMessage<GetComponentIssueTrendResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentIssueTrendResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentIssueTrendItem
 */
export class ComponentIssueTrendItem extends Message<ComponentIssueTrendItem> {
  /**
   * @generated from field: google.protobuf.Timestamp date = 1;
   */
  date?: Timestamp;

  /**
   * @generated from field: int32 critical_vulns = 2;
   */
  criticalVulns = 0;

  /**
   * @generated from field: int32 high_vulns = 3;
   */
  highVulns = 0;

  /**
   * @generated from field: int32 medium_vulns = 4;
   */
  mediumVulns = 0;

  /**
   * @generated from field: int32 low_vulns = 5;
   */
  lowVulns = 0;

  /**
   * @generated from field: int32 negligible_vulns = 6;
   */
  negligibleVulns = 0;

  constructor(data?: PartialMessage<ComponentIssueTrendItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentIssueTrendItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 2, name: "critical_vulns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "high_vulns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "medium_vulns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "low_vulns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "negligible_vulns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentIssueTrendItem {
    return new ComponentIssueTrendItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentIssueTrendItem {
    return new ComponentIssueTrendItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentIssueTrendItem {
    return new ComponentIssueTrendItem().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentIssueTrendItem | PlainMessage<ComponentIssueTrendItem> | undefined, b: ComponentIssueTrendItem | PlainMessage<ComponentIssueTrendItem> | undefined): boolean {
    return proto3.util.equals(ComponentIssueTrendItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentEnrichedSBOMSummary
 */
export class ComponentEnrichedSBOMSummary extends Message<ComponentEnrichedSBOMSummary> {
  /**
   * @generated from field: edgebit.platform.v1alpha.SBOMListItem sbom = 1;
   */
  sbom?: SBOMListItem;

  /**
   * @generated from field: repeated string tags = 2;
   */
  tags: string[] = [];

  /**
   * @generated from field: int32 active_workloads = 3;
   */
  activeWorkloads = 0;

  constructor(data?: PartialMessage<ComponentEnrichedSBOMSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentEnrichedSBOMSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sbom", kind: "message", T: SBOMListItem },
    { no: 2, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "active_workloads", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentEnrichedSBOMSummary {
    return new ComponentEnrichedSBOMSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentEnrichedSBOMSummary {
    return new ComponentEnrichedSBOMSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentEnrichedSBOMSummary {
    return new ComponentEnrichedSBOMSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentEnrichedSBOMSummary | PlainMessage<ComponentEnrichedSBOMSummary> | undefined, b: ComponentEnrichedSBOMSummary | PlainMessage<ComponentEnrichedSBOMSummary> | undefined): boolean {
    return proto3.util.equals(ComponentEnrichedSBOMSummary, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetCIBotCommentRequest
 */
export class GetCIBotCommentRequest extends Message<GetCIBotCommentRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string sbom_id = 2;
   */
  sbomId = "";

  /**
   * @generated from field: string commit_id = 3;
   */
  commitId = "";

  /**
   * @generated from field: string base_commit_id = 4;
   */
  baseCommitId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.CIBotCommentFlavor flavor = 5;
   */
  flavor = CIBotCommentFlavor.CI_BOT_COMMENT_FLAVOR_UNSPECIFIED;

  constructor(data?: PartialMessage<GetCIBotCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetCIBotCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "commit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_commit_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "flavor", kind: "enum", T: proto3.getEnumType(CIBotCommentFlavor) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCIBotCommentRequest {
    return new GetCIBotCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCIBotCommentRequest {
    return new GetCIBotCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCIBotCommentRequest {
    return new GetCIBotCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCIBotCommentRequest | PlainMessage<GetCIBotCommentRequest> | undefined, b: GetCIBotCommentRequest | PlainMessage<GetCIBotCommentRequest> | undefined): boolean {
    return proto3.util.equals(GetCIBotCommentRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetCIBotCommentResponse
 */
export class GetCIBotCommentResponse extends Message<GetCIBotCommentResponse> {
  /**
   * @generated from field: string comment_body = 1;
   */
  commentBody = "";

  /**
   * @generated from field: bool skip_comment = 2;
   */
  skipComment = false;

  constructor(data?: PartialMessage<GetCIBotCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetCIBotCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comment_body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "skip_comment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCIBotCommentResponse {
    return new GetCIBotCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCIBotCommentResponse {
    return new GetCIBotCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCIBotCommentResponse {
    return new GetCIBotCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCIBotCommentResponse | PlainMessage<GetCIBotCommentResponse> | undefined, b: GetCIBotCommentResponse | PlainMessage<GetCIBotCommentResponse> | undefined): boolean {
    return proto3.util.equals(GetCIBotCommentResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListWorkloadsRequest
 */
export class ListWorkloadsRequest extends Message<ListWorkloadsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListWorkloadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListWorkloadsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWorkloadsRequest {
    return new ListWorkloadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWorkloadsRequest {
    return new ListWorkloadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWorkloadsRequest {
    return new ListWorkloadsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListWorkloadsRequest | PlainMessage<ListWorkloadsRequest> | undefined, b: ListWorkloadsRequest | PlainMessage<ListWorkloadsRequest> | undefined): boolean {
    return proto3.util.equals(ListWorkloadsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListWorkloadsResponse
 */
export class ListWorkloadsResponse extends Message<ListWorkloadsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.WorkloadItem workloads = 1;
   */
  workloads: WorkloadItem[] = [];

  constructor(data?: PartialMessage<ListWorkloadsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListWorkloadsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workloads", kind: "message", T: WorkloadItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWorkloadsResponse {
    return new ListWorkloadsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWorkloadsResponse {
    return new ListWorkloadsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWorkloadsResponse {
    return new ListWorkloadsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListWorkloadsResponse | PlainMessage<ListWorkloadsResponse> | undefined, b: ListWorkloadsResponse | PlainMessage<ListWorkloadsResponse> | undefined): boolean {
    return proto3.util.equals(ListWorkloadsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListWorkloadItemsForMachineRequest
 */
export class ListWorkloadItemsForMachineRequest extends Message<ListWorkloadItemsForMachineRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string machine_id = 2;
   */
  machineId = "";

  constructor(data?: PartialMessage<ListWorkloadItemsForMachineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListWorkloadItemsForMachineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWorkloadItemsForMachineRequest {
    return new ListWorkloadItemsForMachineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWorkloadItemsForMachineRequest {
    return new ListWorkloadItemsForMachineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWorkloadItemsForMachineRequest {
    return new ListWorkloadItemsForMachineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListWorkloadItemsForMachineRequest | PlainMessage<ListWorkloadItemsForMachineRequest> | undefined, b: ListWorkloadItemsForMachineRequest | PlainMessage<ListWorkloadItemsForMachineRequest> | undefined): boolean {
    return proto3.util.equals(ListWorkloadItemsForMachineRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListWorkloadItemsForMachineResponse
 */
export class ListWorkloadItemsForMachineResponse extends Message<ListWorkloadItemsForMachineResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.WorkloadItem workload_items = 1;
   */
  workloadItems: WorkloadItem[] = [];

  constructor(data?: PartialMessage<ListWorkloadItemsForMachineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListWorkloadItemsForMachineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workload_items", kind: "message", T: WorkloadItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListWorkloadItemsForMachineResponse {
    return new ListWorkloadItemsForMachineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListWorkloadItemsForMachineResponse {
    return new ListWorkloadItemsForMachineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListWorkloadItemsForMachineResponse {
    return new ListWorkloadItemsForMachineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListWorkloadItemsForMachineResponse | PlainMessage<ListWorkloadItemsForMachineResponse> | undefined, b: ListWorkloadItemsForMachineResponse | PlainMessage<ListWorkloadItemsForMachineResponse> | undefined): boolean {
    return proto3.util.equals(ListWorkloadItemsForMachineResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetWorkloadRequest
 */
export class GetWorkloadRequest extends Message<GetWorkloadRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string workload_id = 2;
   */
  workloadId = "";

  constructor(data?: PartialMessage<GetWorkloadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetWorkloadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workload_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWorkloadRequest {
    return new GetWorkloadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWorkloadRequest {
    return new GetWorkloadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWorkloadRequest {
    return new GetWorkloadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetWorkloadRequest | PlainMessage<GetWorkloadRequest> | undefined, b: GetWorkloadRequest | PlainMessage<GetWorkloadRequest> | undefined): boolean {
    return proto3.util.equals(GetWorkloadRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetWorkloadResponse
 */
export class GetWorkloadResponse extends Message<GetWorkloadResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.WorkloadItem workload = 1;
   */
  workload?: WorkloadItem;

  constructor(data?: PartialMessage<GetWorkloadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetWorkloadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workload", kind: "message", T: WorkloadItem },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetWorkloadResponse {
    return new GetWorkloadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetWorkloadResponse {
    return new GetWorkloadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetWorkloadResponse {
    return new GetWorkloadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetWorkloadResponse | PlainMessage<GetWorkloadResponse> | undefined, b: GetWorkloadResponse | PlainMessage<GetWorkloadResponse> | undefined): boolean {
    return proto3.util.equals(GetWorkloadResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.WorkloadItem
 */
export class WorkloadItem extends Message<WorkloadItem> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  /**
   * @generated from field: int32 total_vulnerabilities = 5;
   */
  totalVulnerabilities = 0;

  /**
   * @generated from field: string sbom_id = 6;
   */
  sbomId = "";

  /**
   * @generated from field: string image_name = 7;
   */
  imageName = "";

  /**
   * @generated from field: google.protobuf.Timestamp started_at = 8;
   */
  startedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp ended_at = 9;
   */
  endedAt?: Timestamp;

  /**
   * @generated from field: map<string, string> labels = 10;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ComponentRef component_ref = 11;
   */
  componentRef?: ComponentRef;

  /**
   * @generated from field: edgebit.platform.v1alpha.MachineRef machine_ref = 12;
   */
  machineRef?: MachineRef;

  /**
   * @generated from field: optional string image_external_id = 13;
   */
  imageExternalId?: string;

  /**
   * @generated from field: optional string external_id = 14;
   */
  externalId?: string;

  constructor(data?: PartialMessage<WorkloadItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.WorkloadItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "total_vulnerabilities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "image_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "started_at", kind: "message", T: Timestamp },
    { no: 9, name: "ended_at", kind: "message", T: Timestamp },
    { no: 10, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 11, name: "component_ref", kind: "message", T: ComponentRef, opt: true },
    { no: 12, name: "machine_ref", kind: "message", T: MachineRef },
    { no: 13, name: "image_external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WorkloadItem {
    return new WorkloadItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WorkloadItem {
    return new WorkloadItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WorkloadItem {
    return new WorkloadItem().fromJsonString(jsonString, options);
  }

  static equals(a: WorkloadItem | PlainMessage<WorkloadItem> | undefined, b: WorkloadItem | PlainMessage<WorkloadItem> | undefined): boolean {
    return proto3.util.equals(WorkloadItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMDownloadUrlRequest
 */
export class GetSBOMDownloadUrlRequest extends Message<GetSBOMDownloadUrlRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string sbom_id = 2;
   */
  sbomId = "";

  constructor(data?: PartialMessage<GetSBOMDownloadUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMDownloadUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMDownloadUrlRequest {
    return new GetSBOMDownloadUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMDownloadUrlRequest {
    return new GetSBOMDownloadUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMDownloadUrlRequest {
    return new GetSBOMDownloadUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMDownloadUrlRequest | PlainMessage<GetSBOMDownloadUrlRequest> | undefined, b: GetSBOMDownloadUrlRequest | PlainMessage<GetSBOMDownloadUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetSBOMDownloadUrlRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMDownloadUrlResponse
 */
export class GetSBOMDownloadUrlResponse extends Message<GetSBOMDownloadUrlResponse> {
  /**
   * @generated from field: string sbom_download_path = 1;
   */
  sbomDownloadPath = "";

  constructor(data?: PartialMessage<GetSBOMDownloadUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMDownloadUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sbom_download_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMDownloadUrlResponse {
    return new GetSBOMDownloadUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMDownloadUrlResponse {
    return new GetSBOMDownloadUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMDownloadUrlResponse {
    return new GetSBOMDownloadUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMDownloadUrlResponse | PlainMessage<GetSBOMDownloadUrlResponse> | undefined, b: GetSBOMDownloadUrlResponse | PlainMessage<GetSBOMDownloadUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetSBOMDownloadUrlResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetVexDownloadUrlRequest
 */
export class GetVexDownloadUrlRequest extends Message<GetVexDownloadUrlRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  constructor(data?: PartialMessage<GetVexDownloadUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetVexDownloadUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVexDownloadUrlRequest {
    return new GetVexDownloadUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVexDownloadUrlRequest {
    return new GetVexDownloadUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVexDownloadUrlRequest {
    return new GetVexDownloadUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVexDownloadUrlRequest | PlainMessage<GetVexDownloadUrlRequest> | undefined, b: GetVexDownloadUrlRequest | PlainMessage<GetVexDownloadUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetVexDownloadUrlRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetVexDownloadUrlResponse
 */
export class GetVexDownloadUrlResponse extends Message<GetVexDownloadUrlResponse> {
  /**
   * @generated from field: string vex_download_path = 1;
   */
  vexDownloadPath = "";

  constructor(data?: PartialMessage<GetVexDownloadUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetVexDownloadUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vex_download_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVexDownloadUrlResponse {
    return new GetVexDownloadUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVexDownloadUrlResponse {
    return new GetVexDownloadUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVexDownloadUrlResponse {
    return new GetVexDownloadUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetVexDownloadUrlResponse | PlainMessage<GetVexDownloadUrlResponse> | undefined, b: GetVexDownloadUrlResponse | PlainMessage<GetVexDownloadUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetVexDownloadUrlResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentIssuesReportDownloadUrlRequest
 */
export class GetComponentIssuesReportDownloadUrlRequest extends Message<GetComponentIssuesReportDownloadUrlRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string start_date = 2;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 3;
   */
  endDate = "";

  constructor(data?: PartialMessage<GetComponentIssuesReportDownloadUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentIssuesReportDownloadUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentIssuesReportDownloadUrlRequest {
    return new GetComponentIssuesReportDownloadUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentIssuesReportDownloadUrlRequest {
    return new GetComponentIssuesReportDownloadUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentIssuesReportDownloadUrlRequest {
    return new GetComponentIssuesReportDownloadUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentIssuesReportDownloadUrlRequest | PlainMessage<GetComponentIssuesReportDownloadUrlRequest> | undefined, b: GetComponentIssuesReportDownloadUrlRequest | PlainMessage<GetComponentIssuesReportDownloadUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetComponentIssuesReportDownloadUrlRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetComponentIssuesReportDownloadUrlResponse
 */
export class GetComponentIssuesReportDownloadUrlResponse extends Message<GetComponentIssuesReportDownloadUrlResponse> {
  /**
   * @generated from field: string report_download_path = 1;
   */
  reportDownloadPath = "";

  constructor(data?: PartialMessage<GetComponentIssuesReportDownloadUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetComponentIssuesReportDownloadUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_download_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentIssuesReportDownloadUrlResponse {
    return new GetComponentIssuesReportDownloadUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentIssuesReportDownloadUrlResponse {
    return new GetComponentIssuesReportDownloadUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComponentIssuesReportDownloadUrlResponse {
    return new GetComponentIssuesReportDownloadUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetComponentIssuesReportDownloadUrlResponse | PlainMessage<GetComponentIssuesReportDownloadUrlResponse> | undefined, b: GetComponentIssuesReportDownloadUrlResponse | PlainMessage<GetComponentIssuesReportDownloadUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetComponentIssuesReportDownloadUrlResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GraphRelationship
 */
export class GraphRelationship extends Message<GraphRelationship> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string related_id = 2;
   */
  relatedId = "";

  /**
   * @generated from field: string relationship_type = 3;
   */
  relationshipType = "";

  constructor(data?: PartialMessage<GraphRelationship>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GraphRelationship";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "related_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "relationship_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GraphRelationship {
    return new GraphRelationship().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GraphRelationship {
    return new GraphRelationship().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GraphRelationship {
    return new GraphRelationship().fromJsonString(jsonString, options);
  }

  static equals(a: GraphRelationship | PlainMessage<GraphRelationship> | undefined, b: GraphRelationship | PlainMessage<GraphRelationship> | undefined): boolean {
    return proto3.util.equals(GraphRelationship, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentRef
 */
export class ComponentRef extends Message<ComponentRef> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  constructor(data?: PartialMessage<ComponentRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentRef {
    return new ComponentRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentRef {
    return new ComponentRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentRef {
    return new ComponentRef().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentRef | PlainMessage<ComponentRef> | undefined, b: ComponentRef | PlainMessage<ComponentRef> | undefined): boolean {
    return proto3.util.equals(ComponentRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.MachineRef
 */
export class MachineRef extends Message<MachineRef> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string hostname = 2;
   */
  hostname = "";

  constructor(data?: PartialMessage<MachineRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.MachineRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MachineRef {
    return new MachineRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MachineRef {
    return new MachineRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MachineRef {
    return new MachineRef().fromJsonString(jsonString, options);
  }

  static equals(a: MachineRef | PlainMessage<MachineRef> | undefined, b: MachineRef | PlainMessage<MachineRef> | undefined): boolean {
    return proto3.util.equals(MachineRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.VCSRepository
 */
export class VCSRepository extends Message<VCSRepository> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string owner = 2;
   */
  owner = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: bool private = 5;
   */
  private = false;

  /**
   * @generated from field: string url = 6;
   */
  url = "";

  /**
   * @generated from field: google.protobuf.Timestamp last_activity = 7;
   */
  lastActivity?: Timestamp;

  constructor(data?: PartialMessage<VCSRepository>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.VCSRepository";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "last_activity", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VCSRepository {
    return new VCSRepository().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VCSRepository {
    return new VCSRepository().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VCSRepository {
    return new VCSRepository().fromJsonString(jsonString, options);
  }

  static equals(a: VCSRepository | PlainMessage<VCSRepository> | undefined, b: VCSRepository | PlainMessage<VCSRepository> | undefined): boolean {
    return proto3.util.equals(VCSRepository, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GitHubIntegrationSettings
 */
export class GitHubIntegrationSettings extends Message<GitHubIntegrationSettings> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SBOMGenerationSource sbom_src_order = 3;
   */
  sbomSrcOrder: SBOMGenerationSource[] = [];

  constructor(data?: PartialMessage<GitHubIntegrationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GitHubIntegrationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sbom_src_order", kind: "enum", T: proto3.getEnumType(SBOMGenerationSource), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitHubIntegrationSettings {
    return new GitHubIntegrationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitHubIntegrationSettings {
    return new GitHubIntegrationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitHubIntegrationSettings {
    return new GitHubIntegrationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: GitHubIntegrationSettings | PlainMessage<GitHubIntegrationSettings> | undefined, b: GitHubIntegrationSettings | PlainMessage<GitHubIntegrationSettings> | undefined): boolean {
    return proto3.util.equals(GitHubIntegrationSettings, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetGitHubIntegrationRequest
 */
export class GetGitHubIntegrationRequest extends Message<GetGitHubIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<GetGitHubIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetGitHubIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGitHubIntegrationRequest {
    return new GetGitHubIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGitHubIntegrationRequest {
    return new GetGitHubIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGitHubIntegrationRequest {
    return new GetGitHubIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGitHubIntegrationRequest | PlainMessage<GetGitHubIntegrationRequest> | undefined, b: GetGitHubIntegrationRequest | PlainMessage<GetGitHubIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetGitHubIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetGitHubIntegrationResponse
 */
export class GetGitHubIntegrationResponse extends Message<GetGitHubIntegrationResponse> {
  /**
   * @generated from field: string gh_acct = 1;
   */
  ghAcct = "";

  /**
   * @generated from field: string gh_acct_type = 2;
   */
  ghAcctType = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.GitHubIntegrationSettings settings = 3;
   */
  settings: GitHubIntegrationSettings[] = [];

  constructor(data?: PartialMessage<GetGitHubIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetGitHubIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gh_acct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "gh_acct_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settings", kind: "message", T: GitHubIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGitHubIntegrationResponse {
    return new GetGitHubIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGitHubIntegrationResponse {
    return new GetGitHubIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGitHubIntegrationResponse {
    return new GetGitHubIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGitHubIntegrationResponse | PlainMessage<GetGitHubIntegrationResponse> | undefined, b: GetGitHubIntegrationResponse | PlainMessage<GetGitHubIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetGitHubIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.HasGitHubIntegrationRequest
 */
export class HasGitHubIntegrationRequest extends Message<HasGitHubIntegrationRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<HasGitHubIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.HasGitHubIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HasGitHubIntegrationRequest {
    return new HasGitHubIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HasGitHubIntegrationRequest {
    return new HasGitHubIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HasGitHubIntegrationRequest {
    return new HasGitHubIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HasGitHubIntegrationRequest | PlainMessage<HasGitHubIntegrationRequest> | undefined, b: HasGitHubIntegrationRequest | PlainMessage<HasGitHubIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(HasGitHubIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.HasGitHubIntegrationResponse
 */
export class HasGitHubIntegrationResponse extends Message<HasGitHubIntegrationResponse> {
  /**
   * @generated from field: bool present = 1;
   */
  present = false;

  constructor(data?: PartialMessage<HasGitHubIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.HasGitHubIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "present", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HasGitHubIntegrationResponse {
    return new HasGitHubIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HasGitHubIntegrationResponse {
    return new HasGitHubIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HasGitHubIntegrationResponse {
    return new HasGitHubIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HasGitHubIntegrationResponse | PlainMessage<HasGitHubIntegrationResponse> | undefined, b: HasGitHubIntegrationResponse | PlainMessage<HasGitHubIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(HasGitHubIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateGitHubIntegrationRequest
 */
export class UpdateGitHubIntegrationRequest extends Message<UpdateGitHubIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.GitHubIntegrationSettings settings = 2;
   */
  settings: GitHubIntegrationSettings[] = [];

  constructor(data?: PartialMessage<UpdateGitHubIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateGitHubIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settings", kind: "message", T: GitHubIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGitHubIntegrationRequest {
    return new UpdateGitHubIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGitHubIntegrationRequest {
    return new UpdateGitHubIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGitHubIntegrationRequest {
    return new UpdateGitHubIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGitHubIntegrationRequest | PlainMessage<UpdateGitHubIntegrationRequest> | undefined, b: UpdateGitHubIntegrationRequest | PlainMessage<UpdateGitHubIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateGitHubIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateGitHubIntegrationResponse
 */
export class UpdateGitHubIntegrationResponse extends Message<UpdateGitHubIntegrationResponse> {
  constructor(data?: PartialMessage<UpdateGitHubIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateGitHubIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGitHubIntegrationResponse {
    return new UpdateGitHubIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGitHubIntegrationResponse {
    return new UpdateGitHubIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGitHubIntegrationResponse {
    return new UpdateGitHubIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGitHubIntegrationResponse | PlainMessage<UpdateGitHubIntegrationResponse> | undefined, b: UpdateGitHubIntegrationResponse | PlainMessage<UpdateGitHubIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateGitHubIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListGitHubRepositoriesRequest
 */
export class ListGitHubRepositoriesRequest extends Message<ListGitHubRepositoriesRequest> {
  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: bool include_configured = 3;
   */
  includeConfigured = false;

  constructor(data?: PartialMessage<ListGitHubRepositoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListGitHubRepositoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "include_configured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGitHubRepositoriesRequest {
    return new ListGitHubRepositoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGitHubRepositoriesRequest {
    return new ListGitHubRepositoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGitHubRepositoriesRequest {
    return new ListGitHubRepositoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGitHubRepositoriesRequest | PlainMessage<ListGitHubRepositoriesRequest> | undefined, b: ListGitHubRepositoriesRequest | PlainMessage<ListGitHubRepositoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListGitHubRepositoriesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListGitHubRepositoriesResponse
 */
export class ListGitHubRepositoriesResponse extends Message<ListGitHubRepositoriesResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VCSRepository repos = 1;
   */
  repos: VCSRepository[] = [];

  constructor(data?: PartialMessage<ListGitHubRepositoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListGitHubRepositoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "repos", kind: "message", T: VCSRepository, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGitHubRepositoriesResponse {
    return new ListGitHubRepositoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGitHubRepositoriesResponse {
    return new ListGitHubRepositoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGitHubRepositoriesResponse {
    return new ListGitHubRepositoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGitHubRepositoriesResponse | PlainMessage<ListGitHubRepositoriesResponse> | undefined, b: ListGitHubRepositoriesResponse | PlainMessage<ListGitHubRepositoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListGitHubRepositoriesResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteGitHubAppInstallRequest
 */
export class CompleteGitHubAppInstallRequest extends Message<CompleteGitHubAppInstallRequest> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<CompleteGitHubAppInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteGitHubAppInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteGitHubAppInstallRequest {
    return new CompleteGitHubAppInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteGitHubAppInstallRequest {
    return new CompleteGitHubAppInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteGitHubAppInstallRequest {
    return new CompleteGitHubAppInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteGitHubAppInstallRequest | PlainMessage<CompleteGitHubAppInstallRequest> | undefined, b: CompleteGitHubAppInstallRequest | PlainMessage<CompleteGitHubAppInstallRequest> | undefined): boolean {
    return proto3.util.equals(CompleteGitHubAppInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteGitHubAppInstallResponse
 */
export class CompleteGitHubAppInstallResponse extends Message<CompleteGitHubAppInstallResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CompleteGitHubAppInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteGitHubAppInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteGitHubAppInstallResponse {
    return new CompleteGitHubAppInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteGitHubAppInstallResponse {
    return new CompleteGitHubAppInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteGitHubAppInstallResponse {
    return new CompleteGitHubAppInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteGitHubAppInstallResponse | PlainMessage<CompleteGitHubAppInstallResponse> | undefined, b: CompleteGitHubAppInstallResponse | PlainMessage<CompleteGitHubAppInstallResponse> | undefined): boolean {
    return proto3.util.equals(CompleteGitHubAppInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AnalyzeGitHubRepoRequest
 */
export class AnalyzeGitHubRepoRequest extends Message<AnalyzeGitHubRepoRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string integration_id = 2;
   */
  integrationId = "";

  /**
   * @generated from field: string owner = 3;
   */
  owner = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  constructor(data?: PartialMessage<AnalyzeGitHubRepoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AnalyzeGitHubRepoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnalyzeGitHubRepoRequest {
    return new AnalyzeGitHubRepoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnalyzeGitHubRepoRequest {
    return new AnalyzeGitHubRepoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnalyzeGitHubRepoRequest {
    return new AnalyzeGitHubRepoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AnalyzeGitHubRepoRequest | PlainMessage<AnalyzeGitHubRepoRequest> | undefined, b: AnalyzeGitHubRepoRequest | PlainMessage<AnalyzeGitHubRepoRequest> | undefined): boolean {
    return proto3.util.equals(AnalyzeGitHubRepoRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AnalyzeGitHubRepoResponse
 */
export class AnalyzeGitHubRepoResponse extends Message<AnalyzeGitHubRepoResponse> {
  /**
   * @generated from field: bool build_action_present = 1;
   */
  buildActionPresent = false;

  constructor(data?: PartialMessage<AnalyzeGitHubRepoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AnalyzeGitHubRepoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "build_action_present", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnalyzeGitHubRepoResponse {
    return new AnalyzeGitHubRepoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnalyzeGitHubRepoResponse {
    return new AnalyzeGitHubRepoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnalyzeGitHubRepoResponse {
    return new AnalyzeGitHubRepoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AnalyzeGitHubRepoResponse | PlainMessage<AnalyzeGitHubRepoResponse> | undefined, b: AnalyzeGitHubRepoResponse | PlainMessage<AnalyzeGitHubRepoResponse> | undefined): boolean {
    return proto3.util.equals(AnalyzeGitHubRepoResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListGitHubIntegrationsRequest
 */
export class ListGitHubIntegrationsRequest extends Message<ListGitHubIntegrationsRequest> {
  constructor(data?: PartialMessage<ListGitHubIntegrationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListGitHubIntegrationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGitHubIntegrationsRequest {
    return new ListGitHubIntegrationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGitHubIntegrationsRequest {
    return new ListGitHubIntegrationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGitHubIntegrationsRequest {
    return new ListGitHubIntegrationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListGitHubIntegrationsRequest | PlainMessage<ListGitHubIntegrationsRequest> | undefined, b: ListGitHubIntegrationsRequest | PlainMessage<ListGitHubIntegrationsRequest> | undefined): boolean {
    return proto3.util.equals(ListGitHubIntegrationsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GitHubIntegrationSummary
 */
export class GitHubIntegrationSummary extends Message<GitHubIntegrationSummary> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string gh_acct = 2;
   */
  ghAcct = "";

  /**
   * @generated from field: string gh_acct_type = 3;
   */
  ghAcctType = "";

  constructor(data?: PartialMessage<GitHubIntegrationSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GitHubIntegrationSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "gh_acct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "gh_acct_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitHubIntegrationSummary {
    return new GitHubIntegrationSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitHubIntegrationSummary {
    return new GitHubIntegrationSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitHubIntegrationSummary {
    return new GitHubIntegrationSummary().fromJsonString(jsonString, options);
  }

  static equals(a: GitHubIntegrationSummary | PlainMessage<GitHubIntegrationSummary> | undefined, b: GitHubIntegrationSummary | PlainMessage<GitHubIntegrationSummary> | undefined): boolean {
    return proto3.util.equals(GitHubIntegrationSummary, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListGitHubIntegrationsResponse
 */
export class ListGitHubIntegrationsResponse extends Message<ListGitHubIntegrationsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.GitHubIntegrationSummary integrations = 1;
   */
  integrations: GitHubIntegrationSummary[] = [];

  constructor(data?: PartialMessage<ListGitHubIntegrationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListGitHubIntegrationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integrations", kind: "message", T: GitHubIntegrationSummary, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListGitHubIntegrationsResponse {
    return new ListGitHubIntegrationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListGitHubIntegrationsResponse {
    return new ListGitHubIntegrationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListGitHubIntegrationsResponse {
    return new ListGitHubIntegrationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListGitHubIntegrationsResponse | PlainMessage<ListGitHubIntegrationsResponse> | undefined, b: ListGitHubIntegrationsResponse | PlainMessage<ListGitHubIntegrationsResponse> | undefined): boolean {
    return proto3.util.equals(ListGitHubIntegrationsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GitHubRepoToImport
 */
export class GitHubRepoToImport extends Message<GitHubRepoToImport> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string owner = 2;
   */
  owner = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.SBOMGenerationSource sbom_source = 5;
   */
  sbomSource = SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED;

  constructor(data?: PartialMessage<GitHubRepoToImport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GitHubRepoToImport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sbom_source", kind: "enum", T: proto3.getEnumType(SBOMGenerationSource) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GitHubRepoToImport {
    return new GitHubRepoToImport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GitHubRepoToImport {
    return new GitHubRepoToImport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GitHubRepoToImport {
    return new GitHubRepoToImport().fromJsonString(jsonString, options);
  }

  static equals(a: GitHubRepoToImport | PlainMessage<GitHubRepoToImport> | undefined, b: GitHubRepoToImport | PlainMessage<GitHubRepoToImport> | undefined): boolean {
    return proto3.util.equals(GitHubRepoToImport, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ImportGitHubReposRequest
 */
export class ImportGitHubReposRequest extends Message<ImportGitHubReposRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.GitHubRepoToImport repos = 2;
   */
  repos: GitHubRepoToImport[] = [];

  constructor(data?: PartialMessage<ImportGitHubReposRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ImportGitHubReposRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repos", kind: "message", T: GitHubRepoToImport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportGitHubReposRequest {
    return new ImportGitHubReposRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportGitHubReposRequest {
    return new ImportGitHubReposRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportGitHubReposRequest {
    return new ImportGitHubReposRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportGitHubReposRequest | PlainMessage<ImportGitHubReposRequest> | undefined, b: ImportGitHubReposRequest | PlainMessage<ImportGitHubReposRequest> | undefined): boolean {
    return proto3.util.equals(ImportGitHubReposRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ImportGitHubReposResponse
 */
export class ImportGitHubReposResponse extends Message<ImportGitHubReposResponse> {
  /**
   * @generated from field: repeated string errors = 1;
   */
  errors: string[] = [];

  constructor(data?: PartialMessage<ImportGitHubReposResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ImportGitHubReposResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportGitHubReposResponse {
    return new ImportGitHubReposResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportGitHubReposResponse {
    return new ImportGitHubReposResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportGitHubReposResponse {
    return new ImportGitHubReposResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImportGitHubReposResponse | PlainMessage<ImportGitHubReposResponse> | undefined, b: ImportGitHubReposResponse | PlainMessage<ImportGitHubReposResponse> | undefined): boolean {
    return proto3.util.equals(ImportGitHubReposResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.IgnoreIssueRequest
 */
export class IgnoreIssueRequest extends Message<IgnoreIssueRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string issue_id = 3;
   */
  issueId = "";

  /**
   * @generated from field: string comment = 4;
   */
  comment = "";

  /**
   * @generated from field: string justification = 5;
   */
  justification = "";

  constructor(data?: PartialMessage<IgnoreIssueRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.IgnoreIssueRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "issue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "justification", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IgnoreIssueRequest {
    return new IgnoreIssueRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IgnoreIssueRequest {
    return new IgnoreIssueRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IgnoreIssueRequest {
    return new IgnoreIssueRequest().fromJsonString(jsonString, options);
  }

  static equals(a: IgnoreIssueRequest | PlainMessage<IgnoreIssueRequest> | undefined, b: IgnoreIssueRequest | PlainMessage<IgnoreIssueRequest> | undefined): boolean {
    return proto3.util.equals(IgnoreIssueRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.IgnoreIssueResponse
 */
export class IgnoreIssueResponse extends Message<IgnoreIssueResponse> {
  constructor(data?: PartialMessage<IgnoreIssueResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.IgnoreIssueResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IgnoreIssueResponse {
    return new IgnoreIssueResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IgnoreIssueResponse {
    return new IgnoreIssueResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IgnoreIssueResponse {
    return new IgnoreIssueResponse().fromJsonString(jsonString, options);
  }

  static equals(a: IgnoreIssueResponse | PlainMessage<IgnoreIssueResponse> | undefined, b: IgnoreIssueResponse | PlainMessage<IgnoreIssueResponse> | undefined): boolean {
    return proto3.util.equals(IgnoreIssueResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.VantaIntegrationSettings
 */
export class VantaIntegrationSettings extends Message<VantaIntegrationSettings> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<VantaIntegrationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.VantaIntegrationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VantaIntegrationSettings {
    return new VantaIntegrationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VantaIntegrationSettings {
    return new VantaIntegrationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VantaIntegrationSettings {
    return new VantaIntegrationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: VantaIntegrationSettings | PlainMessage<VantaIntegrationSettings> | undefined, b: VantaIntegrationSettings | PlainMessage<VantaIntegrationSettings> | undefined): boolean {
    return proto3.util.equals(VantaIntegrationSettings, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetVantaIntegrationRequest
 */
export class GetVantaIntegrationRequest extends Message<GetVantaIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<GetVantaIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetVantaIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVantaIntegrationRequest {
    return new GetVantaIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVantaIntegrationRequest {
    return new GetVantaIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVantaIntegrationRequest {
    return new GetVantaIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVantaIntegrationRequest | PlainMessage<GetVantaIntegrationRequest> | undefined, b: GetVantaIntegrationRequest | PlainMessage<GetVantaIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetVantaIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetVantaIntegrationResponse
 */
export class GetVantaIntegrationResponse extends Message<GetVantaIntegrationResponse> {
  /**
   * @generated from field: string vanta_acct = 1;
   */
  vantaAcct = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VantaIntegrationSettings settings = 3;
   */
  settings: VantaIntegrationSettings[] = [];

  constructor(data?: PartialMessage<GetVantaIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetVantaIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vanta_acct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settings", kind: "message", T: VantaIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVantaIntegrationResponse {
    return new GetVantaIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVantaIntegrationResponse {
    return new GetVantaIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVantaIntegrationResponse {
    return new GetVantaIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetVantaIntegrationResponse | PlainMessage<GetVantaIntegrationResponse> | undefined, b: GetVantaIntegrationResponse | PlainMessage<GetVantaIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetVantaIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.BeginVantaInstallRequest
 */
export class BeginVantaInstallRequest extends Message<BeginVantaInstallRequest> {
  constructor(data?: PartialMessage<BeginVantaInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.BeginVantaInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeginVantaInstallRequest {
    return new BeginVantaInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeginVantaInstallRequest {
    return new BeginVantaInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeginVantaInstallRequest {
    return new BeginVantaInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BeginVantaInstallRequest | PlainMessage<BeginVantaInstallRequest> | undefined, b: BeginVantaInstallRequest | PlainMessage<BeginVantaInstallRequest> | undefined): boolean {
    return proto3.util.equals(BeginVantaInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.BeginVantaInstallResponse
 */
export class BeginVantaInstallResponse extends Message<BeginVantaInstallResponse> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<BeginVantaInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.BeginVantaInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeginVantaInstallResponse {
    return new BeginVantaInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeginVantaInstallResponse {
    return new BeginVantaInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeginVantaInstallResponse {
    return new BeginVantaInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BeginVantaInstallResponse | PlainMessage<BeginVantaInstallResponse> | undefined, b: BeginVantaInstallResponse | PlainMessage<BeginVantaInstallResponse> | undefined): boolean {
    return proto3.util.equals(BeginVantaInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteVantaInstallRequest
 */
export class CompleteVantaInstallRequest extends Message<CompleteVantaInstallRequest> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<CompleteVantaInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteVantaInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteVantaInstallRequest {
    return new CompleteVantaInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteVantaInstallRequest {
    return new CompleteVantaInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteVantaInstallRequest {
    return new CompleteVantaInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteVantaInstallRequest | PlainMessage<CompleteVantaInstallRequest> | undefined, b: CompleteVantaInstallRequest | PlainMessage<CompleteVantaInstallRequest> | undefined): boolean {
    return proto3.util.equals(CompleteVantaInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteVantaInstallResponse
 */
export class CompleteVantaInstallResponse extends Message<CompleteVantaInstallResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CompleteVantaInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteVantaInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteVantaInstallResponse {
    return new CompleteVantaInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteVantaInstallResponse {
    return new CompleteVantaInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteVantaInstallResponse {
    return new CompleteVantaInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteVantaInstallResponse | PlainMessage<CompleteVantaInstallResponse> | undefined, b: CompleteVantaInstallResponse | PlainMessage<CompleteVantaInstallResponse> | undefined): boolean {
    return proto3.util.equals(CompleteVantaInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateVantaIntegrationRequest
 */
export class UpdateVantaIntegrationRequest extends Message<UpdateVantaIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.VantaIntegrationSettings settings = 2;
   */
  settings: VantaIntegrationSettings[] = [];

  constructor(data?: PartialMessage<UpdateVantaIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateVantaIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settings", kind: "message", T: VantaIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateVantaIntegrationRequest {
    return new UpdateVantaIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateVantaIntegrationRequest {
    return new UpdateVantaIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateVantaIntegrationRequest {
    return new UpdateVantaIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateVantaIntegrationRequest | PlainMessage<UpdateVantaIntegrationRequest> | undefined, b: UpdateVantaIntegrationRequest | PlainMessage<UpdateVantaIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateVantaIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateVantaIntegrationResponse
 */
export class UpdateVantaIntegrationResponse extends Message<UpdateVantaIntegrationResponse> {
  constructor(data?: PartialMessage<UpdateVantaIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateVantaIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateVantaIntegrationResponse {
    return new UpdateVantaIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateVantaIntegrationResponse {
    return new UpdateVantaIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateVantaIntegrationResponse {
    return new UpdateVantaIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateVantaIntegrationResponse | PlainMessage<UpdateVantaIntegrationResponse> | undefined, b: UpdateVantaIntegrationResponse | PlainMessage<UpdateVantaIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateVantaIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SlackIntegrationSettings
 */
export class SlackIntegrationSettings extends Message<SlackIntegrationSettings> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<SlackIntegrationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SlackIntegrationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SlackIntegrationSettings {
    return new SlackIntegrationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SlackIntegrationSettings {
    return new SlackIntegrationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SlackIntegrationSettings {
    return new SlackIntegrationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: SlackIntegrationSettings | PlainMessage<SlackIntegrationSettings> | undefined, b: SlackIntegrationSettings | PlainMessage<SlackIntegrationSettings> | undefined): boolean {
    return proto3.util.equals(SlackIntegrationSettings, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSlackIntegrationRequest
 */
export class GetSlackIntegrationRequest extends Message<GetSlackIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<GetSlackIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSlackIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSlackIntegrationRequest {
    return new GetSlackIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSlackIntegrationRequest {
    return new GetSlackIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSlackIntegrationRequest {
    return new GetSlackIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSlackIntegrationRequest | PlainMessage<GetSlackIntegrationRequest> | undefined, b: GetSlackIntegrationRequest | PlainMessage<GetSlackIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetSlackIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSlackIntegrationResponse
 */
export class GetSlackIntegrationResponse extends Message<GetSlackIntegrationResponse> {
  /**
   * @generated from field: string slack_acct = 1;
   */
  slackAcct = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SlackIntegrationSettings settings = 3;
   */
  settings: SlackIntegrationSettings[] = [];

  constructor(data?: PartialMessage<GetSlackIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSlackIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "slack_acct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "settings", kind: "message", T: SlackIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSlackIntegrationResponse {
    return new GetSlackIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSlackIntegrationResponse {
    return new GetSlackIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSlackIntegrationResponse {
    return new GetSlackIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSlackIntegrationResponse | PlainMessage<GetSlackIntegrationResponse> | undefined, b: GetSlackIntegrationResponse | PlainMessage<GetSlackIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetSlackIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.BeginSlackInstallRequest
 */
export class BeginSlackInstallRequest extends Message<BeginSlackInstallRequest> {
  constructor(data?: PartialMessage<BeginSlackInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.BeginSlackInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeginSlackInstallRequest {
    return new BeginSlackInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeginSlackInstallRequest {
    return new BeginSlackInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeginSlackInstallRequest {
    return new BeginSlackInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BeginSlackInstallRequest | PlainMessage<BeginSlackInstallRequest> | undefined, b: BeginSlackInstallRequest | PlainMessage<BeginSlackInstallRequest> | undefined): boolean {
    return proto3.util.equals(BeginSlackInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.BeginSlackInstallResponse
 */
export class BeginSlackInstallResponse extends Message<BeginSlackInstallResponse> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<BeginSlackInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.BeginSlackInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeginSlackInstallResponse {
    return new BeginSlackInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeginSlackInstallResponse {
    return new BeginSlackInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeginSlackInstallResponse {
    return new BeginSlackInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BeginSlackInstallResponse | PlainMessage<BeginSlackInstallResponse> | undefined, b: BeginSlackInstallResponse | PlainMessage<BeginSlackInstallResponse> | undefined): boolean {
    return proto3.util.equals(BeginSlackInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteSlackInstallRequest
 */
export class CompleteSlackInstallRequest extends Message<CompleteSlackInstallRequest> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<CompleteSlackInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteSlackInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteSlackInstallRequest {
    return new CompleteSlackInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteSlackInstallRequest {
    return new CompleteSlackInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteSlackInstallRequest {
    return new CompleteSlackInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteSlackInstallRequest | PlainMessage<CompleteSlackInstallRequest> | undefined, b: CompleteSlackInstallRequest | PlainMessage<CompleteSlackInstallRequest> | undefined): boolean {
    return proto3.util.equals(CompleteSlackInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteSlackInstallResponse
 */
export class CompleteSlackInstallResponse extends Message<CompleteSlackInstallResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CompleteSlackInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteSlackInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteSlackInstallResponse {
    return new CompleteSlackInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteSlackInstallResponse {
    return new CompleteSlackInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteSlackInstallResponse {
    return new CompleteSlackInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteSlackInstallResponse | PlainMessage<CompleteSlackInstallResponse> | undefined, b: CompleteSlackInstallResponse | PlainMessage<CompleteSlackInstallResponse> | undefined): boolean {
    return proto3.util.equals(CompleteSlackInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateSlackIntegrationRequest
 */
export class UpdateSlackIntegrationRequest extends Message<UpdateSlackIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SlackIntegrationSettings settings = 2;
   */
  settings: SlackIntegrationSettings[] = [];

  constructor(data?: PartialMessage<UpdateSlackIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateSlackIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settings", kind: "message", T: SlackIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSlackIntegrationRequest {
    return new UpdateSlackIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSlackIntegrationRequest {
    return new UpdateSlackIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSlackIntegrationRequest {
    return new UpdateSlackIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSlackIntegrationRequest | PlainMessage<UpdateSlackIntegrationRequest> | undefined, b: UpdateSlackIntegrationRequest | PlainMessage<UpdateSlackIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSlackIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateSlackIntegrationResponse
 */
export class UpdateSlackIntegrationResponse extends Message<UpdateSlackIntegrationResponse> {
  constructor(data?: PartialMessage<UpdateSlackIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateSlackIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSlackIntegrationResponse {
    return new UpdateSlackIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSlackIntegrationResponse {
    return new UpdateSlackIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSlackIntegrationResponse {
    return new UpdateSlackIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSlackIntegrationResponse | PlainMessage<UpdateSlackIntegrationResponse> | undefined, b: UpdateSlackIntegrationResponse | PlainMessage<UpdateSlackIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSlackIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AWSAssumeRoleConfig
 */
export class AWSAssumeRoleConfig extends Message<AWSAssumeRoleConfig> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * @generated from field: string role_name = 2;
   */
  roleName = "";

  constructor(data?: PartialMessage<AWSAssumeRoleConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AWSAssumeRoleConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AWSAssumeRoleConfig {
    return new AWSAssumeRoleConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AWSAssumeRoleConfig {
    return new AWSAssumeRoleConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AWSAssumeRoleConfig {
    return new AWSAssumeRoleConfig().fromJsonString(jsonString, options);
  }

  static equals(a: AWSAssumeRoleConfig | PlainMessage<AWSAssumeRoleConfig> | undefined, b: AWSAssumeRoleConfig | PlainMessage<AWSAssumeRoleConfig> | undefined): boolean {
    return proto3.util.equals(AWSAssumeRoleConfig, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AWSIntegrationSettings
 */
export class AWSIntegrationSettings extends Message<AWSIntegrationSettings> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: repeated string ecs_regions = 2;
   */
  ecsRegions: string[] = [];

  constructor(data?: PartialMessage<AWSIntegrationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AWSIntegrationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ecs_regions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AWSIntegrationSettings {
    return new AWSIntegrationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AWSIntegrationSettings {
    return new AWSIntegrationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AWSIntegrationSettings {
    return new AWSIntegrationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: AWSIntegrationSettings | PlainMessage<AWSIntegrationSettings> | undefined, b: AWSIntegrationSettings | PlainMessage<AWSIntegrationSettings> | undefined): boolean {
    return proto3.util.equals(AWSIntegrationSettings, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateAWSIntegrationRequest
 */
export class CreateAWSIntegrationRequest extends Message<CreateAWSIntegrationRequest> {
  constructor(data?: PartialMessage<CreateAWSIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateAWSIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAWSIntegrationRequest {
    return new CreateAWSIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAWSIntegrationRequest {
    return new CreateAWSIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAWSIntegrationRequest {
    return new CreateAWSIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAWSIntegrationRequest | PlainMessage<CreateAWSIntegrationRequest> | undefined, b: CreateAWSIntegrationRequest | PlainMessage<CreateAWSIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(CreateAWSIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateAWSIntegrationResponse
 */
export class CreateAWSIntegrationResponse extends Message<CreateAWSIntegrationResponse> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<CreateAWSIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateAWSIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAWSIntegrationResponse {
    return new CreateAWSIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAWSIntegrationResponse {
    return new CreateAWSIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAWSIntegrationResponse {
    return new CreateAWSIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAWSIntegrationResponse | PlainMessage<CreateAWSIntegrationResponse> | undefined, b: CreateAWSIntegrationResponse | PlainMessage<CreateAWSIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(CreateAWSIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetAWSIntegrationRequest
 */
export class GetAWSIntegrationRequest extends Message<GetAWSIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<GetAWSIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetAWSIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAWSIntegrationRequest {
    return new GetAWSIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAWSIntegrationRequest {
    return new GetAWSIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAWSIntegrationRequest {
    return new GetAWSIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAWSIntegrationRequest | PlainMessage<GetAWSIntegrationRequest> | undefined, b: GetAWSIntegrationRequest | PlainMessage<GetAWSIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetAWSIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetAWSIntegrationResponse
 */
export class GetAWSIntegrationResponse extends Message<GetAWSIntegrationResponse> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.AWSAssumeRoleConfig assume_role_config = 2;
   */
  assumeRoleConfig?: AWSAssumeRoleConfig;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.AWSIntegrationSettings settings = 3;
   */
  settings: AWSIntegrationSettings[] = [];

  constructor(data?: PartialMessage<GetAWSIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetAWSIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assume_role_config", kind: "message", T: AWSAssumeRoleConfig },
    { no: 3, name: "settings", kind: "message", T: AWSIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAWSIntegrationResponse {
    return new GetAWSIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAWSIntegrationResponse {
    return new GetAWSIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAWSIntegrationResponse {
    return new GetAWSIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAWSIntegrationResponse | PlainMessage<GetAWSIntegrationResponse> | undefined, b: GetAWSIntegrationResponse | PlainMessage<GetAWSIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetAWSIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SetAWSIntegrationAssumeRoleConfigRequest
 */
export class SetAWSIntegrationAssumeRoleConfigRequest extends Message<SetAWSIntegrationAssumeRoleConfigRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.AWSAssumeRoleConfig assume_role_config = 2;
   */
  assumeRoleConfig?: AWSAssumeRoleConfig;

  constructor(data?: PartialMessage<SetAWSIntegrationAssumeRoleConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SetAWSIntegrationAssumeRoleConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "assume_role_config", kind: "message", T: AWSAssumeRoleConfig },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetAWSIntegrationAssumeRoleConfigRequest {
    return new SetAWSIntegrationAssumeRoleConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetAWSIntegrationAssumeRoleConfigRequest {
    return new SetAWSIntegrationAssumeRoleConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetAWSIntegrationAssumeRoleConfigRequest {
    return new SetAWSIntegrationAssumeRoleConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetAWSIntegrationAssumeRoleConfigRequest | PlainMessage<SetAWSIntegrationAssumeRoleConfigRequest> | undefined, b: SetAWSIntegrationAssumeRoleConfigRequest | PlainMessage<SetAWSIntegrationAssumeRoleConfigRequest> | undefined): boolean {
    return proto3.util.equals(SetAWSIntegrationAssumeRoleConfigRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SetAWSIntegrationAssumeRoleConfigResponse
 */
export class SetAWSIntegrationAssumeRoleConfigResponse extends Message<SetAWSIntegrationAssumeRoleConfigResponse> {
  constructor(data?: PartialMessage<SetAWSIntegrationAssumeRoleConfigResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SetAWSIntegrationAssumeRoleConfigResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetAWSIntegrationAssumeRoleConfigResponse {
    return new SetAWSIntegrationAssumeRoleConfigResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetAWSIntegrationAssumeRoleConfigResponse {
    return new SetAWSIntegrationAssumeRoleConfigResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetAWSIntegrationAssumeRoleConfigResponse {
    return new SetAWSIntegrationAssumeRoleConfigResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetAWSIntegrationAssumeRoleConfigResponse | PlainMessage<SetAWSIntegrationAssumeRoleConfigResponse> | undefined, b: SetAWSIntegrationAssumeRoleConfigResponse | PlainMessage<SetAWSIntegrationAssumeRoleConfigResponse> | undefined): boolean {
    return proto3.util.equals(SetAWSIntegrationAssumeRoleConfigResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateAWSIntegrationSettingsRequest
 */
export class UpdateAWSIntegrationSettingsRequest extends Message<UpdateAWSIntegrationSettingsRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.AWSIntegrationSettings settings = 2;
   */
  settings: AWSIntegrationSettings[] = [];

  constructor(data?: PartialMessage<UpdateAWSIntegrationSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateAWSIntegrationSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settings", kind: "message", T: AWSIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAWSIntegrationSettingsRequest {
    return new UpdateAWSIntegrationSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAWSIntegrationSettingsRequest {
    return new UpdateAWSIntegrationSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAWSIntegrationSettingsRequest {
    return new UpdateAWSIntegrationSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAWSIntegrationSettingsRequest | PlainMessage<UpdateAWSIntegrationSettingsRequest> | undefined, b: UpdateAWSIntegrationSettingsRequest | PlainMessage<UpdateAWSIntegrationSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAWSIntegrationSettingsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateAWSIntegrationSettingsResponse
 */
export class UpdateAWSIntegrationSettingsResponse extends Message<UpdateAWSIntegrationSettingsResponse> {
  constructor(data?: PartialMessage<UpdateAWSIntegrationSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateAWSIntegrationSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAWSIntegrationSettingsResponse {
    return new UpdateAWSIntegrationSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAWSIntegrationSettingsResponse {
    return new UpdateAWSIntegrationSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAWSIntegrationSettingsResponse {
    return new UpdateAWSIntegrationSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAWSIntegrationSettingsResponse | PlainMessage<UpdateAWSIntegrationSettingsResponse> | undefined, b: UpdateAWSIntegrationSettingsResponse | PlainMessage<UpdateAWSIntegrationSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAWSIntegrationSettingsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.FetchAWSIntegrationIAMDocsRequest
 */
export class FetchAWSIntegrationIAMDocsRequest extends Message<FetchAWSIntegrationIAMDocsRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<FetchAWSIntegrationIAMDocsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.FetchAWSIntegrationIAMDocsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAWSIntegrationIAMDocsRequest {
    return new FetchAWSIntegrationIAMDocsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAWSIntegrationIAMDocsRequest {
    return new FetchAWSIntegrationIAMDocsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAWSIntegrationIAMDocsRequest {
    return new FetchAWSIntegrationIAMDocsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAWSIntegrationIAMDocsRequest | PlainMessage<FetchAWSIntegrationIAMDocsRequest> | undefined, b: FetchAWSIntegrationIAMDocsRequest | PlainMessage<FetchAWSIntegrationIAMDocsRequest> | undefined): boolean {
    return proto3.util.equals(FetchAWSIntegrationIAMDocsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.FetchAWSIntegrationIAMDocsResponse
 */
export class FetchAWSIntegrationIAMDocsResponse extends Message<FetchAWSIntegrationIAMDocsResponse> {
  /**
   * @generated from field: string iam_role_trust_policy = 1;
   */
  iamRoleTrustPolicy = "";

  /**
   * @generated from field: string iam_permissions_policy = 2;
   */
  iamPermissionsPolicy = "";

  constructor(data?: PartialMessage<FetchAWSIntegrationIAMDocsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.FetchAWSIntegrationIAMDocsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "iam_role_trust_policy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "iam_permissions_policy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchAWSIntegrationIAMDocsResponse {
    return new FetchAWSIntegrationIAMDocsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchAWSIntegrationIAMDocsResponse {
    return new FetchAWSIntegrationIAMDocsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchAWSIntegrationIAMDocsResponse {
    return new FetchAWSIntegrationIAMDocsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchAWSIntegrationIAMDocsResponse | PlainMessage<FetchAWSIntegrationIAMDocsResponse> | undefined, b: FetchAWSIntegrationIAMDocsResponse | PlainMessage<FetchAWSIntegrationIAMDocsResponse> | undefined): boolean {
    return proto3.util.equals(FetchAWSIntegrationIAMDocsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListIntegrationsRequest
 */
export class ListIntegrationsRequest extends Message<ListIntegrationsRequest> {
  constructor(data?: PartialMessage<ListIntegrationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListIntegrationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIntegrationsRequest {
    return new ListIntegrationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIntegrationsRequest {
    return new ListIntegrationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIntegrationsRequest {
    return new ListIntegrationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIntegrationsRequest | PlainMessage<ListIntegrationsRequest> | undefined, b: ListIntegrationsRequest | PlainMessage<ListIntegrationsRequest> | undefined): boolean {
    return proto3.util.equals(ListIntegrationsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.IntegrationSummary
 */
export class IntegrationSummary extends Message<IntegrationSummary> {
  /**
   * @generated from field: edgebit.platform.v1alpha.IntegrationType type = 1;
   */
  type = IntegrationType.UNSPECIFIED;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string account = 3;
   */
  account = "";

  /**
   * @generated from field: string avatar_url = 4;
   */
  avatarUrl = "";

  /**
   * @generated from field: string last_error = 5;
   */
  lastError = "";

  constructor(data?: PartialMessage<IntegrationSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.IntegrationSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(IntegrationType) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IntegrationSummary {
    return new IntegrationSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IntegrationSummary {
    return new IntegrationSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IntegrationSummary {
    return new IntegrationSummary().fromJsonString(jsonString, options);
  }

  static equals(a: IntegrationSummary | PlainMessage<IntegrationSummary> | undefined, b: IntegrationSummary | PlainMessage<IntegrationSummary> | undefined): boolean {
    return proto3.util.equals(IntegrationSummary, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListIntegrationsResponse
 */
export class ListIntegrationsResponse extends Message<ListIntegrationsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.IntegrationSummary integrations = 1;
   */
  integrations: IntegrationSummary[] = [];

  constructor(data?: PartialMessage<ListIntegrationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListIntegrationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integrations", kind: "message", T: IntegrationSummary, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIntegrationsResponse {
    return new ListIntegrationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIntegrationsResponse {
    return new ListIntegrationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIntegrationsResponse {
    return new ListIntegrationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIntegrationsResponse | PlainMessage<ListIntegrationsResponse> | undefined, b: ListIntegrationsResponse | PlainMessage<ListIntegrationsResponse> | undefined): boolean {
    return proto3.util.equals(ListIntegrationsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.BeginJiraInstallRequest
 */
export class BeginJiraInstallRequest extends Message<BeginJiraInstallRequest> {
  constructor(data?: PartialMessage<BeginJiraInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.BeginJiraInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeginJiraInstallRequest {
    return new BeginJiraInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeginJiraInstallRequest {
    return new BeginJiraInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeginJiraInstallRequest {
    return new BeginJiraInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BeginJiraInstallRequest | PlainMessage<BeginJiraInstallRequest> | undefined, b: BeginJiraInstallRequest | PlainMessage<BeginJiraInstallRequest> | undefined): boolean {
    return proto3.util.equals(BeginJiraInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.BeginJiraInstallResponse
 */
export class BeginJiraInstallResponse extends Message<BeginJiraInstallResponse> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<BeginJiraInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.BeginJiraInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BeginJiraInstallResponse {
    return new BeginJiraInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BeginJiraInstallResponse {
    return new BeginJiraInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BeginJiraInstallResponse {
    return new BeginJiraInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BeginJiraInstallResponse | PlainMessage<BeginJiraInstallResponse> | undefined, b: BeginJiraInstallResponse | PlainMessage<BeginJiraInstallResponse> | undefined): boolean {
    return proto3.util.equals(BeginJiraInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteJiraInstallRequest
 */
export class CompleteJiraInstallRequest extends Message<CompleteJiraInstallRequest> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  constructor(data?: PartialMessage<CompleteJiraInstallRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteJiraInstallRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteJiraInstallRequest {
    return new CompleteJiraInstallRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteJiraInstallRequest {
    return new CompleteJiraInstallRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteJiraInstallRequest {
    return new CompleteJiraInstallRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteJiraInstallRequest | PlainMessage<CompleteJiraInstallRequest> | undefined, b: CompleteJiraInstallRequest | PlainMessage<CompleteJiraInstallRequest> | undefined): boolean {
    return proto3.util.equals(CompleteJiraInstallRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CompleteJiraInstallResponse
 */
export class CompleteJiraInstallResponse extends Message<CompleteJiraInstallResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<CompleteJiraInstallResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CompleteJiraInstallResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompleteJiraInstallResponse {
    return new CompleteJiraInstallResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompleteJiraInstallResponse {
    return new CompleteJiraInstallResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompleteJiraInstallResponse {
    return new CompleteJiraInstallResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CompleteJiraInstallResponse | PlainMessage<CompleteJiraInstallResponse> | undefined, b: CompleteJiraInstallResponse | PlainMessage<CompleteJiraInstallResponse> | undefined): boolean {
    return proto3.util.equals(CompleteJiraInstallResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.JiraIssueType
 */
export class JiraIssueType extends Message<JiraIssueType> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string icon_url = 4;
   */
  iconUrl = "";

  constructor(data?: PartialMessage<JiraIssueType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.JiraIssueType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JiraIssueType {
    return new JiraIssueType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JiraIssueType {
    return new JiraIssueType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JiraIssueType {
    return new JiraIssueType().fromJsonString(jsonString, options);
  }

  static equals(a: JiraIssueType | PlainMessage<JiraIssueType> | undefined, b: JiraIssueType | PlainMessage<JiraIssueType> | undefined): boolean {
    return proto3.util.equals(JiraIssueType, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.JiraProject
 */
export class JiraProject extends Message<JiraProject> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string key = 2;
   */
  key = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.JiraIssueType avail_issue_types = 4;
   */
  availIssueTypes: JiraIssueType[] = [];

  constructor(data?: PartialMessage<JiraProject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.JiraProject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "avail_issue_types", kind: "message", T: JiraIssueType, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JiraProject {
    return new JiraProject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JiraProject {
    return new JiraProject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JiraProject {
    return new JiraProject().fromJsonString(jsonString, options);
  }

  static equals(a: JiraProject | PlainMessage<JiraProject> | undefined, b: JiraProject | PlainMessage<JiraProject> | undefined): boolean {
    return proto3.util.equals(JiraProject, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.JiraIntegrationSettings
 */
export class JiraIntegrationSettings extends Message<JiraIntegrationSettings> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string jira_project_id = 2;
   */
  jiraProjectId = "";

  /**
   * @generated from field: string jira_issue_type_id = 3;
   */
  jiraIssueTypeId = "";

  constructor(data?: PartialMessage<JiraIntegrationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.JiraIntegrationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "jira_project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "jira_issue_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JiraIntegrationSettings {
    return new JiraIntegrationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JiraIntegrationSettings {
    return new JiraIntegrationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JiraIntegrationSettings {
    return new JiraIntegrationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: JiraIntegrationSettings | PlainMessage<JiraIntegrationSettings> | undefined, b: JiraIntegrationSettings | PlainMessage<JiraIntegrationSettings> | undefined): boolean {
    return proto3.util.equals(JiraIntegrationSettings, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetJiraIntegrationRequest
 */
export class GetJiraIntegrationRequest extends Message<GetJiraIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  constructor(data?: PartialMessage<GetJiraIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetJiraIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetJiraIntegrationRequest {
    return new GetJiraIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetJiraIntegrationRequest {
    return new GetJiraIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetJiraIntegrationRequest {
    return new GetJiraIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetJiraIntegrationRequest | PlainMessage<GetJiraIntegrationRequest> | undefined, b: GetJiraIntegrationRequest | PlainMessage<GetJiraIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(GetJiraIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetJiraIntegrationResponse
 */
export class GetJiraIntegrationResponse extends Message<GetJiraIntegrationResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.JiraIntegrationSettings settings = 1;
   */
  settings: JiraIntegrationSettings[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.JiraProject avail_jira_projects = 2;
   */
  availJiraProjects: JiraProject[] = [];

  constructor(data?: PartialMessage<GetJiraIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetJiraIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: JiraIntegrationSettings, repeated: true },
    { no: 2, name: "avail_jira_projects", kind: "message", T: JiraProject, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetJiraIntegrationResponse {
    return new GetJiraIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetJiraIntegrationResponse {
    return new GetJiraIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetJiraIntegrationResponse {
    return new GetJiraIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetJiraIntegrationResponse | PlainMessage<GetJiraIntegrationResponse> | undefined, b: GetJiraIntegrationResponse | PlainMessage<GetJiraIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(GetJiraIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateJiraIntegrationRequest
 */
export class UpdateJiraIntegrationRequest extends Message<UpdateJiraIntegrationRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.JiraIntegrationSettings settings = 2;
   */
  settings: JiraIntegrationSettings[] = [];

  constructor(data?: PartialMessage<UpdateJiraIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateJiraIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "settings", kind: "message", T: JiraIntegrationSettings, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateJiraIntegrationRequest {
    return new UpdateJiraIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateJiraIntegrationRequest {
    return new UpdateJiraIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateJiraIntegrationRequest {
    return new UpdateJiraIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateJiraIntegrationRequest | PlainMessage<UpdateJiraIntegrationRequest> | undefined, b: UpdateJiraIntegrationRequest | PlainMessage<UpdateJiraIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateJiraIntegrationRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateJiraIntegrationResponse
 */
export class UpdateJiraIntegrationResponse extends Message<UpdateJiraIntegrationResponse> {
  constructor(data?: PartialMessage<UpdateJiraIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateJiraIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateJiraIntegrationResponse {
    return new UpdateJiraIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateJiraIntegrationResponse {
    return new UpdateJiraIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateJiraIntegrationResponse {
    return new UpdateJiraIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateJiraIntegrationResponse | PlainMessage<UpdateJiraIntegrationResponse> | undefined, b: UpdateJiraIntegrationResponse | PlainMessage<UpdateJiraIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateJiraIntegrationResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UserRef
 */
export class UserRef extends Message<UserRef> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = "";

  /**
   * @generated from field: string human_name = 3;
   */
  humanName = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  constructor(data?: PartialMessage<UserRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UserRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "human_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRef {
    return new UserRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRef {
    return new UserRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRef {
    return new UserRef().fromJsonString(jsonString, options);
  }

  static equals(a: UserRef | PlainMessage<UserRef> | undefined, b: UserRef | PlainMessage<UserRef> | undefined): boolean {
    return proto3.util.equals(UserRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProjectAPIKeyRef
 */
export class ProjectAPIKeyRef extends Message<ProjectAPIKeyRef> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string project_id = 2;
   */
  projectId = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  constructor(data?: PartialMessage<ProjectAPIKeyRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProjectAPIKeyRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectAPIKeyRef {
    return new ProjectAPIKeyRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectAPIKeyRef {
    return new ProjectAPIKeyRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectAPIKeyRef {
    return new ProjectAPIKeyRef().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectAPIKeyRef | PlainMessage<ProjectAPIKeyRef> | undefined, b: ProjectAPIKeyRef | PlainMessage<ProjectAPIKeyRef> | undefined): boolean {
    return proto3.util.equals(ProjectAPIKeyRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AutomatedProcessRef
 */
export class AutomatedProcessRef extends Message<AutomatedProcessRef> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<AutomatedProcessRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AutomatedProcessRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutomatedProcessRef {
    return new AutomatedProcessRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutomatedProcessRef {
    return new AutomatedProcessRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutomatedProcessRef {
    return new AutomatedProcessRef().fromJsonString(jsonString, options);
  }

  static equals(a: AutomatedProcessRef | PlainMessage<AutomatedProcessRef> | undefined, b: AutomatedProcessRef | PlainMessage<AutomatedProcessRef> | undefined): boolean {
    return proto3.util.equals(AutomatedProcessRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Actor
 */
export class Actor extends Message<Actor> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from oneof edgebit.platform.v1alpha.Actor.ref
   */
  ref: {
    /**
     * @generated from field: edgebit.platform.v1alpha.UserRef user_ref = 2;
     */
    value: UserRef;
    case: "userRef";
  } | {
    /**
     * @generated from field: edgebit.platform.v1alpha.ProjectAPIKeyRef project_api_key_ref = 3;
     */
    value: ProjectAPIKeyRef;
    case: "projectApiKeyRef";
  } | {
    /**
     * @generated from field: edgebit.platform.v1alpha.AutomatedProcessRef automated_process_ref = 4;
     */
    value: AutomatedProcessRef;
    case: "automatedProcessRef";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Actor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Actor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_ref", kind: "message", T: UserRef, oneof: "ref" },
    { no: 3, name: "project_api_key_ref", kind: "message", T: ProjectAPIKeyRef, oneof: "ref" },
    { no: 4, name: "automated_process_ref", kind: "message", T: AutomatedProcessRef, oneof: "ref" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Actor {
    return new Actor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Actor {
    return new Actor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Actor {
    return new Actor().fromJsonString(jsonString, options);
  }

  static equals(a: Actor | PlainMessage<Actor> | undefined, b: Actor | PlainMessage<Actor> | undefined): boolean {
    return proto3.util.equals(Actor, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ComponentIssueStateChange
 */
export class ComponentIssueStateChange extends Message<ComponentIssueStateChange> {
  /**
   * @generated from field: string component_id = 1;
   */
  componentId = "";

  /**
   * @generated from field: string issue_id = 2;
   */
  issueId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: edgebit.platform.v1alpha.Actor actor = 4;
   */
  actor?: Actor;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ComponentIssueState state = 5;
   */
  state?: ComponentIssueState;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ComponentIssueSeverity severity = 6;
   */
  severity?: ComponentIssueSeverity;

  /**
   * @generated from field: string comment = 7;
   */
  comment = "";

  /**
   * @generated from field: repeated string affected_packages = 8;
   */
  affectedPackages: string[] = [];

  /**
   * @generated from field: string justification = 9;
   */
  justification = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp due_at = 10;
   */
  dueAt?: Timestamp;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ComponentIssueSeverity upstream_severity = 11;
   */
  upstreamSeverity?: ComponentIssueSeverity;

  constructor(data?: PartialMessage<ComponentIssueStateChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ComponentIssueStateChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "issue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "actor", kind: "message", T: Actor },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(ComponentIssueState), opt: true },
    { no: 6, name: "severity", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity), opt: true },
    { no: 7, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "affected_packages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "justification", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "due_at", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "upstream_severity", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentIssueStateChange {
    return new ComponentIssueStateChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentIssueStateChange {
    return new ComponentIssueStateChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentIssueStateChange {
    return new ComponentIssueStateChange().fromJsonString(jsonString, options);
  }

  static equals(a: ComponentIssueStateChange | PlainMessage<ComponentIssueStateChange> | undefined, b: ComponentIssueStateChange | PlainMessage<ComponentIssueStateChange> | undefined): boolean {
    return proto3.util.equals(ComponentIssueStateChange, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssueStateChangesRequest
 */
export class ListComponentIssueStateChangesRequest extends Message<ListComponentIssueStateChangesRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: string issue_id = 3;
   */
  issueId = "";

  constructor(data?: PartialMessage<ListComponentIssueStateChangesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssueStateChangesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "issue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssueStateChangesRequest {
    return new ListComponentIssueStateChangesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssueStateChangesRequest {
    return new ListComponentIssueStateChangesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssueStateChangesRequest {
    return new ListComponentIssueStateChangesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssueStateChangesRequest | PlainMessage<ListComponentIssueStateChangesRequest> | undefined, b: ListComponentIssueStateChangesRequest | PlainMessage<ListComponentIssueStateChangesRequest> | undefined): boolean {
    return proto3.util.equals(ListComponentIssueStateChangesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListComponentIssueStateChangesResponse
 */
export class ListComponentIssueStateChangesResponse extends Message<ListComponentIssueStateChangesResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ComponentIssueStateChange component_issue_state_changes = 1;
   */
  componentIssueStateChanges: ComponentIssueStateChange[] = [];

  constructor(data?: PartialMessage<ListComponentIssueStateChangesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListComponentIssueStateChangesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component_issue_state_changes", kind: "message", T: ComponentIssueStateChange, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListComponentIssueStateChangesResponse {
    return new ListComponentIssueStateChangesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListComponentIssueStateChangesResponse {
    return new ListComponentIssueStateChangesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListComponentIssueStateChangesResponse {
    return new ListComponentIssueStateChangesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListComponentIssueStateChangesResponse | PlainMessage<ListComponentIssueStateChangesResponse> | undefined, b: ListComponentIssueStateChangesResponse | PlainMessage<ListComponentIssueStateChangesResponse> | undefined): boolean {
    return proto3.util.equals(ListComponentIssueStateChangesResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.LookupEPSSDataRequest
 */
export class LookupEPSSDataRequest extends Message<LookupEPSSDataRequest> {
  /**
   * @generated from field: repeated string cve_ids = 1;
   */
  cveIds: string[] = [];

  /**
   * @generated from field: double epss_greater_than = 2;
   */
  epssGreaterThan = 0;

  constructor(data?: PartialMessage<LookupEPSSDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.LookupEPSSDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cve_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "epss_greater_than", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupEPSSDataRequest {
    return new LookupEPSSDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupEPSSDataRequest {
    return new LookupEPSSDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupEPSSDataRequest {
    return new LookupEPSSDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LookupEPSSDataRequest | PlainMessage<LookupEPSSDataRequest> | undefined, b: LookupEPSSDataRequest | PlainMessage<LookupEPSSDataRequest> | undefined): boolean {
    return proto3.util.equals(LookupEPSSDataRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.LookupEPSSDataResponse
 */
export class LookupEPSSDataResponse extends Message<LookupEPSSDataResponse> {
  /**
   * @generated from field: int32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: int32 offset = 2;
   */
  offset = 0;

  /**
   * @generated from field: int32 limit = 3;
   */
  limit = 0;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.EPSSItem data = 4;
   */
  data: EPSSItem[] = [];

  constructor(data?: PartialMessage<LookupEPSSDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.LookupEPSSDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "data", kind: "message", T: EPSSItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookupEPSSDataResponse {
    return new LookupEPSSDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookupEPSSDataResponse {
    return new LookupEPSSDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookupEPSSDataResponse {
    return new LookupEPSSDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LookupEPSSDataResponse | PlainMessage<LookupEPSSDataResponse> | undefined, b: LookupEPSSDataResponse | PlainMessage<LookupEPSSDataResponse> | undefined): boolean {
    return proto3.util.equals(LookupEPSSDataResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateOrgSummaryReportRequest
 */
export class GenerateOrgSummaryReportRequest extends Message<GenerateOrgSummaryReportRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: google.protobuf.Timestamp report_start = 2;
   */
  reportStart?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp report_end = 3;
   */
  reportEnd?: Timestamp;

  constructor(data?: PartialMessage<GenerateOrgSummaryReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateOrgSummaryReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "report_start", kind: "message", T: Timestamp },
    { no: 3, name: "report_end", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateOrgSummaryReportRequest {
    return new GenerateOrgSummaryReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateOrgSummaryReportRequest {
    return new GenerateOrgSummaryReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateOrgSummaryReportRequest {
    return new GenerateOrgSummaryReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateOrgSummaryReportRequest | PlainMessage<GenerateOrgSummaryReportRequest> | undefined, b: GenerateOrgSummaryReportRequest | PlainMessage<GenerateOrgSummaryReportRequest> | undefined): boolean {
    return proto3.util.equals(GenerateOrgSummaryReportRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateOrgSummaryReportResponse
 */
export class GenerateOrgSummaryReportResponse extends Message<GenerateOrgSummaryReportResponse> {
  /**
   * @generated from field: string report_html = 1;
   */
  reportHtml = "";

  /**
   * @generated from field: string report_plaintext = 2;
   */
  reportPlaintext = "";

  constructor(data?: PartialMessage<GenerateOrgSummaryReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateOrgSummaryReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "report_plaintext", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateOrgSummaryReportResponse {
    return new GenerateOrgSummaryReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateOrgSummaryReportResponse {
    return new GenerateOrgSummaryReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateOrgSummaryReportResponse {
    return new GenerateOrgSummaryReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateOrgSummaryReportResponse | PlainMessage<GenerateOrgSummaryReportResponse> | undefined, b: GenerateOrgSummaryReportResponse | PlainMessage<GenerateOrgSummaryReportResponse> | undefined): boolean {
    return proto3.util.equals(GenerateOrgSummaryReportResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateComponentIssuesReportRequest
 */
export class GenerateComponentIssuesReportRequest extends Message<GenerateComponentIssuesReportRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: google.protobuf.Timestamp report_start = 2;
   */
  reportStart?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp report_end = 3;
   */
  reportEnd?: Timestamp;

  constructor(data?: PartialMessage<GenerateComponentIssuesReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateComponentIssuesReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "report_start", kind: "message", T: Timestamp },
    { no: 3, name: "report_end", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateComponentIssuesReportRequest {
    return new GenerateComponentIssuesReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateComponentIssuesReportRequest {
    return new GenerateComponentIssuesReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateComponentIssuesReportRequest {
    return new GenerateComponentIssuesReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateComponentIssuesReportRequest | PlainMessage<GenerateComponentIssuesReportRequest> | undefined, b: GenerateComponentIssuesReportRequest | PlainMessage<GenerateComponentIssuesReportRequest> | undefined): boolean {
    return proto3.util.equals(GenerateComponentIssuesReportRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateComponentIssuesReportResponse
 */
export class GenerateComponentIssuesReportResponse extends Message<GenerateComponentIssuesReportResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.GenerateComponentIssuesReportItem items = 1;
   */
  items: GenerateComponentIssuesReportItem[] = [];

  constructor(data?: PartialMessage<GenerateComponentIssuesReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateComponentIssuesReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: GenerateComponentIssuesReportItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateComponentIssuesReportResponse {
    return new GenerateComponentIssuesReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateComponentIssuesReportResponse {
    return new GenerateComponentIssuesReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateComponentIssuesReportResponse {
    return new GenerateComponentIssuesReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateComponentIssuesReportResponse | PlainMessage<GenerateComponentIssuesReportResponse> | undefined, b: GenerateComponentIssuesReportResponse | PlainMessage<GenerateComponentIssuesReportResponse> | undefined): boolean {
    return proto3.util.equals(GenerateComponentIssuesReportResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GenerateComponentIssuesReportItem
 */
export class GenerateComponentIssuesReportItem extends Message<GenerateComponentIssuesReportItem> {
  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentRef component_ref = 1;
   */
  componentRef?: ComponentRef;

  /**
   * @generated from field: string issue_id = 2;
   */
  issueId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueState state = 3;
   */
  state = ComponentIssueState.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.ComponentIssueSeverity severity = 4;
   */
  severity = ComponentIssueSeverity.UNSPECIFIED;

  /**
   * @generated from field: string vuln_id = 5;
   */
  vulnId = "";

  /**
   * @generated from field: string fix_state = 6;
   */
  fixState = "";

  /**
   * @generated from field: repeated string fix_versions = 7;
   */
  fixVersions: string[] = [];

  /**
   * @generated from field: string package_name = 8;
   */
  packageName = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 9;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 10;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp due_at = 11;
   */
  dueAt?: Timestamp;

  /**
   * @generated from field: map<string, string> links = 12;
   */
  links: { [key: string]: string } = {};

  /**
   * @generated from field: string notes = 13;
   */
  notes = "";

  constructor(data?: PartialMessage<GenerateComponentIssuesReportItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GenerateComponentIssuesReportItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "component_ref", kind: "message", T: ComponentRef },
    { no: 2, name: "issue_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(ComponentIssueState) },
    { no: 4, name: "severity", kind: "enum", T: proto3.getEnumType(ComponentIssueSeverity) },
    { no: 5, name: "vuln_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fix_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "fix_versions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "updated_at", kind: "message", T: Timestamp },
    { no: 11, name: "due_at", kind: "message", T: Timestamp },
    { no: 12, name: "links", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 13, name: "notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateComponentIssuesReportItem {
    return new GenerateComponentIssuesReportItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateComponentIssuesReportItem {
    return new GenerateComponentIssuesReportItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateComponentIssuesReportItem {
    return new GenerateComponentIssuesReportItem().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateComponentIssuesReportItem | PlainMessage<GenerateComponentIssuesReportItem> | undefined, b: GenerateComponentIssuesReportItem | PlainMessage<GenerateComponentIssuesReportItem> | undefined): boolean {
    return proto3.util.equals(GenerateComponentIssuesReportItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.EPSSItem
 */
export class EPSSItem extends Message<EPSSItem> {
  /**
   * @generated from field: string cve = 1;
   */
  cve = "";

  /**
   * @generated from field: double epss = 2;
   */
  epss = 0;

  /**
   * @generated from field: double percentile = 3;
   */
  percentile = 0;

  /**
   * @generated from field: google.protobuf.Timestamp date = 4;
   */
  date?: Timestamp;

  constructor(data?: PartialMessage<EPSSItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.EPSSItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cve", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "epss", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "percentile", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EPSSItem {
    return new EPSSItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EPSSItem {
    return new EPSSItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EPSSItem {
    return new EPSSItem().fromJsonString(jsonString, options);
  }

  static equals(a: EPSSItem | PlainMessage<EPSSItem> | undefined, b: EPSSItem | PlainMessage<EPSSItem> | undefined): boolean {
    return proto3.util.equals(EPSSItem, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMUploadUrlRequest
 */
export class GetSBOMUploadUrlRequest extends Message<GetSBOMUploadUrlRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  constructor(data?: PartialMessage<GetSBOMUploadUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMUploadUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMUploadUrlRequest {
    return new GetSBOMUploadUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMUploadUrlRequest {
    return new GetSBOMUploadUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMUploadUrlRequest {
    return new GetSBOMUploadUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMUploadUrlRequest | PlainMessage<GetSBOMUploadUrlRequest> | undefined, b: GetSBOMUploadUrlRequest | PlainMessage<GetSBOMUploadUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetSBOMUploadUrlRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSBOMUploadUrlResponse
 */
export class GetSBOMUploadUrlResponse extends Message<GetSBOMUploadUrlResponse> {
  /**
   * @generated from field: string sbom_upload_path = 1;
   */
  sbomUploadPath = "";

  constructor(data?: PartialMessage<GetSBOMUploadUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSBOMUploadUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sbom_upload_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSBOMUploadUrlResponse {
    return new GetSBOMUploadUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSBOMUploadUrlResponse {
    return new GetSBOMUploadUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSBOMUploadUrlResponse {
    return new GetSBOMUploadUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSBOMUploadUrlResponse | PlainMessage<GetSBOMUploadUrlResponse> | undefined, b: GetSBOMUploadUrlResponse | PlainMessage<GetSBOMUploadUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetSBOMUploadUrlResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UserUploadedMetadataSBOM
 */
export class UserUploadedMetadataSBOM extends Message<UserUploadedMetadataSBOM> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string component_id = 2;
   */
  componentId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.SBOMFormat sbom_format = 3;
   */
  sbomFormat = SBOMFormat.SBOM_FORMAT_UNSPECIFIED;

  /**
   * @generated from field: map<string, string> labels = 4;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: string image_id = 5;
   */
  imageId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.Image image = 6;
   */
  image?: Image;

  /**
   * @generated from field: repeated string sbom_tags = 7;
   */
  sbomTags: string[] = [];

  constructor(data?: PartialMessage<UserUploadedMetadataSBOM>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UserUploadedMetadataSBOM";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sbom_format", kind: "enum", T: proto3.getEnumType(SBOMFormat) },
    { no: 4, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 5, name: "image_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image", kind: "message", T: Image },
    { no: 7, name: "sbom_tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserUploadedMetadataSBOM {
    return new UserUploadedMetadataSBOM().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserUploadedMetadataSBOM {
    return new UserUploadedMetadataSBOM().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserUploadedMetadataSBOM {
    return new UserUploadedMetadataSBOM().fromJsonString(jsonString, options);
  }

  static equals(a: UserUploadedMetadataSBOM | PlainMessage<UserUploadedMetadataSBOM> | undefined, b: UserUploadedMetadataSBOM | PlainMessage<UserUploadedMetadataSBOM> | undefined): boolean {
    return proto3.util.equals(UserUploadedMetadataSBOM, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UserUploadedSBOMResponse
 */
export class UserUploadedSBOMResponse extends Message<UserUploadedSBOMResponse> {
  /**
   * @generated from field: string sbom_id = 1;
   */
  sbomId = "";

  constructor(data?: PartialMessage<UserUploadedSBOMResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UserUploadedSBOMResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sbom_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserUploadedSBOMResponse {
    return new UserUploadedSBOMResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserUploadedSBOMResponse {
    return new UserUploadedSBOMResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserUploadedSBOMResponse {
    return new UserUploadedSBOMResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UserUploadedSBOMResponse | PlainMessage<UserUploadedSBOMResponse> | undefined, b: UserUploadedSBOMResponse | PlainMessage<UserUploadedSBOMResponse> | undefined): boolean {
    return proto3.util.equals(UserUploadedSBOMResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListEnabledFeaturesRequest
 */
export class ListEnabledFeaturesRequest extends Message<ListEnabledFeaturesRequest> {
  constructor(data?: PartialMessage<ListEnabledFeaturesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListEnabledFeaturesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnabledFeaturesRequest {
    return new ListEnabledFeaturesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnabledFeaturesRequest {
    return new ListEnabledFeaturesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnabledFeaturesRequest {
    return new ListEnabledFeaturesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnabledFeaturesRequest | PlainMessage<ListEnabledFeaturesRequest> | undefined, b: ListEnabledFeaturesRequest | PlainMessage<ListEnabledFeaturesRequest> | undefined): boolean {
    return proto3.util.equals(ListEnabledFeaturesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListEnabledFeaturesResponse
 */
export class ListEnabledFeaturesResponse extends Message<ListEnabledFeaturesResponse> {
  /**
   * @generated from field: repeated string flags = 1;
   */
  flags: string[] = [];

  constructor(data?: PartialMessage<ListEnabledFeaturesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListEnabledFeaturesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "flags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnabledFeaturesResponse {
    return new ListEnabledFeaturesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnabledFeaturesResponse {
    return new ListEnabledFeaturesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnabledFeaturesResponse {
    return new ListEnabledFeaturesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnabledFeaturesResponse | PlainMessage<ListEnabledFeaturesResponse> | undefined, b: ListEnabledFeaturesResponse | PlainMessage<ListEnabledFeaturesResponse> | undefined): boolean {
    return proto3.util.equals(ListEnabledFeaturesResponse, a, b);
  }
}

