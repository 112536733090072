import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormattedTimestamp from "./FormattedTimestamp";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Box, Typography } from "@mui/material";
import { CodeRepo } from "./CodeRepo";
import { Proposal, SourceRepo } from "../features/repoDetailSlice";
import { ProposalState } from "../pb/edgebit/platform/v1alpha/source_repos_pb";

export function ProposalTable(props: { proposals: Proposal[]; repos: SourceRepo[] | null }) {
  return (
    <Container disableGutters>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Proposal Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Repository</TableCell>
            <TableCell>Outcome</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        {props.proposals && props.repos && (
          <TableBody>
            {props.proposals.map((proposal) => (
              <TableRow key={proposal.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <Link component={NavLink} to={"/repos/" + proposal.repoId + "/proposals/" + proposal.id}>
                    {proposalName(proposal)}
                  </Link>
                </TableCell>
                <TableCell>
                  <ProposalStatus state={proposal.state} />
                </TableCell>
                <TableCell>
                  <CodeRepo repo={props.repos?.find((repo) => repo.id === proposal.repoId)} />
                </TableCell>
                <TableCell>
                  {proposal.result && proposal.result.case === "prResult" && (
                    <Link component={NavLink} to={proposal.result.value.url}>
                      PR #{proposal.result.value.id}
                    </Link>
                  )}
                  {!proposal.result && (
                    <Typography variant="body2" sx={{ color: "#999" }}>
                      None
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={proposal.createdAt} />
                </TableCell>
              </TableRow>
            ))}
            {props.proposals.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "10px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      No upgrade proposals exist yet.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Manually create a proposal by searching for a package.
                      <br />
                      EdgeBit automatic analysis may also open Proposals soon.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </Container>
  );
}

const proposalName = (proposal: Proposal) => {
  switch (proposal.proposalType) {
    case "requested":
      const packages = proposal.packages.filter((pkg) => pkg.requested).map((pkg) => pkg.package!.packageName);
      return "Update " + packages.join(", ");
    case "outdated":
      return "Upgrade an outdated dependency";
    case "outdatedWithVulns":
      return "Fix a vulnerability";
    default:
      return "Upgrade";
  }
};

export const ProposalStatus = (props: { state: ProposalState }) => {
  switch (props.state) {
    case ProposalState.MERGED:
      return <Box sx={{ color: "#4FD35D", fontWeight: 600, display: "inline-block" }}>Merged</Box>;
    case ProposalState.ANALYZED:
      return <Box sx={{ color: "#4FD35D", fontWeight: 600, display: "inline-block" }}>Analyzed</Box>;
    case ProposalState.PROPOSED:
      return <Box sx={{ color: "#4FD35D", fontWeight: 600, display: "inline-block" }}>Proposed</Box>;
    case ProposalState.REJECTED:
      return <Box sx={{ color: "#FF1717", display: "inline-block" }}>Rejected</Box>;
    case ProposalState.PENDING_ANALYSIS:
      return <Box sx={{ color: "#333", display: "inline-block" }}>Running analysis</Box>;
    case ProposalState.SUPERSEDED:
      return <Box sx={{ color: "#333", display: "inline-block" }}>Rejected</Box>;
    case ProposalState.ERRORED:
      return <Box sx={{ color: "#ff0000", display: "inline-block" }}>Error</Box>;
    default:
      return <Box sx={{ display: "inline-block" }}>{props.state}</Box>;
  }
};
