import Container from "@mui/material/Container";
import { useState } from "react";
import FormattedTimestamp from "./FormattedTimestamp";
import {
  ComponentIssueSeverity,
  ComponentIssueState,
  ComponentIssueStateChange,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import VulnChip from "./VulnChip";
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const stateNames: Record<ComponentIssueState, string> = {
  [ComponentIssueState.UNSPECIFIED]: "Unknown", // This should never happen
  [ComponentIssueState.IGNORED]: "Ignored",
  [ComponentIssueState.OPEN]: "Open",
  [ComponentIssueState.RESOLVED]: "Resolved",
};

interface ComponentIssueHistoryTableProps {
  issueStateChanges: ComponentIssueStateChange[] | null;
  refreshHistory: () => void;
}

function IssueStateContainer(props: { issue: ComponentIssueStateChange; handleClick: any; menuOpen: any }) {
  var issue = props.issue;

  const getVulnSeverityString = (severity: number): string => {
    const vulnSeverityString = ComponentIssueSeverity.hasOwnProperty(severity)
      ? ComponentIssueSeverity[severity].toString()
      : "";
    return vulnSeverityString.charAt(0).toUpperCase() + vulnSeverityString.slice(1).toLowerCase();
  };

  var actor = "";
  if (issue.actor?.ref.case === "userRef") {
    if (issue.actor?.ref.value.humanName) {
      actor = issue.actor?.ref.value.humanName + " (" + issue.actor?.ref.value.email + ")";
    } else {
      actor = issue.actor?.ref.value.email;
    }
  } else if (issue.actor?.ref.case === "automatedProcessRef") {
    actor = issue.actor?.ref.value.name;
  } else if (issue.actor?.ref.case === "projectApiKeyRef") {
    actor = "Project API";
  }

  // State Change Metadata:
  // Identifying columns: Component ID, Issue ID, ID
  // Created At
  // Actor
  // Comment
  // Justification

  // State Change Issue Fields:
  // State
  // Severity
  // Affected Packages
  // Due At
  // Upstream Severity

  return (
    <>
      <TableRow
        sx={{
          td: { borderBottom: (issue.comment || issue.justification) && "0px" },
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>
          <FormattedTimestamp timestamp={issue.createdAt} />
        </TableCell>
        <TableCell>
          {!!issue.state && <Box sx={{ fontSize: "14px" }}>Vulnerability set to {stateNames[issue.state]}</Box>}
          {!!issue.severity && (
            <Box sx={{ fontSize: "14px" }}>
              Severity set to{" "}
              <VulnChip
                preset={ComponentIssueSeverity[issue.severity]}
                innerText={getVulnSeverityString(issue.severity)}
              />
            </Box>
          )}
          {!!issue.upstreamSeverity && (
            <Box sx={{ fontSize: "14px" }}>
              Upstream severity changed to{" "}
              {
                <VulnChip
                  preset={ComponentIssueSeverity[issue.upstreamSeverity]}
                  innerText={getVulnSeverityString(issue.upstreamSeverity)}
                />
              }
            </Box>
          )}
          {issue.dueAt && (
            <>
              <Box sx={{ display: "inline-block", marginRight: "3px" }}>Due date set to </Box>
              <FormattedTimestamp timestamp={issue.dueAt} defaultText="No change" />
            </>
          )}
        </TableCell>
        <TableCell>{actor}</TableCell>
      </TableRow>
      {(issue.comment || issue.justification) && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={3} sx={{ paddingBottom: "10px", fontSize: "12px" }}>
            {issue.comment && (
              <>
                <Typography variant="h6" sx={{ fontSize: "12px", marginRight: "10px" }}>
                  Comment:
                </Typography>
                <Box>{issue.comment}</Box>
              </>
            )}
            {issue.justification && (
              <>
                <Typography variant="h6" sx={{ fontSize: "12px", marginRight: "10px", marginTop: "10px" }}>
                  Justification:
                </Typography>
                <Box>{issue.justification}</Box>
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export function ComponentIssueHistoryTable(props: ComponentIssueHistoryTableProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container sx={{ marginTop: "15px", marginBottom: "100vh" }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Vulnerability State</TableCell>
              <TableCell>Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Render the history in reverse order */}
            {props.issueStateChanges &&
              [...props.issueStateChanges]
                .reverse()
                .map((issue, index) => (
                  <IssueStateContainer key={index} issue={issue} handleClick={handleClick} menuOpen={menuOpen} />
                ))}
            {/* Empty state */}
            {props.issueStateChanges?.length === 0 && (
              <TableRow sx={{ td: { borderBottom: "0px" } }}>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
                      No history for the issue
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {/* Loading state */}
            {props.issueStateChanges === null && (
              <TableRow sx={{ td: { borderBottom: "0px" } }}>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "400px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                      Loading History...
                    </Typography>
                    <LinearProgress
                      sx={{
                        width: "200px",
                        marginTop: "3px",
                        marginRight: "auto",
                        marginBottom: "20px",
                        marginLeft: "auto",
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
