import { Box } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

function LatestIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0L9.78177 3.69842L13.6569 2.34315L12.3016 6.21823L16 8L12.3016 9.78177L13.6569 13.6569L9.78177 12.3016L8 16L6.21823 12.3016L2.34315 13.6569L3.69842 9.78177L0 8L3.69842 6.21823L2.34315 2.34315L6.21823 3.69842L8 0Z"
        fill="#F7D046"
      />
    </SvgIcon>
  );
}

export function ComponentTags(props: { tags: string[]; defaultTag?: string }) {
  var tags = props.tags;
  var defaultTag = false;

  // Remove all null, undefined and empty strings before display
  tags = tags.filter(function (e) {
    return e;
  });

  // Filter out special values like the default tag so they can be handled specially
  var filtered = tags.filter(function (a: any) {
    if (props.defaultTag && a === props.defaultTag) {
      defaultTag = true;
      return false;
    } else if (a.length === 0) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <>
      {tags.length > 0 ? (
        <Box sx={{ display: "inline-block" }}>
          {defaultTag && (
            <>
              <Box sx={{ display: "inline-block" }}>
                <LatestIcon
                  sx={{
                    marginRight: "5px",
                    fontSize: "18px",
                    verticalAlign: "middle",
                  }}
                ></LatestIcon>
                {props.defaultTag}
              </Box>
              {filtered.length > 0 && <Box sx={{ display: "inline-block", padding: "0 4px" }}>&bull;</Box>}
            </>
          )}
          {filtered.map((tag, index) => (
            <Box sx={{ display: "inline-block", verticalAlign: "baseline" }} key={index}>
              {index >= 1 && index !== filtered?.length && (
                <Box sx={{ display: "inline-block", padding: "0 4px" }}>&bull;</Box>
              )}
              {tag}
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ display: "inline-block", color: "#999" }}>None</Box>
      )}
    </>
  );
}
