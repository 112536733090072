import { UserUploadedSBOMResponse } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { apiBaseUrl } from "./client";

export function downloadFile(filePath: string) {
  const url = `${apiBaseUrl}${filePath}`;
  window.open(url, "_blank");
}

export class FileUploadError extends Error {}

export async function uploadFile(filePath: string, data: Blob) {
  const url = `${apiBaseUrl}${filePath}`;

  const response = await fetch(url, {
    method: "POST",
    body: data,
  });

  if (!response.ok) {
    const responseText = await response.text();
    throw new FileUploadError(responseText);
  }

  const responseText = await response.text();
  const body = UserUploadedSBOMResponse.fromJsonString(responseText);

  return body.sbomId;
}
