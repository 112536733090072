import { Code } from "@bufbuild/connect";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, ThunkExtra } from "../app/store";

export interface User {
  id: string;
  email: string | null;
  createdAt: string;
  lastLoginAt: string;
}

interface UsersState {
  userList: User[] | null;
  connectivity: boolean;
  createUserFormOpen: boolean;
  inviteUserInput: { email: string };
  inviteFormError: string | null;
}

const initialState: UsersState = {
  userList: null,
  connectivity: true,
  createUserFormOpen: false,
  inviteUserInput: { email: "" },
  inviteFormError: null,
};

export const listUsers = createAsyncThunk<User[], void, ThunkExtra>("users/listUsers", async (_, thunkAPI) => {
  try {
    const { userClient } = thunkAPI.extra;
    const response = await userClient.listUsers({});
    const users: User[] = response.users.map((user) => ({
      ...user,
      createdAt: user.createdAt ? user.createdAt.toDate().toString() : "",
      lastLoginAt: user.lastLoginAt ? user.lastLoginAt.toDate().toString() : "",
    }));

    return users;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch users");
  }
});

export const inviteUser = createAsyncThunk<string, { email: string }, ThunkExtra>(
  "users/inviteUser",
  async ({ email }, thunkAPI) => {
    try {
      const { userClient } = thunkAPI.extra;
      await userClient.inviteUser({ email });
      thunkAPI.dispatch(listUsers());
      return email;
    } catch (error: any) {
      const errorMessage = error.code === Code.InvalidArgument ? error.rawMessage : "Unknown error";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  },
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    openCreateUserForm: (state) => {
      state.createUserFormOpen = true;
    },
    closeCreateUserForm: (state) => {
      state.createUserFormOpen = false;
    },
    setInviteUserInput: (state, action: PayloadAction<{ email: string }>) => {
      state.inviteUserInput.email = action.payload.email;
    },
    setConnectivity: (state, action: PayloadAction<boolean>) => {
      state.connectivity = action.payload;
    },
    setInviteFormError: (state, action: PayloadAction<string | null>) => {
      state.inviteFormError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUsers.fulfilled, (state, action) => {
        state.userList = action.payload;
        state.connectivity = true;
      })
      .addCase(listUsers.rejected, (state) => {
        state.connectivity = false;
      })
      .addCase(inviteUser.fulfilled, (state) => {
        state.inviteFormError = null; // Reset error on successful invitation
        state.inviteUserInput = { email: "" };
        state.createUserFormOpen = false;
      })
      .addCase(inviteUser.rejected, (state, action) => {
        if (action.payload) {
          state.inviteFormError = action.payload as string;
        }
      });
  },
});

export const { openCreateUserForm, closeCreateUserForm, setInviteUserInput, setConnectivity, setInviteFormError } =
  usersSlice.actions;
export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
