import DnsIcon from "@mui/icons-material/Dns";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Box } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";

function DockerIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.875 7.33846H13.25V5H15.875V7.33846ZM9.68749 10.1692H7.125V7.83076H9.68749V10.1692ZM12.8125 10.1692H10.1875V7.83076H12.8125V10.1692ZM15.875 10.1692H13.25V7.83076H15.875V10.1692ZM6.625 13H4V10.6615H6.625V13ZM9.68749 13H7.125V10.6615H9.68749V13ZM12.8125 13H10.1875V10.6615H12.8125V13ZM15.875 13H13.25V10.6615H15.875V13ZM19 13H16.375V10.6615H19V13Z"
        fill="#6096FF"
      />
      <path
        d="M27 12.531C27 12.531 25.8458 11.4779 23.4732 11.8496C23.2168 10.0531 21.229 9 21.229 9C21.229 9 19.3694 11.1681 20.7159 13.5841C20.3312 13.7699 19.69 14.0177 18.7281 14.0177H1.09434C0.773725 15.1947 0.773726 23 9.62271 23C15.9709 23 20.7159 20.1505 22.9603 14.9469C26.2947 15.1947 27 12.531 27 12.531Z"
        fill="#6096FF"
      />
    </SvgIcon>
  );
}

function NPMIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 256 256">
      <g>
        <polygon fill="#C12127" points="0 256 0 0 256 0 256 256"></polygon>
        <polygon fill="#FFFFFF" points="48 48 208 48 208 208 176 208 176 80 128 80 128 208 48 208"></polygon>
      </g>
    </SvgIcon>
  );
}

function PythonIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.3883 0C6.54116 0 6.96847 2.96259 6.96847 2.96259L6.97689 6.03197H13.5105V6.95279H4.38016C4.38016 6.95279 0 6.45664 0 13.3506C0 20.2428 3.82389 19.9989 3.82389 19.9989H6.10626V16.8C6.10626 16.8 5.9832 12.9848 9.86947 12.9848H16.3491C16.3491 12.9848 19.9901 13.0436 19.9901 9.47389V3.57142C19.9901 3.57142 20.543 0 13.3883 0ZM9.78518 2.06364C10.435 2.06364 10.9609 2.58837 10.9609 3.23673C10.9609 3.88508 10.435 4.40982 9.78518 4.40982C9.63072 4.41004 9.47773 4.37985 9.33499 4.32098C9.19224 4.2621 9.06254 4.1757 8.95332 4.06672C8.84409 3.95775 8.7575 3.82834 8.69849 3.68591C8.63948 3.54349 8.60922 3.39085 8.60944 3.23673C8.60944 2.58837 9.13537 2.06364 9.78518 2.06364Z"
        fill="url(#paint0_linear_623_21)"
      />
      <path
        d="M14.6117 28C21.4588 28 21.0315 25.0373 21.0315 25.0373L21.0231 21.9678H14.4895V21.0469H23.619C23.619 21.0469 28 21.5431 28 14.6497C28 7.75636 24.1761 8.00108 24.1761 8.00108H21.8937V11.1993C21.8937 11.1993 22.0168 15.0147 18.1305 15.0147H11.6509C11.6509 15.0147 8.00991 14.9558 8.00991 18.5257V24.4284C8.00991 24.4284 7.45702 28 14.6117 28ZM18.2148 25.9363C18.0604 25.9365 17.9074 25.9063 17.7646 25.8474C17.6219 25.7886 17.4922 25.7021 17.3829 25.5932C17.2737 25.4842 17.1871 25.3548 17.1281 25.2123C17.0691 25.0699 17.0389 24.9173 17.0391 24.7631C17.0391 24.1156 17.565 23.5908 18.2148 23.5908C18.8646 23.5908 19.3906 24.1148 19.3906 24.7631C19.3906 25.4124 18.8646 25.9363 18.2148 25.9363Z"
        fill="url(#paint1_linear_623_21)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_623_21"
          x1="2.59168"
          y1="2.33946"
          x2="15.8975"
          y2="15.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#387EB8" />
          <stop offset="1" stopColor="#366994" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_623_21"
          x1="11.8254"
          y1="12.0629"
          x2="26.118"
          y2="25.7862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE052" />
          <stop offset="1" stopColor="#FFC331" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

function GoIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M73.4194 58.8392C67.5854 60.3202 63.6035 61.4309 57.8622 62.9118C56.4731 63.282 56.3805 63.3746 55.1767 61.9862C53.7877 60.4127 52.769 59.3946 50.8244 58.469C44.9904 55.5997 39.3417 56.4327 34.0633 59.8574C27.7664 63.9299 24.5253 69.9462 24.6179 77.4434C24.7105 84.8481 29.8036 90.9569 37.1192 91.9751C43.4162 92.8081 48.6945 90.5867 52.8616 85.8662C53.6951 84.8481 54.4359 83.7374 55.3619 82.4416C52.0282 82.4416 47.8611 82.4416 37.4896 82.4416C35.545 82.4416 35.082 81.2383 35.7302 79.6648C36.934 76.7955 39.1565 71.9825 40.4529 69.576C40.7307 69.0206 41.3789 68.0951 42.768 68.0951C47.4907 68.0951 64.8999 68.0951 76.4753 68.0951C76.29 70.5941 76.2901 73.0932 75.9196 75.5923C74.901 82.2565 72.4007 88.3653 68.3262 93.7337C61.6589 102.527 52.9542 107.988 41.9345 109.469C32.8595 110.672 24.4327 108.913 17.0245 103.36C10.1719 98.1765 6.28261 91.3272 5.26398 82.8118C4.06015 72.723 7.02343 63.6523 13.1352 55.6923C19.71 47.0843 28.4146 41.6234 39.0639 39.6797C47.7685 38.1062 56.1027 39.1243 63.6035 44.215C68.5114 47.4546 72.0303 51.8974 74.3454 57.2657C74.901 58.0988 74.5306 58.5616 73.4194 58.8392Z"
        fill="#00ACD7"
      />
      <path
        d="M104.071 110.024C95.6439 109.839 87.9579 107.432 81.4757 101.879C76.0122 97.1582 72.5859 91.1419 71.4747 84.0149C69.8078 73.5558 72.6785 64.3 78.9755 56.0623C85.7354 47.1768 93.8844 42.5488 104.904 40.6051C114.35 38.9391 123.239 39.8647 131.296 45.3256C138.611 50.3237 143.149 57.0805 144.353 65.9661C145.927 78.4614 142.316 88.6428 133.703 97.3433C127.592 103.545 120.091 107.432 111.479 109.191C108.979 109.654 106.478 109.746 104.071 110.024ZM126.11 72.6303C126.017 71.427 126.017 70.5014 125.832 69.5758C124.165 60.4126 115.739 55.2293 106.941 57.2656C98.3294 59.2093 92.7732 64.6703 90.736 73.3707C89.0691 80.5903 92.588 87.9024 99.2554 90.8642C104.349 93.0856 109.442 92.808 114.35 90.3089C121.665 86.514 125.647 80.5903 126.11 72.6303Z"
        fill="#00ACD7"
      />
    </SvgIcon>
  );
}

function RustIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_775_50)">
        <rect width="106" height="106" fill="white" />
        <path
          d="M44.5 38.5H57.5C65.5 38.5 65.5 46.5 57.5 46.5H44.5V38.5ZM13.5 75.5H53.5V64.5H44.5V56.5H54.5C65.5 56.5 59.5 75.5 68.5 75.5H93.5V56.5H87.5V58.5C87.5 66.5 78.5 65.5 77.5 60.5C76.5 55.5 72.5 51.5 71.5 51.5C86.5 43.5 77.5 27.5 65.5 27.5H18.5V38.5H28.5V64.5H13.5V75.5Z"
          fill="#6B452F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53 14.5C31.737 14.5 14.5 31.737 14.5 53C14.5 74.263 31.737 91.5 53 91.5C74.263 91.5 91.5 74.263 91.5 53C91.5 31.737 74.263 14.5 53 14.5ZM5.5 53C5.5 26.7665 26.7665 5.5 53 5.5C79.2335 5.5 100.5 26.7665 100.5 53C100.5 79.2335 79.2335 100.5 53 100.5C26.7665 100.5 5.5 79.2335 5.5 53Z"
          fill="#6B452F"
        />
        <path d="M99 56L104 53L99 50V56Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.261 48.6947C98.7312 48.4284 99.3083 48.4357 99.7717 48.7138L104.772 51.7138C105.224 51.9848 105.5 52.4731 105.5 53C105.5 53.5269 105.224 54.0152 104.772 54.2862L99.7717 57.2862C99.3083 57.5643 98.7312 57.5716 98.261 57.3053C97.7907 57.039 97.5 56.5404 97.5 56V50C97.5 49.4596 97.7907 48.961 98.261 48.6947ZM100.5 52.6493V53.3507L101.085 53L100.5 52.6493Z"
          fill="#6B452F"
        />
        <path d="M97.5306 64.9169L103.02 62.95L98.7012 59.0322L97.5306 64.9169Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2308 57.607C98.744 57.4376 99.3086 57.5573 99.7089 57.9204L104.028 61.8382C104.418 62.1923 104.594 62.7251 104.491 63.2418C104.388 63.7586 104.022 64.1836 103.526 64.3613L98.0364 66.3282C97.5277 66.5105 96.9602 66.405 96.551 66.0521C96.1417 65.6993 95.9539 65.1535 96.0593 64.6235L97.2298 58.7388C97.3353 58.2087 97.7176 57.7764 98.2308 57.607ZM99.6553 61.9224L99.5185 62.6104L100.16 62.3804L99.6553 61.9224Z"
          fill="#6B452F"
        />
        <path d="M94.3504 73.3753L100.118 72.5171L96.6465 67.832L94.3504 73.3753Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4635 66.3432C96.9998 66.2771 97.5303 66.5047 97.852 66.9389L101.323 71.624C101.637 72.0473 101.706 72.6042 101.504 73.091C101.302 73.5778 100.86 73.9231 100.339 74.0006L94.5714 74.8589C94.0369 74.9384 93.5009 74.7243 93.1683 74.2983C92.8358 73.8724 92.758 73.3004 92.9648 72.8012L95.2609 67.2579C95.4677 66.7586 95.9271 66.4092 96.4635 66.3432ZM97.0187 70.8536L96.7503 71.5016L97.4246 71.4013L97.0187 70.8536Z"
          fill="#6B452F"
        />
        <path d="M89.5806 81.0503L95.4047 81.3338L92.9141 76.0615L89.5806 81.0503Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.0245 74.5661C93.5635 74.606 94.0393 74.9327 94.2701 75.4213L96.7608 80.6936C96.9858 81.17 96.9444 81.7295 96.6517 82.1676C96.359 82.6057 95.8578 82.8581 95.3316 82.8325L89.5075 82.549C88.9677 82.5228 88.4838 82.2082 88.2407 81.7255C87.9977 81.2429 88.033 80.6668 88.3332 80.2175L91.6666 75.2286C91.9669 74.7793 92.4856 74.5262 93.0245 74.5661ZM92.6892 79.0982L92.2995 79.6814L92.9804 79.7145L92.6892 79.0982Z"
          fill="#6B452F"
        />
        <path d="M83.4058 87.6479L89.0627 89.0621L87.6484 83.4053L83.4058 87.6479Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.0488 81.9599C88.5696 82.1042 88.9726 82.5174 89.1037 83.0417L90.5179 88.6986C90.6457 89.2097 90.4959 89.7504 90.1233 90.123C89.7507 90.4956 89.21 90.6454 88.6988 90.5176L83.042 89.1034C82.5177 88.9723 82.1045 88.5693 81.9602 88.0485C81.816 87.5277 81.963 86.9696 82.3451 86.5875L86.5878 82.3448C86.9699 81.9627 87.528 81.8157 88.0488 81.9599ZM86.8358 86.3395L86.3398 86.8355L87.0011 87.0008L86.8358 86.3395Z"
          fill="#6B452F"
        />
        <path d="M76.062 92.9145L81.3342 95.4051L81.0508 89.5811L76.062 92.9145Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.7257 88.2409C82.2084 88.484 82.523 88.9679 82.5492 89.5076L82.8327 95.3317C82.8583 95.858 82.6059 96.3591 82.1678 96.6518C81.7297 96.9445 81.1701 96.986 80.6937 96.7609L75.4215 94.2703C74.9328 94.0394 74.6061 93.5636 74.5663 93.0247C74.5264 92.4857 74.7795 91.967 75.2288 91.6668L80.2176 88.3334C80.667 88.0331 81.2431 87.9978 81.7257 88.2409ZM79.6815 92.2996L79.0983 92.6893L79.7147 92.9805L79.6815 92.2996Z"
          fill="#6B452F"
        />
        <path d="M67.8317 96.6467L72.5168 100.118L73.375 94.3506L67.8317 96.6467Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2978 93.1682C74.7238 93.5007 74.9379 94.0367 74.8584 94.5712L74.0002 100.339C73.9226 100.86 73.5773 101.302 73.0905 101.504C72.6037 101.705 72.0469 101.637 71.6235 101.323L66.9385 97.8518C66.5042 97.5301 66.2767 96.9997 66.3427 96.4633C66.4087 95.9269 66.7582 95.4676 67.2574 95.2608L72.8007 92.9647C73.3 92.7578 73.8719 92.8356 74.2978 93.1682ZM71.5011 96.7501L70.8531 97.0186L71.4008 97.4244L71.5011 96.7501Z"
          fill="#6B452F"
        />
        <path d="M59.0323 98.7018L62.9501 103.02L64.917 97.5312L59.0323 98.7018Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.0522 96.5509C66.4051 96.9602 66.5106 97.5277 66.3283 98.0364L64.3614 103.526C64.1836 104.022 63.7587 104.388 63.2419 104.491C62.7251 104.594 62.1923 104.418 61.8383 104.027L57.9205 99.7088C57.5574 99.3086 57.4377 98.7439 57.6071 98.2308C57.7765 97.7176 58.2088 97.3352 58.7388 97.2298L64.6235 96.0592C65.1536 95.9538 65.6993 96.1416 66.0522 96.5509ZM62.6104 99.5184L61.9225 99.6553L62.3805 100.16L62.6104 99.5184Z"
          fill="#6B452F"
        />
        <path d="M50 99L53 104L56 99H50Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.3053 98.261C57.5716 98.7312 57.5643 99.3083 57.2862 99.7717L54.2862 104.772C54.0152 105.224 53.5269 105.5 53 105.5C52.4731 105.5 51.9848 105.224 51.7138 104.772L48.7138 99.7717C48.4357 99.3083 48.4284 98.7312 48.6947 98.261C48.961 97.7907 49.4596 97.5 50 97.5H56C56.5404 97.5 57.039 97.7907 57.3053 98.261ZM53.3507 100.5H52.6493L53 101.085L53.3507 100.5Z"
          fill="#6B452F"
        />
        <path d="M41.0831 97.5306L43.05 103.02L46.9678 98.7012L41.0831 97.5306Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.393 98.2308C48.5624 98.744 48.4427 99.3086 48.0796 99.7089L44.1618 104.028C43.8077 104.418 43.2749 104.594 42.7582 104.491C42.2414 104.388 41.8164 104.022 41.6387 103.526L39.6718 98.0364C39.4895 97.5277 39.595 96.9602 39.9479 96.551C40.3007 96.1417 40.8465 95.9539 41.3765 96.0593L47.2612 97.2298C47.7913 97.3353 48.2236 97.7176 48.393 98.2308ZM44.0776 99.6553L43.3896 99.5185L43.6196 100.16L44.0776 99.6553Z"
          fill="#6B452F"
        />
        <path d="M32.6247 94.3504L33.4829 100.118L38.168 96.6465L32.6247 94.3504Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6568 96.4635C39.7229 96.9998 39.4953 97.5303 39.0611 97.852L34.376 101.323C33.9527 101.637 33.3958 101.706 32.909 101.504C32.4222 101.302 32.0769 100.86 31.9994 100.339L31.1411 94.5714C31.0616 94.0369 31.2757 93.5009 31.7017 93.1683C32.1276 92.8358 32.6996 92.758 33.1988 92.9648L38.7421 95.2609C39.2414 95.4677 39.5908 95.9271 39.6568 96.4635ZM35.1464 97.0187L34.4984 96.7503L34.5987 97.4246L35.1464 97.0187Z"
          fill="#6B452F"
        />
        <path d="M24.9497 89.5806L24.6662 95.4047L29.9385 92.9141L24.9497 89.5806Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4339 93.0245C31.394 93.5635 31.0673 94.0393 30.5787 94.2701L25.3064 96.7608C24.83 96.9858 24.2705 96.9444 23.8324 96.6517C23.3943 96.359 23.1419 95.8578 23.1675 95.3316L23.451 89.5075C23.4772 88.9677 23.7918 88.4838 24.2745 88.2407C24.7571 87.9977 25.3332 88.033 25.7825 88.3332L30.7714 91.6666C31.2207 91.9669 31.4738 92.4856 31.4339 93.0245ZM26.9018 92.6892L26.3186 92.2995L26.2855 92.9804L26.9018 92.6892Z"
          fill="#6B452F"
        />
        <path d="M18.3521 83.4058L16.9379 89.0627L22.5947 87.6484L18.3521 83.4058Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0401 88.0488C23.8958 88.5696 23.4826 88.9726 22.9583 89.1037L17.3014 90.5179C16.7903 90.6457 16.2496 90.4959 15.877 90.1233C15.5044 89.7507 15.3546 89.21 15.4824 88.6988L16.8966 83.042C17.0277 82.5177 17.4307 82.1045 17.9515 81.9602C18.4723 81.816 19.0304 81.963 19.4125 82.3451L23.6552 86.5878C24.0373 86.9699 24.1843 87.528 24.0401 88.0488ZM19.6605 86.8358L19.1645 86.3398L18.9992 87.0011L19.6605 86.8358Z"
          fill="#6B452F"
        />
        <path d="M13.0855 76.062L10.5949 81.3342L16.4189 81.0508L13.0855 76.062Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7591 81.7257C17.516 82.2084 17.0321 82.523 16.4924 82.5492L10.6683 82.8327C10.142 82.8583 9.6409 82.6059 9.34818 82.1678C9.05545 81.7297 9.01405 81.1701 9.2391 80.6937L11.7297 75.4215C11.9606 74.9328 12.4364 74.6061 12.9753 74.5663C13.5143 74.5264 14.033 74.7795 14.3332 75.2288L17.6666 80.2176C17.9669 80.667 18.0022 81.2431 17.7591 81.7257ZM13.7004 79.6815L13.3107 79.0983L13.0195 79.7147L13.7004 79.6815Z"
          fill="#6B452F"
        />
        <path d="M9.35331 67.8317L5.88197 72.5168L11.6494 73.375L9.35331 67.8317Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8318 74.2978C12.4993 74.7238 11.9633 74.9379 11.4288 74.8584L5.66131 74.0002C5.14015 73.9226 4.6979 73.5773 4.49626 73.0905C4.29463 72.6037 4.36319 72.0469 4.67686 71.6235L8.14821 66.9385C8.46993 66.5042 9.00035 66.2767 9.5367 66.3427C10.0731 66.4087 10.5324 66.7582 10.7392 67.2574L13.0353 72.8007C13.2422 73.3 13.1644 73.8719 12.8318 74.2978ZM9.24987 71.5011L8.98145 70.8531L8.57563 71.4008L9.24987 71.5011Z"
          fill="#6B452F"
        />
        <path d="M7.29821 59.0323L2.97955 62.9501L8.46875 64.917L7.29821 59.0323Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.44908 66.0522C9.0398 66.4051 8.47234 66.5106 7.9636 66.3283L2.47441 64.3614C1.97839 64.1836 1.612 63.7587 1.50921 63.2419C1.40642 62.7251 1.5823 62.1923 1.97254 61.8383L6.2912 57.9205C6.69144 57.5574 7.25607 57.4377 7.76924 57.6071C8.28241 57.7765 8.66479 58.2088 8.77022 58.7388L9.94076 64.6235C10.0462 65.1536 9.85837 65.6993 9.44908 66.0522ZM6.48156 62.6104L6.34472 61.9225L5.83984 62.3805L6.48156 62.6104Z"
          fill="#6B452F"
        />
        <path d="M7 50L2 53L7 56L7 50Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.73905 57.3053C7.26879 57.5716 6.69165 57.5643 6.22826 57.2862L1.22826 54.2862C0.776448 54.0152 0.5 53.5269 0.5 53C0.5 52.4731 0.776448 51.9848 1.22826 51.7138L6.22826 48.7138C6.69165 48.4357 7.26879 48.4284 7.73905 48.6947C8.20931 48.961 8.5 49.4596 8.5 50L8.5 56C8.5 56.5404 8.20931 57.039 7.73905 57.3053ZM5.5 53.3507V52.6493L4.91548 53L5.5 53.3507Z"
          fill="#6B452F"
        />
        <path d="M8.46937 41.0831L2.98017 43.05L7.29883 46.9678L8.46937 41.0831Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.76919 48.393C7.25602 48.5624 6.6914 48.4427 6.29115 48.0796L1.97249 44.1618C1.58225 43.8077 1.40637 43.2749 1.50916 42.7582C1.61196 42.2414 1.97835 41.8164 2.47436 41.6387L7.96356 39.6718C8.47229 39.4895 9.03976 39.595 9.44904 39.9479C9.85832 40.3007 10.0461 40.8465 9.94072 41.3765L8.77017 47.2612C8.66475 47.7913 8.28236 48.2236 7.76919 48.393ZM6.34467 44.0776L6.48151 43.3896L5.8398 43.6196L6.34467 44.0776Z"
          fill="#6B452F"
        />
        <path d="M11.6496 32.6247L5.88217 33.4829L9.35352 38.168L11.6496 32.6247Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.53652 39.6568C9.00016 39.7229 8.46975 39.4953 8.14802 39.0611L4.67668 34.376C4.363 33.9527 4.29444 33.3958 4.49608 32.909C4.69771 32.4222 5.13997 32.0769 5.66112 31.9994L11.4286 31.1411C11.9631 31.0616 12.4991 31.2757 12.8317 31.7017C13.1642 32.1276 13.242 32.6996 13.0352 33.1988L10.7391 38.7421C10.5323 39.2414 10.0729 39.5908 9.53652 39.6568ZM8.98126 35.1464L9.24969 34.4984L8.57545 34.5987L8.98126 35.1464Z"
          fill="#6B452F"
        />
        <path d="M16.4194 24.9497L10.5953 24.6662L13.0859 29.9385L16.4194 24.9497Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9755 31.4339C12.4365 31.394 11.9607 31.0673 11.7299 30.5787L9.23924 25.3064C9.01418 24.83 9.05558 24.2705 9.34831 23.8324C9.64104 23.3943 10.1422 23.1419 10.6684 23.1675L16.4925 23.451C17.0323 23.4772 17.5162 23.7918 17.7593 24.2745C18.0023 24.7571 17.967 25.3332 17.6668 25.7825L14.3334 30.7714C14.0331 31.2207 13.5144 31.4738 12.9755 31.4339ZM13.3108 26.9018L13.7005 26.3186L13.0196 26.2855L13.3108 26.9018Z"
          fill="#6B452F"
        />
        <path d="M22.5942 18.3521L16.9373 16.9379L18.3516 22.5947L22.5942 18.3521Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9512 24.0401C17.4304 23.8958 17.0274 23.4826 16.8963 22.9583L15.4821 17.3014C15.3543 16.7903 15.5041 16.2496 15.8767 15.877C16.2493 15.5044 16.79 15.3546 17.3012 15.4824L22.958 16.8966C23.4823 17.0277 23.8955 17.4307 24.0398 17.9515C24.184 18.4723 24.037 19.0304 23.6549 19.4125L19.4122 23.6552C19.0301 24.0373 18.472 24.1843 17.9512 24.0401ZM19.1642 19.6605L19.6602 19.1645L18.9989 18.9992L19.1642 19.6605Z"
          fill="#6B452F"
        />
        <path d="M29.938 13.0855L24.6658 10.5949L24.9492 16.4189L29.938 13.0855Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.2743 17.7591C23.7916 17.516 23.477 17.0321 23.4508 16.4924L23.1673 10.6683C23.1417 10.142 23.3941 9.6409 23.8322 9.34818C24.2703 9.05545 24.8299 9.01405 25.3063 9.2391L30.5785 11.7297C31.0672 11.9606 31.3939 12.4364 31.4337 12.9753C31.4736 13.5143 31.2205 14.033 30.7712 14.3332L25.7824 17.6666C25.333 17.9669 24.7569 18.0022 24.2743 17.7591ZM26.3185 13.7004L26.9017 13.3107L26.2853 13.0195L26.3185 13.7004Z"
          fill="#6B452F"
        />
        <path d="M38.1683 9.35331L33.4832 5.88197L32.625 11.6494L38.1683 9.35331Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7022 12.8318C31.2762 12.4993 31.0621 11.9633 31.1416 11.4288L31.9998 5.66131C32.0774 5.14015 32.4227 4.6979 32.9095 4.49626C33.3963 4.29463 33.9531 4.36319 34.3765 4.67686L39.0615 8.14821C39.4958 8.46993 39.7233 9.00035 39.6573 9.5367C39.5913 10.0731 39.2418 10.5324 38.7426 10.7392L33.1993 13.0353C32.7 13.2422 32.1281 13.1644 31.7022 12.8318ZM34.4989 9.24987L35.1469 8.98145L34.5992 8.57563L34.4989 9.24987Z"
          fill="#6B452F"
        />
        <path d="M46.9677 7.29821L43.0499 2.97955L41.083 8.46875L46.9677 7.29821Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9478 9.44908C39.5949 9.0398 39.4894 8.47234 39.6717 7.9636L41.6386 2.47441C41.8164 1.97839 42.2413 1.612 42.7581 1.50921C43.2749 1.40642 43.8077 1.5823 44.1617 1.97254L48.0795 6.2912C48.4426 6.69144 48.5623 7.25607 48.3929 7.76924C48.2235 8.28241 47.7912 8.66479 47.2612 8.77022L41.3765 9.94076C40.8464 10.0462 40.3007 9.85837 39.9478 9.44908ZM43.3896 6.48156L44.0775 6.34472L43.6195 5.83984L43.3896 6.48156Z"
          fill="#6B452F"
        />
        <path d="M56 7L53 2L50 7L56 7Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.6947 7.73905C48.4284 7.26879 48.4357 6.69165 48.7138 6.22826L51.7138 1.22826C51.9848 0.776448 52.4731 0.5 53 0.5C53.5269 0.5 54.0152 0.776448 54.2862 1.22826L57.2862 6.22826C57.5643 6.69165 57.5716 7.26879 57.3053 7.73905C57.039 8.20931 56.5404 8.5 56 8.5L50 8.5C49.4596 8.5 48.961 8.20931 48.6947 7.73905ZM52.6493 5.5H53.3507L53 4.91548L52.6493 5.5Z"
          fill="#6B452F"
        />
        <path d="M64.9169 8.46937L62.95 2.98017L59.0322 7.29883L64.9169 8.46937Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.607 7.76919C57.4376 7.25602 57.5573 6.6914 57.9204 6.29115L61.8382 1.97249C62.1923 1.58225 62.7251 1.40637 63.2418 1.50916C63.7586 1.61196 64.1836 1.97835 64.3613 2.47436L66.3282 7.96356C66.5105 8.47229 66.405 9.03976 66.0521 9.44904C65.6993 9.85832 65.1535 10.0461 64.6235 9.94072L58.7388 8.77017C58.2087 8.66475 57.7764 8.28236 57.607 7.76919ZM61.9224 6.34467L62.6104 6.48151L62.3804 5.8398L61.9224 6.34467Z"
          fill="#6B452F"
        />
        <path d="M73.3753 11.6496L72.5171 5.88217L67.832 9.35352L73.3753 11.6496Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.3432 9.53652C66.2771 9.00016 66.5047 8.46975 66.9389 8.14802L71.624 4.67668C72.0473 4.363 72.6042 4.29444 73.091 4.49608C73.5778 4.69771 73.9231 5.13997 74.0006 5.66112L74.8589 11.4286C74.9384 11.9631 74.7243 12.4991 74.2983 12.8317C73.8724 13.1642 73.3004 13.242 72.8012 13.0352L67.2579 10.7391C66.7586 10.5323 66.4092 10.0729 66.3432 9.53652ZM70.8536 8.98126L71.5016 9.24969L71.4013 8.57545L70.8536 8.98126Z"
          fill="#6B452F"
        />
        <path d="M81.0503 16.4194L81.3338 10.5953L76.0615 13.0859L81.0503 16.4194Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.5661 12.9755C74.606 12.4365 74.9327 11.9607 75.4213 11.7299L80.6936 9.23924C81.17 9.01418 81.7295 9.05558 82.1676 9.34831C82.6057 9.64104 82.8581 10.1422 82.8325 10.6684L82.549 16.4925C82.5228 17.0323 82.2082 17.5162 81.7255 17.7593C81.2429 18.0023 80.6668 17.967 80.2175 17.6668L75.2286 14.3334C74.7793 14.0331 74.5262 13.5144 74.5661 12.9755ZM79.0982 13.3108L79.6814 13.7005L79.7145 13.0196L79.0982 13.3108Z"
          fill="#6B452F"
        />
        <path d="M87.6479 22.5942L89.0621 16.9373L83.4053 18.3516L87.6479 22.5942Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.9599 17.9512C82.1042 17.4304 82.5174 17.0274 83.0417 16.8963L88.6986 15.4821C89.2097 15.3543 89.7504 15.5041 90.123 15.8767C90.4956 16.2493 90.6454 16.79 90.5176 17.3012L89.1034 22.958C88.9723 23.4823 88.5693 23.8955 88.0485 24.0398C87.5277 24.184 86.9696 24.037 86.5875 23.6549L82.3448 19.4122C81.9627 19.0301 81.8157 18.472 81.9599 17.9512ZM86.3395 19.1642L86.8355 19.6602L87.0008 18.9989L86.3395 19.1642Z"
          fill="#6B452F"
        />
        <path d="M92.9145 29.938L95.4051 24.6658L89.5811 24.9492L92.9145 29.938Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.2409 24.2743C88.484 23.7916 88.9679 23.477 89.5076 23.4508L95.3317 23.1673C95.858 23.1417 96.3591 23.3941 96.6518 23.8322C96.9445 24.2703 96.986 24.8299 96.7609 25.3063L94.2703 30.5785C94.0394 31.0672 93.5636 31.3939 93.0247 31.4337C92.4857 31.4736 91.967 31.2205 91.6668 30.7712L88.3334 25.7824C88.0331 25.333 87.9978 24.7569 88.2409 24.2743ZM92.2996 26.3185L92.6893 26.9017L92.9805 26.2853L92.2996 26.3185Z"
          fill="#6B452F"
        />
        <path d="M96.6467 38.1683L100.118 33.4832L94.3506 32.625L96.6467 38.1683Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.1682 31.7022C93.5007 31.2762 94.0367 31.0621 94.5712 31.1416L100.339 31.9998C100.86 32.0774 101.302 32.4227 101.504 32.9095C101.705 33.3963 101.637 33.9531 101.323 34.3765L97.8518 39.0615C97.5301 39.4958 96.9997 39.7233 96.4633 39.6573C95.9269 39.5913 95.4676 39.2418 95.2608 38.7426L92.9647 33.1993C92.7578 32.7 92.8356 32.1281 93.1682 31.7022ZM96.7501 34.4989L97.0186 35.1469L97.4244 34.5992L96.7501 34.4989Z"
          fill="#6B452F"
        />
        <path d="M98.7018 46.9677L103.02 43.0499L97.5312 41.083L98.7018 46.9677Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.5509 39.9478C96.9602 39.5949 97.5277 39.4894 98.0364 39.6717L103.526 41.6386C104.022 41.8164 104.388 42.2413 104.491 42.7581C104.594 43.2749 104.418 43.8077 104.027 44.1617L99.7088 48.0795C99.3086 48.4426 98.7439 48.5623 98.2308 48.3929C97.7176 48.2235 97.3352 47.7912 97.2298 47.2612L96.0592 41.3765C95.9538 40.8464 96.1416 40.3007 96.5509 39.9478ZM99.5184 43.3896L99.6553 44.0775L100.16 43.6195L99.5184 43.3896Z"
          fill="#6B452F"
        />
        <path d="M46 11L53 18L60 11H46Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.2284 9.85195C43.6928 8.73093 44.7867 8 46.0001 8H60.0001C61.2135 8 62.3074 8.73093 62.7717 9.85195C63.2361 10.973 62.9794 12.2633 62.1214 13.1213L55.1214 20.1213C53.9498 21.2929 52.0503 21.2929 50.8788 20.1213L43.8788 13.1213C43.0208 12.2633 42.7641 10.973 43.2284 9.85195Z"
          fill="#6B452F"
        />
        <path d="M90.7812 33.3643L86.287 42.1848L95.1075 46.679L90.7812 33.3643Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.0163 30.3735C92.226 30.4687 93.2592 31.2832 93.6341 32.4372L97.9603 45.752C98.3353 46.906 97.9782 48.1723 97.0555 48.9603C96.1329 49.7483 94.8263 49.903 93.7452 49.3521L84.9247 44.8578C83.4484 44.1056 82.8614 42.2991 83.6136 40.8228L88.1079 32.0023C88.6588 30.9212 89.8067 30.2783 91.0163 30.3735Z"
          fill="#6B452F"
        />
        <path d="M83.3506 82.8643L73.573 81.3156L72.0243 91.0933L83.3506 82.8643Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.2676 82.1643C86.5509 83.3442 86.0955 84.5785 85.1139 85.2917L73.7877 93.5207C72.806 94.2339 71.4914 94.2856 70.4568 93.6516C69.4222 93.0176 68.8714 91.8228 69.0612 90.6244L70.6099 80.8468C70.869 79.2103 72.4058 78.0938 74.0422 78.353L83.8198 79.9016C85.0183 80.0914 85.9844 80.9845 86.2676 82.1643Z"
          fill="#6B452F"
        />
        <path d="M33.9766 91.0928L32.4279 81.3152L22.6503 82.8638L33.9766 91.0928Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5434 93.6507C34.5088 94.2847 33.1942 94.233 32.2125 93.5198L20.8863 85.2908C19.9046 84.5776 19.4493 83.3433 19.7325 82.1634C20.0158 80.9836 20.9819 80.0905 22.1803 79.9007L31.958 78.3521C33.5944 78.0929 35.1311 79.2094 35.3903 80.8458L36.9389 90.6234C37.1288 91.8219 36.578 93.0167 35.5434 93.6507Z"
          fill="#6B452F"
        />
        <path d="M10.8926 46.6787L19.7131 42.1844L15.2188 33.3639L10.8926 46.6787Z" fill="#6B452F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.94421 48.9595C8.02154 48.1715 7.66443 46.9052 8.03938 45.7512L12.3656 32.4364C12.7406 31.2824 13.7738 30.4679 14.9834 30.3727C16.1931 30.2775 17.3409 30.9204 17.8918 32.0015L22.3861 40.822C23.1383 42.2983 22.5513 44.1048 21.075 44.857L12.2545 49.3513C11.1734 49.9022 9.86687 49.7475 8.94421 48.9595Z"
          fill="#6B452F"
        />
      </g>
      <defs>
        <clipPath id="clip0_775_50">
          <rect width="106" height="106" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function packageDisplayName(type: string) {
  switch (type) {
    case "rpm":
      return "RPM";
    case "deb":
      return "Debian";
    case "python":
      return "Python";
    case "go-module":
      return "Go";
    case "container":
      return "Container";
    case "base_os":
      return "Base OS";
    case "binary":
      return "Binary";
    case "npm":
      return "NodeJS";
    case "rust-crate":
      return "Rust";
    case "apk":
      return "Alpine";
    case "java-archive":
      return "Java Archive";
    case "alpm":
      return "Arch Linux";
    case "pod":
      return "Cocoapods";
    case "conan":
      return "Conan";
    case "dart-pub":
      return "Dart";
    case "dotnet":
      return ".Net";
    case "gem":
      return "Ruby";
    case "github-action":
      return "GitHub Action";
    case "github-action-workflow":
      return "GitHub Action";
    case "graalvm-native-image":
      return "GraalVM";
    case "hackage":
      return "Hackage";
    case "hex":
      return "Hex";
    case "jenkins-plugin":
      return "Jenkins Plugin";
    case "msrc-kb":
      return "Microsoft Security Package";
    case "linux-kernel":
      return "Linux Kernel";
    case "linux-kernel-module":
      return "Kernel Module";
    case "nix":
      return "nix";
    case "php-composer":
      return "PHP";
    case "portage":
      return "Portage";
    case "R-package":
      return "R";
    case "swift":
      return "Swift";
    default:
      // UnknownPackage
      return type;
  }
}

export function PackageType(props: { children?: any; type: string; name?: boolean }) {
  var type = props.type;
  var text = packageDisplayName(type);

  return (
    <Tooltip title={"Type: " + text}>
      <Box sx={{ display: "inline-flex", verticalAlign: "middle" }}>
        {type === "container" && (
          <DockerIcon
            sx={{
              marginRight: "5px",
              fontSize: "18px",
              verticalAlign: "middle",
            }}
          ></DockerIcon>
        )}
        {type === "python" && (
          <PythonIcon
            sx={{
              marginRight: "5px",
              fontSize: "18px",
              verticalAlign: "middle",
            }}
          ></PythonIcon>
        )}
        {type === "base_os" && (
          <DnsIcon
            sx={{
              marginRight: "5px",
              fontSize: "18px",
              verticalAlign: "middle",
            }}
          ></DnsIcon>
        )}
        {type === "deb" && (
          <Box
            sx={{
              marginRight: "6px",
              fontSize: "11px",
              verticalAlign: "middle",
              background: "#ccc",
              padding: "2px 5px",
              marginLeft: "1px",
              borderRadius: "2px",
              color: "#333",
              maxWidth: "24px",
              fontWeight: "700",
              lineHeight: "10px",
              letterSpacing: "0px",
              height: "16px",
              display: "flex",
              alignSelf: "center",
            }}
          >
            {text.substring(0, 1)}
          </Box>
        )}
        {type === "rpm" && (
          <Box
            sx={{
              marginRight: "6px",
              fontSize: "11px",
              verticalAlign: "middle",
              background: "#ccc",
              padding: "2px 5px",
              marginLeft: "1px",
              borderRadius: "10px",
              color: "#333",
              maxWidth: "24px",
              fontWeight: "700",
              lineHeight: "10px",
              letterSpacing: "0px",
              height: "16px",
              display: "flex",
              alignSelf: "center",
            }}
          >
            {text.substring(0, 1)}
          </Box>
        )}
        {type === "binary" && (
          <Box
            sx={{
              marginRight: "6px",
              fontSize: "11px",
              verticalAlign: "middle",
              background: "#333",
              padding: "2px 5px",
              marginLeft: "1px",
              borderRadius: "10px",
              color: "#fff",
              maxWidth: "24px",
              fontWeight: "700",
              lineHeight: "10px",
              letterSpacing: "0px",
              height: "16px",
              display: "flex",
              alignSelf: "center",
            }}
          >
            {text.substring(0, 1)}
          </Box>
        )}
        {type === "npm" && (
          <NPMIcon
            sx={{
              marginRight: "5px",
              fontSize: "18px",
              verticalAlign: "middle",
            }}
          ></NPMIcon>
        )}
        {type === "go-module" && (
          <GoIcon
            width="150"
            height="150"
            viewBox="0 0 150 150"
            preserveAspectRatio="xMinYMin"
            sx={{
              marginRight: "5px",
              fontSize: "17px",
              verticalAlign: "middle",
            }}
          ></GoIcon>
        )}
        {type === "rust-crate" && (
          <RustIcon
            width="106"
            height="106"
            viewBox="0 0 106 106"
            preserveAspectRatio="xMinYMin"
            sx={{
              marginRight: "5px",
              fontSize: "17px",
              verticalAlign: "middle",
            }}
          ></RustIcon>
        )}
        {[
          "UnknownPackage",
          "apk",
          "alpm",
          "pod",
          "conan",
          "dart-pub",
          "dotnet",
          "gem",
          "github-action",
          "github-action-workflow",
          "graalvm-native-image",
          "hackage",
          "hex",
          "jenkins-plugin",
          "msrc-kb",
          "linux-kernel",
          "linux-kernel-module",
          "nix",
          "php-composer",
          "portage",
          "portage",
          "R-package",
          "swift",
        ].includes(type) && (
          <InventoryIcon
            sx={{
              marginRight: "5px",
              fontSize: "16px",
              verticalAlign: "middle",
            }}
          ></InventoryIcon>
        )}
        {props.name && <Box>{text}</Box>}
      </Box>
    </Tooltip>
  );
}
