// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/source_repos.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum edgebit.platform.v1alpha.ProposalState
 */
export enum ProposalState {
  /**
   * @generated from enum value: PROPOSAL_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PROPOSAL_STATE_PENDING_ANALYSIS = 1;
   */
  PENDING_ANALYSIS = 1,

  /**
   * @generated from enum value: PROPOSAL_STATE_ANALYZED = 2;
   */
  ANALYZED = 2,

  /**
   * @generated from enum value: PROPOSAL_STATE_PROPOSED = 3;
   */
  PROPOSED = 3,

  /**
   * @generated from enum value: PROPOSAL_STATE_MERGED = 4;
   */
  MERGED = 4,

  /**
   * @generated from enum value: PROPOSAL_STATE_REJECTED = 5;
   */
  REJECTED = 5,

  /**
   * @generated from enum value: PROPOSAL_STATE_SUPERSEDED = 6;
   */
  SUPERSEDED = 6,

  /**
   * @generated from enum value: PROPOSAL_STATE_ERRORED = 7;
   */
  ERRORED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(ProposalState)
proto3.util.setEnumType(ProposalState, "edgebit.platform.v1alpha.ProposalState", [
  { no: 0, name: "PROPOSAL_STATE_UNSPECIFIED" },
  { no: 1, name: "PROPOSAL_STATE_PENDING_ANALYSIS" },
  { no: 2, name: "PROPOSAL_STATE_ANALYZED" },
  { no: 3, name: "PROPOSAL_STATE_PROPOSED" },
  { no: 4, name: "PROPOSAL_STATE_MERGED" },
  { no: 5, name: "PROPOSAL_STATE_REJECTED" },
  { no: 6, name: "PROPOSAL_STATE_SUPERSEDED" },
  { no: 7, name: "PROPOSAL_STATE_ERRORED" },
]);

/**
 * @generated from message edgebit.platform.v1alpha.SyncReposRequest
 */
export class SyncReposRequest extends Message<SyncReposRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<SyncReposRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SyncReposRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncReposRequest {
    return new SyncReposRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncReposRequest {
    return new SyncReposRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncReposRequest {
    return new SyncReposRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncReposRequest | PlainMessage<SyncReposRequest> | undefined, b: SyncReposRequest | PlainMessage<SyncReposRequest> | undefined): boolean {
    return proto3.util.equals(SyncReposRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SyncReposResponse
 */
export class SyncReposResponse extends Message<SyncReposResponse> {
  constructor(data?: PartialMessage<SyncReposResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SyncReposResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncReposResponse {
    return new SyncReposResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncReposResponse {
    return new SyncReposResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncReposResponse {
    return new SyncReposResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncReposResponse | PlainMessage<SyncReposResponse> | undefined, b: SyncReposResponse | PlainMessage<SyncReposResponse> | undefined): boolean {
    return proto3.util.equals(SyncReposResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateSourceRepoStatusRequest
 */
export class UpdateSourceRepoStatusRequest extends Message<UpdateSourceRepoStatusRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: bool enabled = 3;
   */
  enabled = false;

  constructor(data?: PartialMessage<UpdateSourceRepoStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateSourceRepoStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSourceRepoStatusRequest {
    return new UpdateSourceRepoStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSourceRepoStatusRequest {
    return new UpdateSourceRepoStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSourceRepoStatusRequest {
    return new UpdateSourceRepoStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSourceRepoStatusRequest | PlainMessage<UpdateSourceRepoStatusRequest> | undefined, b: UpdateSourceRepoStatusRequest | PlainMessage<UpdateSourceRepoStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSourceRepoStatusRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.UpdateSourceRepoStatusResponse
 */
export class UpdateSourceRepoStatusResponse extends Message<UpdateSourceRepoStatusResponse> {
  constructor(data?: PartialMessage<UpdateSourceRepoStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.UpdateSourceRepoStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSourceRepoStatusResponse {
    return new UpdateSourceRepoStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSourceRepoStatusResponse {
    return new UpdateSourceRepoStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSourceRepoStatusResponse {
    return new UpdateSourceRepoStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSourceRepoStatusResponse | PlainMessage<UpdateSourceRepoStatusResponse> | undefined, b: UpdateSourceRepoStatusResponse | PlainMessage<UpdateSourceRepoStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSourceRepoStatusResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceReposRequest
 */
export class ListSourceReposRequest extends Message<ListSourceReposRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<ListSourceReposRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceReposRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceReposRequest {
    return new ListSourceReposRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceReposRequest {
    return new ListSourceReposRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceReposRequest {
    return new ListSourceReposRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceReposRequest | PlainMessage<ListSourceReposRequest> | undefined, b: ListSourceReposRequest | PlainMessage<ListSourceReposRequest> | undefined): boolean {
    return proto3.util.equals(ListSourceReposRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceReposResponse
 */
export class ListSourceReposResponse extends Message<ListSourceReposResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SourceRepo source_repos = 1;
   */
  sourceRepos: SourceRepo[] = [];

  constructor(data?: PartialMessage<ListSourceReposResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceReposResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source_repos", kind: "message", T: SourceRepo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceReposResponse {
    return new ListSourceReposResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceReposResponse {
    return new ListSourceReposResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceReposResponse {
    return new ListSourceReposResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceReposResponse | PlainMessage<ListSourceReposResponse> | undefined, b: ListSourceReposResponse | PlainMessage<ListSourceReposResponse> | undefined): boolean {
    return proto3.util.equals(ListSourceReposResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListProposalsRequest
 */
export class ListProposalsRequest extends Message<ListProposalsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: optional string repo_id = 2;
   */
  repoId?: string;

  constructor(data?: PartialMessage<ListProposalsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListProposalsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProposalsRequest {
    return new ListProposalsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProposalsRequest {
    return new ListProposalsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProposalsRequest {
    return new ListProposalsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProposalsRequest | PlainMessage<ListProposalsRequest> | undefined, b: ListProposalsRequest | PlainMessage<ListProposalsRequest> | undefined): boolean {
    return proto3.util.equals(ListProposalsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListProposalsResponse
 */
export class ListProposalsResponse extends Message<ListProposalsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.Proposal proposals = 1;
   */
  proposals: Proposal[] = [];

  constructor(data?: PartialMessage<ListProposalsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListProposalsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proposals", kind: "message", T: Proposal, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProposalsResponse {
    return new ListProposalsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProposalsResponse {
    return new ListProposalsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProposalsResponse {
    return new ListProposalsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProposalsResponse | PlainMessage<ListProposalsResponse> | undefined, b: ListProposalsResponse | PlainMessage<ListProposalsResponse> | undefined): boolean {
    return proto3.util.equals(ListProposalsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateProposalOptions
 */
export class CreateProposalOptions extends Message<CreateProposalOptions> {
  /**
   * @generated from field: bool legacy_peer_deps = 1;
   */
  legacyPeerDeps = false;

  constructor(data?: PartialMessage<CreateProposalOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateProposalOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "legacy_peer_deps", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProposalOptions {
    return new CreateProposalOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProposalOptions {
    return new CreateProposalOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProposalOptions {
    return new CreateProposalOptions().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProposalOptions | PlainMessage<CreateProposalOptions> | undefined, b: CreateProposalOptions | PlainMessage<CreateProposalOptions> | undefined): boolean {
    return proto3.util.equals(CreateProposalOptions, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateProposalRequest
 */
export class CreateProposalRequest extends Message<CreateProposalRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: repeated string package_ids = 3;
   */
  packageIds: string[] = [];

  /**
   * @generated from field: bool auto_propose = 4;
   */
  autoPropose = false;

  /**
   * @generated from field: optional string base_branch = 5;
   */
  baseBranch?: string;

  /**
   * @generated from field: optional string proposal_type = 6;
   */
  proposalType?: string;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.CreateProposalOptions options = 7;
   */
  options?: CreateProposalOptions;

  /**
   * @generated from field: map<string, string> force_versions = 8;
   */
  forceVersions: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateProposalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateProposalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "package_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "auto_propose", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "base_branch", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "proposal_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "options", kind: "message", T: CreateProposalOptions, opt: true },
    { no: 8, name: "force_versions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProposalRequest {
    return new CreateProposalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProposalRequest {
    return new CreateProposalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProposalRequest {
    return new CreateProposalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProposalRequest | PlainMessage<CreateProposalRequest> | undefined, b: CreateProposalRequest | PlainMessage<CreateProposalRequest> | undefined): boolean {
    return proto3.util.equals(CreateProposalRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.CreateProposalResponse
 */
export class CreateProposalResponse extends Message<CreateProposalResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Proposal proposal = 1;
   */
  proposal?: Proposal;

  constructor(data?: PartialMessage<CreateProposalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.CreateProposalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proposal", kind: "message", T: Proposal },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProposalResponse {
    return new CreateProposalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProposalResponse {
    return new CreateProposalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProposalResponse {
    return new CreateProposalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProposalResponse | PlainMessage<CreateProposalResponse> | undefined, b: CreateProposalResponse | PlainMessage<CreateProposalResponse> | undefined): boolean {
    return proto3.util.equals(CreateProposalResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceRepoPackageForComponentIssuePackageNameRequest
 */
export class ListSourceRepoPackageForComponentIssuePackageNameRequest extends Message<ListSourceRepoPackageForComponentIssuePackageNameRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string package_name = 2;
   */
  packageName = "";

  /**
   * @generated from field: string component_id = 3;
   */
  componentId = "";

  constructor(data?: PartialMessage<ListSourceRepoPackageForComponentIssuePackageNameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceRepoPackageForComponentIssuePackageNameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "component_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceRepoPackageForComponentIssuePackageNameRequest {
    return new ListSourceRepoPackageForComponentIssuePackageNameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceRepoPackageForComponentIssuePackageNameRequest {
    return new ListSourceRepoPackageForComponentIssuePackageNameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceRepoPackageForComponentIssuePackageNameRequest {
    return new ListSourceRepoPackageForComponentIssuePackageNameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceRepoPackageForComponentIssuePackageNameRequest | PlainMessage<ListSourceRepoPackageForComponentIssuePackageNameRequest> | undefined, b: ListSourceRepoPackageForComponentIssuePackageNameRequest | PlainMessage<ListSourceRepoPackageForComponentIssuePackageNameRequest> | undefined): boolean {
    return proto3.util.equals(ListSourceRepoPackageForComponentIssuePackageNameRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceRepoPackageForComponentIssuePackageNameResponse
 */
export class ListSourceRepoPackageForComponentIssuePackageNameResponse extends Message<ListSourceRepoPackageForComponentIssuePackageNameResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.SourceRepoPackage package = 1;
   */
  package?: SourceRepoPackage;

  constructor(data?: PartialMessage<ListSourceRepoPackageForComponentIssuePackageNameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceRepoPackageForComponentIssuePackageNameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package", kind: "message", T: SourceRepoPackage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceRepoPackageForComponentIssuePackageNameResponse {
    return new ListSourceRepoPackageForComponentIssuePackageNameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceRepoPackageForComponentIssuePackageNameResponse {
    return new ListSourceRepoPackageForComponentIssuePackageNameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceRepoPackageForComponentIssuePackageNameResponse {
    return new ListSourceRepoPackageForComponentIssuePackageNameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceRepoPackageForComponentIssuePackageNameResponse | PlainMessage<ListSourceRepoPackageForComponentIssuePackageNameResponse> | undefined, b: ListSourceRepoPackageForComponentIssuePackageNameResponse | PlainMessage<ListSourceRepoPackageForComponentIssuePackageNameResponse> | undefined): boolean {
    return proto3.util.equals(ListSourceRepoPackageForComponentIssuePackageNameResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SearchPackagesRequest
 */
export class SearchPackagesRequest extends Message<SearchPackagesRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string query = 2;
   */
  query = "";

  constructor(data?: PartialMessage<SearchPackagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SearchPackagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchPackagesRequest {
    return new SearchPackagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchPackagesRequest {
    return new SearchPackagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchPackagesRequest {
    return new SearchPackagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchPackagesRequest | PlainMessage<SearchPackagesRequest> | undefined, b: SearchPackagesRequest | PlainMessage<SearchPackagesRequest> | undefined): boolean {
    return proto3.util.equals(SearchPackagesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SearchPackagesResponse
 */
export class SearchPackagesResponse extends Message<SearchPackagesResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SourceRepoPackage packages = 1;
   */
  packages: SourceRepoPackage[] = [];

  constructor(data?: PartialMessage<SearchPackagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SearchPackagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "packages", kind: "message", T: SourceRepoPackage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchPackagesResponse {
    return new SearchPackagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchPackagesResponse {
    return new SearchPackagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchPackagesResponse {
    return new SearchPackagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchPackagesResponse | PlainMessage<SearchPackagesResponse> | undefined, b: SearchPackagesResponse | PlainMessage<SearchPackagesResponse> | undefined): boolean {
    return proto3.util.equals(SearchPackagesResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceRepoPackagesRequest
 */
export class ListSourceRepoPackagesRequest extends Message<ListSourceRepoPackagesRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  constructor(data?: PartialMessage<ListSourceRepoPackagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceRepoPackagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceRepoPackagesRequest {
    return new ListSourceRepoPackagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesRequest {
    return new ListSourceRepoPackagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesRequest {
    return new ListSourceRepoPackagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceRepoPackagesRequest | PlainMessage<ListSourceRepoPackagesRequest> | undefined, b: ListSourceRepoPackagesRequest | PlainMessage<ListSourceRepoPackagesRequest> | undefined): boolean {
    return proto3.util.equals(ListSourceRepoPackagesRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceRepoPackagesResponse
 */
export class ListSourceRepoPackagesResponse extends Message<ListSourceRepoPackagesResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SourceRepoPackage packages = 1;
   */
  packages: SourceRepoPackage[] = [];

  constructor(data?: PartialMessage<ListSourceRepoPackagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceRepoPackagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "packages", kind: "message", T: SourceRepoPackage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceRepoPackagesResponse {
    return new ListSourceRepoPackagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesResponse {
    return new ListSourceRepoPackagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesResponse {
    return new ListSourceRepoPackagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceRepoPackagesResponse | PlainMessage<ListSourceRepoPackagesResponse> | undefined, b: ListSourceRepoPackagesResponse | PlainMessage<ListSourceRepoPackagesResponse> | undefined): boolean {
    return proto3.util.equals(ListSourceRepoPackagesResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceRepoPackagesAndProposalsRequest
 */
export class ListSourceRepoPackagesAndProposalsRequest extends Message<ListSourceRepoPackagesAndProposalsRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  constructor(data?: PartialMessage<ListSourceRepoPackagesAndProposalsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceRepoPackagesAndProposalsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceRepoPackagesAndProposalsRequest {
    return new ListSourceRepoPackagesAndProposalsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesAndProposalsRequest {
    return new ListSourceRepoPackagesAndProposalsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesAndProposalsRequest {
    return new ListSourceRepoPackagesAndProposalsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceRepoPackagesAndProposalsRequest | PlainMessage<ListSourceRepoPackagesAndProposalsRequest> | undefined, b: ListSourceRepoPackagesAndProposalsRequest | PlainMessage<ListSourceRepoPackagesAndProposalsRequest> | undefined): boolean {
    return proto3.util.equals(ListSourceRepoPackagesAndProposalsRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ListSourceRepoPackagesAndProposalsResponse
 */
export class ListSourceRepoPackagesAndProposalsResponse extends Message<ListSourceRepoPackagesAndProposalsResponse> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.SourceRepoPackageAndProposal items = 1;
   */
  items: SourceRepoPackageAndProposal[] = [];

  constructor(data?: PartialMessage<ListSourceRepoPackagesAndProposalsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ListSourceRepoPackagesAndProposalsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: SourceRepoPackageAndProposal, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSourceRepoPackagesAndProposalsResponse {
    return new ListSourceRepoPackagesAndProposalsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesAndProposalsResponse {
    return new ListSourceRepoPackagesAndProposalsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSourceRepoPackagesAndProposalsResponse {
    return new ListSourceRepoPackagesAndProposalsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSourceRepoPackagesAndProposalsResponse | PlainMessage<ListSourceRepoPackagesAndProposalsResponse> | undefined, b: ListSourceRepoPackagesAndProposalsResponse | PlainMessage<ListSourceRepoPackagesAndProposalsResponse> | undefined): boolean {
    return proto3.util.equals(ListSourceRepoPackagesAndProposalsResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SourceRepoPackage
 */
export class SourceRepoPackage extends Message<SourceRepoPackage> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: string project_id = 3;
   */
  projectId = "";

  /**
   * @generated from field: string package_type = 4;
   */
  packageType = "";

  /**
   * @generated from field: string package_name = 5;
   */
  packageName = "";

  /**
   * @generated from field: string package_version = 6;
   */
  packageVersion = "";

  /**
   * @generated from field: bool obsolete = 7;
   */
  obsolete = false;

  constructor(data?: PartialMessage<SourceRepoPackage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SourceRepoPackage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "package_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "package_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "obsolete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SourceRepoPackage {
    return new SourceRepoPackage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SourceRepoPackage {
    return new SourceRepoPackage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SourceRepoPackage {
    return new SourceRepoPackage().fromJsonString(jsonString, options);
  }

  static equals(a: SourceRepoPackage | PlainMessage<SourceRepoPackage> | undefined, b: SourceRepoPackage | PlainMessage<SourceRepoPackage> | undefined): boolean {
    return proto3.util.equals(SourceRepoPackage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalPackage
 */
export class ProposalPackage extends Message<ProposalPackage> {
  /**
   * @generated from field: edgebit.platform.v1alpha.PackageRef package = 1;
   */
  package?: PackageRef;

  /**
   * @generated from field: bool requested = 2;
   */
  requested = false;

  /**
   * @generated from field: string realized_version = 3;
   */
  realizedVersion = "";

  constructor(data?: PartialMessage<ProposalPackage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalPackage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package", kind: "message", T: PackageRef },
    { no: 2, name: "requested", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "realized_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalPackage {
    return new ProposalPackage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalPackage {
    return new ProposalPackage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalPackage {
    return new ProposalPackage().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalPackage | PlainMessage<ProposalPackage> | undefined, b: ProposalPackage | PlainMessage<ProposalPackage> | undefined): boolean {
    return proto3.util.equals(ProposalPackage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PRResult
 */
export class PRResult extends Message<PRResult> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<PRResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PRResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PRResult {
    return new PRResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PRResult {
    return new PRResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PRResult {
    return new PRResult().fromJsonString(jsonString, options);
  }

  static equals(a: PRResult | PlainMessage<PRResult> | undefined, b: PRResult | PlainMessage<PRResult> | undefined): boolean {
    return proto3.util.equals(PRResult, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalResult
 */
export class ProposalResult extends Message<ProposalResult> {
  /**
   * @generated from oneof edgebit.platform.v1alpha.ProposalResult.kind
   */
  kind: {
    /**
     * @generated from field: edgebit.platform.v1alpha.PRResult pr_result = 1;
     */
    value: PRResult;
    case: "prResult";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ProposalResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pr_result", kind: "message", T: PRResult, oneof: "kind" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalResult {
    return new ProposalResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalResult {
    return new ProposalResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalResult {
    return new ProposalResult().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalResult | PlainMessage<ProposalResult> | undefined, b: ProposalResult | PlainMessage<ProposalResult> | undefined): boolean {
    return proto3.util.equals(ProposalResult, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.Proposal
 */
export class Proposal extends Message<Proposal> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: string project_id = 3;
   */
  projectId = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ProposalState state = 4;
   */
  state = ProposalState.UNSPECIFIED;

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalPackage packages = 5;
   */
  packages: ProposalPackage[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ProposalResult result = 8;
   */
  result?: ProposalResult;

  /**
   * @generated from field: string proposal_type = 9;
   */
  proposalType = "";

  constructor(data?: PartialMessage<Proposal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.Proposal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(ProposalState) },
    { no: 5, name: "packages", kind: "message", T: ProposalPackage, repeated: true },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "updated_at", kind: "message", T: Timestamp },
    { no: 8, name: "result", kind: "message", T: ProposalResult, opt: true },
    { no: 9, name: "proposal_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Proposal {
    return new Proposal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Proposal {
    return new Proposal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Proposal {
    return new Proposal().fromJsonString(jsonString, options);
  }

  static equals(a: Proposal | PlainMessage<Proposal> | undefined, b: Proposal | PlainMessage<Proposal> | undefined): boolean {
    return proto3.util.equals(Proposal, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SourceRepoPackageAndProposal
 */
export class SourceRepoPackageAndProposal extends Message<SourceRepoPackageAndProposal> {
  /**
   * @generated from field: edgebit.platform.v1alpha.SourceRepoPackage package = 1;
   */
  package?: SourceRepoPackage;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.Proposal proposal = 2;
   */
  proposal?: Proposal;

  constructor(data?: PartialMessage<SourceRepoPackageAndProposal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SourceRepoPackageAndProposal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "package", kind: "message", T: SourceRepoPackage },
    { no: 2, name: "proposal", kind: "message", T: Proposal, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SourceRepoPackageAndProposal {
    return new SourceRepoPackageAndProposal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SourceRepoPackageAndProposal {
    return new SourceRepoPackageAndProposal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SourceRepoPackageAndProposal {
    return new SourceRepoPackageAndProposal().fromJsonString(jsonString, options);
  }

  static equals(a: SourceRepoPackageAndProposal | PlainMessage<SourceRepoPackageAndProposal> | undefined, b: SourceRepoPackageAndProposal | PlainMessage<SourceRepoPackageAndProposal> | undefined): boolean {
    return proto3.util.equals(SourceRepoPackageAndProposal, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSourceRepoRequest
 */
export class GetSourceRepoRequest extends Message<GetSourceRepoRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  constructor(data?: PartialMessage<GetSourceRepoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSourceRepoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSourceRepoRequest {
    return new GetSourceRepoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSourceRepoRequest {
    return new GetSourceRepoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSourceRepoRequest {
    return new GetSourceRepoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSourceRepoRequest | PlainMessage<GetSourceRepoRequest> | undefined, b: GetSourceRepoRequest | PlainMessage<GetSourceRepoRequest> | undefined): boolean {
    return proto3.util.equals(GetSourceRepoRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetSourceRepoResponse
 */
export class GetSourceRepoResponse extends Message<GetSourceRepoResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.SourceRepo source_repo = 1;
   */
  sourceRepo?: SourceRepo;

  constructor(data?: PartialMessage<GetSourceRepoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetSourceRepoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source_repo", kind: "message", T: SourceRepo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSourceRepoResponse {
    return new GetSourceRepoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSourceRepoResponse {
    return new GetSourceRepoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSourceRepoResponse {
    return new GetSourceRepoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSourceRepoResponse | PlainMessage<GetSourceRepoResponse> | undefined, b: GetSourceRepoResponse | PlainMessage<GetSourceRepoResponse> | undefined): boolean {
    return proto3.util.equals(GetSourceRepoResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.SourceRepo
 */
export class SourceRepo extends Message<SourceRepo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: string target_branch = 3;
   */
  targetBranch = "";

  /**
   * @generated from field: bool active = 7;
   */
  active = false;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from oneof edgebit.platform.v1alpha.SourceRepo.details
   */
  details: {
    /**
     * @generated from field: edgebit.platform.v1alpha.GithubRepoDetails github_repo_details = 6;
     */
    value: GithubRepoDetails;
    case: "githubRepoDetails";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SourceRepo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.SourceRepo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_branch", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
    { no: 6, name: "github_repo_details", kind: "message", T: GithubRepoDetails, oneof: "details" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SourceRepo {
    return new SourceRepo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SourceRepo {
    return new SourceRepo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SourceRepo {
    return new SourceRepo().fromJsonString(jsonString, options);
  }

  static equals(a: SourceRepo | PlainMessage<SourceRepo> | undefined, b: SourceRepo | PlainMessage<SourceRepo> | undefined): boolean {
    return proto3.util.equals(SourceRepo, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GithubRepoDetails
 */
export class GithubRepoDetails extends Message<GithubRepoDetails> {
  /**
   * @generated from field: string owner = 1;
   */
  owner = "";

  /**
   * @generated from field: string repo = 2;
   */
  repo = "";

  constructor(data?: PartialMessage<GithubRepoDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GithubRepoDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GithubRepoDetails {
    return new GithubRepoDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GithubRepoDetails {
    return new GithubRepoDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GithubRepoDetails {
    return new GithubRepoDetails().fromJsonString(jsonString, options);
  }

  static equals(a: GithubRepoDetails | PlainMessage<GithubRepoDetails> | undefined, b: GithubRepoDetails | PlainMessage<GithubRepoDetails> | undefined): boolean {
    return proto3.util.equals(GithubRepoDetails, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PackageRef
 */
export class PackageRef extends Message<PackageRef> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: string project_id = 3;
   */
  projectId = "";

  /**
   * @generated from field: string package_name = 4;
   */
  packageName = "";

  /**
   * @generated from field: string package_version = 5;
   */
  packageVersion = "";

  constructor(data?: PartialMessage<PackageRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PackageRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "package_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackageRef {
    return new PackageRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackageRef {
    return new PackageRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackageRef {
    return new PackageRef().fromJsonString(jsonString, options);
  }

  static equals(a: PackageRef | PlainMessage<PackageRef> | undefined, b: PackageRef | PlainMessage<PackageRef> | undefined): boolean {
    return proto3.util.equals(PackageRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalRef
 */
export class ProposalRef extends Message<ProposalRef> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  constructor(data?: PartialMessage<ProposalRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalRef {
    return new ProposalRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalRef {
    return new ProposalRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalRef {
    return new ProposalRef().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalRef | PlainMessage<ProposalRef> | undefined, b: ProposalRef | PlainMessage<ProposalRef> | undefined): boolean {
    return proto3.util.equals(ProposalRef, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalChanges
 */
export class ProposalChanges extends Message<ProposalChanges> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string action = 3;
   */
  action = "";

  /**
   * @generated from field: string prev_version = 4;
   */
  prevVersion = "";

  /**
   * @generated from field: string version = 5;
   */
  version = "";

  /**
   * @generated from field: string type = 6;
   */
  type = "";

  /**
   * @generated from field: repeated string dependencies = 7;
   */
  dependencies: string[] = [];

  constructor(data?: PartialMessage<ProposalChanges>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalChanges";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "prev_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "dependencies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalChanges {
    return new ProposalChanges().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalChanges {
    return new ProposalChanges().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalChanges {
    return new ProposalChanges().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalChanges | PlainMessage<ProposalChanges> | undefined, b: ProposalChanges | PlainMessage<ProposalChanges> | undefined): boolean {
    return proto3.util.equals(ProposalChanges, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalCombinedPackage
 */
export class ProposalCombinedPackage extends Message<ProposalCombinedPackage> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string current_version = 2;
   */
  currentVersion = "";

  /**
   * @generated from field: string updated_version = 3;
   */
  updatedVersion = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalVulnerability fixed_vulnerabilities = 4;
   */
  fixedVulnerabilities: ProposalVulnerability[] = [];

  /**
   * @generated from field: string diff_url = 5;
   */
  diffUrl = "";

  /**
   * @generated from field: string web_url = 6;
   */
  webUrl = "";

  /**
   * @generated from field: bool upstream_tag_found = 7;
   */
  upstreamTagFound = false;

  /**
   * @generated from field: string branch_url = 8;
   */
  branchUrl = "";

  constructor(data?: PartialMessage<ProposalCombinedPackage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalCombinedPackage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "current_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updated_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fixed_vulnerabilities", kind: "message", T: ProposalVulnerability, repeated: true },
    { no: 5, name: "diff_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "web_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "upstream_tag_found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "branch_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalCombinedPackage {
    return new ProposalCombinedPackage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalCombinedPackage {
    return new ProposalCombinedPackage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalCombinedPackage {
    return new ProposalCombinedPackage().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalCombinedPackage | PlainMessage<ProposalCombinedPackage> | undefined, b: ProposalCombinedPackage | PlainMessage<ProposalCombinedPackage> | undefined): boolean {
    return proto3.util.equals(ProposalCombinedPackage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalVulnerability
 */
export class ProposalVulnerability extends Message<ProposalVulnerability> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string severity = 3;
   */
  severity = "";

  constructor(data?: PartialMessage<ProposalVulnerability>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalVulnerability";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "severity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalVulnerability {
    return new ProposalVulnerability().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalVulnerability {
    return new ProposalVulnerability().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalVulnerability {
    return new ProposalVulnerability().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalVulnerability | PlainMessage<ProposalVulnerability> | undefined, b: ProposalVulnerability | PlainMessage<ProposalVulnerability> | undefined): boolean {
    return proto3.util.equals(ProposalVulnerability, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalCallStackFrame
 */
export class ProposalCallStackFrame extends Message<ProposalCallStackFrame> {
  /**
   * @generated from field: string symbol_name = 1;
   */
  symbolName = "";

  /**
   * @generated from field: string package_name = 2;
   */
  packageName = "";

  /**
   * @generated from field: string filename = 3;
   */
  filename = "";

  /**
   * @generated from field: string relative_filename = 4;
   */
  relativeFilename = "";

  /**
   * @generated from field: int32 line_start = 5;
   */
  lineStart = 0;

  /**
   * @generated from field: int32 line_end = 6;
   */
  lineEnd = 0;

  constructor(data?: PartialMessage<ProposalCallStackFrame>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalCallStackFrame";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "symbol_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "relative_filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "line_start", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "line_end", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalCallStackFrame {
    return new ProposalCallStackFrame().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalCallStackFrame {
    return new ProposalCallStackFrame().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalCallStackFrame {
    return new ProposalCallStackFrame().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalCallStackFrame | PlainMessage<ProposalCallStackFrame> | undefined, b: ProposalCallStackFrame | PlainMessage<ProposalCallStackFrame> | undefined): boolean {
    return proto3.util.equals(ProposalCallStackFrame, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalChangeImpact
 */
export class ProposalChangeImpact extends Message<ProposalChangeImpact> {
  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalCallStackFrame call_stack = 1;
   */
  callStack: ProposalCallStackFrame[] = [];

  /**
   * @generated from field: string target_filename = 2;
   */
  targetFilename = "";

  /**
   * @generated from field: string target_relative_filename = 3;
   */
  targetRelativeFilename = "";

  /**
   * @generated from field: int32 target_line_start = 4;
   */
  targetLineStart = 0;

  /**
   * @generated from field: int32 target_line_end = 5;
   */
  targetLineEnd = 0;

  /**
   * @generated from field: string target_symbol = 6;
   */
  targetSymbol = "";

  /**
   * @generated from field: string target_package = 7;
   */
  targetPackage = "";

  /**
   * @generated from field: string target_analysis = 8;
   */
  targetAnalysis = "";

  /**
   * @generated from field: string target_category = 9;
   */
  targetCategory = "";

  /**
   * @generated from field: bool target_semantic = 10;
   */
  targetSemantic = false;

  /**
   * @generated from field: bool target_exported = 11;
   */
  targetExported = false;

  /**
   * @generated from field: string target_diff = 12;
   */
  targetDiff = "";

  /**
   * @generated from field: string target_branch_url = 13;
   */
  targetBranchUrl = "";

  constructor(data?: PartialMessage<ProposalChangeImpact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalChangeImpact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "call_stack", kind: "message", T: ProposalCallStackFrame, repeated: true },
    { no: 2, name: "target_filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_relative_filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "target_line_start", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "target_line_end", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "target_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "target_package", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "target_analysis", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "target_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "target_semantic", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "target_exported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "target_diff", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "target_branch_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalChangeImpact {
    return new ProposalChangeImpact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalChangeImpact {
    return new ProposalChangeImpact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalChangeImpact {
    return new ProposalChangeImpact().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalChangeImpact | PlainMessage<ProposalChangeImpact> | undefined, b: ProposalChangeImpact | PlainMessage<ProposalChangeImpact> | undefined): boolean {
    return proto3.util.equals(ProposalChangeImpact, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalAnalysisResult
 */
export class ProposalAnalysisResult extends Message<ProposalAnalysisResult> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string proposal_id = 2;
   */
  proposalId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp completed_at = 4;
   */
  completedAt?: Timestamp;

  /**
   * @generated from field: string revision = 5;
   */
  revision = "";

  /**
   * @generated from field: string job_id = 6;
   */
  jobId = "";

  /**
   * @generated from field: string error_message = 7;
   */
  errorMessage = "";

  /**
   * @generated from field: edgebit.platform.v1alpha.ProposalAnalysisAssets assets = 8;
   */
  assets?: ProposalAnalysisAssets;

  /**
   * string callsite_summary = 9; // deprecated
   *
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalChanges changes = 10;
   */
  changes: ProposalChanges[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalCombinedPackage packages = 11;
   */
  packages: ProposalCombinedPackage[] = [];

  /**
   * string symbol_summary = 12; // deprecated
   *
   * @generated from field: edgebit.platform.v1alpha.ProposalState state = 13;
   */
  state = ProposalState.UNSPECIFIED;

  /**
   * @generated from field: edgebit.platform.v1alpha.ProposalAnalysisMetrics analysis_metrics = 14;
   */
  analysisMetrics?: ProposalAnalysisMetrics;

  /**
   * @generated from field: map<string, double> resource_metrics = 15;
   */
  resourceMetrics: { [key: string]: number } = {};

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalChangeImpact impact_all = 16;
   */
  impactAll: ProposalChangeImpact[] = [];

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalChangeImpact impact_direct = 17;
   */
  impactDirect: ProposalChangeImpact[] = [];

  constructor(data?: PartialMessage<ProposalAnalysisResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalAnalysisResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "proposal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "completed_at", kind: "message", T: Timestamp },
    { no: 5, name: "revision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "assets", kind: "message", T: ProposalAnalysisAssets },
    { no: 10, name: "changes", kind: "message", T: ProposalChanges, repeated: true },
    { no: 11, name: "packages", kind: "message", T: ProposalCombinedPackage, repeated: true },
    { no: 13, name: "state", kind: "enum", T: proto3.getEnumType(ProposalState) },
    { no: 14, name: "analysis_metrics", kind: "message", T: ProposalAnalysisMetrics },
    { no: 15, name: "resource_metrics", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
    { no: 16, name: "impact_all", kind: "message", T: ProposalChangeImpact, repeated: true },
    { no: 17, name: "impact_direct", kind: "message", T: ProposalChangeImpact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalAnalysisResult {
    return new ProposalAnalysisResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalAnalysisResult {
    return new ProposalAnalysisResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalAnalysisResult {
    return new ProposalAnalysisResult().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalAnalysisResult | PlainMessage<ProposalAnalysisResult> | undefined, b: ProposalAnalysisResult | PlainMessage<ProposalAnalysisResult> | undefined): boolean {
    return proto3.util.equals(ProposalAnalysisResult, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalAnalysisMetrics
 */
export class ProposalAnalysisMetrics extends Message<ProposalAnalysisMetrics> {
  /**
   * @generated from field: string schema = 1;
   */
  schema = "";

  /**
   * @generated from field: map<string, uint32> counters = 2;
   */
  counters: { [key: string]: number } = {};

  /**
   * @generated from field: map<string, edgebit.platform.v1alpha.ProposalAnalysisMetricsAttemptFailure> attempts_failures = 3;
   */
  attemptsFailures: { [key: string]: ProposalAnalysisMetricsAttemptFailure } = {};

  constructor(data?: PartialMessage<ProposalAnalysisMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalAnalysisMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schema", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "counters", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 13 /* ScalarType.UINT32 */} },
    { no: 3, name: "attempts_failures", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ProposalAnalysisMetricsAttemptFailure} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalAnalysisMetrics {
    return new ProposalAnalysisMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalAnalysisMetrics {
    return new ProposalAnalysisMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalAnalysisMetrics {
    return new ProposalAnalysisMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalAnalysisMetrics | PlainMessage<ProposalAnalysisMetrics> | undefined, b: ProposalAnalysisMetrics | PlainMessage<ProposalAnalysisMetrics> | undefined): boolean {
    return proto3.util.equals(ProposalAnalysisMetrics, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalAnalysisMetricsAttemptFailure
 */
export class ProposalAnalysisMetricsAttemptFailure extends Message<ProposalAnalysisMetricsAttemptFailure> {
  /**
   * @generated from field: uint32 attempts = 1;
   */
  attempts = 0;

  /**
   * @generated from field: map<string, uint32> failures = 2;
   */
  failures: { [key: string]: number } = {};

  constructor(data?: PartialMessage<ProposalAnalysisMetricsAttemptFailure>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalAnalysisMetricsAttemptFailure";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "attempts", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "failures", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 13 /* ScalarType.UINT32 */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalAnalysisMetricsAttemptFailure {
    return new ProposalAnalysisMetricsAttemptFailure().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalAnalysisMetricsAttemptFailure {
    return new ProposalAnalysisMetricsAttemptFailure().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalAnalysisMetricsAttemptFailure {
    return new ProposalAnalysisMetricsAttemptFailure().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalAnalysisMetricsAttemptFailure | PlainMessage<ProposalAnalysisMetricsAttemptFailure> | undefined, b: ProposalAnalysisMetricsAttemptFailure | PlainMessage<ProposalAnalysisMetricsAttemptFailure> | undefined): boolean {
    return proto3.util.equals(ProposalAnalysisMetricsAttemptFailure, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalAnalysisAssets
 */
export class ProposalAnalysisAssets extends Message<ProposalAnalysisAssets> {
  /**
   * @generated from field: string svg_url = 1;
   */
  svgUrl = "";

  /**
   * @generated from field: string png_url = 2;
   */
  pngUrl = "";

  /**
   * @generated from field: string overall_behavior = 3;
   */
  overallBehavior = "";

  /**
   * @generated from field: repeated edgebit.platform.v1alpha.ProposalAnalysisMessage symbol_behavior = 4;
   */
  symbolBehavior: ProposalAnalysisMessage[] = [];

  /**
   * ProposalAnalysisResolverMetrics job_metrics = 6;
   * map<string, double> resource_metrics = 7;
   *
   * @generated from field: string safety_score = 5;
   */
  safetyScore = "";

  constructor(data?: PartialMessage<ProposalAnalysisAssets>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalAnalysisAssets";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "svg_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "png_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "overall_behavior", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol_behavior", kind: "message", T: ProposalAnalysisMessage, repeated: true },
    { no: 5, name: "safety_score", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalAnalysisAssets {
    return new ProposalAnalysisAssets().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalAnalysisAssets {
    return new ProposalAnalysisAssets().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalAnalysisAssets {
    return new ProposalAnalysisAssets().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalAnalysisAssets | PlainMessage<ProposalAnalysisAssets> | undefined, b: ProposalAnalysisAssets | PlainMessage<ProposalAnalysisAssets> | undefined): boolean {
    return proto3.util.equals(ProposalAnalysisAssets, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ProposalAnalysisMessage
 */
export class ProposalAnalysisMessage extends Message<ProposalAnalysisMessage> {
  /**
   * @generated from field: string evaluation = 1;
   */
  evaluation = "";

  /**
   * @generated from field: string kind = 2;
   */
  kind = "";

  /**
   * @generated from field: string filename = 3;
   */
  filename = "";

  /**
   * @generated from field: string symbol_name = 4;
   */
  symbolName = "";

  /**
   * @generated from field: string semantic_change = 5;
   */
  semanticChange = "";

  constructor(data?: PartialMessage<ProposalAnalysisMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ProposalAnalysisMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "evaluation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "semantic_change", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProposalAnalysisMessage {
    return new ProposalAnalysisMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProposalAnalysisMessage {
    return new ProposalAnalysisMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProposalAnalysisMessage {
    return new ProposalAnalysisMessage().fromJsonString(jsonString, options);
  }

  static equals(a: ProposalAnalysisMessage | PlainMessage<ProposalAnalysisMessage> | undefined, b: ProposalAnalysisMessage | PlainMessage<ProposalAnalysisMessage> | undefined): boolean {
    return proto3.util.equals(ProposalAnalysisMessage, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProposalOverviewRequest
 */
export class GetProposalOverviewRequest extends Message<GetProposalOverviewRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: string proposal_id = 3;
   */
  proposalId = "";

  constructor(data?: PartialMessage<GetProposalOverviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProposalOverviewRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "proposal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProposalOverviewRequest {
    return new GetProposalOverviewRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProposalOverviewRequest {
    return new GetProposalOverviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProposalOverviewRequest {
    return new GetProposalOverviewRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProposalOverviewRequest | PlainMessage<GetProposalOverviewRequest> | undefined, b: GetProposalOverviewRequest | PlainMessage<GetProposalOverviewRequest> | undefined): boolean {
    return proto3.util.equals(GetProposalOverviewRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.GetProposalOverviewResponse
 */
export class GetProposalOverviewResponse extends Message<GetProposalOverviewResponse> {
  /**
   * @generated from field: edgebit.platform.v1alpha.Proposal proposal = 1;
   */
  proposal?: Proposal;

  /**
   * @generated from field: optional edgebit.platform.v1alpha.ProposalAnalysisResult pending_analysis_result = 2;
   */
  pendingAnalysisResult?: ProposalAnalysisResult;

  /**
   * @generated from field: edgebit.platform.v1alpha.ProposalAnalysisResult latest_analysis_result = 3;
   */
  latestAnalysisResult?: ProposalAnalysisResult;

  constructor(data?: PartialMessage<GetProposalOverviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.GetProposalOverviewResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proposal", kind: "message", T: Proposal },
    { no: 2, name: "pending_analysis_result", kind: "message", T: ProposalAnalysisResult, opt: true },
    { no: 3, name: "latest_analysis_result", kind: "message", T: ProposalAnalysisResult },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProposalOverviewResponse {
    return new GetProposalOverviewResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProposalOverviewResponse {
    return new GetProposalOverviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProposalOverviewResponse {
    return new GetProposalOverviewResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProposalOverviewResponse | PlainMessage<GetProposalOverviewResponse> | undefined, b: GetProposalOverviewResponse | PlainMessage<GetProposalOverviewResponse> | undefined): boolean {
    return proto3.util.equals(GetProposalOverviewResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ScanRepoRequest
 */
export class ScanRepoRequest extends Message<ScanRepoRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  constructor(data?: PartialMessage<ScanRepoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ScanRepoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScanRepoRequest {
    return new ScanRepoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScanRepoRequest {
    return new ScanRepoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScanRepoRequest {
    return new ScanRepoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ScanRepoRequest | PlainMessage<ScanRepoRequest> | undefined, b: ScanRepoRequest | PlainMessage<ScanRepoRequest> | undefined): boolean {
    return proto3.util.equals(ScanRepoRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.ScanRepoResponse
 */
export class ScanRepoResponse extends Message<ScanRepoResponse> {
  constructor(data?: PartialMessage<ScanRepoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.ScanRepoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScanRepoResponse {
    return new ScanRepoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScanRepoResponse {
    return new ScanRepoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScanRepoResponse {
    return new ScanRepoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ScanRepoResponse | PlainMessage<ScanRepoResponse> | undefined, b: ScanRepoResponse | PlainMessage<ScanRepoResponse> | undefined): boolean {
    return proto3.util.equals(ScanRepoResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AnalyzeProposalRequest
 */
export class AnalyzeProposalRequest extends Message<AnalyzeProposalRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: string proposal_id = 3;
   */
  proposalId = "";

  constructor(data?: PartialMessage<AnalyzeProposalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AnalyzeProposalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "proposal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnalyzeProposalRequest {
    return new AnalyzeProposalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnalyzeProposalRequest {
    return new AnalyzeProposalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnalyzeProposalRequest {
    return new AnalyzeProposalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AnalyzeProposalRequest | PlainMessage<AnalyzeProposalRequest> | undefined, b: AnalyzeProposalRequest | PlainMessage<AnalyzeProposalRequest> | undefined): boolean {
    return proto3.util.equals(AnalyzeProposalRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.AnalyzeProposalResponse
 */
export class AnalyzeProposalResponse extends Message<AnalyzeProposalResponse> {
  constructor(data?: PartialMessage<AnalyzeProposalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.AnalyzeProposalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnalyzeProposalResponse {
    return new AnalyzeProposalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnalyzeProposalResponse {
    return new AnalyzeProposalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnalyzeProposalResponse {
    return new AnalyzeProposalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AnalyzeProposalResponse | PlainMessage<AnalyzeProposalResponse> | undefined, b: AnalyzeProposalResponse | PlainMessage<AnalyzeProposalResponse> | undefined): boolean {
    return proto3.util.equals(AnalyzeProposalResponse, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PostProposalPRRequest
 */
export class PostProposalPRRequest extends Message<PostProposalPRRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string repo_id = 2;
   */
  repoId = "";

  /**
   * @generated from field: string proposal_id = 3;
   */
  proposalId = "";

  /**
   * @generated from field: string result_id = 4;
   */
  resultId = "";

  constructor(data?: PartialMessage<PostProposalPRRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PostProposalPRRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "repo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "proposal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "result_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostProposalPRRequest {
    return new PostProposalPRRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostProposalPRRequest {
    return new PostProposalPRRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostProposalPRRequest {
    return new PostProposalPRRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PostProposalPRRequest | PlainMessage<PostProposalPRRequest> | undefined, b: PostProposalPRRequest | PlainMessage<PostProposalPRRequest> | undefined): boolean {
    return proto3.util.equals(PostProposalPRRequest, a, b);
  }
}

/**
 * @generated from message edgebit.platform.v1alpha.PostProposalPRResponse
 */
export class PostProposalPRResponse extends Message<PostProposalPRResponse> {
  /**
   * @generated from field: string pr_url = 1;
   */
  prUrl = "";

  constructor(data?: PartialMessage<PostProposalPRResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "edgebit.platform.v1alpha.PostProposalPRResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pr_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostProposalPRResponse {
    return new PostProposalPRResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostProposalPRResponse {
    return new PostProposalPRResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostProposalPRResponse {
    return new PostProposalPRResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PostProposalPRResponse | PlainMessage<PostProposalPRResponse> | undefined, b: PostProposalPRResponse | PlainMessage<PostProposalPRResponse> | undefined): boolean {
    return proto3.util.equals(PostProposalPRResponse, a, b);
  }
}

