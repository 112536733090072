// import * as React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import FormattedTimestamp from "./FormattedTimestamp";
import { PrettySBOMFormat } from "./PrettySBOMFormat";
import { ComponentTags } from "../components/ComponentTags";
import { SBOMListItem } from "../features/sbomListSlice";
import { ComponentTag } from "../features/componentDetailSlice";

export function SBOMTable(props: {
  sboms: SBOMListItem[] | null;
  showComponent?: boolean;
  showMachine?: boolean;
  showTags?: boolean;
  tags?: ComponentTag[] | null;
}) {
  // Group Component Tags by SBOM ID
  if (props.tags && props.sboms && props.showTags) {
    var tagsBySBOMId: { [key: string]: string[] } = {};
    props.tags.forEach((tag: ComponentTag) => {
      if (!tagsBySBOMId[tag.sbomId]) {
        tagsBySBOMId[tag.sbomId] = [];
      }
      tagsBySBOMId[tag.sbomId].push(tag.name);
    });
  }

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Artifact ID</TableCell>
              <TableCell>Format</TableCell>
              {props.showComponent && <TableCell>Component</TableCell>}
              {props.showMachine && <TableCell>Source Machine</TableCell>}
              {props.showTags && <TableCell>Tags</TableCell>}
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.sboms?.map((sbom: SBOMListItem) => (
              <TableRow key={sbom.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Link component={NavLink} to={"/sboms/" + sbom.id}>
                    {(sbom.machineId ? "Realtime " : "Build ") + sbom.id.substring(0, 6)}
                  </Link>
                </TableCell>
                <TableCell>
                  <PrettySBOMFormat format={sbom.format} />
                </TableCell>
                {props.showComponent && (
                  <TableCell>
                    <Link component={NavLink} to={"/components/" + sbom.componentRef?.id}>
                      {sbom.componentRef?.displayName}
                    </Link>
                  </TableCell>
                )}
                {props.showMachine && (
                  <TableCell>
                    {sbom.machineId && (
                      <Link component={NavLink} to={"/machines/" + sbom.machineRef?.id}>
                        {sbom.machineRef?.hostname}
                      </Link>
                    )}
                  </TableCell>
                )}
                {props.showTags && (
                  <TableCell>
                    {tagsBySBOMId && tagsBySBOMId[sbom.id] ? (
                      <ComponentTags tags={tagsBySBOMId[sbom.id]} />
                    ) : (
                      <Typography variant="body2" gutterBottom sx={{ color: "#999" }}>
                        No Tags
                      </Typography>
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <FormattedTimestamp timestamp={sbom.createdAt} />
                </TableCell>
              </TableRow>
            ))}
            {props.sboms?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      You don't have any SBOMs
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      To upload an SBOM install an agent or configure CI
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: "10px", marginBottom: "20px" }}
                      href={"https://edgebit.io/docs/0.x/install-agent/"}
                      target="_blank"
                    >
                      Install on Linux
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "20px",
                        marginLeft: "20px",
                      }}
                      href={"https://edgebit.io/docs/0.x/install-kubernetes/"}
                      target="_blank"
                    >
                      Install on Kubernetes
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
