import { ReactElement, createContext, useContext, useEffect, useState } from "react";
import { useClient } from "../api/client";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";

export const FlagContext = createContext<{
  flags: string[];
}>({
  flags: [],
});

export function FeatureFlagGate(props: {
  children?: ReactElement | null;
  flag: string;
  fallback?: ReactElement | null;
}) {
  const { children, flag, fallback } = props;
  const flags = useContext(FlagContext).flags;
  const isFlagActive = flags.includes(flag);

  return isFlagActive ? (children as ReactElement | null) : (fallback as ReactElement | null);
}

export function FeatureFlagGateProvider(props: { children?: any }) {
  const apiClient = useClient(EdgeBitPublicAPIService);
  const [flags, setFlags] = useState<string[]>([]);

  useEffect(() => {
    apiClient
      .listEnabledFeatures({})
      .then(
        (res: any) => {
          setFlags(res.flags);
        },
        (err: any) => {
          console.error("Error fetching flags", err);
        },
      )
      .catch((err: any) => {
        console.log(err);
      });
  }, [apiClient]);

  return <FlagContext.Provider value={{ flags: flags }}>{props.children}</FlagContext.Provider>;
}
