import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Code, ConnectError } from "@bufbuild/connect";
import { Box, Typography } from "@mui/material";

import { useClient } from "../api/client";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";

export const JiraCompleteInstall = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const [next, setNext] = useState<string | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const errMsg = urlParams.get("error");
        if (errMsg) {
          throw new Error(errMsg);
        }

        const state = urlParams.get("state");
        if (!state) {
          throw new Error("Missing state");
        }

        await client.completeJiraInstall({
          state: state,
        });

        setNext(`/admin/integrations/`);
      } catch (err) {
        if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
          setError(err.rawMessage);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Unknown error");
        }
        setNext(`/admin/integrations/`);
      }
    };

    fetch();
  }, [client]);

  useEffect(() => {
    if (next && !error) {
      navigate(next);
    }
  }, [next, error, navigate]);

  useEffect(() => {
    document.title = "Completing Jira installation...";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
        <Box
          sx={{
            textAlign: "center",
            background: "rgb(255, 255, 255)",
            borderRadius: "5px",
            padding: "40px 40px 30px",
            maxWidth: "600px",
          }}
        >
          {error ? (
            <>
              <Typography variant="h4" gutterBottom style={{ color: "#000" }}>
                Error: {error}
              </Typography>
              <EdgeBitPrimaryButton
                variant="contained"
                color="primary"
                onClick={() => navigate(next as string)}
                sx={{ marginTop: "20px" }}
              >
                Go back
              </EdgeBitPrimaryButton>
            </>
          ) : (
            <Typography variant="h4" gutterBottom style={{ color: "#000" }}>
              Completing Jira installation...
            </Typography>
          )}
        </Box>
      </Box>
    </BodyWrapper>
  );
};
