import { Grid, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ProjectContext } from "../App";
import { downloadFile } from "../api/files";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import FormattedTimestamp from "../components/FormattedTimestamp";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import { OverviewBoxItem } from "../components/OverviewBoxItem";
import { OverviewBoxTextItem } from "../components/OverviewBoxTextItem";
import { PackageType } from "../components/PackageType";
import { PrettySBOMFormat } from "../components/PrettySBOMFormat";
import SBOMDetailPackageTable from "../components/SBOMDetailPackageTable";
import {
  ExternalImageID,
  SBOMResponse,
  fetchSBOMDetails,
  fetchSBOMDownloadUrl,
  fetchSBOMInventory,
  selectSBOMConnectivity,
  selectSBOMDetail,
  selectSBOMInventory,
} from "../features/sbomDetailSlice";
import { ContainerArtifactInfo } from "../components/ContainerArtifactInfo";

export const SBOMDetail = () => {
  const { sbomId } = useParams();
  const project = useContext(ProjectContext);

  const dispatch = useAppDispatch();
  const sbomDetail = useAppSelector(selectSBOMDetail);
  const sbomInventory = useAppSelector(selectSBOMInventory);
  const connectivity = useAppSelector(selectSBOMConnectivity);
  const navigate = useNavigate();

  useEffect(() => {
    if (project && sbomId) {
      dispatch(fetchSBOMDetails({ projectId: project.id, sbomId }));
      dispatch(fetchSBOMInventory({ projectId: project.id, sbomId }));
    }
  }, [dispatch, project, sbomId]);

  useEffect(() => {
    if (sbomDetail) {
      document.title = sbomDetail.id + " / SBOMs";
    }
  }, [sbomDetail]);

  // Redirect if no SBOM is found
  useEffect(() => {
    if (!connectivity) {
      navigate("/sboms/");
    }
  }, [connectivity, navigate]);

  const handleSBOMDownload = (sbomId: string) => {
    if (project && sbomId) {
      dispatch(fetchSBOMDownloadUrl({ projectId: project.id, sbomId })).then((action) => {
        if (fetchSBOMDownloadUrl.fulfilled.match(action)) {
          downloadFile(action.payload);
        } else {
          console.log("Error fetching SBOM download URL");
        }
      });
    }
  };

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        SBOMs /{" "}
        {sbomDetail &&
          sbomDetail.id &&
          (sbomDetail.machineId ? "Realtime" : "Build") + " " + sbomDetail.id.substring(0, 6)}
      </Typography>

      {sbomDetail && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBox title="Source Artifact">
              <Grid container spacing={2}>
                {!sbomDetail.machineId && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      {sbomDetail.pullRequest ? (
                        <OverviewBoxTextItem
                          label="Source Pull Request"
                          emptyMessage="No pull request provided"
                          text={sbomDetail.pullRequest}
                        />
                      ) : (
                        <OverviewBoxTextItem
                          label="Source Repo"
                          emptyMessage="No source repo provided"
                          text={sbomDetail.sourceRepoUrl}
                        />
                      )}
                      <OverviewBoxTextItem
                        label="Source Commit"
                        emptyMessage="No commit provided"
                        text={sbomDetail.sourceCommitId}
                      />
                      <OverviewBoxItem
                        label="Image Details"
                        emptyMessage="No image details provided"
                        isEmpty={!sbomDetail.image}
                      >
                        <ReleaseArtifactInfo sbom={sbomDetail} />
                      </OverviewBoxItem>
                      {sbomDetail.imageIds.length > 0 && (
                        <OverviewBoxItem
                          label="Image IDs"
                          emptyMessage="No IDs provided"
                          isEmpty={sbomDetail.imageIds.length === 0}
                        >
                          <ImageExternalIDList imageIds={sbomDetail.imageIds} />
                        </OverviewBoxItem>
                      )}
                    </Grid>
                  </React.Fragment>
                )}
                {sbomDetail.machineId && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        Machine
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        <Link component={NavLink} to={"/machines/" + sbomDetail.machineRef?.id}>
                          {sbomDetail.machineRef?.hostname}
                        </Link>
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </OverviewBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <OverviewBox title="Metadata">
              <Grid container spacing={0}>
                {!sbomDetail.machineId && (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        SBOM ID
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        {sbomDetail.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        Component
                      </Typography>
                      {sbomDetail.componentRef ? (
                        <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                          <Link component={NavLink} to={"/components/" + sbomDetail.componentRef.id}>
                            {sbomDetail.componentRef.displayName}
                          </Link>
                        </Typography>
                      ) : (
                        <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                          None
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        SBOM Type
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        Build Artifact
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        Created
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        <FormattedTimestamp timestamp={sbomDetail.createdAt} />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        SBOM Format
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        <PrettySBOMFormat format={sbomDetail.format} />
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "0px", marginBottom: "5px" }}
                        onClick={() => handleSBOMDownload(sbomDetail.id)}
                      >
                        Download SBOM
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
                {sbomDetail.machineId && (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        SBOM ID
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        {sbomDetail.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        Component
                      </Typography>
                      {sbomDetail.componentRef ? (
                        <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                          <Link component={NavLink} to={"/components/" + sbomDetail.componentRef.id}>
                            {sbomDetail.componentRef.displayName}
                          </Link>
                        </Typography>
                      ) : (
                        <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                          None
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        SBOM Type
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        Realtime Artifact
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        Created
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        <FormattedTimestamp timestamp={sbomDetail.createdAt} />
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ fontSize: "14px", marginTop: "10px" }}>
                        SBOM Format
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
                        <PrettySBOMFormat format={sbomDetail.format} />
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "0px", marginBottom: "5px" }}
                        onClick={() => handleSBOMDownload(sbomDetail.id)}
                      >
                        Download SBOM
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </OverviewBox>
          </Grid>
        </Grid>
      )}

      <Typography variant="h4" gutterBottom>
        Packages
      </Typography>
      <SBOMDetailPackageTable packages={sbomInventory?.packages} relationships={sbomInventory?.relationships} />
    </BodyWrapperProjectScoped>
  );
};

type ReleaseArtifactInfoProps = {
  sbom: SBOMResponse;
};

const ReleaseArtifactInfo = (props: ReleaseArtifactInfoProps) => {
  const { image } = props.sbom;

  if (!image) {
    return <Box>No image provided</Box>;
  }

  switch (image.case) {
    case "docker":
      return (
        <ContainerArtifactInfo
          imageIds={props.sbom.imageIds.length > 0 ? props.sbom.imageIds : props.sbom.imageId}
          imageName={image.value.tag}
        />
      );
    case "ami":
      return <PackageType type={"base_os"} name={false} />;
    default:
      return <Box>{image?.case}</Box>;
  }
};

const ImageExternalIDList = (props: { imageIds: ExternalImageID[] }) => {
  return (
    <Box>
      {props.imageIds.map((imageId) => (
        <ImageExternalID key={imageId.id} imageId={imageId} />
      ))}
    </Box>
  );
};

const ImageExternalID = (props: { imageId: ExternalImageID }) => {
  return (
    <Box sx={{ display: "inline" }}>
      <Box sx={{ fontSize: "10px", color: "#999", fontFamily: "monospace" }}>{props.imageId.id}</Box>
    </Box>
  );
};
