import React, { ChangeEvent, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  SelectChangeEvent,
  LinearProgress,
  Link,
  Box,
  Grid,
} from "@mui/material";
import { useClient } from "../api/client";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { FileUploadError, uploadFile } from "../api/files";
import { EdgeBitTextField } from "./EdgeBitTextField";
import { NavLink } from "react-router-dom";
import {
  AMI,
  Component,
  DockerImage,
  GenericImage,
  Image,
  SBOMFormat,
  UserUploadedMetadataSBOM,
} from "../pb/edgebit/platform/v1alpha/platform_pb";
import { parseLabelString } from "./ComponentSettings";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";

// SBOM types
const sbomTypes: { [key: string]: SBOMFormat } = {
  syft: SBOMFormat.SBOM_FORMAT_SYFT,
  "syft.json": SBOMFormat.SBOM_FORMAT_SYFT,
  spdx: SBOMFormat.SBOM_FORMAT_SPDX_JSON,
  "spdx.json": SBOMFormat.SBOM_FORMAT_SPDX_JSON,
  cyclonedx: SBOMFormat.SBOM_FORMAT_CYCLONEDX_JSON,
  "cdx.json": SBOMFormat.SBOM_FORMAT_CYCLONEDX_JSON,
};

// Image Types
const imageTypes = [
  { value: "none", displayName: "None" },
  { value: "docker", displayName: "Docker/OCI" },
  // { value: "ami", displayName: "AMI" },
];

export function UploadSBOMButton(props: { projectId: string; givenComponentId?: string }) {
  const { projectId, givenComponentId } = props;

  const client = useClient(EdgeBitPublicAPIService);
  const [uploadSBOMFormOpen, setUploadSBOMFormOpen] = useState<boolean>(false);
  const [uploadSBOMFormError, setUploadSBOMFormError] = useState<string | null>(null);
  const [sbomType, setSbomType] = useState<SBOMFormat>(SBOMFormat.SBOM_FORMAT_UNSPECIFIED);
  const [sbomFile, setSbomFile] = useState<File | null>(null);
  const [imageId, setImageId] = useState<string>("");
  const [repoDigests, setRepoDigests] = useState<string>("");
  const [imageType, setImageType] = useState<string>("");
  const [labels, setLabels] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadStatusMessage, setUploadStatusMessage] = useState("");
  const [amiName, setAmiName] = useState<string>("");
  const [dockerTag, setDockerTag] = useState<string>("");
  const [amiTags, setAmiTags] = useState<string[]>([]);
  const [uploadedSbomId, setUploadedSbomId] = useState<string>("");
  const [sbomTags, setSbomTags] = useState<string[]>([]);
  const [componentId, setComponentId] = useState<string>("");
  const [components, setComponents] = useState<Component[] | undefined>(undefined);

  const resetFormState = () => {
    setIsUploaded(false);
    setUploadStatusMessage("");
    setLabels("");
    setImageType("");
    setImageId("");
    setSbomType(SBOMFormat.SBOM_FORMAT_UNSPECIFIED);
    setSbomFile(null);
    setUploadedFileName("");
    setIsUploading(false);
    setAmiName("");
    setDockerTag("");
    setUploadedSbomId("");
    setUploadSBOMFormError("");
    setSbomTags([]);

    if (!givenComponentId) {
      setComponentId("");
    } else {
      setComponentId(givenComponentId);
    }
  };

  const openUploadSBOMForm = () => {
    setUploadSBOMFormOpen(true);
    resetFormState();
    setImageType("docker");

    if (!givenComponentId) {
      setComponentId("");

      client.listComponents({ projectId: projectId }).then(
        (res) => {
          setComponents(res.components);
        },
        (err) => {
          console.log(err);
          setUploadSBOMFormError("");
        },
      );
    } else {
      setComponentId(givenComponentId);
    }
  };

  const closeUploadSBOMForm = () => {
    setUploadSBOMFormOpen(false);
    resetFormState();
  };

  const handleImageTypeChange = (event: SelectChangeEvent) => {
    setImageType(event.target.value as string);
  };

  const handleImageIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageId(event.target.value);
  };

  const handleRepoDigestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepoDigests(event.target.value);
  };

  // TODO: Labels validation
  const handleLabelsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabels(event.target.value);
  };

  const handleDockerImageTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDockerTag(event.target.value);
  };

  const handleAmiNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmiName(event.target.value);
  };

  const handleAmiTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var tagArray = event.target.value.split(",");
    setAmiTags(tagArray);
  };

  const handleSbomTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var tagArray = event.target.value.split(",");
    setSbomTags(tagArray);
  };

  const handleComponentIdChange = (event: any) => {
    setComponentId(event.target.value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUploadSBOMFormError("");
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // Check file format
      if (file) {
        let fileExtension = "";

        if (file.name.endsWith(".syft.json")) {
          fileExtension = "syft.json";
        } else if (file.name.endsWith(".spdx.json")) {
          fileExtension = "spdx.json";
        } else if (file.name.endsWith(".syft")) {
          fileExtension = "syft";
        } else if (file.name.endsWith(".spdx")) {
          fileExtension = "spdx";
        } else if (file.name.endsWith(".cdx.json")) {
          fileExtension = "cdx.json";
        }

        if (fileExtension) {
          const sbomType = sbomTypes[fileExtension] || SBOMFormat.SBOM_FORMAT_UNSPECIFIED;

          setSbomType(sbomType);
          setSbomFile(file);
          setUploadedFileName(file.name);
        } else {
          // Handle the case where the file format is not supported
          setUploadSBOMFormError("Invalid File Format");
          setSbomFile(null);
          setUploadedFileName("");
        }
      }
    } else {
      setSbomFile(null);
      setUploadedFileName("");
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const uploadSBOM = async () => {
    setUploadSBOMFormError("");

    if (!sbomFile) {
      setUploadSBOMFormError("Please select a file to upload.");
      setIsUploading(false);
      return;
    }

    let image;
    if (imageType === "generic") {
      const genericImage = new GenericImage({});
      image = new Image({ kind: { value: genericImage, case: "generic" } });
    } else if (imageType === "docker") {
      const dockerImage = new DockerImage({ tag: dockerTag, repoDigests: repoDigests.split(",") });
      image = new Image({ kind: { value: dockerImage, case: "docker" } });
    } else if (imageType === "ami") {
      const amiImage = new AMI({ name: amiName, tags: amiTags });
      image = new Image({ kind: { value: amiImage, case: "ami" } });
    }

    let userUploadedMetadataSBOM;
    if (image) {
      userUploadedMetadataSBOM = new UserUploadedMetadataSBOM({
        projectId: projectId,
        componentId: componentId,
        sbomFormat: sbomType,
        labels: parseLabelString(labels || ""),
        imageId: imageId,
        image: image,
        sbomTags: sbomTags,
      });
    } else {
      userUploadedMetadataSBOM = new UserUploadedMetadataSBOM({
        projectId: projectId,
        componentId: componentId,
        sbomFormat: sbomType,
        labels: parseLabelString(labels || ""),
        imageId: imageId,
        sbomTags: sbomTags,
      });
    }

    const metadataJson = userUploadedMetadataSBOM.toJsonString();
    const metadataBlob = new Blob([metadataJson + "\n"]);
    const fileBlob = new Blob([sbomFile]);
    const postData = new Blob([metadataBlob, fileBlob]);

    setIsUploading(true);

    try {
      const res = await client.getSBOMUploadUrl({
        projectId: projectId,
        componentId: componentId,
      });

      const sbomID = await uploadFile(res.sbomUploadPath, postData);
      setUploadedSbomId(sbomID);
      setUploadStatusMessage("File uploaded successfully.");
      setIsUploaded(true);
      setIsUploading(false);
    } catch (err: any) {
      console.log("SBOM upload failed", err);

      if (err instanceof FileUploadError) {
        setUploadSBOMFormError(`Upload failed: ${err.message}`);
      } else {
        setUploadSBOMFormError("Failed to upload file.");
      }

      setIsUploading(false);
    }
  };

  return (
    <>
      <EdgeBitPrimaryButton type="submit" variant="outlined" size="medium" onClick={openUploadSBOMForm}>
        Upload SBOM
      </EdgeBitPrimaryButton>

      <Dialog
        open={uploadSBOMFormOpen}
        onClose={closeUploadSBOMForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { minWidth: "500px" } }}
      >
        <DialogTitle>Upload New SBOM</DialogTitle>
        <DialogContent>
          {!isUploaded && (
            <>
              <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                <Button variant="outlined" size="large" onClick={handleFileButtonClick}>
                  Choose SBOM File
                  <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    accept=".syft, .spdx, .json"
                    onChange={handleFileChange}
                  />
                </Button>

                {uploadedFileName && !uploadStatusMessage && (
                  <Typography
                    sx={{
                      marginLeft: "14px",
                      fontSize: "calc(10px + 0.25vw)",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {uploadedFileName || "No file selected"}
                  </Typography>
                )}
              </div>

              {!uploadedFileName && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "14px", color: "#333", marginTop: "10px" }}>
                    Supported file formats:
                  </Typography>
                  <Grid container spacing={1} sx={{ "& pre": { margin: "0px", fontSize: "13px" } }}>
                    <Grid item xs={3}>
                      <pre>.spdx</pre>
                      <pre>.spdx.json</pre>
                    </Grid>
                    <Grid item xs={3}>
                      <pre>.syft</pre>
                      <pre>.syft.json</pre>
                    </Grid>
                    <Grid item xs={3}>
                      <pre>.cdx.json</pre>
                    </Grid>
                  </Grid>
                </>
              )}

              {uploadedFileName && !givenComponentId && (
                <>
                  <FormControl fullWidth sx={{ marginBottom: "10px", marginTop: "30px" }} size="small">
                    <InputLabel>Component</InputLabel>
                    <Select
                      labelId="component"
                      id="component"
                      value={componentId}
                      label="Component"
                      onChange={handleComponentIdChange}
                    >
                      {components?.map((component) => (
                        <MenuItem key={component.id} value={component.id}>
                          {component.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {uploadedFileName && !uploadStatusMessage && (
                <>
                  <EdgeBitTextField
                    label="SBOM Tags"
                    placeholder="latest,v1.23.4"
                    id="sbom-tags"
                    type="text"
                    variant="filled"
                    autoComplete=""
                    style={{
                      marginTop: 30,
                      marginBottom: 5,
                      width: "100%",
                    }}
                    size="small"
                    onChange={handleSbomTagsChange}
                  />
                  <Typography variant="body1" sx={{ fontSize: "14px", color: "#333" }}>
                    Tags are unique, human-readable identifiers such as "latest" or "v1.23.4". EdgeBit will
                    automatically track vulnerabilities in the default tag for each Component, typically "latest".
                  </Typography>

                  <EdgeBitTextField
                    label="SBOM Labels"
                    placeholder="builder=ci,workflow=release"
                    id="labels"
                    type="text"
                    variant="filled"
                    autoComplete=""
                    style={{ marginTop: 30, marginBottom: 5, width: "100%" }}
                    size="small"
                    onChange={handleLabelsChange}
                  />
                  <Typography variant="body1" sx={{ fontSize: "14px", color: "#333" }}>
                    Optional key/value labels can be used to specify additional identifying information on the SBOM.
                  </Typography>

                  <FormControl fullWidth sx={{ marginBottom: "10px", marginTop: "30px" }} size="small">
                    <InputLabel id="image-type-select-label">Image Type</InputLabel>
                    <Select
                      labelId="image-type-select-label"
                      id="image-type-select"
                      value={imageType}
                      label="Image Type"
                      onChange={handleImageTypeChange}
                    >
                      {imageTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {imageType === "docker" && uploadedFileName && !uploadStatusMessage && (
                <>
                  {imageType && (
                    <>
                      <EdgeBitTextField
                        label="Docker Image Tag"
                        placeholder="registry.example.com/foobar:latest"
                        id="image-tag"
                        type="text"
                        variant="filled"
                        autoComplete=""
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          width: "100%",
                        }}
                        size="small"
                        onChange={handleDockerImageTagChange}
                      />
                      <Typography variant="body1" sx={{ fontSize: "14px", color: "#333", marginBottom: "10px" }}>
                        Specify the Docker Image Tag. <br />
                      </Typography>
                      <EdgeBitTextField
                        label="Docker Image ID"
                        placeholder="sha256:e2063f45214cf590eb06108eb0ae29325a72e2f47065c2018c268aecf20b3cd4"
                        id="image-id"
                        type="text"
                        variant="filled"
                        autoComplete=""
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          width: "100%",
                        }}
                        size="small"
                        onChange={handleImageIdChange}
                      />
                      <Typography variant="body1" sx={{ fontSize: "14px", color: "#333" }}>
                        Specify the Docker Image ID for this SBOM to match with running Workloads. <br />
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ display: "inline-block", fontSize: "14px", color: "#333", marginRight: "5px" }}
                      >
                        Find it with:
                      </Typography>
                      <Box component="pre" sx={{ display: "inline-block", fontSize: "13px", marginTop: "0px" }}>
                        {"docker inspect --format '{{.ID}}' foobar:latest"}
                      </Box>
                      <EdgeBitTextField
                        label="Docker Repo Manifest"
                        placeholder="sha256:45214cf5eb06108eb0ae29325a72e2e2063f90f47065c2018c268aecf20b3cd4, sha256:590f47065c201eb06108eb0ae29325a72e2e2063f45214cf8c268aecf20b3cd4"
                        id="repo-digest"
                        type="text"
                        variant="filled"
                        autoComplete=""
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          width: "100%",
                        }}
                        size="small"
                        onChange={handleRepoDigestChange}
                      />
                      <Typography variant="body1" sx={{ fontSize: "14px", color: "#333" }}>
                        Specify comma-separated Docker Repo Digest(s) to match with running Workloads. <br />
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ display: "inline-block", fontSize: "14px", color: "#333", marginRight: "5px" }}
                      >
                        Find it with:
                      </Typography>
                      <Box component="pre" sx={{ display: "inline-block", fontSize: "13px", marginTop: "0px" }}>
                        {"docker inspect --format '{{join .RepoDigests \", \"}}' foobar:latest"}
                      </Box>
                    </>
                  )}
                </>
              )}

              {imageType === "ami" && uploadedFileName && !uploadStatusMessage && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "14px", color: "#333" }}>
                    AMI Name
                  </Typography>
                  <EdgeBitTextField
                    label="AMI Image Name"
                    placeholder="Enter AMI image name"
                    id="AMI-image-name"
                    type="text"
                    variant="filled"
                    autoComplete=""
                    style={{
                      marginTop: 11,
                      marginBottom: 11,
                      width: "100%",
                    }}
                    size="small"
                    onChange={handleAmiNameChange}
                  />

                  <Typography variant="body1" sx={{ fontSize: "14px", color: "#999" }}>
                    Add AMI tags separated by commas ','
                  </Typography>
                  <EdgeBitTextField
                    label="AMI Tags"
                    placeholder="Enter AMI tags separated by commas ','"
                    id="AMI-tags"
                    type="text"
                    variant="filled"
                    autoComplete=""
                    style={{
                      marginTop: 11,
                      marginBottom: 11,
                      width: "100%",
                    }}
                    size="small"
                    onChange={handleAmiTagsChange}
                  />
                </>
              )}
            </>
          )}

          {isUploading && <LinearProgress sx={{ mt: 2, mb: 2 }} />}
          {!isUploading && uploadStatusMessage && !uploadSBOMFormError && (
            <Alert severity="success" sx={{ marginTop: "10px" }}>
              {uploadStatusMessage}{" "}
              <Link component={NavLink} to={"/sboms/" + uploadedSbomId}>
                SBOM
              </Link>
            </Alert>
          )}

          {uploadSBOMFormError && (
            <Alert severity="error" sx={{ marginTop: "10px" }}>
              {uploadSBOMFormError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton
            onClick={uploadSBOM}
            disabled={!sbomFile || !sbomType || isUploaded || componentId === ""}
          >
            Upload SBOM
          </EdgeBitPrimaryButton>
          <Button onClick={closeUploadSBOMForm}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
