import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import FormattedTimestamp from "./FormattedTimestamp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Project } from "../features/projectSlice";

export function ProjectTable(props: {
  projects: Project[] | null;
  handleModifyProjectClick: (projectid: string | undefined) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleModifyProject = () => {
    props.handleModifyProjectClick(anchorEl?.dataset.projectid);
    setAnchorEl(null);
  };

  return (
    <Container disableGutters>
      <Menu
        id="proj-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "proj-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleModifyProject}>Modify Project</MenuItem>
      </Menu>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "30px" }}></TableCell>
            <TableCell>Project Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects?.map((project: Project) => (
            <TableRow key={project.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>
                <Button
                  id="proj-button"
                  aria-controls={menuOpen ? "proj-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ minWidth: "auto", float: "left" }}
                  data-projectid={project.id}
                >
                  <SettingsIcon
                    sx={{
                      verticalAlign: "middle",
                      fontSize: "18px",
                      color: "#666",
                    }}
                  ></SettingsIcon>
                </Button>
              </TableCell>
              <TableCell>
                <Link component={NavLink} to={"/projects/" + project.id}>
                  {project.name}
                </Link>
              </TableCell>
              <TableCell>{project.description}</TableCell>
              <TableCell>
                <FormattedTimestamp timestamp={project.updatedAt} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}
