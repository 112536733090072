import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../api/client";
import { AuthContext } from "../components/AuthContextProvider";
import { BodyWrapper } from "../components/BodyWrapper";
import { UserAccountService } from "../pb/edgebit/platform/v1alpha/user_account_connectweb";

export const Logout = () => {
  const userClient = useClient(UserAccountService);
  const { setAuthToken } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    userClient.logout({}).then(
      (res) => {
        setAuthToken(null);
        // It's important to navigate to some other page here, otherwise the
        // login page will redirect back here when it finishes and we'll be
        // logged right back out.
        navigate("/login", {
          state: {
            fromLogout: true,
          },
        });
      },
      (err) => {
        console.error("Error logging out", err);
      },
    );
  }, [userClient, setAuthToken, navigate]);

  return <BodyWrapper />;
};
