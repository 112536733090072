import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";
import { NavLink } from "react-router-dom";

import { SBOMGenerationSource } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { sbomSourceStrings } from "./ComponentSettings";

export type GitHubRepository = {
  id: string;
  owner: string;
  name: string;
  description: string;
  url: string;
  selected: boolean;
  integrationId: string;
  availSBOMSources: SBOMGenerationSource[];
  selectedSBOMSource: SBOMGenerationSource;
};

export interface GitHubRepositories {
  repos: GitHubRepository[];
  loading: boolean;
  enabled: boolean;
  onSelectionChange: (changed: GitHubRepository) => void;
  onSBOMChange: (changed: GitHubRepository) => void;
}

export function GitHubImportableRepos(props: GitHubRepositories) {
  const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var repoId = event.target.value;

    const repository = props.repos.find((repo) => repo.id === repoId);
    if (repository) {
      repository.selected = !repository.selected;
      props.onSelectionChange(repository);
    }
  };

  const onSBOMSourceChange = (event: SelectChangeEvent) => {
    const ev = event as React.ChangeEvent<HTMLInputElement>;
    var repoId = ev.target.name;

    const repository = props.repos.find((repo) => repo.id === repoId);
    if (repository) {
      repository.selectedSBOMSource = parseInt(event.target.value);
      props.onSBOMChange(repository);
    }
  };

  return (
    <Container sx={{ mt: 3, mb: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Repository Name</TableCell>
              <TableCell>Generate a Pull Request</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.repos.map((repo: GitHubRepository) => {
              return (
                <TableRow
                  key={repo.id}
                  sx={{
                    height: "53px",
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: repo.selected ? "#F2F5FD" : "#fff",
                  }}
                >
                  <TableCell sx={{ width: "80px" }}>
                    <Checkbox
                      checked={repo.selected}
                      onChange={onSelectionChange}
                      inputProps={{ "aria-label": "controlled" }}
                      value={repo.id}
                      size="small"
                    />
                  </TableCell>
                  <TableCell sx={{ width: "50%" }}>
                    <Box sx={{ fontWeight: "500" }}>{repo.owner + "/" + repo.name}</Box>
                    <Box sx={{ fontSize: "11px", marginTop: "3px" }}>{repo.description}</Box>
                  </TableCell>
                  <TableCell sx={{ width: "40%" }}>
                    {repo.selected ? (
                      <FormControl sx={{ minWidth: 120, backgroundColor: "#fff" }} size="small">
                        <Select
                          name={repo.id}
                          value={repo.selectedSBOMSource.toString()}
                          displayEmpty
                          onChange={onSBOMSourceChange}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {repo.availSBOMSources.map((s) => (
                            <MenuItem key={s.toString()} value={s.toString()} sx={{ textTransform: "capitalize" }}>
                              {sbomSourceStrings.get(s)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <> </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {!props.loading && props.enabled && props.repos.length === 0 && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      All repositories are configured or EdgeBit can't access any repositories
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Grant EdgeBit access to more repositories on GitHub
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!props.loading && !props.enabled && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      This project isn't configured with GitHub
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                      Click on your GitHub integration to configure this project to use it.
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: "10px", marginBottom: "20px" }}
                      component={NavLink}
                      to={"/admin/integrations/"}
                    >
                      View Integrations
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {props.loading && (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "400px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                      Loading GitHub Repositories...
                    </Typography>
                    <LinearProgress
                      sx={{
                        width: "200px",
                        marginTop: "3px",
                        marginRight: "auto",
                        marginBottom: "20px",
                        marginLeft: "auto",
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
