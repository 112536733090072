import { Code } from "@bufbuild/connect";
import { Alert, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useUnauthenticatedClient } from "../api/client";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import { SignupService } from "../pb/edgebit/platform/v1alpha/signup_connectweb";
import { useSearchParams, useLocation } from "react-router-dom";
import { sanitizeNextPath } from "../utils/next_urls";

export const Signup = () => {
  const client = useUnauthenticatedClient(SignupService);

  const [formError, setFormError] = useState<string | null>(null);

  const [signupFormState, setSignupFormState] = useState({
    orgName: "",
    email: "",
    password: "",
  });

  const [orgNameHelperText, setOrgNameHelperText] = useState<string | null>(null);

  // We'll look for a "next" query parameter to redirect to after signup.
  const [queryParams] = useSearchParams();
  const location = useLocation();
  const next = sanitizeNextPath(queryParams.get("next") || location?.state?.next);

  useEffect(() => {
    if (signupFormState.orgName.length < 3) {
      setOrgNameHelperText("Must be at least 3 characters");
    } else if (signupFormState.orgName.toLowerCase() !== signupFormState.orgName) {
      setOrgNameHelperText("Must be lowercase");
    } else if (!signupFormState.orgName.charAt(0).match(/[a-z0-9]/)) {
      setOrgNameHelperText("Must start with an alphanumeric character");
    } else {
      setOrgNameHelperText(null);
    }
  }, [signupFormState]);

  const handleSignupSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    client
      .createOrg({
        orgName: signupFormState.orgName,
        email: signupFormState.email,
        password: signupFormState.password,
      })
      .then(
        (res) => {
          const port = window.location.port;
          const protocol = window.location.protocol;
          let target = `${protocol}//${res.hostname}`;
          if (port) {
            target = `${target}:${port}`;
          }

          if (next === "/") {
            window.location.assign(`${target}/login?next=/onboarding`);
          } else {
            window.location.assign(`${target}/login?next=${next}`);
          }
        },
        (err) => {
          if (err.code === Code.InvalidArgument) {
            setFormError(err.rawMessage);
          } else {
            setFormError("Unknown error");
          }
        },
      );
  };

  useEffect(() => {
    document.title = "Sign Up - EdgeBit";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
        <Box
          sx={{
            textAlign: "center",
            background: "#fff",
            borderRadius: "5px",
            padding: "40px 40px 30px 40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "355px" }}>
            <Typography variant="h4" gutterBottom>
              Let's get started
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Already using EdgeBit? Sign in to your org address.
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": {},
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSignupSubmit}
            >
              <EdgeBitTextField
                label="Organization ID"
                placeholder="company"
                id="org"
                variant="filled"
                helperText={orgNameHelperText}
                style={{ marginTop: 11, width: "255px" }}
                onChange={(e) =>
                  setSignupFormState({
                    ...signupFormState,
                    orgName: e.target.value,
                  })
                }
              />
              <Box
                sx={{
                  display: "inline-block",
                  width: "101px",
                  height: "58px",
                  marginTop: "11px",
                  marginLeft: "-2px",
                  paddingLeft: "10px",
                  paddingRight: "5px",
                  paddingTop: "24px",
                  textAlign: "left",
                  fontWeight: "500",
                  background: "#fcfcfc",
                  border: "1px solid #e2e2e2",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                .edgebit.io
              </Box>
              <EdgeBitTextField
                label="Work Email"
                placeholder="you@company.com"
                type="email"
                autoComplete="email"
                id="email"
                variant="filled"
                style={{ marginTop: 11, width: "355px" }}
                onChange={(e) =>
                  setSignupFormState({
                    ...signupFormState,
                    email: e.target.value,
                  })
                }
              />
              <EdgeBitTextField
                label="Password"
                placeholder="•••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="filled"
                style={{ marginTop: 11, width: "355px" }}
                onChange={(e) =>
                  setSignupFormState({
                    ...signupFormState,
                    password: e.target.value,
                  })
                }
              />
              {formError && (
                <Alert style={{ marginTop: 11 }} severity="error">
                  {formError}
                </Alert>
              )}
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ fontSize: "11px", textAlign: "left", marginTop: "15px" }}
              >
                By using EdgeBit, you are agreeing to our{" "}
                <a target="_blank" href="https://edgebit.io/legal/privacy" rel="noreferrer">
                  privacy policy
                </a>{" "}
                and{" "}
                <a target="_blank" href="https://edgebit.io/legal/terms" rel="noreferrer">
                  terms of use
                </a>
                .
              </Typography>
              <EdgeBitPrimaryButton
                type="submit"
                variant="outlined"
                size="large"
                sx={{ marginTop: "30px", marginBottom: "10px" }}
              >
                Sign Up
              </EdgeBitPrimaryButton>
            </Box>
          </Box>
          <Box
            sx={{
              borderLeft: "0px solid #ddd",
              width: "350px",
              marginLeft: "40px",
              paddingLeft: "35px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box component="img" sx={{ maxWidth: "100%" }} src="/execution.png" alt="Supply chain risk by execution" />
          </Box>
        </Box>
      </Box>
    </BodyWrapper>
  );
};
