import React, { useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useClient } from "../api/client";
import { ProjectContext } from "../App";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { OrgAccessToken } from "../pb/edgebit/platform/v1alpha/platform_pb";
import FormattedTimestamp from "./FormattedTimestamp";
import { EdgeBitPrimaryButton } from "./EdgeBitPrimaryButton";

export function OrgAccessTokenTable(props: {
  tokens: OrgAccessToken[] | null;
  handleCreateTokenClick: (event: React.MouseEvent<HTMLElement>) => void;
  refreshTokens: () => void;
}) {
  const [deleteTokenId, setDeleteTokenId] = useState<string>("");
  const client = useClient(EdgeBitPublicAPIService);

  // TODO: this whole pattern is totally unmaintainable and has tons of weird edge cases.
  const project = useContext(ProjectContext);

  const handleDeleteToken = () => {
    if (project) {
      client
        .deleteOrgAccessToken({
          projectId: project.id,
          id: deleteTokenId,
        })
        .then(
          (res) => {
            props.refreshTokens();
          },
          (err) => {
            console.log(err);
          },
        );
    }
    setDeleteTokenId("");
  };

  const handleOpenConfirmationDialog = (tokenID: string) => {
    setDeleteTokenId(tokenID);
  };

  const handleCloseConfirmationDialog = () => {
    setDeleteTokenId("");
  };

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Last Used</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tokens?.map((token: OrgAccessToken) => (
              <TableRow key={token.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {token.description}
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={token.createdAt} />
                </TableCell>
                <TableCell>
                  <FormattedTimestamp timestamp={token.lastUsed} />
                </TableCell>
                <TableCell>
                  <Link onClick={() => handleOpenConfirmationDialog(token.id)}>
                    <DeleteRoundedIcon sx={{ color: "#333" }} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            {props.tokens?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "550px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                      You don't have any access tokens
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: "10px", marginBottom: "20px" }}
                      onClick={props.handleCreateTokenClick}
                    >
                      Create an Access Token
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Confirmation Dialog */}
      <Dialog open={Boolean(deleteTokenId)} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>Delete Token</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Access Token?</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleDeleteToken}>Delete</EdgeBitPrimaryButton>
          <Button onClick={handleCloseConfirmationDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
