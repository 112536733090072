import React, { useEffect } from "react";
import {
  Alert,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import { GlobalError } from "../components/GlobalError";
import { OrgUserListTable } from "../components/OrgUserListTable";

import {
  listUsers,
  inviteUser,
  openCreateUserForm,
  closeCreateUserForm,
  setInviteUserInput,
  selectUsers,
} from "../features/usersSlice";

import { useAppDispatch, useAppSelector } from "../app/hooks";

export const OrgUserList = () => {
  const dispatch = useAppDispatch();
  const { userList, connectivity, createUserFormOpen, inviteUserInput, inviteFormError } = useAppSelector(selectUsers);

  useEffect(() => {
    document.title = "User Directory - EdgeBit";
    dispatch(listUsers());
  }, [dispatch]);

  const handleOpenCreateUserForm = () => {
    dispatch(openCreateUserForm());
  };

  const handleCloseCreateUserForm = () => {
    dispatch(closeCreateUserForm());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setInviteUserInput({ email: e.target.value }));
  };

  const handleInviteUser = () => {
    if (inviteUserInput && inviteUserInput.email) {
      dispatch(inviteUser({ email: inviteUserInput.email }));
    } else {
      console.error("Email address is required to send an invite.");
    }
  };

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Dialog open={createUserFormOpen} onClose={handleCloseCreateUserForm} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To invite an additional user, enter their email address below. They will receive an email with a link to
            create an account.
          </DialogContentText>
          <EdgeBitTextField
            id="email"
            label="Email"
            placeholder="alice@company.com"
            variant="filled"
            autoFocus
            value={inviteUserInput.email} // Ensure this matches the structure of your state
            onChange={handleChange}
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "15px" }}
          />
          {inviteFormError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {inviteFormError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleInviteUser}>Send Invite</EdgeBitPrimaryButton>
          <Button tabIndex={-1} onClick={handleCloseCreateUserForm}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
        Users
      </Typography>
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px" }}
        onClick={handleOpenCreateUserForm}
      >
        Invite User
      </EdgeBitPrimaryButton>
      <OrgUserListTable
        users={userList}
        handleCreateUserClick={handleOpenCreateUserForm}
        refreshUsers={() => dispatch(listUsers())}
      />
    </BodyWrapper>
  );
};
