// @generated by protoc-gen-connect-web v0.9.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/login.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { APIAccessTokenLoginRequest, APIAccessTokenLoginResponse, ExchangeInviteTokenRequest, ExchangeInviteTokenResponse, GetLoginMethodRequest, GetLoginMethodResponse, OIDCCallbackRequest, OIDCCallbackResponse, PasswordLoginRequest, PasswordLoginResponse } from "./login_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service edgebit.platform.v1alpha.LoginService
 */
export const LoginService = {
  typeName: "edgebit.platform.v1alpha.LoginService",
  methods: {
    /**
     * @generated from rpc edgebit.platform.v1alpha.LoginService.GetLoginMethod
     */
    getLoginMethod: {
      name: "GetLoginMethod",
      I: GetLoginMethodRequest,
      O: GetLoginMethodResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.LoginService.PasswordLogin
     */
    passwordLogin: {
      name: "PasswordLogin",
      I: PasswordLoginRequest,
      O: PasswordLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.LoginService.OIDCCallback
     */
    oIDCCallback: {
      name: "OIDCCallback",
      I: OIDCCallbackRequest,
      O: OIDCCallbackResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.LoginService.ExchangeInviteToken
     */
    exchangeInviteToken: {
      name: "ExchangeInviteToken",
      I: ExchangeInviteTokenRequest,
      O: ExchangeInviteTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.LoginService.APIAccessTokenLogin
     */
    aPIAccessTokenLogin: {
      name: "APIAccessTokenLogin",
      I: APIAccessTokenLoginRequest,
      O: APIAccessTokenLoginResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

