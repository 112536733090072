import React, { useState } from "react";
import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";

function matchesPrefix(label: { [key: string]: any }, prefix: string[] | string | undefined | null): boolean {
  var labelKey = label[0];
  // if no prefix is specified, return all labels
  if (prefix === undefined) {
    return true;
  }
  // if prefix is null, return only labels that have no prefix
  // this lets you filter out labels with prefixes when displaying them under their prefix
  if (prefix === null) {
    return !labelKey.includes(":");
  }
  // if prefix is a string, return only labels that start with the prefix
  if (typeof prefix === "string" && prefix.length > 0 && labelKey.startsWith(prefix)) {
    return true;
  }
  if (Array.isArray(prefix) && prefix.length > 0) {
    return prefix.some((p) => labelKey.startsWith(p));
  }
  return false;
}

export function LabelDisplay(props: {
  labels: { [key: string]: string } | undefined;
  truncate?: number | undefined;
  prefix?: string[] | string | undefined | null;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  var middleTruncationOccurred = false;
  const middleTruncate = (text: string, maxLength: number) => {
    // Return the string if its short or if we're not told to truncate anything
    // This gives you access to the full value to copy/paste
    if (text.length <= maxLength || !props.truncate) {
      return text;
    }

    middleTruncationOccurred = true;

    const halfLength = Math.floor((maxLength - 3) / 2);
    const firstHalf = text.slice(0, halfLength);
    const secondHalf = text.slice(text.length - halfLength);

    return firstHalf + "..." + secondHalf;
  };

  let filteredLabels: Array<[string, any]> = [];
  if (props.labels) {
    filteredLabels = Object.entries(props.labels).filter((label: object) => matchesPrefix(label, props.prefix));
  }

  if (filteredLabels.length === 0) {
    return <Box sx={{ display: "inline-block", marginTop: "5px", color: "#999" }}>No labels set</Box>;
  }

  return (
    <Box sx={{ display: "inline-block", position: "relative", marginTop: "5px" }}>
      <Box onMouseEnter={handlePopoverOpen}>
        {Array.from(filteredLabels.slice(0, props.truncate), ([name, value]) => (
          <Box
            key={name}
            sx={{
              background: "#f1f1f1",
              padding: "0px 5px",
              marginRight: "7px",
              marginBottom: "5px",
              display: "inline-block",
              borderRadius: "3px",
              fontWeight: "500",
            }}
          >
            {middleTruncate(name + "=" + value, 30)}
          </Box>
        ))}
        {props.truncate && props.truncate < filteredLabels.length && (
          <Box
            sx={{
              background: "#f1f1f1",
              padding: "0px 5px",
              marginRight: "7px",
              marginBottom: "5px",
              display: "inline-block",
              borderRadius: "3px",
              fontWeight: "500",
            }}
          >
            +{filteredLabels.length - props.truncate}
          </Box>
        )}
      </Box>
      {((props.truncate && props.truncate < filteredLabels.length) || middleTruncationOccurred) && (
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          sx={{ marginTop: "-3px", marginLeft: "-3px" }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box
            sx={{
              fontSize: "14px",
              width: "700px",
              paddingLeft: "3px",
              paddingTop: "3px",
            }}
            onMouseLeave={handlePopoverClose}
          >
            {Array.from(filteredLabels, ([name, value]) => (
              <Box
                key={name}
                sx={{
                  background: "#f1f1f1",
                  padding: "0px 5px",
                  marginRight: "7px",
                  marginBottom: "5px",
                  display: "inline-block",
                  borderRadius: "3px",
                  fontWeight: "500",
                }}
              >
                {name}={value}
              </Box>
            ))}
          </Box>
        </Popover>
      )}
    </Box>
  );
}
