import { useEffect, useState } from "react";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { ReportDataSelect } from "../components/ReportDataSelect";

export function ReportColsTable() {
  interface ReportColumn {
    name: string;
    type: string;
    value: string | null;
  }

  // Track state and set default columns
  const defaultCols = {
    name: "First Column",
    type: "sbom:depdency_name",
    value: null,
  };
  const [reportCols, setReportCols] = useState(new Set<ReportColumn>());

  useEffect(() => {
    const defaultSet = new Set(reportCols);
    defaultSet.add(defaultCols);
    setReportCols(defaultSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle deletes
  const deleteRow = (rowID: number) => {
    const newSet = new Set(reportCols);
    newSet.delete(Array.from(reportCols)[rowID]);
    setReportCols(newSet);
    console.log(rowID);
  };

  // Handle adds
  const addCol = {
    name: "New Column",
    type: "sbom:depdency_name",
    value: null,
  };
  const addRow = () => {
    const newSet = new Set(reportCols);
    newSet.add(addCol);
    setReportCols(newSet);
    console.log(newSet);
  };

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>Order</TableCell>
              <TableCell>Column Name</TableCell>
              <TableCell>Column Value</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(reportCols).map((col: ReportColumn, index: number) => (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell sx={{ textAlign: "center" }}>{index + 1}</TableCell>
                <TableCell>
                  <TextField required label="" value={col.name} size="small" />
                </TableCell>
                <TableCell>
                  <ReportDataSelect />
                </TableCell>
                <TableCell>
                  <Link onClick={() => deleteRow(index)}>
                    <DeleteRoundedIcon sx={{ color: "#333" }} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}

            {Array.from(reportCols).length === 0 && (
              <EmptyState>
                <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
                  No columns in this report
                </Typography>
              </EmptyState>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        size="small"
        sx={{ marginTop: "10px", marginBottom: "20px", marginLeft: "0px" }}
        onClick={addRow}
      >
        Add another column
      </Button>
    </Container>
  );
}

function EmptyState(props: { children: any }) {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Box
          sx={{
            textAlign: "center",
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            width: "550px",
            marginTop: "20px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {props.children}
        </Box>
      </TableCell>
    </TableRow>
  );
}
