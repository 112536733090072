import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { combineSlices, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { usersSlice } from "../features/usersSlice";
import { createPromiseClient, PromiseClient, Transport } from "@bufbuild/connect";
import { UserDirectoryService } from "../pb/edgebit/platform/v1alpha/users_connectweb";
import { projectSlice } from "../features/projectSlice";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { sbomSlice } from "../features/sbomDetailSlice";
import { sbomListSlice } from "../features/sbomListSlice";
import { componentDetailSlice } from "../features/componentDetailSlice";
import { overviewSlice } from "../features/overviewSlice";
import { githubSlice } from "../features/githubSlice";
import { integrations } from "../features/integrationsSlice";
import { repoDetailSlice } from "../features/repoDetailSlice";
import { repoListSlice } from "../features/repoListSlice";
import { proposalDetailSlice } from "../features/proposalDetailSlice";
import { SourceRepoService } from "../pb/edgebit/platform/v1alpha/source_repos_connectweb";
import { proposalListSlice } from "../features/proposalListSlice";

const rootReducer = combineSlices(
  usersSlice,
  projectSlice,
  sbomSlice,
  sbomListSlice,
  componentDetailSlice,
  overviewSlice,
  githubSlice,
  integrations,
  repoDetailSlice,
  repoListSlice,
  proposalDetailSlice,
  proposalListSlice,
);

export type RootState = ReturnType<typeof rootReducer>;

export const makeStore = (transport: Transport, preloadedState?: Partial<RootState>) => {
  const userClient = createPromiseClient(UserDirectoryService, transport);
  const apiClient = createPromiseClient(EdgeBitPublicAPIService, transport);
  const repoClient = createPromiseClient(SourceRepoService, transport);

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { userClient, apiClient, repoClient },
        },
      }),
    preloadedState,
  });

  setupListeners(store.dispatch);
  return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, Action>;
export type ThunkExtra = {
  // ATTENTION: If you add a new service here, you must also add it to the `makeStore`
  // function above, or the entire app will light up like a christmas tree of inscrutable
  // type errors and in 45 minutes you'll wish you'd read this.
  extra: {
    apiClient: PromiseClient<typeof EdgeBitPublicAPIService>;
    userClient: PromiseClient<typeof UserDirectoryService>;
    repoClient: PromiseClient<typeof SourceRepoService>;
  };
};
