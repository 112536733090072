import { Alert, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import { useLocation, useSearchParams } from "react-router-dom";
import { sanitizeNextPath, signupReflector } from "../utils/next_urls";

export const TenantSelector = () => {
  const [tenant, setTenant] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  const handleRedirect = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const validateTenant = (tenant: string) => {
    const regex = /^[a-zA-Z0-9-]{1,63}$/;
    var test = regex.test(tenant);
    if (!test) {
      setError(true);
      setTenant(null);
    } else {
      setTenant(tenant);
      setError(false);
    }
    return test;
  };

  // We'll look for a "next" query parameter to redirect to after login.
  const [queryParams] = useSearchParams();
  const location = useLocation();
  const next = sanitizeNextPath(queryParams.get("next") || location?.state?.next);

  useEffect(() => {
    document.title = "Organization ID - EdgeBit";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
        <Box
          sx={{
            textAlign: "center",
            background: "#fff",
            borderRadius: "5px",
            padding: "40px 40px 30px 40px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "355px" }}>
            <Typography variant="h4" gutterBottom>
              Let's get started
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Provide your org ID used with EdgeBit.
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": {},
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleRedirect}
            >
              <EdgeBitTextField
                label="Organization ID"
                placeholder="you@company.com"
                type="email"
                autoComplete="email"
                id="email"
                variant="filled"
                style={{ marginTop: 11, width: "255px" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  validateTenant(event.target.value);
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  validateTenant(event.target.value);
                }}
              />
              <Box
                sx={{
                  display: "inline-block",
                  width: "101px",
                  height: "58px",
                  marginTop: "11px",
                  marginLeft: "-2px",
                  paddingLeft: "10px",
                  paddingRight: "5px",
                  paddingTop: "24px",
                  textAlign: "left",
                  fontWeight: "500",
                  background: "#fcfcfc",
                  border: "1px solid #e2e2e2",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                .edgebit.io
              </Box>
              {error && (
                <Alert severity="error" sx={{ marginTop: "10px", textAlign: "left" }}>
                  Organization IDs must be 1-63 characters and not contain special characters.
                </Alert>
              )}
              <EdgeBitPrimaryButton
                type="submit"
                variant="outlined"
                size="large"
                disabled={error}
                sx={{ marginTop: "30px", marginBottom: "10px" }}
                onClick={() => {
                  if (next !== "/") {
                    window.location.href = `https://${tenant}.edgebit.io/login?next=${next}`;
                  } else {
                    window.location.href = `https://${tenant}.edgebit.io/login`;
                  }
                }}
              >
                Next Step
              </EdgeBitPrimaryButton>
              <Box sx={{ marginTop: "25px", fontSize: "13px" }}>
                Or <a href={`${signupReflector()}/signup?next=${next}`}>sign up for a new account</a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </BodyWrapper>
  );
};
