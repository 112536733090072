import { Container, Typography } from "@mui/material";

export function OverviewBox(props: {
  children?: any;
  title: string;
  subtitle?: string;
  align?: string;
  justify?: string;
  data?: any;
  sx?: any;
  disabled?: boolean;
}) {
  var alignCSS = "left";
  if (props.align) {
    alignCSS = props.align;
  }

  let disabledCSS = {};
  if (props.disabled) {
    disabledCSS = {
      pointerEvents: "none",
      opacity: 0.5,
    };
  }

  return (
    <Container
      sx={{
        mt: 2,
        mb: 2.5,
        ml: 0,
        mr: 0,
        padding: "20px",
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        border: "1px solid #ddd",
        borderBottom: "0px",
        background: "#fff",
        ...props.sx,
        ...disabledCSS,
      }}
    >
      <Typography variant="h6" sx={{ textAlign: alignCSS }}>
        {props.title}
      </Typography>

      {props.subtitle && (
        <Typography variant="h6" sx={{ fontWeight: "300", textAlign: alignCSS }}>
          {props.subtitle}
        </Typography>
      )}

      {props.children}
    </Container>
  );
}
