import { Typography } from "@mui/material";
import { OverviewBoxItem } from "./OverviewBoxItem";

type OverviewBoxTextItemProps = {
  label: string;
  emptyMessage: string;
  text: string | undefined;
};

export function OverviewBoxTextItem(props: OverviewBoxTextItemProps) {
  return (
    <OverviewBoxItem label={props.label} emptyMessage={props.emptyMessage} isEmpty={!props.text}>
      <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: "10px" }}>
        {props.text}
      </Typography>
    </OverviewBoxItem>
  );
}
