import { Typography, TableContainer, Paper, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useClient } from "../api/client";
import { BodyWrapper } from "../components/BodyWrapper";
import { GlobalError } from "../components/GlobalError";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
// import { Project } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { ProjectTable } from "../components/ProjectTable";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitTextField } from "../components/EdgeBitTextField";
import { Code } from "@bufbuild/connect";
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { useSelector } from "react-redux";
import { Project, createProject, fetchProjects, selectProjects, updateProject } from "../features/projectSlice";

export const ProjectList = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [createProjectFormOpen, setCreateProjectFormOpen] = useState<boolean>(false);
  const [modifyProjectFormOpen, setModifyProjectFormOpen] = useState<boolean>(false);
  const [mutatedProject, setMutatedProject] = useState({
    id: "",
    name: "",
    description: "",
  });
  const [mutateFormError, setMutateFormError] = useState<string | null>(null);
  const [createProjectFormError, setCreateProjectFormError] = useState<string | null>(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const projectList = useSelector(selectProjects);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [client, dispatch]);

  // Create the Project and refresh the list
  const updatedParams = createSearchParams(searchParams);
  const handleCreateProject = () => {
    if (!mutatedProject.name.trim()) {
      setCreateProjectFormError("Invalid Project Name");
    } else {
      dispatch(
        createProject({
          name: mutatedProject.name,
          description: mutatedProject.description,
        }),
      )
        .unwrap()
        .then((createdProject) => {
          // Success: Navigate to the detail page of the created project
          updatedParams.set("project", createdProject.name);
          navigate(`/projects/${createdProject.id}?${updatedParams.toString()}`, { replace: true });
        })
        .catch((error) => {
          if (error.code === Code.InvalidArgument) {
            setCreateProjectFormError(error.message);
          } else {
            setCreateProjectFormError("Unknown error");
          }
        });
    }
  };

  // Create Project Modal
  const openCreateProjectForm = () => {
    setCreateProjectFormOpen(true);
  };

  const closeCreateProjectForm = () => {
    setMutatedProject({ ...mutatedProject, description: "", name: "" });
    setCreateProjectFormOpen(false);
  };

  // Modify Project Modal
  const openModifyProjectForm = (projectid: string | undefined) => {
    if (projectid) {
      // find matching project details and set them to our mutated object
      let project = projectList?.find((project: Project) => project.id === projectid);
      if (project) {
        setMutatedProject({
          ...mutatedProject,
          id: project.id,
          name: project.name,
          description: project.description,
        });
        setModifyProjectFormOpen(true);
      }
    }
  };

  const closeModifyProjectForm = () => {
    setMutatedProject({ ...mutatedProject, description: "", name: "" });
    setModifyProjectFormOpen(false);
  };

  const handleSaveProject = () => {
    dispatch(
      updateProject({
        id: mutatedProject.id,
        name: mutatedProject.name,
        description: mutatedProject.description,
      }),
    )
      .unwrap()
      .then(() => {
        setMutateFormError(null);
        closeModifyProjectForm();
        dispatch(fetchProjects());
      })
      .catch((err) => {
        if (err.code === Code.InvalidArgument) {
          setMutateFormError(err.rawmessage);
        } else {
          setConnectivity(false);
          setMutateFormError("Unknown error");
        }
      });
  };

  // Set title and read URL params
  useEffect(() => {
    document.title = "Projects - EdgeBit";
  }, []);

  return (
    <BodyWrapper>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "0px" }}>
        Projects allow you to organize access boundaries by business unit, team or product line.
      </Typography>

      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px" }}
        onClick={openCreateProjectForm}
      >
        Create Project
      </EdgeBitPrimaryButton>
      <TableContainer component={Paper} sx={{ display: "table", marginTop: "20px", border: "1px solid #ddd" }}>
        <ProjectTable projects={projectList} handleModifyProjectClick={openModifyProjectForm}></ProjectTable>
      </TableContainer>

      {/* Modify Project Modal */}
      <Dialog open={modifyProjectFormOpen} onClose={closeModifyProjectForm} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">Modify Project</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginTop: "10px" }}>
            Enter a new project description for <strong>{mutatedProject.name}</strong>:
          </DialogContentText>
          <EdgeBitTextField
            id="description"
            label="Project Description"
            value={mutatedProject.description}
            variant="filled"
            autoFocus
            onChange={(e) =>
              setMutatedProject({
                ...mutatedProject,
                description: e.target.value,
              })
            }
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "15px", minWidth: "400px" }}
            placeholder="Short phrase to identify this project in the project dropdown."
          />
          {mutateFormError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {mutateFormError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleSaveProject}>Save Project</EdgeBitPrimaryButton>
          <Button tabIndex={-1} onClick={closeModifyProjectForm}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Project Modal */}
      <Dialog open={createProjectFormOpen} onClose={closeModifyProjectForm} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title">Create New Project</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginTop: "10px" }}>
            Enter a name and description for your new project:
          </DialogContentText>
          <EdgeBitTextField
            id="name"
            label="Project Name"
            value={mutatedProject.name}
            variant="filled"
            autoFocus
            onChange={(e) => setMutatedProject({ ...mutatedProject, name: e.target.value })}
            sx={{ marginBottom: "10px", marginTop: "15px", minWidth: "200px" }}
          />
          <EdgeBitTextField
            id="description"
            label="Project Description"
            value={mutatedProject.description}
            variant="filled"
            onChange={(e) =>
              setMutatedProject({
                ...mutatedProject,
                description: e.target.value,
              })
            }
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "15px", minWidth: "400px" }}
            placeholder="Short phrase to identify this project in the project dropdown."
          />
          {createProjectFormError && (
            <Alert style={{ marginTop: 11 }} severity="error">
              {createProjectFormError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleCreateProject}>Create Project</EdgeBitPrimaryButton>
          <Button tabIndex={-1} onClick={closeCreateProjectForm}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </BodyWrapper>
  );
};
