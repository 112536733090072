// @generated by protoc-gen-connect-web v0.9.0 with parameter "target=ts,import_extension=none"
// @generated from file edgebit/platform/v1alpha/users.proto (package edgebit.platform.v1alpha, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteUserRequest, DeleteUserResponse, InviteUserRequest, InviteUserResponse, ListUsersRequest, ListUsersResponse } from "./users_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service edgebit.platform.v1alpha.UserDirectoryService
 */
export const UserDirectoryService = {
  typeName: "edgebit.platform.v1alpha.UserDirectoryService",
  methods: {
    /**
     * @generated from rpc edgebit.platform.v1alpha.UserDirectoryService.ListUsers
     */
    listUsers: {
      name: "ListUsers",
      I: ListUsersRequest,
      O: ListUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.UserDirectoryService.InviteUser
     */
    inviteUser: {
      name: "InviteUser",
      I: InviteUserRequest,
      O: InviteUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc edgebit.platform.v1alpha.UserDirectoryService.DeleteUser
     */
    deleteUser: {
      name: "DeleteUser",
      I: DeleteUserRequest,
      O: DeleteUserResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

