import { Box, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ComponentIssueSeverity, EPSSItem, VulnSeverity } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { useState, useEffect } from "react";
import React from "react";
import { NavLink } from "react-router-dom";
import VulnChip from "./VulnChip";
import HelpIcon from "@mui/icons-material/Help";
import { useClient } from "../api/client";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { ComponentIssue } from "../features/componentDetailSlice";

// Based on https://en.wikipedia.org/wiki/Ordinal_indicator#English
// Convert f to an integer percentage, and return a string with the correct ordinal suffix.
// We assume that f is a float between 0 and 1, inclusive.
const formatPercentile = (f: number): string => {
  const i = Math.round(f * 100);

  let suffix = "th";

  // 11th, 12th, 13th are extra-special cases.
  if (i < 11 || i > 13) {
    // Other percentages ending in 1, 2, 3 are normal-special cases.
    switch (i % 10) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
    }
  }

  return i + suffix;
};

const getVulnSeverityString = (severity: number): string => {
  const vulnSeverityString = ComponentIssueSeverity.hasOwnProperty(severity)
    ? ComponentIssueSeverity[severity].toString()
    : "";
  return vulnSeverityString.charAt(0).toUpperCase() + vulnSeverityString.slice(1).toLowerCase();
};

export function EPSSActions(props: { issues: ComponentIssue[] | undefined | null; threshold: number }) {
  const client = useClient(EdgeBitPublicAPIService);

  const [epssData, setEpssData] = useState<EPSSItem[] | undefined>(undefined);
  const [epssIssues, setepssIssues] = useState<ComponentIssue[]>([]);

  let issues = props.issues;

  useEffect(() => {
    // once we have the desired ComponentIssues, query for EPSS scores
    if (!epssData && issues) {
      // find the unique list of CVEs to look up from the ComponentIssues
      let queryGroup: string[] = [];
      issues.map((issue) => {
        if (issue.details.value?.id.includes("CVE")) {
          if (!queryGroup.includes(issue.details.value?.id)) {
            queryGroup.push(issue.details.value?.id);
          }
        }
        return 0;
      });

      // query for EPSS scores for a single 100 CVE chunk
      // only return CVE scores that are above the policy threshold
      const fetchEPSSChunk = async (listOfCVEs: string[]) => {
        try {
          const response = await client.lookupEPSSData({
            cveIds: listOfCVEs,
            epssGreaterThan: props.threshold,
          });

          return response.data;
        } catch (error) {
          console.error("Error fetching EPSS: ", error);
        }
      };

      // loop through the desired CVE list in chunks
      const chunkSize = 100;
      let data: EPSSItem[] = [];
      const fetchEPSSData = async () => {
        for (let i = 0; i < queryGroup.length; i += chunkSize) {
          const chunk = queryGroup.slice(i, i + chunkSize);
          const fetchedData = await fetchEPSSChunk(chunk);
          if (fetchedData) {
            data = [...data, ...fetchedData];
          }
        }

        if (!epssData) {
          setEpssData(data);
        } else {
          setEpssData([epssData, ...data]);
        }
      };

      fetchEPSSData();
    }

    // once we have both issues and EPSS data gather a list of the relevant CompontentIssues
    if (epssData && issues) {
      issues.map((issue) => {
        if (epssData.filter((vuln) => vuln.cve === issue?.details?.value?.id).length > 0) {
          if (epssIssues.filter((vuln) => vuln.id === issue.id).length === 0) {
            setepssIssues([...epssIssues, issue]);
          }
        }
        return 0;
      });
    }
  }, [client, props.threshold, epssData, epssIssues, issues]);

  return (
    <>
      {epssIssues
        ?.sort((a, b) => {
          // Find corresponding vuln objects in epssData
          const vulnA = epssData?.find((vuln) => vuln.cve === a?.details?.value?.id);
          const vulnB = epssData?.find((vuln) => vuln.cve === b?.details?.value?.id);

          // Sort based on descending epss score
          return (vulnB?.epss || 0) - (vulnA?.epss || 0);
        })
        .map((issue) => (
          <Box key={issue.id} sx={{ marginBottom: "10px" }}>
            <Box sx={{ fontSize: "14px" }}>
              <Link component={NavLink} to={"/components/" + issue.componentId + "/issues/" + issue.id}>
                <Box component="span" sx={{ fontWeight: "600", paddingRight: "3px" }}>
                  {issue.componentRef?.displayName}
                </Box>
                #{issue.id}
              </Link>
              {issue.details.value && (
                <Box sx={{ display: "inline-block", transform: "scale(.8)" }}>
                  <VulnChip preset={VulnSeverity[issue.severity]} innerText={getVulnSeverityString(issue.severity)} />
                </Box>
              )}
            </Box>
            {epssData
              ?.filter((vuln) => vuln.cve === issue?.details?.value?.id)
              .map((vuln) => (
                <React.Fragment key={vuln.cve}>
                  <Box sx={{ fontSize: "14px" }}>
                    {(vuln.epss * 100).toFixed(2)}% probability &bull; {formatPercentile(vuln.percentile)} percentile of
                    public CVEs
                  </Box>
                </React.Fragment>
              ))}
          </Box>
        ))}
      {epssData?.length === 0 && (
        <>
          <Box sx={{ marginTop: "10px" }}>
            No immediate actions required
            <Tooltip
              title={
                "No vulnerabilities found with an exploit probability over your policy of " +
                (props.threshold * 100).toFixed(2) +
                "%."
              }
            >
              <HelpIcon
                sx={{
                  fontSize: "15px",
                  verticalAlign: "text-bottom",
                  marginLeft: "5px",
                  color: "#6096FF",
                }}
              ></HelpIcon>
            </Tooltip>
          </Box>
        </>
      )}
      {!epssData && <Box>Loading</Box>}
    </>
  );
}
