import { Box } from "@mui/material";

export function FeatureFlag(props: { children?: any; name: string; hideByDefault: boolean }) {
  var show = !props.hideByDefault;

  if (localStorage.getItem("flag-" + props.name)) {
    // Use if set, otherwise use default visibility
    show = localStorage.getItem("flag-" + props.name) === "true";
  }

  return <Box>{show && props.children}</Box>;
}
