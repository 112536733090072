import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Code, ConnectError } from "@bufbuild/connect";
import { Box, Typography } from "@mui/material";

import { useClient } from "../api/client";
import { BodyWrapper } from "../components/BodyWrapper";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";

export const GithubAppCompleteInstall = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const [next, setNext] = useState<string | null>(null);

  const { type } = useParams();

  useEffect(() => {
    const fetch = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const state = urlParams.get("state");
      if (state) {
        try {
          const resp = await client.completeGitHubAppInstall({
            state: state,
          });

          // redirect back to integration or to code analysis start
          switch (type) {
            case "admin":
              setNext(`/admin/github/` + resp.id);
              break;
            case "codeanalysis":
              setNext(`/codeanalysis/start`);
              break;
            default:
              setNext(`/admin/github/` + resp.id);
              break;
          }
        } catch (err) {
          if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
            setError(err.rawMessage);
          } else {
            setError("Unknown error");
          }
        }
      }
    };

    fetch();
  }, [client, type]);

  useEffect(() => {
    if (next) {
      navigate(next);
    }
  }, [next, navigate]);

  useEffect(() => {
    document.title = "Completing GitHub App installation...";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
        {error ? (
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Error: {error}
          </Typography>
        ) : (
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Completing GitHub App installation...
          </Typography>
        )}
      </Box>
    </BodyWrapper>
  );
};
