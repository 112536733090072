import { Button, ButtonProps, styled } from "@mui/material";

export const EdgeBitPrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  backgroundColor: theme.palette.primary.main,
  fontWeight: "800",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },
  "&.Mui-disabled": {
    background: "#eaeaea",
    color: "#c0c0c0",
  },
}));
