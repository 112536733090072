import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from "@mui/icons-material";
import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState } from "react";
import { PackageType } from "./PackageType";
import { VulnSeverity, ComponentIssueSeverity, ComponentIssueState } from "../pb/edgebit/platform/v1alpha/platform_pb";
import VulnChip from "./VulnChip";
import VulnMultiIconDisplay from "./VulnMultiIconDisplay";
import { NavLink } from "react-router-dom";
import getStateText from "../utils/vuln_state";
import RelativeTimestamp from "./RelativeTimestamp";
import { ComponentIssue } from "../features/componentDetailSlice";

type ComponentIssuesGroup = {
  packageName: string;
  componentIssues: ComponentIssue[];
};

type Vuln = {
  severity: VulnSeverity;
};

function createPackageGroupings(issues: ComponentIssue[]): ComponentIssuesGroup[] {
  const packageGroups: { [packageName: string]: ComponentIssue[] } = {};

  // Group the issues by package name
  for (const issue of issues) {
    const packageName = issue.packageName;
    if (!packageGroups[packageName]) {
      packageGroups[packageName] = [];
    }
    packageGroups[packageName].push(issue);
  }

  // Convert the grouped data into an array of ComponentIssuesGroup
  const componentIssuesGroups: ComponentIssuesGroup[] = Object.entries(packageGroups).map(
    ([packageName, componentIssues]) => ({
      packageName,
      componentIssues,
    }),
  );

  const severityWeights: { [severity: string]: number } = {
    low: 1,
    medium: 2,
    high: 3,
    critical: 4,
  };

  const packageScores: Map<string, number> = new Map();

  componentIssuesGroups.forEach((group) => {
    const severityCounts = group.componentIssues.reduce(
      (acc, vuln) => {
        const vulnSeverity = VulnSeverity[vuln.severity].toLowerCase();
        acc[vulnSeverity] = (acc[vulnSeverity] || 0) + 1;
        return acc;
      },
      {} as { [severity: string]: number },
    );

    const weightedScore = Object.entries(severityCounts).reduce((score, [severity, count]) => {
      return score + (severityWeights[severity] || 0) * count;
    }, 0);

    packageScores.set(group.packageName, weightedScore);
  });

  const sortedComponentIssuesGroups = componentIssuesGroups.sort((groupA, groupB) => {
    const scoreA = packageScores.get(groupA.packageName) || 0;
    const scoreB = packageScores.get(groupB.packageName) || 0;
    return scoreB - scoreA;
  });

  return sortedComponentIssuesGroups;
}

function showIssue(issue: ComponentIssue, filterCVE: string) {
  // Find if any vulns match our filter
  switch (filterCVE) {
    case "cveCriticalHigh":
      return (
        issue.severity.valueOf() === ComponentIssueSeverity.CRITICAL.valueOf() ||
        issue.severity.valueOf() === ComponentIssueSeverity.HIGH.valueOf()
      );

    case "sla": {
      return issue.dueAt && new Date().getTime() > new Date(issue.dueAt).getTime();
    }

    case "slaSoon": {
      return (
        // overdue or due within 2 weeks
        issue.dueAt &&
        (new Date().getTime() > new Date(issue.dueAt).getTime() ||
          new Date(issue.dueAt).getTime() - new Date().getTime() < 14 * 24 * 60 * 60 * 1000)
      );
    }

    default:
      return true;
  }
}

function showVuln(vuln: Vuln, filterCVE: string) {
  if (
    filterCVE === "cveCriticalHigh" &&
    (vuln.valueOf() === ComponentIssueSeverity.MEDIUM.valueOf() ||
      vuln.valueOf() === ComponentIssueSeverity.LOW.valueOf() ||
      vuln.valueOf() === ComponentIssueSeverity.NEGLIGIBLE.valueOf() ||
      vuln.valueOf() === ComponentIssueSeverity.UNSPECIFIED.valueOf())
  ) {
    return false;
  }

  return true;
}

export function OverviewIssueTable(props: { issues: ComponentIssue[] | undefined; defaultFilterCVE?: string }) {
  // CVE filter
  const [filterCVE, setFilterCVE] = useState("cveCriticalHigh");
  const [appliedDefaultFilter, setAppliedDefaultFilter] = useState(false);
  const handleCVEChange = (event: React.MouseEvent<HTMLElement>, newFilterCVE: string) => {
    setFilterCVE(newFilterCVE);
  };

  // Set default filter
  if (props.defaultFilterCVE && filterCVE !== props.defaultFilterCVE && !appliedDefaultFilter) {
    setFilterCVE(props.defaultFilterCVE);
    setAppliedDefaultFilter(true);
  }

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={filterCVE}
        exclusive
        onChange={handleCVEChange}
        aria-label="Platform"
        sx={{ marginBottom: "10px", marginRight: "20px" }}
      >
        <ToggleButton value="cveAll">All CVEs</ToggleButton>
        <ToggleButton value="cveCriticalHigh">Critical &amp; High CVEs</ToggleButton>
        <ToggleButton value="slaSoon">SLA Due Soon</ToggleButton>
        <ToggleButton value="sla">SLA Violations</ToggleButton>
      </ToggleButtonGroup>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Severity Summary</TableCell>
            </TableRow>
          </TableHead>
          <RiskTableBody issues={props.issues} filterCVE={filterCVE} />
        </Table>
      </TableContainer>
    </Container>
  );
}

function ConvertComponentIssuesToVulns(issues: ComponentIssue[]): Vuln[] {
  const vulns: Vuln[] = issues.map((issue: ComponentIssue) => {
    const vuln: Vuln = {
      severity: issue.severity.valueOf(),
    };

    return vuln;
  });

  return vulns;
}

function RiskTableBody(props: { issues: ComponentIssue[] | undefined; filterCVE: string }) {
  // Expanded rows
  const [expandedRows, setExpandedRows] = useState(new Set<string>());

  const handlePkgExpansionToggled = (issue: ComponentIssuesGroup) => {
    const expanded = new Set(expandedRows);
    if (expanded.has(issue.packageName)) {
      expanded.delete(issue.packageName);
    } else {
      expanded.add(issue.packageName);
    }

    setExpandedRows(expanded);
  };

  // Loading State
  if (props.issues === undefined) {
    return (
      <TableBody>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan={6}>
            <Box
              sx={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                width: "400px",
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                Analyzing Active SBOMs...
              </Typography>
              <LinearProgress
                sx={{
                  width: "200px",
                  marginTop: "3px",
                  marginRight: "auto",
                  marginBottom: "20px",
                  marginLeft: "auto",
                }}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  // Empty state: no workloads
  // if (props.workloadCount === 0) {
  //   return (
  //     <EmptyState>
  //       <Box sx={{textAlign: "center", display: "block", justifyContent: "center", alignItems: "center", width: '550px', marginTop: "20px", marginLeft: 'auto', marginRight: 'auto'}}>
  //         <Typography variant="h6" gutterBottom sx={{display: 'block'}}>You don't have any workloads</Typography>
  //         <Button href="/onboarding" variant="outlined" size="small" sx={{marginTop: '10px', marginBottom: '20px'}}>Visit Onboarding to Start</Button>
  //       </Box>
  //     </EmptyState>
  //   )
  // }

  // Empty State: No vulns found at all
  if (props.issues.length === 0) {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No vulnerable packages found!
        </Typography>
      </EmptyState>
    );
  }

  // Empty State: no vulns found after filtering
  const filteredPackages = props.issues.filter((pkg: ComponentIssue) => showIssue(pkg, props.filterCVE));
  if (filteredPackages.length === 0 && props.filterCVE === "cveCriticalHigh") {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No Critical or High vulnerabilities!
        </Typography>
      </EmptyState>
    );
  }
  if (filteredPackages.length === 0 && props.filterCVE === "cveAll") {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No vulnerabilities!
        </Typography>
      </EmptyState>
    );
  }
  if (filteredPackages.length === 0 && props.filterCVE === "sla") {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No overdue vulnerabilities!
        </Typography>
      </EmptyState>
    );
  }
  if (filteredPackages.length === 0 && props.filterCVE === "slaSoon") {
    return (
      <EmptyState>
        <Typography variant="h6" gutterBottom sx={{ display: "block", marginBottom: "20px" }}>
          No vulnerabilities due within 2 weeks!
        </Typography>
      </EmptyState>
    );
  }

  const groupedPackages = createPackageGroupings(filteredPackages);

  return (
    <TableBody>
      {groupedPackages.map((issueGroup: ComponentIssuesGroup) => (
        <React.Fragment key={issueGroup.packageName}>
          <TableRow key={issueGroup.packageName} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>
              <Link
                component="button"
                sx={{
                  color: "#000",
                  textDecoration: "none",
                  verticalAlign: "unset",
                }}
                onClick={() => handlePkgExpansionToggled(issueGroup)}
              >
                {expandedRows.has(issueGroup.packageName) ? (
                  <IndeterminateCheckBoxOutlined
                    fontSize="inherit"
                    sx={{
                      color: "#999",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                ) : (
                  <AddBoxOutlined
                    fontSize="inherit"
                    sx={{
                      color: "#999",
                      verticalAlign: "middle",
                      marginRight: "4px",
                    }}
                  />
                )}
                {issueGroup.packageName}
              </Link>
            </TableCell>
            <TableCell>
              <PackageType type={issueGroup.componentIssues[0].packageType} name={true} />
            </TableCell>
            <TableCell>
              <VulnMultiIconDisplay
                vulns={ConvertComponentIssuesToVulns(issueGroup.componentIssues).filter((vuln: Vuln) =>
                  showVuln(vuln, props.filterCVE),
                )}
                showVulnSeverity={true}
                showComponentVulns={false}
              />
            </TableCell>
          </TableRow>
          {expandedRows.has(issueGroup.packageName) && (
            <>
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    padding: "0",
                    background: "#fafafa",
                    borderBottom: "0",
                  }}
                >
                  <Table
                    size="small"
                    aria-label="simple table"
                    sx={{
                      borderBottom: "0",
                      marginLeft: "0px",
                      background: "#fafafa",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        >
                          Vulnerability ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        >
                          Severity
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        >
                          Component & Issue ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        >
                          SLA
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        >
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {issueGroup.componentIssues.map((issue: ComponentIssue) => (
                        <React.Fragment key={issue.details.value?.id}>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                whiteSpace: "nowrap",
                                maxWidth: "200px",
                              }}
                            >
                              <Link href={issue.details.value?.references[0]?.url}>{issue.details.value?.id}</Link>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                              }}
                            >
                              {issue.details.value && (
                                <VulnChip
                                  preset={VulnSeverity[issue.severity]}
                                  innerText={getStateText(issue.details.value)}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                              }}
                            >
                              <Link component={NavLink} to={"/components/" + issue.componentId + "/vulnerabilities"}>
                                {issue.componentRef?.displayName}
                              </Link>
                              <Box sx={{ color: "#999", display: "inline-block" }}>&nbsp;&bull;&nbsp;</Box>
                              <Link component={NavLink} to={"/components/" + issue.componentId + "/issues/" + issue.id}>
                                #{issue.id}
                              </Link>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                maxWidth: "500px",
                              }}
                            >
                              {issue.dueAt &&
                                issue.updatedAt &&
                                (issue.state === ComponentIssueState.RESOLVED ||
                                  issue.state === ComponentIssueState.IGNORED) &&
                                new Date(issue.updatedAt) <= new Date(issue.dueAt) &&
                                "Met"}
                              {issue.dueAt &&
                                issue.updatedAt &&
                                (issue.state === ComponentIssueState.RESOLVED ||
                                  issue.state === ComponentIssueState.IGNORED) &&
                                new Date(issue.updatedAt) > new Date(issue.dueAt) &&
                                "Overdue"}
                              {issue.dueAt && issue.state === ComponentIssueState.OPEN && (
                                <RelativeTimestamp timestamp={issue.dueAt} />
                              )}
                              {!issue.dueAt && <Box sx={{ color: "#999" }}>No SLA set</Box>}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                maxWidth: "500px",
                              }}
                            >
                              {issue.details.value?.description ? (
                                issue.details.value?.description
                              ) : (
                                <Box sx={{ color: "#999" }}>No description included</Box>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            </>
          )}
        </React.Fragment>
      ))}
    </TableBody>
  );
}

function EmptyState(props: { children: any }) {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={6}>
          <Box
            sx={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              width: "550px",
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {props.children}
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
