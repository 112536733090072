import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { PackageType } from "./PackageType";
import { WorkloadItem } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { LabelDisplay } from "./LabelDisplay";

export function MachineDetailTable(props: { workloadItems: WorkloadItem[] | undefined }) {
  function workloadName(workload: WorkloadItem): string {
    if (workload.labels["kube:pod:name"]) {
      return workload.labels["kube:pod:name"];
    }
    if (workload.labels["container-name"]) {
      return workload.labels["container-name"];
    }
    if (workload.imageName) {
      return workload.imageName;
    }
    return workload.name || "Unnamed";
  }

  function sortWorkloads(a: WorkloadItem, b: WorkloadItem) {
    if (a.componentRef !== undefined && b.componentRef === undefined) {
      return -1;
    } else if (a.componentRef === undefined && b.componentRef !== undefined) {
      return 1;
    } else {
      return 0;
    }
  }

  return (
    <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Workload Name</TableCell>
            <TableCell>Component</TableCell>
            <TableCell>Labels</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.workloadItems?.sort(sortWorkloads).map((workload: WorkloadItem) => (
            <React.Fragment key={workload.id}>
              <TableRow sx={{ "& td": { verticalAlign: "top" }, "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ verticalAlign: "top" }}>
                  <Box sx={{ display: "inline-flex" }}>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                        marginRight: "2px",
                        marginTop: "1px",
                        alignSelf: "self-start",
                        maxWidth: "24px",
                      }}
                    >
                      <PackageType type={workload.type} name={false} />
                    </Box>
                    <Box sx={{ flexGrow: 8 }}>
                      <Box>
                        <Link component={NavLink} to={"/workloads/" + workload.id}>
                          {workloadName(workload)}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ paddingLeft: "20px" }}>
                  {workload.componentRef ? (
                    <>
                      {workload.componentRef?.displayName}
                      <Box sx={{ marginTop: "7px", marginBottom: "2px" }}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            margin: "3px 5px 3px 0",
                            fontSize: "10px",
                            padding: "2px 5px",
                          }}
                          component={NavLink}
                          to={"/components/" + workload.componentRef?.id + "/vulnerabilities"}
                        >
                          View Open Issues
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            margin: "3px 0px",
                            fontSize: "10px",
                            padding: "2px 5px",
                          }}
                          component={NavLink}
                          to={"/sboms/" + workload.sbomId}
                        >
                          View SBOM
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: "inline-block", color: "#999" }}>No Component</Box>
                  )}
                </TableCell>
                <TableCell>
                  <LabelDisplay labels={workload.labels} truncate={2}></LabelDisplay>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          {props.workloadItems?.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "550px",
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                    No workloads found
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ display: "block" }}>
                    Workloads will appear after you've registered your first machine.
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                    component={NavLink}
                    to={"/machines/"}
                  >
                    View Machines
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
