import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import useProjectId from "../hooks/useProjectId";
// import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { ReportColsTable } from "../components/ReportColsTable";

export const ReportCreate = () => {
  // API client
  // const client = useClient(EdgeBitPublicAPIService);

  const [currentProjectID, setCurrentProjectID] = useState<string | undefined>();

  const [connectivity, setConnectivity] = useState<boolean>(true);

  useProjectId((projectId) => {
    setCurrentProjectID(projectId);

    // pass lint temporarily
    setConnectivity(true);
  });

  // Save Form
  const handleReportSave = () => {
    if (!currentProjectID) {
      console.log("No project ID set, can't create token");
      return;
    }

    // client.createOrgAccessToken({
    //     projectId: projectId,
    //     description: createAPITokenFormData?.description || 'New Build Token',
    // }).then(
    //     (res) => {
    //         setDesiredTags(null);
    //         setAPITokenModalValue(res);
    //     },
    //     (err) => {
    //         console.log(err);
    //         setConnectivity(false);
    //     }
    // );
  };

  useEffect(() => {
    document.title = "Create Report";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Create New Aggregated Report
      </Typography>
      <Typography variant="h4" gutterBottom sx={{ fontSize: "18px", marginTop: "30px" }}>
        Report Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Report Name:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField required id="report-name" label="" defaultValue="" size="small" fullWidth />
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: "18px",
          borderTop: "1px solid #ddd",
          marginTop: "20px",
          paddingTop: "20px",
        }}
      >
        Aggregation
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999" }}>
        EdgeBit will collect and aggregate data for all matching SBOMs and vulnerabilities.
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Component Label Query:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField required id="report-label-query" label="" defaultValue="app=foobar" size="small" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Build-time data:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={9}>
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Include build-time SBOMs" />
            <FormControl sx={{ marginTop: "10px", minWidth: 120 }} size="small">
              <InputLabel id="build-recency">Build Recency</InputLabel>
              <Select
                labelId="build-recency"
                id="build-recency"
                value="last"
                label="Build Recency"
                sx={{ maxWidth: "250px" }}
              >
                <MenuItem value={"last"}>Include Last Build Only</MenuItem>
                <MenuItem value={"24hours"}>Builds in Last 24 hours</MenuItem>
                <MenuItem value={"7days"}>Builds in Last 7 days</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: "16px", marginTop: "10px", fontWeight: "500" }}>
            Real-time data:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={9}>
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Include real-time SBOMs" />
            <FormControl sx={{ marginTop: "10px", minWidth: 120 }} size="small">
              <InputLabel id="realtime-recency">Real-time Recency</InputLabel>
              <Select
                labelId="realtime-recency"
                id="realtime-recency"
                value="live"
                label="Real-time Recency"
                sx={{ maxWidth: "250px" }}
              >
                <MenuItem value={"live"}>Include Live Machines Only</MenuItem>
                <MenuItem value={"24hours"}>Machines Seen in Last 24 hours</MenuItem>
                <MenuItem value={"7days"}>Machines Seen in Last 7 days</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: "18px",
          borderTop: "1px solid #ddd",
          marginTop: "20px",
          paddingTop: "20px",
        }}
      >
        Columns
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ fontSize: "14px", color: "#999" }}>
        Select which columns will be included in the aggregated report.
      </Typography>
      <ReportColsTable />
      <Box
        sx={{
          borderTop: "1px solid #ddd",
          marginTop: "30px",
          paddingTop: "10px",
        }}
      >
        <EdgeBitPrimaryButton
          type="submit"
          variant="outlined"
          size="medium"
          onClick={handleReportSave}
          sx={{ marginTop: "20px", marginBottom: "0px" }}
        >
          Create Report
        </EdgeBitPrimaryButton>
      </Box>
    </BodyWrapperProjectScoped>
  );
};
