import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { RadioGroup, Radio } from "@mui/material";
import { FormControl, FormLabel, FormControlLabel } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useClient } from "../api/client";
import { ProjectContext } from "../App";
import { AgentDeployTokenTable } from "../components/AgentDeployTokenTable";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { OrgAccessTokenTable } from "../components/OrgAccessTokenTable";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import {
  AgentDeployToken,
  CreateAgentDeployTokenResponse,
  CreateOrgAccessTokenResponse,
  OrgAccessToken,
} from "../pb/edgebit/platform/v1alpha/platform_pb";

export const OrgAccessTokenList = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [connectivity, setConnectivity] = useState<boolean>(true);

  useEffect(() => {
    document.title = "Access Tokens - EdgeBit";
  }, []);

  // TODO: this whole pattern is totally unmaintainable and has tons of weird edge cases.
  const project = useContext(ProjectContext);

  // API Token State & Management

  const [apiTokenList, setAPITokenList] = useState<OrgAccessToken[] | null>(null);
  const [apiTokenModalValue, setAPITokenModalValue] = useState<CreateOrgAccessTokenResponse | null>(null);
  const [apiTokenModalOpen, setAPITokenModalOpen] = useState<true | false>(false);
  const [createAPITokenFormData, setCreateAPITokenFormData] = useState<{
    description: string;
  } | null>(null);

  let refreshAPITokenList = () => {
    if (!project) {
      return;
    }
    client.listOrgAccessTokens({ projectId: project.id }).then(
      (res) => {
        setAPITokenList(res.tokens);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
  };

  useEffect(refreshAPITokenList, [project, client]);

  const handleCreateAPITokenSubmit = () => {
    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    client
      .createOrgAccessToken({
        projectId: project.id,
        description: createAPITokenFormData?.description || "New Build Token",
      })
      .then(
        (res) => {
          setCreateAPITokenFormData(null);
          setAPITokenModalValue(res);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
  };

  const handleAPITokenValueClose = () => {
    refreshAPITokenList();
    setAPITokenModalOpen(false);
  };

  const handleCreateAPITokenOpen = () => {
    setCreateAPITokenFormData({ description: "" });
    setAPITokenModalValue(null);
    setAPITokenModalOpen(true);
  };

  const handleCreateAPITokenClose = () => {
    setCreateAPITokenFormData(null);
  };

  // Agent Deploy Token State & Management

  const [agentTokenList, setAgentTokenList] = useState<AgentDeployToken[] | null>(null);
  const [agentTokenModalValue, setAgentTokenModalValue] = useState<CreateAgentDeployTokenResponse | null>(null);
  const [agentTokenModalOpen, setAgentTokenModalOpen] = useState<true | false>(false);

  const defaultCreateAgentTokenFormData = {
    active: false,
    description: "",
    role: "node-agent",
  };
  const [createAgentTokenFormData, setCreateAgentTokenFormData] = useState(defaultCreateAgentTokenFormData);

  let refreshAgentTokenList = () => {
    if (!project) {
      return;
    }
    client.listAgentDeployTokens({ projectId: project.id }).then(
      (res) => {
        setAgentTokenList(res.tokens);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
  };

  useEffect(refreshAgentTokenList, [project, client]);

  const handleCreateAgentTokenSubmit = () => {
    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    client
      .createAgentDeployToken({
        projectId: project.id,
        description: createAgentTokenFormData.description || "New Agent Deploy Token",
        roles: [createAgentTokenFormData.role],
      })
      .then(
        (res) => {
          handleCreateAgentTokenClose();
          setAgentTokenModalValue(res);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
  };

  const handleAgentTokenValueClose = () => {
    refreshAgentTokenList();
    setAgentTokenModalOpen(false);
  };

  const handleCreateAgentTokenOpen = () => {
    setAgentTokenModalValue(null);
    setCreateAgentTokenFormData({
      ...createAgentTokenFormData,
      active: true,
    });
    setAgentTokenModalOpen(true);
  };

  const handleCreateAgentTokenClose = () => {
    setCreateAgentTokenFormData(defaultCreateAgentTokenFormData);
  };

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      {/* API Token Secret Details Dialog */}
      <Dialog
        open={apiTokenModalOpen}
        onClose={handleAPITokenValueClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Secret for " + apiTokenModalValue?.description}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Copy your new token's secret – you won't be able to see this again:
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Token Secret"
            variant="outlined"
            focused
            value={apiTokenModalValue?.token || "Loading"}
            fullWidth
            sx={{ marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <Button onClick={handleAPITokenValueClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create API Token Form */}
      <Dialog
        open={createAPITokenFormData ? true : false}
        onClose={handleCreateAPITokenClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Name Your New Token"}</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            onChange={(e) => setCreateAPITokenFormData({ description: e.target.value })}
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "5px" }}
          />
          <DialogContentText id="alert-dialog-description">
            Name your token with a description of where it's used, e.g. "GitHub Actions for frontend repo".
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleCreateAPITokenSubmit} autoFocus>
            Create Token
          </EdgeBitPrimaryButton>
          <Button onClick={handleCreateAPITokenClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Agent Deploy Token Secret Details Dialog */}
      <Dialog
        open={agentTokenModalOpen}
        onClose={handleAgentTokenValueClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Secret for " + agentTokenModalValue?.description}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Copy your new token's secret – you won't be able to see this again:
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Token Secret"
            variant="outlined"
            focused
            value={agentTokenModalValue?.token || "Loading"}
            fullWidth
            sx={{ marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <Button onClick={handleAgentTokenValueClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Agent Deploy Token Form */}
      <Dialog
        open={createAgentTokenFormData.active}
        onClose={handleCreateAgentTokenClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"New Agent Deployment Token"}</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Short description of where the token will be used"
            variant="outlined"
            onChange={(e) =>
              setCreateAgentTokenFormData({
                ...createAgentTokenFormData,
                description: e.target.value,
              })
            }
            fullWidth
            sx={{ marginBottom: "10px", marginTop: "5px" }}
          />
          <FormControl>
            <FormLabel>Select the role the for the new token</FormLabel>
            <RadioGroup
              value={createAgentTokenFormData.role}
              onChange={(e, v) =>
                setCreateAgentTokenFormData({
                  ...createAgentTokenFormData,
                  role: v,
                })
              }
            >
              <FormControlLabel value="node-agent" control={<Radio />} label="Node Agent" />
              <FormControlLabel value="cluster-agent" control={<Radio />} label="Cluster Agent" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-start", borderTop: "1px solid #ddd", padding: "10px 10px 10px 23px" }}
        >
          <EdgeBitPrimaryButton onClick={handleCreateAgentTokenSubmit} autoFocus>
            Create Token
          </EdgeBitPrimaryButton>
          <Button onClick={handleCreateAgentTokenClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Agent Deploy Tokens */}
      <Typography variant="h4" gutterBottom>
        Agent Deployment Tokens
      </Typography>
      <Typography variant="body1" gutterBottom>
        Generate deployment tokens for the EdgeBit agent for Linux.
        <br />
        Deployment tokens are narrowly scoped and used as refresh tokens used to periodically obtain short-lived
        credentials. Read <a href="https://edgebit.io/docs/0.x/install-agent/">Install Agent on Linux</a> to learn more.
      </Typography>
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px" }}
        onClick={handleCreateAgentTokenOpen}
      >
        Create Deployment Token
      </EdgeBitPrimaryButton>
      <AgentDeployTokenTable
        tokens={agentTokenList}
        handleCreateTokenClick={handleCreateAgentTokenOpen}
        refreshTokens={refreshAgentTokenList}
      />

      {/* API Tokens*/}
      <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
        Build/API Access Tokens
      </Typography>
      <Typography variant="body1" gutterBottom>
        Generate access tokens for use in CI/CD pipelines. Access tokens are long lived and do not expire until deleted.
        <br />
        Read <a href="https://edgebit.io/docs/0.x/install-build/">Configure a Build Pipeline</a> to learn how to upload
        a software bill of material (SBOM) and build metadata per code commit.
      </Typography>
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        sx={{ marginTop: "20px", marginBottom: "0px" }}
        onClick={handleCreateAPITokenOpen}
      >
        Create Access Token
      </EdgeBitPrimaryButton>
      <OrgAccessTokenTable
        tokens={apiTokenList}
        handleCreateTokenClick={handleCreateAPITokenOpen}
        refreshTokens={refreshAPITokenList}
      />
    </BodyWrapperProjectScoped>
  );
};
