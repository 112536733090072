import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Machine } from "../pb/edgebit/platform/v1alpha/platform_pb";

interface FilterRowProps {
  namespace: string;
  value: string;
  handleFilterToggled: any;
  filterLabels: Array<string>;
}

function FilterRow(props: FilterRowProps) {
  const { namespace, value } = props;

  var isActive = false;
  props.filterLabels.forEach((filterLabelString) => {
    if (filterLabelString === namespace + "=" + value) {
      isActive = true;
    }
  });

  return (
    <>
      {isActive ? (
        <Typography
          sx={{
            fontSize: "12px",
            background: "#E9EEFF",
            color: "#3256AB",
            cursor: "pointer",
            padding: "3px 5px 3px 15px",
            borderBottom: "1px solid #fff",
          }}
          onClick={() => props.handleFilterToggled(namespace + "=" + value)}
        >
          {middleTruncate(value, 33)}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontSize: "12px",
            cursor: "pointer",
            padding: "3px 5px 3px 15px",
            borderBottom: "1px solid #fff",
          }}
          onClick={() => props.handleFilterToggled(namespace + "=" + value)}
        >
          {middleTruncate(value, 33)}
        </Typography>
      )}
    </>
  );
}

const middleTruncate = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }

  const halfLength = Math.floor((maxLength - 3) / 2);
  const firstHalf = text.slice(0, halfLength);
  const secondHalf = text.slice(text.length - halfLength);

  return firstHalf + "..." + secondHalf;
};

export function MachineFilter(props: {
  machines: Machine[] | null;
  handleFilterToggled: any;
  filterLabels: Array<string>;
}) {
  // Object to store unique labels by key and value
  const uniqueLabels: { [key: string]: string[] } = {};

  // Iterate over the WorkloadItem objects and collect unique labels
  props.machines?.forEach((machine) => {
    const labels = machine.labels;
    Object.entries(labels).forEach(([labelKey, labelValue]) => {
      if (!uniqueLabels[labelKey]) {
        uniqueLabels[labelKey] = [];
      }
      if (!uniqueLabels[labelKey].includes(labelValue)) {
        uniqueLabels[labelKey].push(labelValue);
      }
    });
  });

  return (
    <Box
      sx={{
        display: "table-cell",
        borderRight: "1px solid #ddd",
        minWidth: "200px",
        padding: "8px 0 0 0",
        verticalAlign: "top",
      }}
    >
      <Typography sx={{ fontWeight: 500, fontSize: "14px", margin: "0px 15px 0px 15px" }}>Cloud Accounts</Typography>
      {uniqueLabels["cloud:account-id"] && uniqueLabels["cloud:account-id"].length > 0 ? (
        <>
          <Box>
            {uniqueLabels["cloud:account-id"].map((accountId, index) => (
              <FilterRow
                namespace={"cloud:account-id"}
                value={accountId}
                key={accountId + index}
                handleFilterToggled={props.handleFilterToggled}
                filterLabels={props.filterLabels}
              ></FilterRow>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: "12px",
              padding: "3px 5px 3px 15px",
              borderBottom: "1px solid #fff",
              color: "#999",
            }}
          >
            No account IDs reported
          </Typography>
        </>
      )}

      <Typography sx={{ fontWeight: 500, fontSize: "14px", margin: "5px 15px 0px 15px" }}>Cloud Region</Typography>
      {uniqueLabels["cloud:region"] && uniqueLabels["cloud:region"].length > 0 ? (
        <>
          <Box>
            {uniqueLabels["cloud:region"].map((namespace, index) => (
              <FilterRow
                namespace={"cloud:region"}
                value={namespace}
                key={namespace + index}
                handleFilterToggled={props.handleFilterToggled}
                filterLabels={props.filterLabels}
              ></FilterRow>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: "12px",
              padding: "3px 5px 3px 15px",
              borderBottom: "1px solid #fff",
              color: "#999",
            }}
          >
            No cloud regions reported
          </Typography>
        </>
      )}

      <Typography sx={{ fontWeight: 500, fontSize: "14px", margin: "5px 15px 0 15px" }}>Clusters</Typography>
      {uniqueLabels["kube:cluster:name"] && uniqueLabels["kube:cluster:name"].length > 0 && (
        <>
          <Box>
            {uniqueLabels["kube:cluster:name"].map((name, index) => (
              <FilterRow
                namespace={"kube:cluster:name"}
                value={name}
                key={name + index}
                handleFilterToggled={props.handleFilterToggled}
                filterLabels={props.filterLabels}
              ></FilterRow>
            ))}
          </Box>
        </>
      )}
      {uniqueLabels["aws:ecs:clusterName"] && uniqueLabels["aws:ecs:clusterName"].length > 0 && (
        <>
          <Box>
            {uniqueLabels["aws:ecs:clusterName"].map((name, index) => (
              <FilterRow
                namespace={"aws:ecs:clusterName"}
                value={name}
                key={name + index}
                handleFilterToggled={props.handleFilterToggled}
                filterLabels={props.filterLabels}
              ></FilterRow>
            ))}
          </Box>
        </>
      )}
      {(!uniqueLabels["kube:cluster:name"] || uniqueLabels["kube:cluster:name"].length === 0) &&
        (!uniqueLabels["aws:ecs:clusterName"] || uniqueLabels["aws:ecs:clusterName"].length === 0) && (
          <>
            <Typography
              sx={{
                fontSize: "12px",
                padding: "3px 5px 3px 15px",
                borderBottom: "1px solid #fff",
                color: "#999",
              }}
            >
              No clusters reported
            </Typography>
          </>
        )}

      <Typography sx={{ fontWeight: 500, fontSize: "14px", margin: "5px 15px 0px 15px" }}>OS Name</Typography>
      {uniqueLabels["os-pretty-name"] && uniqueLabels["os-pretty-name"].length > 0 ? (
        <>
          <Box>
            {uniqueLabels["os-pretty-name"].map((namespace, index) => (
              <FilterRow
                namespace={"os-pretty-name"}
                value={namespace}
                key={namespace + index}
                handleFilterToggled={props.handleFilterToggled}
                filterLabels={props.filterLabels}
              ></FilterRow>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: "12px",
              padding: "3px 5px 3px 15px",
              borderBottom: "1px solid #fff",
              color: "#999",
            }}
          >
            No OS names reported
          </Typography>
        </>
      )}
    </Box>
  );
}
