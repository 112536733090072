import { Typography, TableContainer, Paper, Grid, FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { MachineTable } from "../components/MachineTable";
import { MachineFilter } from "../components/MachineFilter";
import useProjectId from "../hooks/useProjectId";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { Machine } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";

export const MachineList = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [machineList, setMachineList] = useState<any | null>(null);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [filterLabels, setFilterLabels] = useState(new Array<string>());
  const [machineGroupLabel, setMachineGroupLabel] = useState<string>("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  // Table Filter
  const handleFilterToggled = (newLabelPair: string) => {
    const desiredFilter = Array.from(filterLabels);

    const index = desiredFilter.indexOf(newLabelPair);
    if (index !== -1) {
      desiredFilter.splice(index, 1);
    } else {
      desiredFilter.push(newLabelPair);
    }

    setFilterLabels(desiredFilter);
  };

  // Table Grouping
  const handleGroupingChange = (event: SelectChangeEvent) => {
    setMachineGroupLabel(event.target.value);
  };

  // Iterate over the Machine objects and collect unique labels
  const uniqueLabels: { [key: string]: string[] } = {};
  if (machineList) {
    machineList.forEach((machine: Machine) => {
      const labels = machine.labels;
      Object.entries(labels).forEach(([labelKey, labelValue]) => {
        if (!uniqueLabels[labelKey]) {
          uniqueLabels[labelKey] = [];
        }
        if (!uniqueLabels[labelKey].includes(labelValue)) {
          uniqueLabels[labelKey].push(labelValue);
        }
      });
    });
  }

  useProjectId((projectId) => {
    client.listMachines({ projectId: projectId }).then(
      (res) => {
        setMachineList(res.machines);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
  });

  useEffect(() => {
    document.title = "Machines - EdgeBit";

    // Read URL parameters and deserialize
    var readFilterParams = searchParams.get("labels")?.split(",") || [];
    setFilterLabels(readFilterParams);
    var readGroupParams = searchParams.get("group") || "";
    setMachineGroupLabel(readGroupParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Navigate to new search location when changed
  // If state is empty, remove it for cleaner URLs
  useEffect(() => {
    const updatedParams = createSearchParams(searchParams);

    if (filterLabels.length > 0 && filterLabels[0] !== "") {
      updatedParams.set("labels", filterLabels.toString());
    } else {
      updatedParams.delete("labels");
    }

    if (machineGroupLabel) {
      updatedParams.set("group", machineGroupLabel);
    } else {
      updatedParams.delete("group");
    }

    navigate({ search: updatedParams.toString() }, { replace: true });
  }, [filterLabels, machineGroupLabel, navigate, searchParams]);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom>
            Machines
          </Typography>
          <Typography variant="body1" gutterBottom>
            Connected EdgeBit agents will automatically show here. Read{" "}
            <a href="https://edgebit.io/docs/0.x/install-agent/">Install Agent on Linux</a> to learn more.
          </Typography>
          <EdgeBitPrimaryButton
            type="submit"
            variant="outlined"
            size="medium"
            sx={{ marginTop: "20px", marginBottom: "0px" }}
            onClick={() => {
              window.open("https://edgebit.io/docs/0.x/install-agent/", "_blank");
            }}
          >
            Install a new Agent
          </EdgeBitPrimaryButton>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right", alignSelf: "end" }}>
          <FormControl sx={{ marginTop: "20px", minWidth: 120, verticalAlign: "bottom" }} size="small">
            <InputLabel id="demo-select-small-label">Group by:</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={machineGroupLabel}
              label="Group by:"
              onChange={handleGroupingChange}
            >
              {uniqueLabels &&
                Object.keys(uniqueLabels).map((label) => (
                  <MenuItem key={label} value={label}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ display: "table", marginTop: "20px", border: "1px solid #ddd" }}>
        <MachineFilter
          machines={machineList}
          handleFilterToggled={handleFilterToggled}
          filterLabels={filterLabels}
        ></MachineFilter>
        <MachineTable
          machines={machineList}
          filterLabels={filterLabels}
          groupingLabel={machineGroupLabel}
        ></MachineTable>
      </TableContainer>
    </BodyWrapperProjectScoped>
  );
};
