import { Code, createPromiseClient, Interceptor, PromiseClient, Transport } from "@bufbuild/connect";
import { createConnectTransport } from "@bufbuild/connect-web";
import { ServiceType } from "@bufbuild/protobuf";
import { useContext, useMemo } from "react";
import { AuthContext } from "../components/AuthContextProvider";

const authTokenLocalStorageKey = "auth-token";

export function storeAuthToken(token: string) {
  window.localStorage.setItem(authTokenLocalStorageKey, JSON.stringify(token));
}

export function loadAuthToken(): string | null {
  const rawToken = window.localStorage.getItem(authTokenLocalStorageKey);
  if (!rawToken) {
    return null;
  }

  try {
    return JSON.parse(rawToken);
  } catch {
    return null;
  }
}

export function invalidateAuthToken() {
  window.localStorage.removeItem(authTokenLocalStorageKey);
}

export const apiBaseUrl = window.location.origin;

export const ssoAuthURL = `${apiBaseUrl}/v1/sso/authenticate`;

export const buildSSOAuthURL = (next: string) => {
  const url = new URL(ssoAuthURL);
  url.searchParams.set("next", next);
  return url.toString();
};

export function useClient<T extends ServiceType>(service: T): PromiseClient<T> {
  const { transport } = useContext(AuthContext);
  if (!transport) {
    throw new Error("missing transport");
  }

  return useMemo(() => createPromiseClient(service, transport), [service, transport]);
}

const unauthenticatedTransport = createConnectTransport({
  jsonOptions: { ignoreUnknownFields: true },
  baseUrl: apiBaseUrl,
});

export function useUnauthenticatedClient<T extends ServiceType>(service: T): PromiseClient<T> {
  return useMemo(() => createPromiseClient(service, unauthenticatedTransport), [service]);
}

export function createAuthenticatedTransport(authToken: string, setAuthToken: any): Transport {
  const authInterceptor: Interceptor = (next) => async (req) => {
    req.header.set("authorization", `Bearer ${authToken}`);
    return await next(req).catch((err) => {
      if (err.code === Code.Unauthenticated) {
        console.log("call failed due to authentication error; unsettting auth token");
        setAuthToken(null);
      }

      throw err;
    });
  };

  return createConnectTransport({
    baseUrl: apiBaseUrl,
    interceptors: [authInterceptor],
    jsonOptions: { ignoreUnknownFields: true },
  });
}
