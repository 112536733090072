import { Transport } from "@bufbuild/connect";
import { createContext, useEffect, useState } from "react";
import { createAuthenticatedTransport, invalidateAuthToken, loadAuthToken, storeAuthToken } from "../api/client";

export type AuthTokenSetter = (token: string | null) => void;

export const AuthContext = createContext<{
  transport: Transport | null;
  setAuthToken: AuthTokenSetter;
}>({
  transport: null,
  setAuthToken: () => {},
});

export function AuthContextProvider(props: { children?: any }) {
  const [authToken, setAuthToken] = useState<string | null>(loadAuthToken());
  const [authenticatedTransport, setAuthenticatedTransport] = useState<Transport | null>(null);

  useEffect(() => {
    if (authToken) {
      storeAuthToken(authToken);
      setAuthenticatedTransport(createAuthenticatedTransport(authToken, setAuthToken));
    } else {
      invalidateAuthToken();
      setAuthenticatedTransport(null);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={{ transport: authenticatedTransport, setAuthToken: setAuthToken }}>
      {props.children}
    </AuthContext.Provider>
  );
}
