import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";

export default function TextOverflow(props: { max: number; text: string }) {
  let maxWidth = props.max * 8;

  return (
    <>
      {props.text.length > props.max ? (
        <Tooltip title={props.text} placement="bottom-start">
          <Box
            sx={{
              display: "inline-block",
              maxWidth: { maxWidth },
              overflow: "hidden",
              textWrap: "nowrap",
              textOverflow: "ellipsis",
              verticalAlign: "top",
              "&:hover": { cursor: "pointer" },
            }}
          >
            {props.text}
          </Box>
        </Tooltip>
      ) : (
        <Box sx={{ display: "inline-block", verticalAlign: "top" }}>{props.text}</Box>
      )}
    </>
  );
}
