import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContextProvider";

export function AuthenticatedRoute(props: { children?: any }) {
  const { transport } = useContext(AuthContext);

  if (transport) {
    return <>{props.children}</>;
  } else {
    return <Navigate to="/login" state={{ next: window.location.pathname + window.location.search }} replace />;
  }
}
