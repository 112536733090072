import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListSubheader from "@mui/material/ListSubheader";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";

export function ReportDataSelect() {
  const [selection, setSelection] = useState("sbom:depdency_name");

  const handleChange = (event: SelectChangeEvent) => {
    setSelection(event.target.value as string);
  };

  return (
    <FormControl sx={{ minWidth: 120, display: "flex", flexDirection: "row" }} size="small">
      <Select
        labelId="build-recency"
        id="build-recency"
        value={selection}
        sx={{ maxWidth: "350px", flex: "1" }}
        onChange={handleChange}
      >
        <ListSubheader sx={{ lineHeight: "26px" }}>SBOM Labels</ListSubheader>
        <MenuItem value={"label:value"}>Value from Label</MenuItem>
        <ListSubheader
          sx={{
            lineHeight: "26px",
            borderTop: "1px solid #ccc",
            marginTop: "5px",
            paddingTop: "5px",
          }}
        >
          SBOM Fields
        </ListSubheader>
        <MenuItem value={"sbom:depdency_name"}>Dependency Name</MenuItem>
        <MenuItem value={"sbom:dependency_version"}>Dependecy Version</MenuItem>
        <ListSubheader
          sx={{
            lineHeight: "26px",
            borderTop: "1px solid #ccc",
            marginTop: "5px",
            paddingTop: "5px",
          }}
        >
          Open Source Attributes
        </ListSubheader>
        <MenuItem value={"oss:latest_version"}>Latest Version</MenuItem>
        <MenuItem value={"oss:license_type"}>License Type</MenuItem>
      </Select>
      {selection === "label:value" && (
        <TextField
          required
          id="label-value"
          label=""
          defaultValue="app"
          size="small"
          sx={{ flex: "1", width: "100px", marginLeft: "10px" }}
        />
      )}
    </FormControl>
  );
}
