import { Alert, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Code, ConnectError } from "@bufbuild/connect";
import { PartialMessage } from "@bufbuild/protobuf";

import { ProjectContext } from "../App";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { GitHubImportableRepos, GitHubRepository } from "../components/GitHubImportableRepos";
import { GlobalError } from "../components/GlobalError";
import { GitHubRepoToImport, SBOMGenerationSource } from "../pb/edgebit/platform/v1alpha/platform_pb";

export const GitHubImport = () => {
  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const project = useContext(ProjectContext);
  const [repos, setRepos] = useState<GitHubRepository[]>([]);
  const [connectivity, setConnectivity] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [enabled, setEnabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const defaultSBOMSources = [
    SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED,
    SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE,
  ];

  // Do the import
  const handleImport = async () => {
    if (!project) {
      console.log("No project ID set, can't create token");
      return;
    }

    try {
      const pbRepos = repos.reduce((prev: PartialMessage<GitHubRepoToImport>[], repo) => {
        if (repo.selected) {
          prev.push({
            integrationId: repo.integrationId,
            owner: repo.owner,
            name: repo.name,
            url: repo.url,
            sbomSource: repo.selectedSBOMSource,
          });
        }

        return prev;
      }, []);

      await client.importGitHubRepos({
        projectId: project.id,
        repos: pbRepos,
      });

      navigate("/components/");
    } catch (err) {
      console.log(err);
      if (err instanceof ConnectError && err.code === Code.InvalidArgument) {
        setError(err.rawMessage);
      } else {
        setError("Unknown error");
      }
    }
  };

  useEffect(() => {
    if (project) {
      client.hasGitHubIntegration({ projectId: project.id }).then(
        (res) => {
          setEnabled(res.present);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
    }
    if (project && enabled) {
      const fetch = async () => {
        try {
          const repos = (
            await client.listGitHubRepositories({
              projectId: project.id,
              includeConfigured: false,
            })
          ).repos;

          setRepos(
            repos.map((repo) => ({
              id: repo.name,
              owner: repo.owner,
              name: repo.name,
              description: repo.description,
              url: repo.url,
              selected: false,
              selectedSBOMSource: SBOMGenerationSource.SBOM_GENERATION_SOURCE_UNSPECIFIED,
              availSBOMSources: [],
              integrationId: repo.integrationId,
            })),
          );

          setLoading(false);
        } catch (err) {
          console.log(err);
          setConnectivity(false);
          setLoading(false);
        }
      };

      fetch();
    }
  }, [project, client, enabled]);

  const handleRepoSelectionChange = async (changed: GitHubRepository) => {
    if (changed.selected) {
      const repoInfo = await client.analyzeGitHubRepo({
        projectId: project?.id,
        integrationId: changed.integrationId,
        owner: changed.owner,
        name: changed.name,
      });

      const available = repoInfo.buildActionPresent
        ? [...defaultSBOMSources, SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD]
        : defaultSBOMSources;

      changed.availSBOMSources = available;
      changed.selectedSBOMSource = repoInfo.buildActionPresent
        ? SBOMGenerationSource.SBOM_GENERATION_SOURCE_BUILD
        : SBOMGenerationSource.SBOM_GENERATION_SOURCE_SOURCE;
    }

    setRepos([...repos]);
  };

  const handleSBOMChange = async (changed: GitHubRepository) => {
    setRepos([...repos]);
  };

  useEffect(() => {
    document.title = "Import Repositories - EdgeBit";
  });

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom sx={{ marginBottom: "20px" }}>
        Import from GitHub
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ "& ul": { margin: "5px 0", paddingLeft: "25px" } }}>
        During import:
        <ul>
          <li>an EdgeBit Component is created</li>
          <li>a Pull Request is made to configure each repository's GitHub Actions workflow</li>
          <li>PR checks and the EdgeBit bot are enabled</li>
        </ul>
        Read <a href="https://edgebit.io/docs/0.x/install-build/">the configuration guide</a> for more details.
      </Typography>
      <GitHubImportableRepos
        repos={repos}
        loading={loading}
        enabled={enabled}
        onSelectionChange={handleRepoSelectionChange}
        onSBOMChange={handleSBOMChange}
      />
      {error && (
        <Alert style={{ marginTop: 11 }} severity="error">
          {error}
        </Alert>
      )}
      <EdgeBitPrimaryButton
        type="submit"
        variant="outlined"
        size="medium"
        onClick={handleImport}
        sx={{ marginTop: "20px", marginBottom: "0px" }}
      >
        Import
      </EdgeBitPrimaryButton>
    </BodyWrapperProjectScoped>
  );
};
