// import * as React from 'react';
import { CloudOff, CloudQueue } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { LabelDisplay } from "../components/LabelDisplay";
import { NavLink } from "react-router-dom";
import { Machine } from "../pb/edgebit/platform/v1alpha/platform_pb";

function hasLabels(machine: Machine, filterLabels: Array<string>): boolean {
  const labels = machine.labels;
  var match = false;

  Object.entries(labels).forEach(([labelKey, labelValue]) => {
    filterLabels.forEach((filterLabelString) => {
      if (filterLabelString === labelKey + "=" + labelValue) {
        match = true;
      }
    });
  });

  if (match || filterLabels.length === 0 || filterLabels[0] === "") {
    return true;
  }

  return false; // No labels matched
}

function machineName(machine: Machine): string {
  if (machine.hostname.length > 0) {
    return machine.hostname;
  } else {
    return machine.labels["kube:node:name"] || "unknown";
  }
}

export function MachineTable(props: {
  machines: Machine[] | null;
  filterLabels: Array<string>;
  groupingLabel: string;
}) {
  // Group machines by the desired label
  const desiredLabelGroup = props.groupingLabel;
  const groupedMachines: { [group: string]: Machine[] } = {};

  props.machines
    ?.filter((machine: Machine) => hasLabels(machine, props.filterLabels))
    .forEach((machine: Machine) => {
      const group = machine.labels[desiredLabelGroup] || "Ungrouped";
      if (!groupedMachines[group]) {
        groupedMachines[group] = [];
      }
      groupedMachines[group].push(machine);
    });

  return (
    <Container sx={{ verticalAlign: "top" }} disableGutters>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Machine Hostname</TableCell>
            <TableCell>Workloads</TableCell>
            <TableCell>Labels</TableCell>
            <TableCell>Agent Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedMachines)
            .sort(([groupA], [groupB]) => {
              if (groupA === "Ungrouped") return 1; // "Ungrouped" should be placed at the end
              if (groupB === "Ungrouped") return -1; // "Ungrouped" should be placed at the end
              return groupA.localeCompare(groupB); // Sort alphabetically for other groups
            })
            .map(([group, machines]) => (
              <React.Fragment key={group}>
                {/* Add row for grouping if not an empty string */}
                {props.groupingLabel && (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      colSpan={4}
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        background: "#f9f9f9",
                        padding: "2px 12px",
                      }}
                    >
                      {group}
                    </TableCell>
                  </TableRow>
                )}

                {machines
                  .sort((a, b) => (a.agentAlive > b.agentAlive ? -1 : 1))
                  .map((machine: Machine) => (
                    <React.Fragment key={machine.id}>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            paddingLeft: props.groupingLabel ? "30px" : "20px",
                          }}
                        >
                          <Link component={NavLink} to={"/machines/" + machine.id}>
                            {machineName(machine)}
                          </Link>
                        </TableCell>
                        <TableCell>{machine.workloads}</TableCell>
                        <TableCell sx={{ maxWidth: "500px" }}>
                          <LabelDisplay labels={machine.labels} truncate={2}></LabelDisplay>
                        </TableCell>
                        <TableCell>
                          {machine.agentAlive ? (
                            <Box sx={{ display: "flex" }}>
                              <CloudQueue fontSize="small" sx={{ marginRight: "7px" }} />
                              Connected
                            </Box>
                          ) : (
                            <Box sx={{ display: "flex" }}>
                              <CloudOff color="warning" fontSize="small" sx={{ marginRight: "7px" }} />
                              Disconnected
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
          {props.machines?.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "550px",
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Typography variant="h6" gutterBottom sx={{ display: "block" }}>
                    You don't have any machines
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ display: "block", marginBottom: "10px" }}>
                    Newly added machines can take a few seconds to appear
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: "10px", marginBottom: "20px" }}
                    onClick={() => {
                      window.open("https://edgebit.io/docs/0.x/install-agent/", "_blank");
                    }}
                  >
                    Linux Install Documentation
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      window.open("https://edgebit.io/docs/0.x/install-kubernetes/", "_blank");
                    }}
                  >
                    Kubernetes Install Documentation
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}
