import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { EdgeBitPrimaryButton } from "../components/EdgeBitPrimaryButton";
import { GlobalError } from "../components/GlobalError";
import { OverviewBox } from "../components/OverviewBox";
import useInterval from "../hooks/useInterval";
import useProjectId from "../hooks/useProjectId";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";

export const Onboarding = () => {
  const client = useClient(EdgeBitPublicAPIService);

  const [deployToken, setDeployToken] = useState<string | null>(null);

  const [currentProjectID, setCurrentProjectID] = useState<string | undefined>();

  const [connectivity, setConnectivity] = useState<boolean>(true);

  const [machineCount, setMachineCount] = useState<number>(0);

  const [pollDelay] = useState<number>(3000);

  const [isPlaying, setPlaying] = useState<boolean>(false);

  const [showToken, setShowToken] = useState<boolean>(false);

  useProjectId((projectId) => {
    setCurrentProjectID(projectId);
  });

  // Poll for new machines
  const navigate = useNavigate();
  useInterval(
    () => {
      client.listMachines({ projectId: currentProjectID }).then(
        (res) => {
          var lastMachine = res.machines
            .sort((a, b) => (a.lastUpdated && b.lastUpdated && b.lastUpdated > a.lastUpdated ? 1 : 0))
            .at(-1);

          if (lastMachine && machineCount && machineCount < res.machines.length) {
            setPlaying(false);
            navigate(`/machines/` + lastMachine?.id);
          }
          setMachineCount(res.machines.length);
        },
        (err) => {
          console.log(err);
          setConnectivity(false);
        },
      );
    },
    // Delay in milliseconds or null to stop it
    isPlaying ? pollDelay : null,
  );

  // Generate and show token
  const handleShowToken = () => {
    // Get new deploy token
    client.generateAgentDeployToken({ projectId: currentProjectID }).then(
      (res) => {
        setDeployToken(res.deployToken);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
    setShowToken(true);
    setPlaying(true);
  };

  useEffect(() => {
    document.title = "Onboarding - EdgeBit";
  }, []);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Typography variant="h4" gutterBottom>
        Start Mapping Your Supply Chain
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <OverviewBox title="Start from a build:" subtitle="Configure your pipeline to upload an SBOM" align="center">
            <Box sx={{ textAlign: "center" }}>
              <img
                src={"github-action-status.png"}
                style={{
                  maxHeight: "130px",
                  maxWidth: "100%",
                  margin: "15px auto",
                  display: "block",
                  boxShadow: "1px 1px 8px rgb(0 0 0 / 10%)",
                  border: "1px solid #ddd",
                }}
                alt=""
              />
              <EdgeBitPrimaryButton
                type="submit"
                variant="outlined"
                size="medium"
                sx={{ marginTop: "20px", marginBottom: "0px" }}
                onClick={() => {
                  window.open("https://edgebit.io/docs/0.x/install-build/", "_blank");
                }}
              >
                Build Pipeline Documentation
              </EdgeBitPrimaryButton>
            </Box>
          </OverviewBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <OverviewBox title="Start from server:" subtitle="Build a realtime SBOM from any Linux server" align="center">
            {showToken ? (
              <Box sx={{ textAlign: "center" }}>
                <Box component="p" sx={{ marginTop: "20px", fontSize: "16px" }}>
                  Run this to install on Linux:
                </Box>
                <Box
                  component="pre"
                  sx={{
                    whiteSpace: "pre-line",
                    fontSize: "11px",
                    maxWidth: "370px",
                    textAlign: "left",
                    margin: "20px auto 0 auto",
                    fontWeight: "600",
                  }}
                >
                  export EDGEBIT_ID={deployToken}
                  <br />
                  export EDGEBIT_URL=https://{window.location.hostname}
                  <br />
                  curl https://install.edgebit.io | sudo -E sh
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LinearProgress
                    sx={{
                      width: "50px",
                      marginTop: "3px",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Waiting for measurements
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    margin: "0 auto",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    maxWidth: "270px",
                    alignContent: "center",
                    flexWrap: "unset",
                  }}
                >
                  <EdgeBitPrimaryButton
                    type="submit"
                    variant="outlined"
                    size="medium"
                    sx={{ marginTop: "20px", marginBottom: "0px" }}
                    onClick={() => {
                      window.open("https://edgebit.io/docs/0.x/install-agent/", "_blank");
                    }}
                  >
                    Install Agent Documentation
                  </EdgeBitPrimaryButton>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: "10px", marginBottom: "10px" }}
                    href={"https://edgebit.io/docs/0.x/install-agent/#installing-with-a-package-manager"}
                    target="_blank"
                  >
                    Install via RPM/DEB documentation
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={"machine-sbom.png"}
                  style={{
                    maxHeight: "130px",
                    maxWidth: "100%",
                    margin: "15px auto",
                    display: "block",
                    boxShadow: "1px 1px 8px rgb(0 0 0 / 10%)",
                    border: "1px solid #ddd",
                  }}
                  alt=""
                />
                <EdgeBitPrimaryButton
                  type="submit"
                  variant="outlined"
                  size="medium"
                  sx={{ marginTop: "20px", marginBottom: "0px" }}
                  onClick={handleShowToken}
                >
                  Get Started
                </EdgeBitPrimaryButton>
              </Box>
            )}
          </OverviewBox>
        </Grid>
      </Grid>
    </BodyWrapperProjectScoped>
  );
};
