import { ImageExternalIDType } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { ExternalImageID } from "../features/sbomDetailSlice";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { PackageType } from "./PackageType";

function getOCIImageID(ids: ExternalImageID[] | string | undefined): string | undefined {
  if (typeof ids === "string") {
    return ids;
  }
  if (!ids) {
    return undefined;
  }

  for (const id of ids) {
    if (id.type === ImageExternalIDType.IMAGE_EXTERNAL_ID_TYPE_OCI_DOCKER_ID) {
      return id.id;
    }
  }
  return undefined;
}

export function ContainerArtifactInfo(props: { imageIds: ExternalImageID[] | string | undefined; imageName: string }) {
  return (
    <>
      <Box sx={{ display: "inline-flex" }}>
        {/*
            If there is no image ID or image tag, this SBOM doesn't have a relationship
            to any specific artifact, so skip displaying the container icon.

            In the future, we may want to have some distinction between the SBOM type
            (source, build, analyzed, etc) and artifact type (library, package, container,
            etc), so for now we're not going to attempt to eg designate this a source-based
            SBOM just because it doesn't have an image ID (eg, it should be possible to
            associate a source-based SBOM with a container image artifact).
        */}
        {(props.imageIds || props.imageName) && <PackageType type={"container"} name={false} />}
        <Box sx={{ display: "inline" }}>
          {props.imageName}
          {getOCIImageID(props.imageIds) ? (
            <Box sx={{ fontSize: "10px", color: "#999" }}>{getOCIImageID(props.imageIds)}</Box>
          ) : (
            <Box sx={{ fontSize: "14px", color: "#999" }}>No image ID provided</Box>
          )}
        </Box>
      </Box>
      {props.imageIds?.length === 0 && (
        <Alert severity="warning" sx={{ marginTop: "10px" }}>
          Provide an image ID and/or repo digest at SBOM upload time to associate SBOMs with running containers.
        </Alert>
      )}
    </>
  );
}
