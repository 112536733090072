import { Typography, TableContainer, Paper, Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useClient } from "../api/client";
import { BodyWrapperProjectScoped } from "../components/BodyWrapperProjectScoped";
import { GlobalError } from "../components/GlobalError";
import { WorkloadTable } from "../components/WorkloadTable";
import { WorkloadFilter } from "../components/WorkloadFilter";
import useProjectId from "../hooks/useProjectId";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { WorkloadItem } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";

export const WorkloadList = () => {
  const client = useClient(EdgeBitPublicAPIService);
  const [workloadList, setWorkloadList] = useState<any | null>(null);
  const [connectivity, setConnectivity] = useState<boolean>(true);
  const [filterLabels, setFilterLabels] = useState(new Array<string>());
  const [workloadGroupLabel, setWorkloadGroupLabel] = useState<string>("container-name");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  // Table Filter
  const handleFilterToggled = (newLabelPair: string) => {
    const desiredFilter = Array.from(filterLabels);

    const index = desiredFilter.indexOf(newLabelPair);
    if (index !== -1) {
      desiredFilter.splice(index, 1);
    } else {
      desiredFilter.push(newLabelPair);
    }

    setFilterLabels(desiredFilter);
  };

  // Table Grouping
  const handleGroupingChange = (event: SelectChangeEvent) => {
    setWorkloadGroupLabel(event.target.value);
  };

  // Iterate over the WorkloadItem objects and collect unique labels
  const uniqueLabels: { [key: string]: string[] } = {};
  if (workloadList) {
    workloadList.forEach((workloadItem: WorkloadItem) => {
      const labels = workloadItem.labels;
      Object.entries(labels).forEach(([labelKey, labelValue]) => {
        if (!uniqueLabels[labelKey]) {
          uniqueLabels[labelKey] = [];
        }
        if (!uniqueLabels[labelKey].includes(labelValue)) {
          uniqueLabels[labelKey].push(labelValue);
        }
      });
    });
  }

  useProjectId((projectId) => {
    client.listWorkloads({ projectId: projectId }).then(
      (res) => {
        setWorkloadList(res.workloads);
      },
      (err) => {
        console.log(err);
        setConnectivity(false);
      },
    );
  });

  // Set title and read URL params
  useEffect(() => {
    document.title = "Workloads - EdgeBit";

    // Read URL parameters and deserialize
    var readFilterParams = searchParams.get("labels")?.split(",") || [];
    setFilterLabels(readFilterParams);
    var readGroupParams = searchParams.get("group");
    if (readGroupParams) setWorkloadGroupLabel(readGroupParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Navigate to new search location when changed
  // If state is empty, remove it for cleaner URLs
  useEffect(() => {
    const updatedParams = createSearchParams(searchParams);

    if (filterLabels.length > 0 && filterLabels[0] !== "") {
      updatedParams.set("labels", filterLabels.toString());
    } else {
      updatedParams.delete("labels");
    }

    if (workloadGroupLabel) {
      updatedParams.set("group", workloadGroupLabel);
    } else {
      updatedParams.delete("group");
    }

    navigate({ search: updatedParams.toString() }, { replace: true });
  }, [filterLabels, workloadGroupLabel, navigate, searchParams]);

  return (
    <BodyWrapperProjectScoped>
      {!connectivity && <GlobalError message="Error communicating with backend" />}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom>
            Workloads
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "0px" }}>
            Workloads consist of container images and base operating systems used across your infrastructure.
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right", alignSelf: "end" }}>
          <FormControl sx={{ marginTop: "20px", minWidth: 120, verticalAlign: "bottom" }} size="small">
            <InputLabel id="demo-select-small-label">Group by:</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={workloadGroupLabel}
              label="Group by:"
              onChange={handleGroupingChange}
            >
              {uniqueLabels &&
                Object.keys(uniqueLabels)
                  .sort()
                  .map((label) => (
                    <MenuItem key={label} value={label}>
                      {label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ display: "table", marginTop: "20px", border: "1px solid #ddd" }}>
        <WorkloadFilter
          workloads={workloadList}
          handleFilterToggled={handleFilterToggled}
          filterLabels={filterLabels}
        ></WorkloadFilter>
        <WorkloadTable
          workloads={workloadList}
          filterLabels={filterLabels}
          groupingLabel={workloadGroupLabel}
        ></WorkloadTable>
      </TableContainer>
    </BodyWrapperProjectScoped>
  );
};
