import { Timestamp } from "@bufbuild/protobuf";
import Box from "@mui/material/Box";

export default function RelativeTimestamp(props: { timestamp?: Timestamp | string; defaultText?: string }) {
  let defaultText = props.defaultText ? props.defaultText : "Never";

  if (!props.timestamp) {
    return <>{defaultText}</>;
  } else {
    let now = Number(new Date().getTime());
    let secondsPast;

    if (props.timestamp instanceof Timestamp) {
      secondsPast = (now - Number(props.timestamp.toDate())) / 1000;
    } else {
      secondsPast = (now - Number(new Date(props.timestamp))) / 1000;
    }

    let output = 0;
    let outputUnit = "";

    if (Math.abs(secondsPast) < 60) {
      output = secondsPast;
      outputUnit = "second";
    } else if (Math.abs(secondsPast) < 60 * 60) {
      output = secondsPast / 60;
      outputUnit = "minute";
    } else if (Math.abs(secondsPast) <= 60 * 60 * 24) {
      output = secondsPast / (60 * 60);
      outputUnit = "hour";
    } else if (Math.abs(secondsPast) <= 60 * 60 * 24 * 7) {
      output = secondsPast / (60 * 60 * 24);
      outputUnit = "day";
    } else if (Math.abs(secondsPast) <= 60 * 60 * 24 * 90) {
      // intentionally measure under 90 days in weeks
      output = secondsPast / (60 * 60 * 24 * 7);
      outputUnit = "week";
    } else if (Math.abs(secondsPast) <= 60 * 60 * 24 * 365) {
      output = secondsPast / (60 * 60 * 24 * 31);
      outputUnit = "month";
    } else if (Math.abs(secondsPast) > 60 * 60 * 24 * 365) {
      output = secondsPast / (60 * 60 * 24 * 365);
      outputUnit = "year";
    }

    // pluralize if needed
    if (Math.abs(parseInt(output.toString())) > 1) {
      outputUnit += "s";
    }

    // if overdue, indicated as a negative value, make it positive so it reads correctly
    if (output < 1) {
      return (
        <Box component="span">
          Due in {parseInt((output * -1).toString())} {outputUnit}
        </Box>
      );
    } else {
      return (
        <Box component="span" sx={{ backgroundColor: "#F7E7E5", color: "#DB0000" }}>
          Overdue by {parseInt(output.toString())} {outputUnit}
        </Box>
      );
    }
  }
}
