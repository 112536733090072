import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { useState, useContext } from "react";
import FormattedTimestamp from "./FormattedTimestamp";
import SettingsIcon from "@mui/icons-material/Settings";
import { ComponentTags } from "../components/ComponentTags";
import { ComponentActive } from "../components/ComponentActive";
import { EdgeBitPublicAPIService } from "../pb/edgebit/platform/v1alpha/platform_connectweb";
import { useClient } from "../api/client";
import LinearProgress from "@mui/material/LinearProgress";
import { downloadFile } from "../api/files";
import { ProjectContext } from "../App";
import { Box } from "@mui/material";
import { ComponentEnrichedSBOMSummary, GetComponentTagsOverviewResponse } from "../features/componentDetailSlice";

interface ComponentUsageSBOMTableProps {
  tagsOverview: GetComponentTagsOverviewResponse | null;
  handleTagModal: (id: string | undefined, tags: string | undefined) => void;
  refreshSBOMs: () => void;
}

export function ComponentUsageSBOMTable(props: ComponentUsageSBOMTableProps) {
  // API client
  const client = useClient(EdgeBitPublicAPIService);
  const project = useContext(ProjectContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleModifyTags = () => {
    props.handleTagModal(anchorEl?.dataset.sbomid, anchorEl?.dataset.sbomtags);
    setAnchorEl(null);
  };

  const handleSBOMDownload = (sbomId: string | undefined) => {
    if (!project) {
      return;
    }
    var windowUrl = "";
    client.getSBOMDownloadUrl({ projectId: project.id, sbomId: sbomId }).then(
      (res) => {
        windowUrl = res.sbomDownloadPath;
        downloadFile(windowUrl);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  if (!props.tagsOverview) {
    return (
      <Container sx={{ mt: 3 }} disableGutters>
        <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "30px" }}></TableCell>
                <TableCell sx={{ width: "20%" }}>SBOM ID</TableCell>
                <TableCell sx={{ width: "auto" }}>Tags</TableCell>
                <TableCell sx={{ width: "22%" }}>Active Workloads</TableCell>
                <TableCell sx={{ width: "33%" }}>Creation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="empty-latest">
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "400px",
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Typography variant="h6" gutterBottom sx={{ display: "block", width: "400px" }}>
                      Loading Tags...
                    </Typography>
                    <LinearProgress
                      sx={{
                        width: "200px",
                        marginTop: "3px",
                        marginRight: "auto",
                        marginBottom: "20px",
                        marginLeft: "auto",
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 3 }} disableGutters>
      <Menu
        id="sbom-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "sbom-button",
        }}
      >
        <MenuItem onClick={handleModifyTags}>Modify Tags</MenuItem>
        {props.tagsOverview && props.tagsOverview.defaultSbom ? (
          <MenuItem onClick={() => handleSBOMDownload(props.tagsOverview?.defaultSbom?.sbom?.id)}>
            Download SBOM
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleSBOMDownload(anchorEl?.dataset.sbomid)}>Download SBOM</MenuItem>
        )}
      </Menu>
      <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "30px" }}></TableCell>
              <TableCell sx={{ width: "20%" }}>SBOM ID</TableCell>
              <TableCell sx={{ width: "auto" }}>Tags</TableCell>
              <TableCell sx={{ width: "22%" }}>Active Workloads</TableCell>
              <TableCell sx={{ width: "33%" }}>Creation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tagsOverview?.defaultSbom && props.tagsOverview.defaultSbom.sbom ? (
              <ActiveSBOMRow
                summary={props.tagsOverview.defaultSbom}
                handleMenuClick={handleClick}
                menuOpen={anchorEl?.dataset.sbomid === props.tagsOverview.defaultSbom.sbom?.id}
                defaultTagName={props.tagsOverview.defaultTagName}
              />
            ) : (
              <TableRow key="empty-latest">
                <TableCell colSpan={5} sx={{ color: "#666", borderBottom: "0px" }}>
                  <Typography variant="body1" sx={{ fontSize: "14px", color: "#999", lineHeight: "50px" }}>
                    No SBOMs tagged '{props.tagsOverview.selectedTagName}' yet
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.tagsOverview.otherActiveSboms &&
      props.tagsOverview.otherActiveSboms.length > 0 &&
      props.tagsOverview.retiredSbomCount >= 0 ? (
        <>
          <Typography component={"span"} variant="body1" sx={{ fontSize: "14px", color: "#999", lineHeight: "50px" }}>
            {props.tagsOverview.undeployedSbomCount} undeployed builds collapsed
          </Typography>
          <TableContainer component={Paper} sx={{ border: "1px solid #ddd" }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableBody>
                {props.tagsOverview.otherActiveSboms.length > 0 ? (
                  props.tagsOverview.otherActiveSboms.map((summary: ComponentEnrichedSBOMSummary) => (
                    <ActiveSBOMRow
                      key={summary.sbom?.id}
                      summary={summary}
                      handleMenuClick={handleClick}
                      menuOpen={anchorEl?.dataset.sbomid === summary.sbom?.id}
                      defaultTagName={props.tagsOverview?.defaultTagName}
                    />
                  ))
                ) : (
                  <TableRow key="empty-active">
                    <TableCell colSpan={5} sx={{ color: "#666", borderBottom: "0px" }}>
                      <Typography
                        component={"span"}
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          color: "#999",
                          lineHeight: "50px",
                        }}
                      >
                        No SBOMs with active workloads found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <></>
      )}
      {props.tagsOverview.retiredSbomCount === 0 ? (
        <></>
      ) : (
        <Typography component={"span"} variant="body1" sx={{ fontSize: "14px", color: "#999", lineHeight: "50px" }}>
          {props.tagsOverview.retiredSbomCount} retired {props.tagsOverview.retiredSbomCount === 1 ? "build" : "builds"}{" "}
          collapsed
        </Typography>
      )}
    </Container>
  );
}

interface ActiveSBOMRowProps {
  summary: ComponentEnrichedSBOMSummary;
  handleMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  menuOpen: boolean;
  defaultTagName: string | undefined;
}

function ActiveSBOMRow(props: ActiveSBOMRowProps) {
  if (props.summary.sbom === undefined) {
    return <></>;
  }

  const sbomId = props.summary.sbom.id;
  const sbomTags = props.summary.tags;
  const shortId = sbomId.substring(0, 6);
  const defaultTag = props.defaultTagName ? props.defaultTagName : "latest";
  const sbomDisplayName = props.summary.sbom.machineId ? `Realtime ${shortId}` : `Build ${shortId}`;

  return (
    <TableRow key={sbomId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row" sx={{ width: "30px" }}>
        <Button
          id="sbom-button"
          aria-controls="sbom-menu"
          aria-haspopup="true"
          aria-expanded={props.menuOpen}
          onClick={props.handleMenuClick}
          sx={{ minWidth: "auto" }}
          data-sbomid={sbomId}
          data-sbomtags={sbomTags}
        >
          <SettingsIcon sx={{ verticalAlign: "middle", fontSize: "18px", color: "#666" }}></SettingsIcon>
        </Button>
      </TableCell>
      <TableCell sx={{ width: "20%" }}>
        <Link component={NavLink} to={"/sboms/" + sbomId}>
          {sbomDisplayName}
        </Link>
      </TableCell>
      <TableCell sx={{ width: "auto" }}>
        <ComponentTags tags={props.summary.tags} defaultTag={defaultTag} />
      </TableCell>
      <TableCell sx={{ width: "22%" }}>
        <ComponentActive workloads={props.summary.activeWorkloads} />
      </TableCell>
      <TableCell sx={{ width: "33%" }}>
        <FormattedTimestamp timestamp={props.summary.sbom.createdAt} />
      </TableCell>
    </TableRow>
  );
}
