import { Code } from "@bufbuild/connect";
import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUnauthenticatedClient } from "../api/client";
import { AuthContext } from "../components/AuthContextProvider";
import { BodyWrapper } from "../components/BodyWrapper";
import { LoginService } from "../pb/edgebit/platform/v1alpha/login_connectweb";

export const OIDCCallback = () => {
  const { transport, setAuthToken } = useContext(AuthContext);

  const client = useUnauthenticatedClient(LoginService);
  const navigate = useNavigate();
  const [next, setNext] = useState("/");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    client
      .oIDCCallback({
        queryString: window.location.search,
      })
      .then(
        (res) => {
          setNext(res.next);
          setAuthToken(res.token);
        },
        (err) => {
          if (err.code === Code.InvalidArgument) {
            setError(err.rawMessage);
          } else {
            setError("Unknown error");
          }
        },
      );
  }, [setAuthToken, client]);

  useEffect(() => {
    if (transport) {
      navigate(next);
    }
  }, [transport, next, navigate]);

  useEffect(() => {
    document.title = "Logging in...";
  }, []);

  return (
    <BodyWrapper>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "99",
          backgroundColor: "#132243",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" sx={{ height: 40, marginBottom: "30px" }} src="/logo.png" alt="EdgeBit" />
        {error ? (
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Error: {error}
          </Typography>
        ) : (
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Logging in...
          </Typography>
        )}
      </Box>
    </BodyWrapper>
  );
};
