import Box from "@mui/material/Box";
import { SourceRepo } from "../features/repoDetailSlice";

export const CodeRepo = (
  props: { owner: string; name: string; description?: string } | { repo: SourceRepo | undefined },
) => {
  let owner: string;
  let name: string;
  let description: string | undefined;

  if ("repo" in props) {
    switch (props.repo?.details.case) {
      case "githubRepoDetails":
        owner = props.repo.details.value.owner;
        name = props.repo.details.value.repo;
        break;
      default:
        owner = "Unknown";
        name = "Unknown";
    }
  } else {
    owner = props.owner;
    name = props.name;
    description = props.description;
  }

  return (
    <>
      <Box sx={{ fontWeight: "500", display: "inline-block" }}>
        <Box
          component="img"
          sx={{
            display: "inline-block",
            borderRadius: "3px",
            width: "20px",
            height: "20px",
            background: "#f5f5f5",
            verticalAlign: "middle",
            marginRight: "5px",
          }}
          src={"https://avatars.githubusercontent.com/" + owner}
        />
        {owner + "/" + name}
      </Box>
      {description && <Box sx={{ fontSize: "11px", marginTop: "3px" }}>{description}</Box>}
    </>
  );
};
