import { ComponentIssue } from "../features/componentDetailSlice";

export function getJiraIssueID(issue: ComponentIssue) {
  const issueKeyLabel = Object.keys(issue.labels).find((key) => key.startsWith("edgebit.io/jira/issue-key"));
  if (issueKeyLabel) {
    return issue.labels[issueKeyLabel];
  }
  return "";
}

export function getJiraIssueLink(hostname: string, issueID: string) {
  return `https://${hostname}.atlassian.net/browse/${issueID}`;
}
