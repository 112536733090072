/**
 * sanitizeNextPath sanitizes the next path to redirect to after login,
 * defaulting to '/' if the path is empty or invalid.
 *
 * To be valid a path must:
 * - be an absolute path, starting with '/', eg '/foo/bar'
 * - not result in a redirect loop, eg '/login' or '/logout'
 * - not be a full URL, eg 'https://example.com/foo/bar'
 * - not send the user to a different host eg using protocol-relative URLs, eg '//example.com/foo/bar'
 *
 * @param next - A path to redirect to after login.
 * @returns a sanitized path to redirect to after login.
 */
export function sanitizeNextPath(next: string) {
  // If next empty, doesn't start with '/', or is '/login' or '/logout', default to '/'.
  if (!next || !next.startsWith("/") || next === "/login" || next === "/logout") {
    return "/";
  }

  // In order to guard against things like protocol-relative URLs disguised as a path,
  // parse it as a URL and check that the host matches the current host.
  try {
    const parsed = new URL(next, window.location.origin);
    if (parsed.host !== window.location.host) {
      return "/";
    }

    return next;
  } catch (e) {
    return "/";
  }
}

export function appReflector() {
  const proto = window.location.protocol;
  var host = window.location.hostname.split(".");
  const port = window.location.port;
  // replace the org with "app"
  host[0] = "app";
  return `${proto}//${host.join(".")}:${port}`;
}

export function signupReflector() {
  const proto = window.location.protocol;
  var host = window.location.hostname.split(".");
  const port = window.location.port;
  // replace the org with "signup"
  host[0] = "signup";
  return `${proto}//${host.join(".")}:${port}`;
}
