import Box from "@mui/material/Box";
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar } from "recharts";
import VulnChip from "./VulnChip";
import { ComponentIssueTrendItem } from "../features/componentDetailSlice";

export function VulnHistory(props: { type: string; trend: ComponentIssueTrendItem[] | null }) {
  var style;
  var legend = false;

  // Reformat data
  const trend = props.trend?.map((item) => {
    const date = item.date ? new Date(item.date) : new Date();

    return {
      name: new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
      }).format(date),

      critical: item.criticalVulns,
      high: item.highVulns,
      medium: item.mediumVulns,
      low: item.lowVulns,
      negligible: item.negligibleVulns,
    };
  });

  if (props.type === "preview") {
    style = {
      width: "100%",
      maxWidth: "400px",
      height: "180px",
      maxHeight: "auto",
      paddingTop: "20px",
      position: "relative",
    };
  } else if (props.type === "full") {
    style = {
      width: "100%",
      height: "250px",
      paddingTop: "20px",
      position: "relative",
    };
    legend = true;
  } else {
    style = {
      width: "100%",
      maxWidth: "400px",
      height: "200px",
      maxHeight: "auto",
      position: "relative",
    };
  }

  const renderLegend = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <VulnChip preset="critical" innerText="Critical" />
          <VulnChip preset="high" innerText="High" />
          <VulnChip preset="medium" innerText="Medium" />
          <VulnChip preset="low" innerText="Low" />
          <VulnChip preset="negligible" innerText="Negligible" />
        </div>
      </>
    );
  };

  return (
    <Box sx={style}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={200}
          data={trend}
          margin={{
            top: 0,
            right: 0,
            left: -20,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: "12px" }} />
          <YAxis tick={{ fontSize: "13px" }} />
          <Tooltip
            wrapperStyle={{
              lineHeight: "13px",
              borderColor: "#6096FF",
              boxShadow: "1px 1px 6px rgba(0,0,0,.25)",
            }}
            labelStyle={{ fontWeight: "600" }}
            itemStyle={{
              textTransform: "capitalize",
              fontWeight: "500",
              fontSize: "14px",
            }}
            cursor={{ fill: "#F1F4FD", stroke: "#6096FF", strokeWidth: "2" }}
            formatter={function (value, name) {
              return `${value}`;
            }}
            labelFormatter={function (value) {
              return `${value}`;
            }}
            itemSorter={(item) => {
              return item.name as string;
            }}
            offset={20}
          />
          {legend && <Legend content={renderLegend} />}
          <Bar dataKey="negligible" stackId="a" fill="#4FD35D" isAnimationActive={false} barSize={10} />
          <Bar dataKey="low" stackId="a" fill="#4FD35D" isAnimationActive={false} barSize={10} />
          <Bar dataKey="medium" stackId="a" fill="#F6D046" isAnimationActive={false} barSize={10} />
          <Bar dataKey="high" stackId="a" fill="#FF7A00" isAnimationActive={false} barSize={10} />
          <Bar dataKey="critical" stackId="a" fill="#FF0000" isAnimationActive={false} barSize={10} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
