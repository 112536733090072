import { VulnFixState, Vulnerability } from "../pb/edgebit/platform/v1alpha/platform_pb";
import { SBOMInventoryVuln } from "../features/sbomDetailSlice";
import { VulnIssueDetails } from "../features/componentDetailSlice";

export default function getStateText(vuln: SBOMInventoryVuln | Vulnerability | VulnIssueDetails) {
  switch (vuln.fixState) {
    case VulnFixState.UNSPECIFIED:
      return "Unreported fix state";
    case VulnFixState.NOTFIXED:
      return "Not fixed yet";
    case VulnFixState.WONTFIX:
      // This is really "Maintainer won't fix" but that string is confusing to users
      return "Not fixed yet";
    case VulnFixState.FIXED:
      if (vuln.fixVersions) {
        return "Fixed, upgrade to " + vuln.fixVersions.toString();
      } else {
        return "Fixed, upgrade required";
      }
  }
}
