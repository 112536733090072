import { Timestamp } from "@bufbuild/protobuf";

export default function FormattedTimestamp(props: { timestamp?: Timestamp | string; defaultText?: string }) {
  let defaultText = props.defaultText ? props.defaultText : "Never";

  if (!props.timestamp) {
    return <>{defaultText}</>;
  } else {
    return (
      <>
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(timestampToDate(props.timestamp))}
      </>
    );
  }
}

function timestampToDate(timestamp: Timestamp | string): Date {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  }

  return new Date(timestamp);
}
