import CloudOffIcon from "@mui/icons-material/CloudOff";
import { Chip } from "@mui/material";

export function GlobalError(props: { children?: any; message: string; fixed?: boolean }) {
  return (
    <Chip
      color="error"
      icon={<CloudOffIcon />}
      label={props.message}
      sx={{
        position: props.fixed ? "fixed" : "absolute",
        left: "49%",
        top: "30px",
        zIndex: 1000,
      }}
    />
  );
}
